/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
*  🤖  Generated by generator/codegen.schema-as-ts-module.ts
*  📅  Generated on 4/2/2025 at 11:25:22 PM
*  ⚠️  Do not modify this file directly. 
*     run "yarn workspace @attentive/mock-data generate" to update
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/


export const schema = `
# Directs the executor to defer this fragment when the \`if\` argument is true.
directive @defer(
  # Deferred when true.
  if: Boolean! = true

  # A label to attach to the deferred result.
  label: String
) on FRAGMENT_SPREAD | INLINE_FRAGMENT

# This input may only have one of its fields set.
directive @oneOf on INPUT_OBJECT

enum AIBrandVoiceAllCapsUsage {
  ALL_CAPS_USAGE_ADAPTIVE
  ALL_CAPS_USAGE_FREQUENTLY
  ALL_CAPS_USAGE_NEVER
  ALL_CAPS_USAGE_SPARINGLY
}

enum AIBrandVoiceEmojiFrequency {
  EMOJI_FREQUENCY_ADAPTIVE
  EMOJI_FREQUENCY_FREQUENTLY
  EMOJI_FREQUENCY_NEVER
  EMOJI_FREQUENCY_SPARINGLY
}

enum AIBrandVoiceProductTitleFormatting {
  PRODUCT_TITLE_FORMATTING_LOWER_CASE
  PRODUCT_TITLE_FORMATTING_TITLE_CASE
  PRODUCT_TITLE_FORMATTING_UPPER_CASE
}

# Generated from com/attentivemobile/contracts/messaging/automation/brandvoice/AIBrandVoice.proto
type AIBrandVoiceSettings {
  allCapsCount: Int
  allCapsUsage: AIBrandVoiceAllCapsUsage
  characterCount: Int
  company: Company
  emojiCount: Int
  emojiFrequency: AIBrandVoiceEmojiFrequency!
  exclusionWords: [String!]!
  id: String!
  productTitleFormatting: AIBrandVoiceProductTitleFormatting
  toneKeywords: [String!]!
  urgency: AIBrandVoiceUrgency
  wordCount: Int
}

input AIBrandVoiceSettingsInput {
  allCapsUsage: AIBrandVoiceAllCapsUsage!
  emojiFrequency: AIBrandVoiceEmojiFrequency!
  exclusionWords: [String!]
  productTitleFormatting: AIBrandVoiceProductTitleFormatting!
  toneKeywords: [String!]
  urgency: AIBrandVoiceUrgency!
}

input AIBrandVoiceSettingsInputType {
  allCapsCount: Int
  allCapsUsage: AIBrandVoiceAllCapsUsage
  characterCount: Int
  emojiCount: Int
  emojiFrequency: AIBrandVoiceEmojiFrequency
  exclusionWords: [String!]
  productTitleFormatting: AIBrandVoiceProductTitleFormatting
  toneKeywords: [String!]
  urgency: AIBrandVoiceUrgency
  wordCount: Int
}

input AIBrandVoiceSettingsUpdateInput {
  allCapsUsage: AIBrandVoiceAllCapsUsage!
  emojiFrequency: AIBrandVoiceEmojiFrequency!
  exclusionWords: [String!]
  id: String!
  productTitleFormatting: AIBrandVoiceProductTitleFormatting!
  toneKeywords: [String!]
  urgency: AIBrandVoiceUrgency!
}

input AIBrandVoiceSettingsUpdateV2Input {
  allCapsUsage: AIBrandVoiceAllCapsUsage!
  emojiFrequency: AIBrandVoiceEmojiFrequency!
  exclusionWords: [String!]
  id: String!
  productTitleFormatting: AIBrandVoiceProductTitleFormatting!
  toneKeywords: [String!]
  urgency: AIBrandVoiceUrgency!
}

# Generated from com/attentivemobile/contracts/generativeaiservice/brandvoice/AIBrandVoice.proto
type AIBrandVoiceSettingsV2 {
  allCapsCount: Int
  allCapsUsage: AIBrandVoiceAllCapsUsage
  characterCount: Int
  company: Company
  emojiCount: Int
  emojiFrequency: AIBrandVoiceEmojiFrequency!
  exclusionWords: [String!]!
  id: String!
  productTitleFormatting: AIBrandVoiceProductTitleFormatting
  toneKeywords: [String!]!
  urgency: AIBrandVoiceUrgency
  wordCount: Int
}

input AIBrandVoiceSettingsV2Input {
  allCapsUsage: AIBrandVoiceAllCapsUsage!
  emojiFrequency: AIBrandVoiceEmojiFrequency!
  exclusionWords: [String!]
  productTitleFormatting: AIBrandVoiceProductTitleFormatting!
  toneKeywords: [String!]
  urgency: AIBrandVoiceUrgency!
}

enum AIBrandVoiceUrgency {
  URGENCY_ADAPTIVE
  URGENCY_FREQUENTLY
  URGENCY_NEVER
  URGENCY_SPARINGLY
}

# Generated from com/attentivemobile/contracts/generativeaiservice/text/TextGenerationModel.proto
type AIGeneratedText implements Node {
  aiGeneratedTextMetadata: [AiGeneratedTextMetadataEntry!]!
  company: Company
  groupId: String!
  id: ID!
  source: String!
  text: String!
}

# Generated from com/attentivemobile/contracts/generativeaiservice/text/TextGenerationContract.proto
type AIGeneratedTextConnection {
  edges: [AIGeneratedTextEdge!]!
  pageInfo: PageInfo!
  totalCount: Long!
}

# Generated from com/attentivemobile/contracts/generativeaiservice/text/TextGenerationModel.proto
type AIGeneratedTextEdge {
  cursor: String!
  node: AIGeneratedText!
}

enum AIGeneratedTextOrigin {
  GENERATED_TEXT_ORIGIN_AUTOMATED_JOURNEYS
  GENERATED_TEXT_ORIGIN_AUTOMATED_JOURNEYS_CHAIN
  GENERATED_TEXT_ORIGIN_AUTOMATED_WELCOME_JOURNEY
  GENERATED_TEXT_ORIGIN_BRAND_VOICE_PREVIEW
  GENERATED_TEXT_ORIGIN_BRAND_VOICE_SUMMARY
  GENERATED_TEXT_ORIGIN_CAMPAIGN_SUGGESTIONS_HOMEPAGE
  GENERATED_TEXT_ORIGIN_COPY_ASSISTANT_EMAIL_SUBJECT_LINE
  GENERATED_TEXT_ORIGIN_COPY_ASSISTANT_SMS
  GENERATED_TEXT_ORIGIN_UNKNOWN
}

# Generated from com/attentivemobile/contracts/generativeaiservice/image/ImageGenerationContract.proto
type AIImageGenerationOptionsPayload {
  sceneConstructionOptions: [SceneConstructionOption!]!
}

enum AIImageGenerationType {
  AI_IMAGE_GENERATION_TYPE_SCENE_CONSTRUCTION
  AI_IMAGE_GENERATION_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/personalization/service/aijourneys/defaultmessage/AIJourneyDefaultMessage.proto
type AIJourneyDefaultMessage implements Node {
  company: Company
  id: ID!
  messageContent: String!
  messageType: MessageType!
}

# Generated from com/attentivemobile/contracts/messaging/automation/aijourneys/onboarding/AIJourneyLinkConfiguration.proto
type AIJourneyLinkConfiguration {
  linkConfig: AIJourneyLinkConfigurationLinkConfig
  messageType: MessageType!
}

input AIJourneyLinkConfigurationInput {
  linkConfig: AIJourneyLinkConfigurationInputLinkConfig
  messageType: MessageType!
}

input AIJourneyLinkConfigurationInputLinkConfig {
  abandonmentLinkConfigInput: AbandonmentLinkConfigurationInput
  welcomeLinkConfigInput: WelcomeLinkConfigurationInput
}

union AIJourneyLinkConfigurationLinkConfig = AbandonmentLinkConfiguration | WelcomeLinkConfiguration

# Generated from com/attentivemobile/contracts/messaging/automation/aijourneys/onboarding/AIJourneyLinkConfigServiceContract.proto
type AIJourneyTrackingParams {
  aiJourneyCustomTrackingParams: [AiJourneyCustomTrackingParamsEntry!]!
  messageType: MessageType!
  utmCampaign: String!
}

input AIJourneyTrackingParamsInput {
  aiJourneyCustomTrackingParamsInput: [AiJourneyCustomTrackingParamsInputEntry!]
  messageType: MessageType!
  utmCampaign: String!
}

# Generated from com/attentivemobile/contracts/messaging/automation/aijourneys/onboarding/AIJourneyLinkConfiguration.proto
type AbandonmentLinkConfiguration {
  abandonmentLinkConfig: CustomLinkConfig! @deprecated(reason: "deprecated")
  customLink: CustomLinkConfig!
}

input AbandonmentLinkConfigurationInput {
  customLinkInput: CustomLinkConfigInput!
}

# Generated from com/attentivemobile/contracts/account-service/Contract.proto
type Account implements Node {
  assignedCompanies: [AccountAssignedCompany!]
  authenticators: GetAuthenticatorsPayload
  companyAccountPermissions(companyId: ID!): GetAccountPermissionsByCompanyAndAccountPayload
  conciergeAgent: ConciergeAgent
  created: DateTime!
  email: String!
  firstName: String!
  id: ID!
  internalId: Long!
  lastName: String!
  mfaPhoneNumberHint: String
  phone: String!
  recentCompanies(after: String, first: Int): CompanyConnection
  shouldUseSso: Boolean!
}

# Generated from com/attentivemobile/contracts/account-service/Contract.proto
type AccountAssignedCompany {
  company: Company
}

# Generated from com/attentivemobile/contracts/account-service/Contract.proto
type AccountConnection {
  edges: [AccountEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/account-service/Contract.proto
type AccountEdge {
  cursor: String!
  node: Account!
}

input AccountFilter {
  role: Role
  searchTerm: String
}

# Generated from com/attentivemobile/contracts/concierge/service/Feedback.proto
type AccountItem {
  viewedBy: Account
}

# Generated from com/attentivemobile/contracts/account-service/MFAContract.proto
type AccountMfaPreferencesPayload {
  mfaPasscodeDeliveryContactHint: String
  preferredOtpDeliveryMethod: MfaOneTimePasscodeDeliveryMethod
}

enum AccountPermissionDomainName {
  ACCOUNT_PERMISSION_DOMAIN_NAME_ANALYTICS
  ACCOUNT_PERMISSION_DOMAIN_NAME_APPLICATIONS
  ACCOUNT_PERMISSION_DOMAIN_NAME_BRAND_KIT
  ACCOUNT_PERMISSION_DOMAIN_NAME_EMAIL
  ACCOUNT_PERMISSION_DOMAIN_NAME_GROWTH
  ACCOUNT_PERMISSION_DOMAIN_NAME_MESSAGING
  ACCOUNT_PERMISSION_DOMAIN_NAME_OFFERS
  ACCOUNT_PERMISSION_DOMAIN_NAME_SEGMENTS
  ACCOUNT_PERMISSION_DOMAIN_NAME_SUBSCRIPTIONS
  ACCOUNT_PERMISSION_DOMAIN_NAME_TACTICAL
  ACCOUNT_PERMISSION_DOMAIN_NAME_TAGS
}

enum AccountPermissionFeatureName {
  ACCOUNT_PERMISSION_FEATURE_NAME_ANALYTICS_MANAGEMENT
  ACCOUNT_PERMISSION_FEATURE_NAME_API_APPLICATIONS
  ACCOUNT_PERMISSION_FEATURE_NAME_BRAND_KIT_MANAGEMENT
  ACCOUNT_PERMISSION_FEATURE_NAME_CAMPAIGNS
  ACCOUNT_PERMISSION_FEATURE_NAME_CONCIERGE
  ACCOUNT_PERMISSION_FEATURE_NAME_CONVERSATIONS
  ACCOUNT_PERMISSION_FEATURE_NAME_EMAIL_SETTINGS
  ACCOUNT_PERMISSION_FEATURE_NAME_EMAIL_TEMPLATE_MANAGEMENT
  ACCOUNT_PERMISSION_FEATURE_NAME_JOURNEYS
  ACCOUNT_PERMISSION_FEATURE_NAME_KEYWORDS
  ACCOUNT_PERMISSION_FEATURE_NAME_MESSAGE_SERVICE_MANAGEMENT
  ACCOUNT_PERMISSION_FEATURE_NAME_OFFER_MANAGEMENT
  ACCOUNT_PERMISSION_FEATURE_NAME_POLLS
  ACCOUNT_PERMISSION_FEATURE_NAME_SEGMENT_MANAGEMENT
  ACCOUNT_PERMISSION_FEATURE_NAME_SIGNUP_UNITS
  ACCOUNT_PERMISSION_FEATURE_NAME_SUBSCRIBER_BULK_OPT_OUT
  ACCOUNT_PERMISSION_FEATURE_NAME_SUBSCRIBER_MANAGEMENT
  ACCOUNT_PERMISSION_FEATURE_NAME_SUBSCRIBER_UPLOAD
  ACCOUNT_PERMISSION_FEATURE_NAME_TAG_MANAGEMENT
  ACCOUNT_PERMISSION_FEATURE_NAME_VENDOR_INTEGRATIONS
}

# Generated from com/attentivemobile/contracts/account/permission/service/AccountPermissionsService.proto
type AccountPermissions implements Node {
  assignedAccounts: ListAccountsForPermissionsPayload
  company: Company
  createdAt: DateTime!
  description: String!
  featurePermissions: [FeaturePermissions!]!
  id: ID!
  isDefault: Boolean!
  name: String!
  updatedAt: DateTime!
}

# Generated from com/attentivemobile/contracts/account/permission/service/AccountPermissionsService.proto
type AccountPermissionsConnection {
  edges: [AccountPermissionsEdge!]!
  pageInfo: PageInfo!
  totalCount: Int!
}

# Generated from com/attentivemobile/contracts/account/permission/service/AccountPermissionDomainService.proto
type AccountPermissionsDomain implements Node {
  createdAt: DateTime!
  description: String!
  displayName: String!
  features: [DomainFeature!]!
  id: ID!
  name: AccountPermissionDomainName!
  updatedAt: DateTime!
}

# Generated from com/attentivemobile/contracts/account/permission/service/AccountPermissionsService.proto
type AccountPermissionsEdge {
  cursor: String!
  node: AccountPermissions!
}

input AddBrandImageInput {
  companyId: ID!
  fileUuid: String!
  name: String!
  size: Int!
  style: ImageStyle!
  type: ImageType!
}

# Generated from com/attentivemobile/contracts/brand-kit/BrandImage.proto
type AddBrandImagePayload {
  brandImage: BrandImage!
}

input AddCampaignMessageGroupInput {
  campaignId: ID!
  campaignMessageGroupName: String!
  companyId: ID!
  experimentMetadata: CreateCampaignMessageGroupExperimentInput
  messageChannel: ComposeMessageChannel!
  userId: ID!
}

input AddConciergeCompanyPromotionInput {
  companyId: ID!
  end: DateTime
  limitedTime: Boolean!
  note: String!
  start: DateTime
  startPromoting: DateTime
  title: String!
  type: ConciergeCompanyPromotionType!
}

input AddEmailIPAssignmentsInput {
  ipAssignmentsToAdd: [EmailIPAssignmentInput!]
  newIpAssignments: [EmailIPAssignmentInput!] @deprecated(reason: "deprecated")
}

union AddEmailIPAssignmentsPayload = AddEmailIPAssignmentsSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type AddEmailIPAssignmentsSuccess {
  assignmentsAdded: Long! @deprecated(reason: "deprecated")
  emailIpAssignments: [EmailIPAssignment!]!
}

input AddNewEtlConfigInput {
  companyId: ID!
  enabled: EtlEnabled
  etlConfigId: Long
  etlConfigStaticValue: [EtlConfigStaticValueInput!]
  etlReportMetadata: EtlReportMetadataInput
  eventTypes: [EventType!]
  externalSftpConfig: ExternalSftpConfigInput
  features: [EtlConfigFeature!]
}

# Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto
type AddNewEtlConfigPayload {
  message: String!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type AddToCartEvent {
  companyProduct: Product
  companyProductId: ID!
  created: DateTime! @deprecated(reason: "deprecated")
  id: String! @deprecated(reason: "deprecated")
  productName: String! @deprecated(reason: "deprecated")
  productUrl: String! @deprecated(reason: "deprecated")
}

input AddVariantToCampaignMessageGroupInput {
  campaignMessageGroupId: ID!
  companyId: ID!
  contentInput: AddVariantToCampaignMessageGroupInputContentInput
  experimentType: ExperimentType
  textContent: TextContentInput @deprecated(reason: "deprecated")
}

input AddVariantToCampaignMessageGroupInputContentInput {
  emailSubjectAndPreviewInput: EmailSubjectAndPreviewContentInput
  textContentInput: TextContentInput
}

# Generated from com/attentivemobile/contracts/journeys/api/RevisionStep.proto
type AdderStep {
  childrenSteps: [NonAdderStep!]!
  stepConfig: AdderStepConfig!
}

# Generated from com/attentivemobile/contracts/journeys/api/RevisionStepConfig.proto
type AdderStepConfig {
  allowedStepTypes: [RevisionStepValue!]!
}

# Generated from com/attentivemobile/contracts/company-service/onboarding/Company.proto
type Address {
  addressLine1: String!
  addressLine2: String!
  city: String!
  country: String!
  state: String!
  zipcode: String!
}

input AddressInput {
  addressLine1: String
  addressLine2: String
  city: String
  country: String
  state: String
  zipcode: String
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type AdminNotificationConfiguration implements Node {
  deleted: Boolean!
  dismissible: Boolean!
  id: ID!
  name: String!
  notificationTemplateId: Long!
  slug: String!
  type: NotificationType!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type AdminNotificationConfigurationEdge {
  cursor: String!
  node: AdminNotificationConfiguration!
}

input AdminNotificationConfigurationInput {
  dismissible: Boolean!
  name: String!
  slug: String!
  type: NotificationType!
}

input AdminSetSftpUserPasswordInput {
  companyId: ID!
}

# Generated from com/attentivemobile/contracts/solutions/sftp/SftpUser.proto
type AdminSetSftpUserPasswordPayload {
  responseMsg: String!
}

# Generated from com/attentivemobile/contracts/externaltokens/AffiliateUsers.proto
type AffiliateUser {
  company: Company
  email: String!
  id: String!
}

# Generated from com/attentivemobile/contracts/externaltokens/AffiliateUsers.proto
type AffiliateUserConnection {
  edges: [AffiliateUserEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/externaltokens/AffiliateUsers.proto
type AffiliateUserEdge {
  cursor: String!
  node: AffiliateUser!
}

input AgentMessageFeedbackFilterInput {
  recipient: ID
  searchTerm: String
  status: MessageFeedbackStatusFilter
  type: MessageFeedbackTypeFilter
}

# Generated from com/attentivemobile/contracts/concierge/service/Template.proto
type AgentTemplate {
  company: Company
  createdBy: Account
  disabledReason: AgentTemplateDisabledReason
  id: String!
  isVisible: Boolean!
  shortcut: String
  source: AgentTemplateSource
  templateSource: TemplateSource
  text: String!
  title: String!
  updated: DateTime!
}

union AgentTemplateDisabledReason = UnsupportedMacros

# Generated from com/attentivemobile/contracts/concierge/service/TemplateRequest.proto
type AgentTemplateRequest {
  company: Company
  created: DateTime!
  dateReviewed: DateTime
  id: String!
  notes: String
  requestedBy: Account
  reviewedBy: Account
  shortcut: String
  source: AgentTemplateRequestSource
  status: TemplateRequestStatus!
  templateSource: TemplateSource
  text: String!
  title: String!
}

union AgentTemplateRequestSource = CompanySettingsSource | ConversationSource | FeedbackSource

union AgentTemplateSource = CompanySettingsSource | ConversationSource | FeedbackSource

enum Aggregator {
  AGGREGATOR_OPEN_MARKET
  AGGREGATOR_SAP
  AGGREGATOR_SYNIVERSE
  AGGREGATOR_UNKNOWN
  AGGREGATOR_UNSET
}

# Generated from com/attentivemobile/contracts/phonenumbers/external/Contract.proto
type AggregatorInfo {
  id: String!
  name: String!
  rateLimit: Long!
}

# Generated from com/attentivemobile/contracts/generativeaiservice/brandvoice/AIBrandVoice.proto
type AiBrandVoiceSummary {
  brandVoiceSummary: String!
}

# Generated from com/attentivemobile/protobuf/common/AudienceAiProduct.proto
type AiCategory {
  category: ProductCategory
}

# Generated from com/attentivemobile/contracts/generativeaiservice/text/TextGenerationModel.proto
type AiGeneratedTextMetadataEntry {
  key: String!
  value: String!
}

enum AiGrowTrialEligibilityStatus {
  AI_GROW_TRIAL_ELIGIBILITY_STATUS_DEACTIVATED
  AI_GROW_TRIAL_ELIGIBILITY_STATUS_INELIGIBLE
  AI_GROW_TRIAL_ELIGIBILITY_STATUS_IN_TRIAL
  AI_GROW_TRIAL_ELIGIBILITY_STATUS_NOT_PRIORITIZED
  AI_GROW_TRIAL_ELIGIBILITY_STATUS_ONBOARDED
  AI_GROW_TRIAL_ELIGIBILITY_STATUS_PRIORITIZED
  AI_GROW_TRIAL_ELIGIBILITY_STATUS_SIGNED_UP
  AI_GROW_TRIAL_ELIGIBILITY_STATUS_UNKNOWN
  AI_GROW_TRIAL_ELIGIBILITY_STATUS_WAITLISTED
}

# Generated from com/attentivemobile/contracts/messaging/automation/aijourneys/onboarding/AIJourneyLinkConfigServiceContract.proto
type AiJourneyCustomTrackingParamsEntry {
  key: String!
  value: String!
}

input AiJourneyCustomTrackingParamsInputEntry {
  key: String!
  value: String!
}

enum AiProEligibilityStatus {
  AI_PRO_ELIGIBILITY_STATUS_ELIGIBLE
  AI_PRO_ELIGIBILITY_STATUS_ENROLLED
  AI_PRO_ELIGIBILITY_STATUS_INELIGIBLE
  AI_PRO_ELIGIBILITY_STATUS_PENDING
  AI_PRO_ELIGIBILITY_STATUS_UNKNOWN
}

# Generated from com/attentivemobile/contracts/multiproductenablement/service/aipro/Contract.proto
type AiProIneligibleReason {
  reason: AiProIneligibleReasonReason
}

# Generated from com/attentivemobile/contracts/multiproductenablement/service/aipro/Contract.proto
type AiProIneligibleReasonCampaignsWithEnoughSendsBelowThreshold {
  campaignCount: Int!
  thresholdCount: Int!
  thresholdPercentage: Int!
}

# Generated from com/attentivemobile/contracts/multiproductenablement/service/aipro/Contract.proto
type AiProIneligibleReasonEligibleCampaignsWithoutConversions {
  campaignCount: Int!
  campaignWithoutConversionsCount: Int!
}

# Generated from com/attentivemobile/contracts/multiproductenablement/service/aipro/Contract.proto
type AiProIneligibleReasonMonthToMonth {
  reason: String!
}

# Generated from com/attentivemobile/contracts/multiproductenablement/service/aipro/Contract.proto
type AiProIneligibleReasonQualifiedCampaignsSentBelowThreshold {
  campaignCount: Int!
  thresholdCount: Int!
}

union AiProIneligibleReasonReason = AiProIneligibleReasonCampaignsWithEnoughSendsBelowThreshold | AiProIneligibleReasonEligibleCampaignsWithoutConversions | AiProIneligibleReasonMonthToMonth | AiProIneligibleReasonQualifiedCampaignsSentBelowThreshold | AiProIneligibleReasonSingleAccountManagement | AiProIneligibleReasonUnknown

# Generated from com/attentivemobile/contracts/multiproductenablement/service/aipro/Contract.proto
type AiProIneligibleReasonSingleAccountManagement {
  reason: String!
}

# Generated from com/attentivemobile/contracts/multiproductenablement/service/aipro/Contract.proto
type AiProIneligibleReasonUnknown {
  reason: String!
}

# Generated from com/attentivemobile/contracts/multiproductenablement/service/aipro/Contract.proto
type AiProSubscriptionStatus {
  status: AiProSubscriptionStatusStatus
}

# Generated from com/attentivemobile/contracts/multiproductenablement/service/aipro/Contract.proto
type AiProSubscriptionStatusChurned {
  comment: String
  convertedToPayingDate: Date!
  endDate: Date!
  reason: String
  startDate: Date!
}

# Generated from com/attentivemobile/contracts/multiproductenablement/service/aipro/Contract.proto
type AiProSubscriptionStatusDoesNotExist {
  comment: String
}

# Generated from com/attentivemobile/contracts/multiproductenablement/service/aipro/Contract.proto
type AiProSubscriptionStatusExtended {
  endDate: Date!
  extendedDate: Date!
  startDate: Date!
}

# Generated from com/attentivemobile/contracts/multiproductenablement/service/aipro/Contract.proto
type AiProSubscriptionStatusPaid {
  convertedAt: DateTime!
  convertedToPayingDate: Date!
  endDate: Date!
  extendedDate: Date!
  startDate: Date!
}

# Generated from com/attentivemobile/contracts/multiproductenablement/service/aipro/Contract.proto
type AiProSubscriptionStatusPaused {
  comment: String
  reason: String
}

# Generated from com/attentivemobile/contracts/multiproductenablement/service/aipro/Contract.proto
type AiProSubscriptionStatusPending {
  endDate: Date!
  startDate: Date!
}

union AiProSubscriptionStatusStatus = AiProSubscriptionStatusChurned | AiProSubscriptionStatusDoesNotExist | AiProSubscriptionStatusExtended | AiProSubscriptionStatusPaid | AiProSubscriptionStatusPaused | AiProSubscriptionStatusPending | AiProSubscriptionStatusTrial

# Generated from com/attentivemobile/contracts/multiproductenablement/service/aipro/Contract.proto
type AiProSubscriptionStatusTrial {
  endDate: Date!
  startDate: Date!
}

# Generated from com/attentivemobile/contracts/multiproductenablement/service/aipro/Contract.proto
type AiProTrialActionFailure implements StandardError {
  humanReadableMessage: String
  message: String!
  status: StandardErrorStatus!
  title: String!
  traceId: String!
}

# Generated from com/attentivemobile/protobuf/common/AudienceAiProduct.proto
type AiProduct {
  product: Product
}

# Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/SearchDefinitionOutput.proto
type Alias {
  alias: String!
}

input AllocateCouponsInput {
  assignmentColumn: AssignmentLocation!
  companyId: ID!
  couponType: CouponType!
  filename: String!
  messageId: String
  subIdentifier: SubscriberIdentifier!
  totalRows: Long!
}

# Generated from com/attentivemobile/contracts/solutions/couponallocator/CouponAllocator.proto
type AllocateCouponsPayload {
  message: String!
}

# Generated from com/attentivemobile/contracts/solutions/couponallocator/CouponAllocator.proto
type AllocationDateRange {
  endTime: DateTime
  startTime: DateTime
}

input AllocationDateRangeInput {
  endTime: DateTime
  startTime: DateTime
}

# Generated from com/attentivemobile/contracts/solutions/couponallocator/CouponAllocator.proto
type AllocationJobsPayload {
  selectedJobs: [CouponAllocationJob!]!
}

# Generated from com/attentivemobile/contracts/creative-service/creative/CreativeGraphService.proto
type ApiSignupCreative {
  emailWelcomeJourney: Journey
  id: ID!
  smsWelcomeJourney: Journey
}

type AppStatus {
  errorCode: String
  errorMessage: String
  state: String!
}

type AppTeardownTransaction {
  reasonMessage: String
}

enum ApplicableCustomers {
  ALL_CUSTOMERS
  SELECT_CUSTOMERS
  SELECT_SEGMENTS
  UNKNOWN_CUSTOMER_TYPE
}

enum ApplicableProductType {
  ALL_ITEMS
  SELECT_CATEGORIES
  SELECT_PRODUCTS
  UNKNOWN_PRODUCT_TYPE
}

# Generated from com/attentivemobile/contracts/externaltokens/Contract.proto
# Application details.
type Application implements Node {
  applicationWebhook: ApplicationWebhook
  customEventTypes(companyId: ID): [CustomEventDefinition!]!
  distributionStatus: DistributionStatus!

  # ID of the application.
  id: ID!
  internalId: Long!
  listing: ApplicationListing!

  # Name of the application.
  name: String!
  providedJourneyTriggers: JourneyTriggers
  publishedAt: DateTime
  responseCodeStats(companyApplicationId: Long, endTime: DateTime!, startTime: DateTime!): ApplicationResponseCodeStats
  scopes: [Scope!]!
  settings: ApplicationSettings
}

# Generated from com/attentivemobile/contracts/externaltokens/Contract.proto
type ApplicationCategory {
  application: Application
  name: ApplicationCategoryName!
  primary: Boolean!
}

enum ApplicationCategoryName {
  ANALYTICS
  APPLICATION_CATEGORY_UNKNOWN
  AUTOMATION
  CUSTOMER_DATA_PLATFORM
  CUSTOMER_EXPERIENCE
  ECOMMERCE
  EMAIL_SERVICE_PROVIDER
  LOYALTY
  OTHER
  REVIEWS_UGC
  SHIPPING_RETURNS
}

# Generated from com/attentivemobile/contracts/externaltokens/Contract.proto
type ApplicationConnection {
  edges: [ApplicationEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/externaltokens/Contract.proto
type ApplicationEdge {
  cursor: String!
  node: Application!
}

# Generated from com/attentivemobile/contracts/externaltokens/Contract.proto
type ApplicationListing {
  attentiveFeatures: [AttentiveFeature!]!
  categories: [ApplicationCategory!]!
  companyUrl: Url
  creatorCompanyName: String!
  description: String
  documentationLinks: [DocumentationLink!]!
  imageUrl: Url
  installUrl: Url
  jointValueProp: String
  prerequisites: [String!]!
  salesEmail: String
  supportEmail: String
  tier: PartnershipTier!
}

# Generated from com/attentivemobile/protobuf/common/EventOrigin.proto
type ApplicationOrigin {
  application: Application
}

# Generated from com/attentivemobile/contracts/externaltokens/Contract.proto
type ApplicationResponseCodeStat {
  endpoint: String!
  publicEndpoint: PublicEndpoint!
  responseCodeCount: Long!
  responseCodeValue: Long!
}

# Generated from com/attentivemobile/contracts/externaltokens/Contract.proto
type ApplicationResponseCodeStats {
  responseCodeStats: [ApplicationResponseCodeStat!]!
}

# Generated from com/attentivemobile/contracts/externaltokens/Contract.proto
type ApplicationSettings {
  clientId: String!
  clientSecret: String!
  contactEmail: String!
  createdAt: DateTime!
  creatorCompany: Company
  creatorCompanyId: String! @deprecated(reason: "deprecated")
  documentationFormUrl: Url
  hint: String!
  redirectUrls: [Url!]!
  submittedForApproval: Boolean!
  supportsWildcardRedirect: Boolean!
}

# Generated from com/attentivemobile/contracts/journeys/api/JourneyTrigger.proto
type ApplicationTriggerSource {
  application: Application
  customEventType: String!
}

input ApplicationTriggerSourceInput {
  applicationId: ID!
  customEventType: String!
}

# Generated from com/attentivemobile/contracts/externaltokens/Contract.proto
type ApplicationWebhook implements Node {
  application: Application
  clientSecret: String!
  destination: String
  examplePayloads(eventType: ApplicationWebhookEventTypeInput!): GetApplicationWebhookExamplePayloadsResponse
  id: ID!
  selectedEvents: [WebhookEventType!]
  status: WebhookStatus!
  type: ApplicationWebhookType!
}

input ApplicationWebhookEventTypeInput {
  displayName: String!
  name: String!
  type: String!
}

enum ApplicationWebhookType {
  APPLICATION_WEBHOOK_TYPE_UNKNOWN
  SUBSCRIPTION
  UNIVERSAL
}

input ApplyBrandElementStylesInput {
  companyId: ID!
  styles: [BrandElementStyleInput!]
}

# Generated from com/attentivemobile/contracts/brand-kit/BrandElementStyles.proto
type ApplyBrandElementStylesPayload {
  company: Company
  styles: [BrandElementStyle!]!
}

input ApplyBrandStyleInput {
  companyId: ID!
  styleJson: JsonStringInput!
  templateJson: JsonStringInput!
}

union ApplyBrandStylePayload = ApplyBrandStyleSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/composition/Contract.proto
type ApplyBrandStyleSuccess {
  beeEditorJson: JsonString!
}

enum ApplyIdentityAIParameterJourneyRevisionStatus {
  APPLY_IDENTITY_AI_PARAMETER_JOURNEY_REVISION_STATUS_ACTIVE
  APPLY_IDENTITY_AI_PARAMETER_JOURNEY_REVISION_STATUS_DRAFT
  APPLY_IDENTITY_AI_PARAMETER_JOURNEY_REVISION_STATUS_UNKNOWN
}

input ApplyIdentityAIParameterJourneyRevisionStatusFilter {
  journeyRevisionStatuses: [ApplyIdentityAIParameterJourneyRevisionStatus!]
}

enum ApplyIdentityAIParameterMode {
  APPLY_IDENTITY_AI_PARAMETER_MODE_APPEND
  APPLY_IDENTITY_AI_PARAMETER_MODE_STRIP
  APPLY_IDENTITY_AI_PARAMETER_MODE_UNKNOWN
}

input ApplyIdentityAIParameterPayload {
  applyMode: ApplyIdentityAIParameterMode!
  journeyRevisionStatusFilter: ApplyIdentityAIParameterJourneyRevisionStatusFilter
  macroDefaultValue: String
  targetQueryParameter: String!
}

enum ApprovalStatus {
  APPROVAL_STATUS_APPROVAL_REQUESTED
  APPROVAL_STATUS_APPROVED
  APPROVAL_STATUS_NEEDS_APPROVAL
  APPROVAL_STATUS_REJECTED
  APPROVAL_STATUS_UNKNOWN
}

input ArchiveCreativeInput {
  companyId: ID!
  creativeId: ID!
}

# Generated from com/attentivemobile/contracts/creative-service/creative/CreativeGraphService.proto
type ArchiveCreativePayload {
  creative: Creative!
}

# Generated from com/attentivemobile/contracts/keywords/KeywordRuleService.proto
type AreaCodeRule {
  areaCodes: [String!]!
  created: DateTime!
  id: Int!
  updated: DateTime!
}

input AskGraphQuestionInput {
  accountId: ID!
  content: String!
}

union AskGraphQuestionPayload = AskGraphQuestionSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/graph/guru/service/GraphGuruService.proto
type AskGraphQuestionSuccess {
  runId: String!
  success: Boolean!
}

union Asset = Folder | Image | Video

input AssetFilters {
  isGlobal: Boolean
  likeName: String
  rcsContentType: RcsContentType
}

input AssetId {
  folderId: ID
  imageId: ID
  videoId: ID
}

enum AssetsDirection {
  ASSETS_DIRECTION_ASCENDING
  ASSETS_DIRECTION_DESCENDING
}

enum AssetsOrderBy {
  ASSETS_ORDER_BY_DATE_ADDED
  ASSETS_ORDER_BY_DATE_MODIFIED
  ASSETS_ORDER_BY_DIMENSIONS
  ASSETS_ORDER_BY_DISPLAY_NAME
  ASSETS_ORDER_BY_SIZE
  ASSETS_ORDER_BY_TYPE
  ASSETS_ORDER_BY_UNKNOWN
}

input AssetsOrderByAndDirection {
  direction: AssetsDirection!
  orderBy: AssetsOrderBy!
}

input AssignCreativesToJourneyInput {
  companyId: ID!
  creativeId: [ID!]
  journeyId: ID!
}

# Generated from com/attentivemobile/contracts/journeys/api/Contract.proto
type AssignCreativesToJourneyPayload {
  journey: Journey!
}

# Generated from com/attentivemobile/contracts/account/permission/service/AccountPermissionsService.proto
type AssignedAccountEdge {
  account: Account
}

enum AssignmentLocation {
  FIRST_NAME
  LAST_NAME
}

# Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto
type AssociatedCompanies {
  company: Company
  isEnabled: Boolean!
}

# Generated from com/attentivemobile/contracts/composition/MessageAttachment.proto
type Attachment {
  attachmentBody: AttachmentBody!
  attachmentType: AttachmentType!
  id: String
}

union AttachmentBody = EventLookupAttachment | OfferAttachment | RecommendationAttachment | SegmentAttachment | UserPropertyAttachment

input AttachmentBodyProperties {
  eventLookupProperties: EventLookupAttachmentProperties
  offerProperties: OfferAttachmentProperties
  recommendationProperties: RecommendationAttachmentProperties
  segmentProperties: SegmentAttachmentProperties
  userPropertyProperties: UserPropertyAttachmentProperties
}

enum AttachmentType {
  ATTACHMENT_TYPE_EVENT_LOOKUP
  ATTACHMENT_TYPE_OFFER
  ATTACHMENT_TYPE_RECOMMENDATION
  ATTACHMENT_TYPE_SEGMENT
  ATTACHMENT_TYPE_UNKNOWN
  ATTACHMENT_TYPE_USER_PROPERTY
}

enum AttentiveAwsRegion {
  ATTENTIVE_AWS_REGION_UNKNOWN
  ATTENTIVE_AWS_REGION_US_EAST_1
  ATTENTIVE_AWS_REGION_US_EAST_2
}

# Generated from com/attentivemobile/contracts/externaltokens/Contract.proto
type AttentiveFeature {
  description: String!
  type: AttentiveFeatureType!
}

enum AttentiveFeatureType {
  ATTENTIVE_FEATURE_TYPE_UNKNOWN
  JOURNEYS
  PRIVACY
  SEGMENTS
  SUBSCRIBERS
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type AttentiveS3Settings {
  bucket: String!
  keyName: String!
  timezone: String!
}

input AttentiveS3SettingsInput {
  bucket: String!
  keyName: String!
  timezone: String!
}

enum AttributeDataType {
  DATA_TYPE_BOOLEAN
  DATA_TYPE_DATETIME
  DATA_TYPE_NUMBER
  DATA_TYPE_OBJECT
  DATA_TYPE_STRING
  DATA_TYPE_UNKNOWN
}

enum AttributeScope {
  ATTRIBUTE_SCOPE_COMPANY
  ATTRIBUTE_SCOPE_GLOBAL
  ATTRIBUTE_SCOPE_UNKNOWN
}

# Generated from com/attentivemobile/protobuf/common/targeting/segmentation/SearchAttribute.proto
type AttributeSource {
  applicationId: String
  companyId: Long
  vendor: String
}

enum AttributeStatus {
  STATUS_DISABLED
  STATUS_ENABLED
  STATUS_UNKNOWN
}

enum AttributeType {
  ATTRIBUTE_TYPE_EVENT
  ATTRIBUTE_TYPE_PROPERTY
  ATTRIBUTE_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/lift/service/Contract.proto
type AttributionSettings {
  emailClickWindow: AttributionWindow!
  emailViewWindow: AttributionWindow!
  filterMachineOpened: Boolean!
  newRevenueModel: NewRevenueModel!
  textClickWindow: AttributionWindow!
  textViewWindow: AttributionWindow!
}

# Generated from com/attentivemobile/contracts/lift/service/Contract.proto
type AttributionWindow {
  attributionWindowState: AttributionWindowState!
  days: Int
  hours: Int
  value: Int! @deprecated(reason: "deprecated")
}

input AttributionWindowInput {
  attributionWindowState: AttributionWindowState!
  days: Int
  hours: Int
  value: Int!
}

enum AttributionWindowState {
  ATTRIBUTION_WINDOW_STATE_NULL
  ATTRIBUTION_WINDOW_STATE_SET
  ATTRIBUTION_WINDOW_STATE_UNKNOWN
}

input AudienceAiProductCategoryInput {
  id: ID!
  name: String!
}

# Generated from com/attentivemobile/protobuf/common/AudienceAiProduct.proto
type AudienceAiProductFilter {
  categories: [AiCategory!]!
  products: [AiProduct!]!
}

input AudienceAiProductFilterInput {
  categories: [AudienceAiProductCategoryInput!]
  products: [ID!]
}

# Generated from com/attentivemobile/contracts/survey/poll/Contract.proto
type AudienceParticipant {
  keyword: String!
  keywordDescription: String!
  phone: String!
  response: String!
  subscriberId: SubscriberID!
  timestamp: DateTime!
}

# Generated from com/attentivemobile/contracts/survey/poll/Contract.proto
type AudienceResponse {
  count: GetAudienceCountResponse
  participants: GetAudienceParticipantsResponse
  poll: Poll
}

# Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/SearchDefinitionOutput.proto
type AudienceSearchCondition {
  attribute: SearchAttributeIdentifier!
  isNot: Boolean
  subConditions: [SearchSubCondition!]!
}

input AudienceSearchConditionInput {
  attribute: SearchAttributeInput!
  isNot: Boolean
  subConditions: [SearchSubConditionInput!]
}

# Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/SearchDefinitionOutput.proto
type AudienceSearchConditions {
  conditions: [AudienceSearchCondition!]!
}

input AudienceSearchConditionsInput {
  conditions: [AudienceSearchConditionInput!]
}

# Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/SearchDefinitionOutput.proto
type AudienceSearchDefinition {
  operator: SearchOperator!
  searchExpressions: [AudienceSearchExpressions!]!
}

input AudienceSearchDefinitionInput {
  operator: SearchOperator!
  searchExpressions: [AudienceSearchExpressionsInput!]
}

# Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/SearchDefinitionOutput.proto
type AudienceSearchExpressions {
  children: AudienceSearchExpressionsChildren
  operator: SearchOperator!
}

union AudienceSearchExpressionsChildren = AudienceSearchConditions

input AudienceSearchExpressionsInput {
  children: AudienceSearchExpressionsInputChildren
  operator: SearchOperator!
}

input AudienceSearchExpressionsInputChildren {
  searchConditions: AudienceSearchConditionsInput
}

input AuditFloridaSubscribersV2Input {
  activeSubscribersOnly: Boolean
  companyId: ID!
  includeSignUpSources: Boolean
}

# Generated from com/attentivemobile/contracts/litigator/service/Contract.proto
type AuditFloridaSubscribersV2Payload {
  status: FloridaSubscribersAuditStatusV2!
}

enum AuditSubscriberStatusV2 {
  AUDIT_SUBSCRIBER_STATUS_AUDIT_REPORT_RUNNING
  AUDIT_SUBSCRIBER_STATUS_FAILURE
  AUDIT_SUBSCRIBER_STATUS_PHONE_INVALID
  AUDIT_SUBSCRIBER_STATUS_SUCCESS
  AUDIT_SUBSCRIBER_STATUS_UNKNOWN
}

input AuditSubscriberV2Input {
  companyId: ID!
  markAsLitigator: Boolean!
  phoneNumber: String!
  savePermanently: Boolean
  shouldRunAudit: Boolean!
}

# Generated from com/attentivemobile/contracts/litigator/service/Contract.proto
type AuditSubscriberV2Payload {
  status: AuditSubscriberStatusV2!
  subscriberIdsOptedOut: [Long!]!
}

type AuthStatus {
  userAuthStatus: UserAuthStatus!
}

# Generated from com/attentivemobile/contracts/account-service/AuthenticatorContract.proto
type Authenticator {
  account: Account
  credentialName: String!
}

# Relates to the Auto Campaigns functionality (campaigns composed and scheduled automatically using AI). For example, filtering for 'AUTO_CAMPAIGN_FILTER_MANUAL' campaigns will only include campaigns _not_ automatically created.
enum AutoCampaignFilter {
  AUTO_CAMPAIGN_FILTER_ALL
  AUTO_CAMPAIGN_FILTER_AUTO
  AUTO_CAMPAIGN_FILTER_MANUAL
  AUTO_CAMPAIGN_FILTER_UNKNOWN
}

input AutoInstallTagInput {
  companyId: ID!
  tagUrl: String!
}

# Generated from com/attentivemobile/contracts/integrations/tag/Contract.proto
type AutoInstallTagStatus {
  isAutoinstalled: Boolean!
  isEligibleForAutoinstall: Boolean!
  tagsInstalled: [AutoInstalledTag!]!
}

# Generated from com/attentivemobile/contracts/integrations/tag/Contract.proto
type AutoInstallTagStatusPayload {
  tagStatus: AutoInstallTagStatus!
}

# Generated from com/attentivemobile/contracts/integrations/tag/Contract.proto
type AutoInstalledTag {
  tagUrl: String!
  vendor: Vendor!
}

# Generated from com/attentivemobile/contracts/messaging/automation/autojourneys/AutoJourneysModel.proto
type AutoJourney implements Node {
  aiJourneyDefaultMessages: [AIJourneyDefaultMessage!]
  aiJourneyLinkTrackingParams: [AIJourneyTrackingParams!]
  aiJourneyLinkTypeConfigurations: [AIJourneyLinkConfiguration!]
  autoJourneyType: AutoJourneyType
  clientSettings: AutoJourneyClientSettings!
  company: Company
  config: AutoJourneyConfig!
  created: DateTime
  getShopifyAutoApplyFormats: GetShopifyAutoApplyFormatsPayload
  id: ID!
  messages: [AutoJourneyMessage!]!
  name: String!
  status: AutoJourneyStatus!
  type: JourneyTriggerType! @deprecated(reason: "deprecated")
  updated: DateTime
  verifyAIJourneyMessageSent(lookbackDurationMinutes: Int!, messageType: E2ETestMessageType!, userCompanyId: ID!): VerifyAIJourneyMessageSentPayload
}

# Generated from com/attentivemobile/contracts/messaging/automation/autojourneys/AutoJourneysModel.proto
type AutoJourneyClientSettings {
  utmInputSettings: UtmSettings!
}

input AutoJourneyClientSettingsInput {
  utmSettingsInput: UtmSettingsInput!
}

# Generated from com/attentivemobile/contracts/messaging/automation/autojourneys/AutoJourneysModel.proto
type AutoJourneyConfig {
  journey: Journey
  journeyId: Long
  trafficPercentage: Float
}

input AutoJourneyConfigInput {
  journeyId: Long
  trafficPercentage: Float
}

# Generated from com/attentivemobile/contracts/messaging/automation/autojourneys/AutoJourneysModel.proto
type AutoJourneyMessage {
  composeMessage: ComposeMessage
}

enum AutoJourneyStatus {
  AUTO_JOURNEY_STATUS_ACTIVE
  AUTO_JOURNEY_STATUS_DELETED
  AUTO_JOURNEY_STATUS_PAUSED
  AUTO_JOURNEY_STATUS_UNKNOWN
}

enum AutoJourneyTriggerType {
  AUTO_JOURNEY_TRIGGER_TYPE_CART_ABANDONMENT
  AUTO_JOURNEY_TRIGGER_TYPE_UNKNOWN
}

enum AutoJourneyType {
  CART_ABANDONMENT
  UNIFIED
  UNKNOWN
  WELCOME
}

# Generated from com/attentivemobile/contracts/company-service/region/Model.proto
type AutomatedMessage {
  message: String!
  type: AutomatedMessageType!
}

input AutomatedMessageInput {
  message: String!
  type: AutomatedMessageType!
}

enum AutomatedMessageType {
  AUTOMATED_MESSAGE_TYPE_ALREADY_SUBSCRIBED
  AUTOMATED_MESSAGE_TYPE_AUTO
  AUTOMATED_MESSAGE_TYPE_DOUBLE_OPT_IN
  AUTOMATED_MESSAGE_TYPE_FUZZY_OPT_OUT
  AUTOMATED_MESSAGE_TYPE_HELP
  AUTOMATED_MESSAGE_TYPE_LEGAL
  AUTOMATED_MESSAGE_TYPE_START
  AUTOMATED_MESSAGE_TYPE_STOP
  AUTOMATED_MESSAGE_TYPE_UNKNOWN
  AUTOMATED_MESSAGE_TYPE_UNSUBSCRIBE
}

# Generated from com/attentivemobile/contracts/messaging/automation/Contract.proto
type AutomationProgram implements Node {
  company: Company

  # The Coupon Set that will be attached to campaigns created by Auto Campaigns
  couponSet: CouponSet
  description: String!
  emailNotificationSettings: EmailNotificationSettings
  id: ID!
  messageLinkTargetUrl: String
  prebuiltContent: [PrebuiltContent!]!
  prebuiltProgramId: String!
  programCadence: ProgramCadence
  programOrchestration: [ProgramOrchestration!]!
  scheduleType: ScheduleType!
  status: ProgramStatus!
  thumbnail: String!
  title: String!
  uuid: String!
}

# Generated from com/attentivemobile/contracts/messaging/automation/Contract.proto
type AutomationProgramConnection {
  edges: [AutomationProgramEdge!]!
  pageInfo: PageInfo!
  totalCount: Long!
}

# Generated from com/attentivemobile/contracts/messaging/automation/Contract.proto
type AutomationProgramEdge {
  cursor: String!
  node: AutomationProgram!
}

# Generated from com/attentivemobile/contracts/brand-kit/BrandFont.proto
type AvailableBrandFont {
  fileUuid: String
  fontFamilyName: String!
  format: String!
  url: Url!
  variants: [BrandFontVariant!]!
}

# Generated from com/attentivemobile/contracts/brand-kit/BrandFont.proto
type AvailableBrandFontSource {
  format: String!
  url: Url!
}

input AvailableBrandFontsInput {
  fileUuid: String
  fontFamilyName: String!
  format: String!
  url: String
  variants: [BrandFontVariantInput!]
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type BEEColumn {
  modules: [BEEModuleContentsWrapper!]!
}

union BEEModuleContents = ButtonContents | HeadingContents | ImageContents | ParagraphContents

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type BEEModuleContentsWrapper {
  contents: BEEModuleContents!
}

enum BackfillType {
  SCHEDULED_REPORT
  SFTP
}

input BaseAudience {
  excludedSegments: [ID!]
  includedSegments: [ID!]
}

input BaseAudienceDefinition {
  channels: [Channel!]
  isExcludingFatiguedUsers: Boolean!
  isIncludingOptOutUsers: Boolean!
}

input BaseAudienceDefinitionInput {
  channels: [Channel!]
  isExcludingFatiguedUsers: Boolean!
  isIncludingOptOutUsers: Boolean!
}

input BatchMigrateJobsInput {
  allowedDatastreamNames: [String!]
  batchSize: Int!
  completedCheckpointCount: Int!
  flinkDeploymentMode: FlinkDeploymentMode
  newCluster: Cluster
  oldCluster: Cluster
  pulsarClusterName: String
  startMode: OperationMode!
  stopMode: OperationMode!
}

# Generated from com/attentivemobile/contracts/event/platform/batchjobs/service/Contract.proto
type BatchMigrateJobsPayload {
  workflowId: String!
}

# Generated from com/attentivemobile/contracts/compose/api/Contract.proto
type BeeFreeAuthToken {
  accessToken: String!
  error: String
  expiresIn: Int!
  refreshToken: String!
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type BeeModuleStyleOverrides {
  textAlign: String
  width: String
}

# Generated from com/attentivemobile/contracts/event/ecommerce/Order.proto
# A representation of the amounts billed to a customer.
type BillingTotals {
  # The price of the order after discounts are applied, but before shipping costs, duties, taxes, and tips are applied
  subtotalPrice: Money!

  # The sum of all discounts applicable to this order
  totalDiscount: Money!

  # The sum of all duty costs
  totalDutyCost: Money!

  # The final price of the order (sum of the subtotal prices and all shipping costs, duties, taxes, and tips applicable to the order)
  totalPrice: Money!

  # The sum of all shipping costs
  totalShippingCost: Money!

  # The sum of all taxes applicable to this order
  totalTax: Money!
}

# Generated from com/attentivemobile/contracts/personalization/service/aijourneys/onboarding/companyinfo/BlockCompanyInfo.proto
type BlockCompanyInfo {
  company: Company
  companyInfoType: BlockCompanyInfoType!
  companyInfoValue: String!
}

input BlockCompanyInfoInput {
  companyInfoType: BlockCompanyInfoType!
  companyInfoValue: String!
}

enum BlockCompanyInfoType {
  BLOCK_COMPANY_INFO_TYPE_ADDITIONAL
  BLOCK_COMPANY_INFO_TYPE_RETURNS
  BLOCK_COMPANY_INFO_TYPE_SHIPPING
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type BlogPost implements Node {
  categoryName: String!
  id: ID!
  imageUrl: String!
  published: DateTime!
  slug: String!
  subtitle: String
  title: String!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type BlogPostConnection {
  edges: [BlogPostEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type BlogPostEdge {
  cursor: String!
  node: BlogPost!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type BoolPropertyValue {
  value: Boolean!
}

# Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/ComparisonTypes.proto
type BooleanComparison {
  comparator: SearchBooleanComparator!
  value: Boolean!
}

input BooleanComparisonInput {
  comparator: SearchBooleanComparator!
  value: Boolean!
}

input BooleanDatasetFilter {
  value: Boolean!
}

# Generated from com/attentivemobile/contracts/metricsapi/DatasetsContract.proto
type BooleanDatasetValue {
  value: Boolean!
}

# Generated from com/attentivemobile/contracts/user/profile/property/UserPropertyV2.proto
type BooleanPropertyValueV2 {
  booleanValue: Boolean!
}

# Generated from com/attentivemobile/contracts/brand-kit/BrandColorSchemeService.proto
type BrandColor {
  element: Element!
  value: String!
}

input BrandColorInput {
  element: Element!
  value: String!
}

# Generated from com/attentivemobile/contracts/brand-kit/BrandElementStyles.proto
type BrandElementStyle {
  element: StylableElement!
  property: ElementProperty!
  value: String!
}

input BrandElementStyleInput {
  element: StylableElementInput!
  property: ElementPropertyInput!
  value: String!
}

# Generated from com/attentivemobile/contracts/brand-kit/BrandFont.proto
type BrandFontProperties {
  property: String!
  value: String!
}

input BrandFontPropertiesInput {
  property: String!
  value: String!
}

# Generated from com/attentivemobile/contracts/brand-kit/BrandFont.proto
type BrandFontVariant {
  style: String!
  weight: String!
}

input BrandFontVariantInput {
  fileUuid: String
  style: String!
  weight: String!
}

# Generated from com/attentivemobile/contracts/brand-kit/BrandImage.proto
type BrandImage {
  companyId: String!
  fileUuid: String!
  name: String!
  size: Int!
  style: ImageStyle!
  type: ImageType!
  url: Url!
}

input BulkMigrateTagPresetsInput {
  companyIds: [ID!]
  preset: String!
}

# Generated from com/attentivemobile/contracts/tagbuildservice/taghealth/Contract.proto
type BulkMigrateTagPresetsPayload {
  releaseIds: [Long!]!
}

input BulkModifyTagExperimentsInput {
  companyIds: [ID!]
  experimentId: String!
  isExperimentOn: Boolean!
}

union BulkModifyTagExperimentsPayload = BulkModifyTagExperimentsSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/tagbuildservice/taghealth/Contract.proto
type BulkModifyTagExperimentsSuccess {
  releaseIds: [Long!]!
}

input BulkUpdateCompositionMessagesInput {
  companyId: ID!
  messagesToUpdate: [UpdateCompositionMessageInput!]
}

# Generated from com/attentivemobile/contracts/composition/Contract.proto
type BulkUpdateCompositionMessagesPayload {
  updatedMessages: [ComposeMessage!]!
}

# Generated from com/attentivemobile/contracts/segmentation/service/search/contract.proto
type BulkUserSearchCountsResponse {
  audienceResponses: [UserSearchCountsResponse!]!
}

# Generated from com/attentivemobile/contracts/company-service/Contract.proto
type BusinessHours {
  close: String!
  open: String!
  timezone: String!
}

enum ButtonAction {
  BUTTON_ACTION_CLOSE
  BUTTON_ACTION_LINK
  BUTTON_ACTION_UNKNOWN
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type ButtonContents {
  href: String!
  styleOverrides: BeeModuleStyleOverrides
  text: String!
}

enum CadenceComparator {
  CADENCE_COMPARATOR_LATEST
  CADENCE_COMPARATOR_UNKNOWN
}

input CadenceCondition {
  comparator: CadenceComparator!
}

# Generated from com/attentivemobile/contracts/message/api/query/Contract.proto
type Campaign implements Node {
  company: Company
  id: ID!
  name: String!
}

enum CampaignAction {
  CAMPAIGN_ACTION_CAMPAIGN_RATE_LIMIT_UPDATED
  CAMPAIGN_ACTION_CANCELLED
  CAMPAIGN_ACTION_CREATED
  CAMPAIGN_ACTION_DELETED
  CAMPAIGN_ACTION_DOWNGRADED
  CAMPAIGN_ACTION_DRAFTED
  CAMPAIGN_ACTION_PAUSED
  CAMPAIGN_ACTION_RESCHEDULED
  CAMPAIGN_ACTION_RESUMED
  CAMPAIGN_ACTION_SEND_FINISHED
  CAMPAIGN_ACTION_SEND_STARTED
  CAMPAIGN_ACTION_TIMEZONE_SEND_FINISHED
  CAMPAIGN_ACTION_TIMEZONE_SEND_STARTED
  CAMPAIGN_ACTION_UNKNOWN
  CAMPAIGN_ACTION_UPDATED
}

# Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto
type CampaignAudienceFilter {
  oneofFilterProperties: CampaignAudienceFilterOneofFilterProperties
}

union CampaignAudienceFilterOneofFilterProperties = PartnerCampaignAudienceFilter | SampleAudienceFilter | SegmentAudienceFilter | SliceAudienceFilter | SubscriberOptimizedSendTimeAudienceFilter | TimezoneAudienceFilter

# Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto
type CampaignCompositionValidationError {
  code: CampaignCompositionValidationErrorCode!
  message: String!
}

enum CampaignCompositionValidationErrorCode {
  CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_COUPON_REFILL_REQUIRED
  CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_EMAIL_MESSAGE_FROM_ADDRESS_EMPTY
  CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_EMAIL_MESSAGE_REPLY_TO_ADDRESS_EMPTY
  CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_EMAIL_MESSAGE_SUBJECT_EMPTY
  CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_EMPTY_CAMPAIGN
  CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_EMPTY_EXPERIMENT_METADATA
  CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_GROUP_LIMIT_EXCEEDED
  CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_GROUP_ORDER_ERROR
  CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_INVALID_SEGMENT
  CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_MESSAGE_BODY_EMPTY
  CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_NAME_EMPTY
  CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_NAME_LENGTH_EXCEEDED
  CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_SEGMENT_INCLUDED_AND_EXCLUDED
  CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_START_TIME_EMPTY
  CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_START_TIME_INVALID
  CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_STE_MESSAGE_CONTENT_OUT_OF_SYNC
  CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_STO_DATE_CONFLICT
  CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_SUBSCRIBER_LOCAL_TZ_SEND_NOT_ALLOWED
  CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_UNKNOWN
}

# Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto
type CampaignEvent {
  action: CampaignAction!
  comment: String!
  source: String!
  timestamp: DateTime!
  traceId: String!
}

# Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto
type CampaignExperimentMetadata {
  campaignExperimentSubtype: CampaignExperimentSubtype
  delayPeriodSeconds: Int!
  isPickAWinnerEnabled: Boolean!
  samplePercentage: Float!
  testSendTime: SendTime!
  winnerPickedTimestamp: DateTime
  winningMessageMember: CampaignMessageMember
  winningVariantCriteria: CampaignWinningVariantCriteria!
}

input CampaignExperimentMetadataAttachmentInput {
  delayPeriodSeconds: Int
  experimentSendTimes: [DateTime!] @deprecated(reason: "deprecated")
  isPickAWinnerEnabled: Boolean
  metadataId: ID @deprecated(reason: "deprecated")
  samplePercentage: Float
  winnerPickedTimestamp: DateTime
  winningCampaignMessageMemberId: ID
  winningVariantCriteria: CampaignWinningVariantCriteria
}

enum CampaignExperimentSubtype {
  CAMPAIGN_EXPERIMENT_SUBTYPE_CONTENT_BODY_EXPERIMENT
  CAMPAIGN_EXPERIMENT_SUBTYPE_SUBJECT_LINE_EXPERIMENT
  CAMPAIGN_EXPERIMENT_SUBTYPE_UNKNOWN
}

# Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto
type CampaignHistory {
  events: [CampaignEvent!]!
}

type CampaignMessage {
  attachments: [CampaignMessageAttachment!]!
  links: [CampaignMessageLink!]!
  messageId: Int!
  name: String!
  numSegments: Int!
  text: String!
}

type CampaignMessageAttachment {
  linkId: Int!
  shortLink: String!
  shortUrlMacroPlaceholder: String!
  target: String!
  type: String!
}

# Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto
type CampaignMessageGroup implements Node {
  assignedTags(after: String, before: String, first: Int, last: Int, taggableEntityType: TaggableEntityType!): TagsConnection
  company: Company
  created: DateTime!
  downgradeApproved: Boolean!
  experimentMetadata: CampaignExperimentMetadata
  history: CampaignHistory!
  id: ID!
  messageMembers: [CampaignMessageMember!]!
  name: String!
  passedTimezonesStrategy: PassedTimezonesStrategy
  rateLimits: RateLimits!
  rawUuid: String!
  scheduleType: CampaignScheduleType!
  segments: [SegmentAudienceFilter!]!
  sendTimeExperimentMetadata: SendTimeExperimentMetadata
  sendTimes: [SendTime!]!
  smartSendingEnabled: Boolean!
  startTime: DateTime
  status: CampaignMessageGroupStatus!
  type: CampaignMessageGroupType!
  updated: DateTime!
  validationErrors: [CampaignCompositionValidationError!]!
}

# Generated from com/attentivemobile/contracts/campaigns/service/Contract.proto
type CampaignMessageGroupEdge {
  cursor: String!
  node: CampaignMessageGroup!
}

enum CampaignMessageGroupExperimentSubtype {
  CAMPAIGN_MESSAGE_GROUP_EXPERIMENT_SUBTYPE_CONTENT_BODY_EXPERIMENT
  CAMPAIGN_MESSAGE_GROUP_EXPERIMENT_SUBTYPE_SUBJECT_LINE_EXPERIMENT
  CAMPAIGN_MESSAGE_GROUP_EXPERIMENT_SUBTYPE_UNKNOWN
}

enum CampaignMessageGroupExperimentType {
  CAMPAIGN_MESSAGE_GROUP_EXPERIMENT_TYPE_EXPERIMENT
  CAMPAIGN_MESSAGE_GROUP_EXPERIMENT_TYPE_SEND_TIME_EXPERIMENT
  CAMPAIGN_MESSAGE_GROUP_EXPERIMENT_TYPE_UNKNOWN
}

# Only one of the compose_message_id and compose_message_id_to_clone will be accepted. compose_message_id - if passed in, we will update this message. compose_message_id_to_clone - if passed in, we will copy this message into a new message
input CampaignMessageGroupMessageMemberInput {
  channel: ComposeMessageChannel!
  composeMessageId: ID
  composeMessageIdToClone: ID
  schedulingMetadata: SchedulingMetadataInput
}

enum CampaignMessageGroupStatus {
  CAMPAIGN_MESSAGE_GROUP_STATUS_ATTENTIVE_REVIEW
  CAMPAIGN_MESSAGE_GROUP_STATUS_CANCELLED
  CAMPAIGN_MESSAGE_GROUP_STATUS_DRAFT
  CAMPAIGN_MESSAGE_GROUP_STATUS_NEEDS_COUPONS
  CAMPAIGN_MESSAGE_GROUP_STATUS_PAUSED
  CAMPAIGN_MESSAGE_GROUP_STATUS_RESCHEDULED
  CAMPAIGN_MESSAGE_GROUP_STATUS_SCHEDULED
  CAMPAIGN_MESSAGE_GROUP_STATUS_SCHEDULED_DRAFT
  CAMPAIGN_MESSAGE_GROUP_STATUS_SENDING
  CAMPAIGN_MESSAGE_GROUP_STATUS_SEND_ERROR
  CAMPAIGN_MESSAGE_GROUP_STATUS_SENT
  CAMPAIGN_MESSAGE_GROUP_STATUS_UNKNOWN
  CAMPAIGN_MESSAGE_GROUP_STATUS_UNSAVED_DRAFT
}

enum CampaignMessageGroupType {
  CAMPAIGN_MESSAGE_GROUP_TYPE_EXPERIMENT
  CAMPAIGN_MESSAGE_GROUP_TYPE_ONE_TIME
  CAMPAIGN_MESSAGE_GROUP_TYPE_SEND_TIME_EXPERIMENT
  CAMPAIGN_MESSAGE_GROUP_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/campaigns/service/Contract.proto
type CampaignMessageGroupsConnection {
  edges: [CampaignMessageGroupEdge!]!
  pageInfo: PageInfo!
  totalCount: Long!
}

type CampaignMessageLink {
  id: Int!
  shortLink: String!
  target: String!
  type: String!
}

# Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto
type CampaignMessageMember implements Node {
  company: Company
  composeMessage: ComposeMessage
  estimatedRecipients(channel: ComposeMessageChannel, isSmartSendingEnabled: Boolean, segmentIdsExcluded: [ID!], segmentIdsIncluded: [ID!]): EstimatedRecipientCount
  experimentStatSnapshotValue: Float
  id: ID!
  remainingRecipients: RemainingRecipientsCount
  schedulingMetadata: SchedulingMetadata
}

# Generated from com/attentivemobile/contracts/messaging/automation/Contract.proto
type CampaignOrchestration {
  campaign: ComposeCampaign
}

enum CampaignScheduleType {
  CAMPAIGN_SCHEDULE_TYPE_INSTANT
  CAMPAIGN_SCHEDULE_TYPE_SUBSCRIBER_LOCAL_TIME
  CAMPAIGN_SCHEDULE_TYPE_SUBSCRIBER_OPTIMIZED_SEND_TIME
  CAMPAIGN_SCHEDULE_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/concierge/service/Message.proto
type CampaignSource {
  campaign: Campaign
}

# Generated from com/attentivemobile/contracts/campaigns/service/Stats.proto
type CampaignStats {
  campaignId: String!
  campaignUuid: String!
  messagesStats: [MessageStats!]!
  sendCounts: Int!
  sendRequests: Int!
}

enum CampaignStatus {
  CAMPAIGN_STATUS_ATTENTIVE_REVIEW
  CAMPAIGN_STATUS_CANCELLED
  CAMPAIGN_STATUS_DRAFT
  CAMPAIGN_STATUS_NEEDS_COUPONS
  CAMPAIGN_STATUS_PAUSED
  CAMPAIGN_STATUS_RESCHEDULED
  CAMPAIGN_STATUS_SCHEDULED
  CAMPAIGN_STATUS_SCHEDULED_DRAFT
  CAMPAIGN_STATUS_SENDING
  CAMPAIGN_STATUS_SEND_ERROR
  CAMPAIGN_STATUS_SENT
  CAMPAIGN_STATUS_UNKNOWN
  CAMPAIGN_STATUS_UNSAVED_DRAFT
}

input CampaignTagInput {
  name: String!
  uuid: String!
}

input CampaignTagsInput {
  tag: [CampaignTagInput!]
}

enum CampaignWinningVariantCriteria {
  CAMPAIGN_WINNING_VARIANT_CRITERIA_CLICK_THROUGH_RATE
  CAMPAIGN_WINNING_VARIANT_CRITERIA_CONVERSION_RATE
  CAMPAIGN_WINNING_VARIANT_CRITERIA_OPEN_RATE
  CAMPAIGN_WINNING_VARIANT_CRITERIA_OPT_OUT_RATE
  CAMPAIGN_WINNING_VARIANT_CRITERIA_TOTAL_REVENUE
  CAMPAIGN_WINNING_VARIANT_CRITERIA_UNKNOWN
}

# Generated from com/attentivemobile/contracts/campaigns/service/Stats.proto
type CampaignsStats {
  campaignsStats: [CampaignStats!]!
}

input CancelSyncRunInput {
  runId: ID!
  syncId: String!
}

union CancelSyncRunPayload = CancelSyncRunSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type CancelSyncRunSuccess {
  success: Boolean!
}

input CancelUserFileDownloadInput {
  companyId: ID!
  fileId: ID!
}

# Generated from com/attentivemobile/contracts/user/profile/download/Contract.proto
type CancelUserFileDownloadPayload {
  file: UserFileDownload
}

enum Carrier {
  CARRIER_APPALACHIAN
  CARRIER_ATT
  CARRIER_BELL
  CARRIER_CLEARSKY
  CARRIER_GOOGLE_VOICE
  CARRIER_INTEROP
  CARRIER_MISC
  CARRIER_ROGERS
  CARRIER_TELUS
  CARRIER_TMOBILE
  CARRIER_UNKNOWN
  CARRIER_US_CELLULAR
  CARRIER_VERIZON
}

input CarrierDateMapping {
  carrier: Carrier!
  endDate: DateTime
  startDate: DateTime
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type CartUpdatedEvent {
  company: Company

  # TEMP: A list of products related to this event
  products: [ProductWrapper!]!
}

# Generated from com/attentivemobile/contracts/account-service/AuthenticatorContract.proto
type ChallengeResponse {
  company: Company
  nonce: String!
}

input ChangeBrandImageNameInput {
  companyId: ID!
  fileUuid: String!
  name: String!
}

# Generated from com/attentivemobile/contracts/brand-kit/BrandImage.proto
type ChangeBrandImageNamePayload {
  image: BrandImage!
}

input ChangeCompanyAttnDomainInput {
  companyId: ID!
  newCompanyDomain: String!
  oldCompanyDomain: String!
}

# Generated from com/attentivemobile/contracts/solutions/companyconfiguration/Configuration.proto
type ChangeCompanyAttnDomainPayload {
  company: Company
  newCompanyDomain: String
}

input ChangeEventReplayInput {
  eventReplayId: ID!
  ignoreGracePeriod: Boolean
}

# Generated from com/attentivemobile/contracts/tactical/eventreplay/EventReplayService.proto
type ChangeEventReplayPayload {
  eventReplay: EventReplay!
}

input ChangeEventReplayV2Input {
  eventReplayId: [ID!]
  ignoreGracePeriod: Boolean
}

# Generated from com/attentivemobile/contracts/tactical/eventreplay/EventReplayService.proto
type ChangeEventReplayV2Payload {
  eventReplay: [EventReplay!]!
  eventReplayFailures: [EventReplayFailures!]!
}

enum Channel {
  CHANNEL_EMAIL
  CHANNEL_PUSH
  CHANNEL_RBM
  CHANNEL_TEXT
  CHANNEL_UNKNOWN
}

# Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto
type ChannelDataEntry {
  key: String!
  value: ChannelOptions!
}

input ChannelDataInputEntry {
  key: String!
  value: ChannelOptions!
}

enum ChannelOptions {
  CHANNEL_EMAIL
  CHANNEL_SMS
}

union ChannelProperties = EmailProperties | PushChannelProperties | RbmChannelProperties | RcsChannelProperties | TextMessageProperties

input ChatMessageAdminToolInput {
  content: String!
  role: TemplateRole!
}

input ChatMessageInput {
  content: String!
  role: String!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type CheckoutAbandonedEvent {
  checkoutUrl: String!
  created: DateTime! @deprecated(reason: "deprecated")
  id: String! @deprecated(reason: "deprecated")
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type CheckoutStartedEvent {
  checkoutUrl: String!
  companyProductIds: [ID!]! @deprecated(reason: "deprecated")
  created: DateTime! @deprecated(reason: "deprecated")
  id: String! @deprecated(reason: "deprecated")
  productBundle: [ProductInfo!]! @deprecated(reason: "deprecated")

  # TEMP: A list of products related to this event
  products: [ProductWrapper!]!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type CheckoutUpdatedEvent {
  checkoutUrl: String!
  companyProductIds: [ID!]! @deprecated(reason: "deprecated")
  created: DateTime! @deprecated(reason: "deprecated")
  id: String! @deprecated(reason: "deprecated")
  productBundle: [ProductInfo!]! @deprecated(reason: "deprecated")

  # TEMP: A list of products related to this event
  products: [ProductWrapper!]!
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Flink.proto
type Checkpoint {
  externalPath: String!
  status: CheckpointStatus!
  type: CheckpointType!
}

enum CheckpointStatus {
  STATUS_COMPLETED
  STATUS_FAILED
  STATUS_IN_PROGRESS
  STATUS_UNKNOWN
}

enum CheckpointType {
  TYPE_CHECKPOINT
  TYPE_SAVEPOINT
  TYPE_UNKNOWN
}

input ChurnAiProSubscriptionInput {
  churnReason: String!
  comment: String
  companyId: ID!
}

union ChurnAiProSubscriptionPayload = AiProTrialActionFailure | ChurnAiProSubscriptionSuccess

# Generated from com/attentivemobile/contracts/multiproductenablement/service/aipro/Contract.proto
type ChurnAiProSubscriptionSuccess {
  success: Boolean!
}

# Generated from com/attentivemobile/contracts/gateway/service/RateLimiting.proto
type CircuitBreakerRateLimitsResponse {
  circuitBreakerRateLimitResponses: [UpdateCircuitBreakerRateLimitSuccess!]!
}

# Generated from com/attentivemobile/contracts/conversations/v1/Conversation.proto
type ClientConversation {
  isInbound: Boolean!
  isUnread: Boolean!
  mediaUrl: String!
  previewText: String!
  timestamp: DateTime!
  user: User
}

# Generated from com/attentivemobile/contracts/conversations/v1/Conversation.proto
type ClientConversationConnection {
  edges: [ClientConversationEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/conversations/v1/Conversation.proto
type ClientConversationEdge {
  cursor: String!
  node: ClientConversation!
}

enum ClientConversationFilter {
  CLIENT_CONVERSATION_FILTER_ALL
  CLIENT_CONVERSATION_FILTER_CONCIERGE
  CLIENT_CONVERSATION_FILTER_CONCIERGE_CONVERTED
  CLIENT_CONVERSATION_FILTER_CONCIERGE_ESCALATED
  CLIENT_CONVERSATION_FILTER_MULTIPLE_CONCIERGE_CONVERSATION
  CLIENT_CONVERSATION_FILTER_UNREAD
}

enum ClientConversationSort {
  CLIENT_CONVERSATION_SORT_LATEST
  CLIENT_CONVERSATION_SORT_OLDEST
}

# Generated from com/attentivemobile/contracts/multiproductenablement/service/AiGrowTrialStatusGraphService.proto
type ClientEligibility {
  company: Company
  created: DateTime!
  deleted: DateTime
  eligibilityDate: DateTime
  eligibilityStatus: AiGrowTrialEligibilityStatus!
  formSubmittedDate: DateTime
  internalId: String!
  signupFormLink: String
  uiActivationDate: DateTime
  updated: DateTime!
  waitlistSignupDate: DateTime
}

input ClientMessageFeedbackFilterInput {
  searchTerm: String
  status: MessageFeedbackStatusFilter
  type: MessageFeedbackTypeFilter
}

# Generated from com/attentivemobile/contracts/solutions/clientsegmentimports/ClientSegmentImports.proto
type ClientSegmentImportsRow {
  csiId: Long
  customFilePrefix: String
  hasHeaders: Boolean
  inboundS3Bucket: String
  isEnabled: Boolean
  isEncrypted: Boolean
  isSegmentNameInFileName: Boolean
  jobName: String
  piiType: String
  scheduledRunTime: String
  sftpHostname: String
  sftpUploadsDirectory: String
  sftpUsername: String
  stripQuotesFromSegmentNames: Boolean
}

input ClientSegmentImportsRowInput {
  csiId: Long
  customFilePrefix: String
  hasHeaders: Boolean
  inboundS3Bucket: String
  isEnabled: Boolean
  isEncrypted: Boolean
  isSegmentNameInFileName: Boolean
  jobName: String
  piiType: String
  scheduledRunTime: String
  sftpHostname: String
  sftpUploadsDirectory: String
  sftpUsername: String
  stripQuotesFromSegmentNames: Boolean
}

input CloneCampaignMessageGroupInput {
  campaignMessageGroupId: ID!
  companyId: ID!
  createNewCampaign: Boolean!
  userId: ID!
}

input CloneComposeCampaignInput {
  campaignId: ID!
  companyId: ID!
  userId: ID!
}

input CloneCreativeInput {
  companyId: ID!
  creativeConfig: JsonStringInput
  creativeId: ID!
  language: String
  name: String!

  # Used to change the the country block lists, should be set by the backend.
  rules: JsonStringInput @deprecated(reason: "deprecated")

  # Changed when the smsBody is set to new language default.
  smsBody: String
}

# Generated from com/attentivemobile/contracts/creative-service/creative/CreativeGraphService.proto
type CloneCreativePayload {
  creativeOrError: CloneCreativePayloadCreativeOrError
}

union CloneCreativePayloadCreativeOrError = Creative | CreativeMutationError

input CloneEdsJobInput {
  companyId: ID!
  description: String
  name: String!
  taskId: String!
}

union CloneEdsJobPayload = CloneEdsJobSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type CloneEdsJobSuccess {
  taskUuid: String
}

input CloneJourneyInput {
  companyId: ID!
  journeyId: ID!
}

# Generated from com/attentivemobile/contracts/journeys/api/Contract.proto
type CloneJourneyPayload {
  journey: Journey!
}

input ClonePollInput {
  companyId: ID!
  description: String
  name: String
  pollId: ID!
  shouldCloneKeywords: Boolean
}

union ClonePollPayload = ClonePollSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/survey/poll/Contract.proto
type ClonePollSuccess {
  newPoll: Poll
}

input CloseConciergeChatInput {
  chatId: ID!
}

# Generated from com/attentivemobile/contracts/concierge/service/Contract.proto
type CloseConciergeChatPayload {
  account: Account
}

enum Cluster {
  CLUSTER_BFCM
  CLUSTER_CORE
  CLUSTER_FEEDER
  CLUSTER_LOADTEST
  CLUSTER_NORMA
  CLUSTER_PANDORA
  CLUSTER_SANDBOX
  CLUSTER_SOURCE
  CLUSTER_UNKNOWN
  CLUSTER_VIRGO
}

# Generated from com/attentivemobile/contracts/user/profile/property/UserPropertyV2.proto
type CollectionPropertyValueV2 {
  jsonValues: [JSON!]!
}

# Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto
type Columns {
  conversions: [ConversionsFeedCols!]!
  general: [GeneralDataFeedCols!]!
}

input ColumnsInput {
  conversions: [ConversionsFeedCols!]
  general: [GeneralDataFeedCols!]
}

input CombineImagesInput {
  metadata: GifRequestMetadata
  urls: [String!]
}

union CombineImagesPayload = CombineImagesSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/composition/media/gif/Contract.proto
type CombineImagesSuccess {
  isWithinSizeLimit: Boolean!
  url: String!
}

enum CommonCreativeErrors {
  COMMON_CREATIVE_ERRORS_NAME_COLLISION
  COMMON_CREATIVE_ERRORS_UNKNOWN
}

enum CommonCreativeScheduleMutationErrors {
  COMMON_CREATIVE_SCHEDULE_ERRORS_OVERLAPPING_SCHEDULES
  COMMON_CREATIVE_SCHEDULE_ERRORS_UNKNOWN
}

# Generated from com/attentivemobile/contracts/company-service/Contract.proto
# Company details.
type Company implements Node {
  aIGeneratedTexts(after: String, before: String, first: Int, last: Int): AIGeneratedTextConnection
  accounts(after: String, filter: AccountFilter!, first: Int): AccountConnection

  # address_country should be read from the default region unless a different region is specified. The property on a region is named country_code
  addressCountry: String! @deprecated(reason: "deprecated")

  # address_line_1 should be read from the default region unless a different region is specified. The property on a region is named address_1
  addressLine1: String! @deprecated(reason: "deprecated")

  # address_line_2 should be read from the default region unless a different region is specified. The property on a region is named address_2
  addressLine2: String! @deprecated(reason: "deprecated")
  affiliateUsers(after: String, first: Int): AffiliateUserConnection
  aiBrandVoiceSettings: AIBrandVoiceSettings
  aiBrandVoiceSettingsV2: AIBrandVoiceSettingsV2
  aiGrowTrialEligibility: ClientEligibility
  aiJourneyDefaultMessages: [AIJourneyDefaultMessage!]
  aiJourneysConfig: GetConfigResponse
  aiProSubscriptionStatus: GetAiProSubscriptionStatusPayload
  allCompanyAccountPermissions(after: String, before: String, first: Int, last: Int): AccountPermissionsConnection
  applicationWebhookEventTypes: [WebhookEventType!]
  attentiveDomain: String!
  attributionSettings: AttributionSettings
  autoJourneys(statuses: [AutoJourneyStatus!]): ListAutoJourneysResponse
  availableBrandFonts: GetAvailableBrandFontsResponse
  availableJourneyTemplates(languages: [String!]): [JourneyTemplate!]
  availableJourneyTriggers: JourneyTriggers
  bestsellerProducts: ProductRecommendationsRedactedResponse @deprecated(reason: "deprecated")
  bestsellerProductsV2: GraphGetBestsellingProductsForCompanyResponse
  bestsellersRecommendations(excludedProductOriginIds: [String!], productDiversityCriteria: ProductDiversityCriteriaInput, productSegmentId: ID, userId: String!): ProductRecommendationsResponse
  blockCompanyInfo(companyInfoType: [BlockCompanyInfoType!]): [BlockCompanyInfo!]
  blogPosts(after: String, before: String, categoryName: String, first: Int, last: Int): BlogPostConnection

  # Filtered assets that a company has uploaded to the asset library service
  brandAssets(after: String, before: String, filters: AssetFilters, first: Int, folderId: ID, last: Int, orderByAndDirection: AssetsOrderByAndDirection): FolderChildrenConnection
  brandColorScheme: [BrandColor!]
  brandElementStyles: [BrandElementStyle!]
  brandImages(fileUuid: String): [BrandImage!]
  brandVoice: CompanyBrandVoice
  builtApplications(after: String, before: String, first: Int, last: Int): ApplicationConnection

  # business_hours should be read from the default region unless a different region is specified. The property on a region is named quiet_hours
  businessHours: BusinessHours! @deprecated(reason: "deprecated")
  cartLink: String!

  # city should be read from the default region unless a different region is specified. The property on a region is named locality
  city: String! @deprecated(reason: "deprecated")
  clientConversationUnreadCount: UnreadCountResponse
  clientConversations(after: String, filter: ClientConversationFilter!, first: Int, sort: ClientConversationSort!): ClientConversationConnection
  clientSegmentImports: [ClientSegmentImportsRow!]
  companyCodes(handler: String): CompanyCodeConnection
  companyGlobalRateLimits: CompanyGlobalRateLimitsPayload
  companyLinkParameters: CompanyLinkParametersResponse
  companyLinks: [CompanyLink!]
  companyVendorIntegrations(externalVendorApiId: ID, segmentId: ID, status: CompanyVendorIntegrationStatus, vendor: String): [CompanyVendorIntegration!]
  compatibleContent(channel: ComposeMessageChannel!, contentCategory: ContentCategory, contentMessageType: ContentMessageType, proposedAttachments: [NewAttachmentRequest!], proposedMediaUrls: [UrlInput!]): CompatibleContent
  conciergeCompanyDetails: ConciergeCompanyDetails
  conciergeSelfServeSettings: ConciergeSelfServeSettings
  conciergeSettings: ConciergeSettings
  contactCard: CompanyContactCard
  contentSettings(after: String, before: String, first: Int, last: Int, userId: ID): FindUserContentSettingsConnection
  conversations(after: String, first: Int, sort: ConversationSort!): ConversationConnection @deprecated(reason: "deprecated")
  conversationsSettings: ConversationsSettings
  country: String!
  countrySubdivisions: [CountrySubdivision!]
  couponSets(after: String, first: Int): CouponSetConnection
  creativeAssets: CreativeAssets
  creativeByName(name: String!): GetCreativeByNameResponse
  creativeExperiments(after: String, before: String, filter: ListCreativeExperimentsFilterProperties, first: Int, last: Int, sort: [ListCreativeExperimentsSort!], status: CreativeExperimentStatus): CreativeExperimentConnection
  creativePresets(after: String, before: String, creativeFormatId: String, first: Int, last: Int, readyToLaunch: Boolean): CreativePresetConnection

  # Provides a collection of Rules configured within the Creatives domain for the target Company.
  creativeRules(after: String, before: String, filter: ListCompanyCreativeRulesFilter, first: Int, last: Int): CreativeRuleConnection
  creativeScheduleWorkflows(after: String, before: String, filter: GetCreativeSchedulesRequestFilter, first: Int, last: Int, sort: [GetCreativeSchedulesRequestSort!]): CreativeWorkflowScheduleConnection
  creativeSchedules(after: String, before: String, filter: GetCreativeSchedulesRequestFilter, first: Int, last: Int, sort: [GetCreativeSchedulesRequestSort!]): CreativeScheduleConnection @deprecated(reason: "deprecated")
  creativeTemplates: CreativeTemplateConnection
  creatives(couponSetId: String, customAttributeName: String, status: String, subtype: String, type: String): CreativeConnection @deprecated(reason: "deprecated")
  creativesListWithABTests(after: String, before: String, filter: ListCreativesAndExperimentsFilterProperties, first: Int, last: Int, sort: [ListCreativesAndExperimentsSort!]): CreativesAndExperimentsConnection
  creativesV2(after: String, before: String, filter: ListCreativesFilterProperties, first: Int, last: Int, sort: [ListCreativesSort!]): CreativeConnection
  csTier: String
  currencies: [String!]
  customEventDefinitions(after: String, before: String, first: Int, last: Int): CustomEventDefinitionConnection
  customEventKeys(after: String, before: String, filter: ListCustomEventKeysRequestFilter, first: Int, last: Int): CustomEventKeyConnection

  # Custom Events sent by this Company
  customEvents(after: String, before: String, filter: ListCustomEventsRequestFilter, first: Int, last: Int): CustomEventsConnection
  customKeywords: [CustomKeyword!]

  # Only use if absolutely necessary. Should be removed when all services using custom properties are migrated to GQL.
  customPropertyDefinitionByInternalId(internalId: String!): CustomPropertyDefinition @deprecated(reason: "deprecated")
  customReport(reportId: String!): Report @deprecated(reason: "deprecated")
  customReportById(id: ID!): Report

  # Query a specific EDS sync associated with a specified company
  dataSync(syncId: ID!): Sync

  # The EDS syncs associated with a specified company
  dataSyncs(after: String, before: String, filter: ListSyncsFilter!, first: Int, last: Int): Syncs
  dataset(after: String, before: String, columns: [String!], datasetId: String!, filters: [DatasetFilter!], first: Int, last: Int, orderBy: [DatasetOrdering!], requestTimestamp: DateTime): DatasetConnection

  # Provides the default CDS, which has a raw status of active but might ultimately be inactive due to schedules.
  defaultCouponDisplayScreenCreative: GetActiveCouponDisplayScreenCreativePayload
  defaultRegion: Region
  defaultWelcomeJourney: Journey
  deleteRequests(after: String, before: String, filter: PrivacyRequestSearchFilter, first: Int, last: Int, sorting: PrivacyRequestSorting): DeleteRequestConnection
  dimensionValues(after: String, before: String, dimensionId: String!, first: Int, last: Int, search: ListDimensionValuesSearchInput): DimensionValueConnection
  displayName: String!
  domain: String
  draftProductSegment(filters: [ProductSegmentFilterInput!], includeProductOriginIds: Boolean): DraftProductSegment
  duplicatePollKeywords: GetDuplicateKeywordsResponse
  email: String!
  emailAccount(fieldMask: FieldMask): EmailAccount
  emailCampaignSchedulabilityStatus: EmailCampaignSchedulabilityStatus

  # email_fatigue_rule should be read from the default region unless a different region is specified.
  emailFatigueRule: CompanyEmailFatigueRule! @deprecated(reason: "deprecated")
  emailSettings: EmailSettings
  enabledAttachmentTypes: GetEnabledAttachmentTypesResponse
  etlConfigs(after: String, before: String, first: Int, last: Int): EtlConfigConnection
  eventReplays: EventReplaysResponse
  eventSummariesForProducts(ids: [ID!], lookBackDays: Int): EventSummariesForProductsPayload
  exportDataset(columns: [String!], datasetId: String!, filters: [DatasetFilter!], orderBy: [DatasetOrdering!]): ExportDatasetResponse
  externalId: Long!

  # fatigue_rule should be read from the default region unless a different region is specified.
  fatigueRule: CompanySmsFatigueRule! @deprecated(reason: "deprecated")
  generateMessageWorkflowStatus: FetchGenerateMessageWorkflowStatusPayload
  generativeAiOptInStatus: GetGenerativeAIOptInStatusResponse

  # ID of the company.
  id: ID!
  installedApplications(after: String, before: String, first: Int, last: Int): InstalledApplicationConnection
  integrationErrors(companyVendorIntegrationFeatureId: ID, companyVendorIntegrationId: ID, externalVendorApiId: ID, vendor: String): [IntegrationError!]
  internalId: Long!
  isAttentiveDomainAvailable(attentiveDomain: String!): Boolean
  isFeatureEnabled(name: String!): Boolean
  journeySources(nodeType: String!): JourneysWithNodeTypeResponse
  journeys(after: String, before: String, filter: JourneysFilter, first: Int, last: Int): JourneyConnection
  legacyReports(after: String, before: String, existingOnly: Boolean, first: Int, last: Int, searchTerm: String): LegacyReportConnection
  legacyScheduledReports(after: String, before: String, existingOnly: Boolean, first: Int, last: Int, searchTerm: String): LegacyScheduledReportConnection
  legacyTagConfig(includeReadOnly: Boolean): [LegacyTagConfig!]
  messageFeedback(after: String, filter: ClientMessageFeedbackFilterInput, first: Int, sort: MessageFeedbackSortInput): MessageFeedbackConnection
  messageTemplates(after: String, before: String, channel: Channel!, first: Int, last: Int, templateTypes: [MessagingTemplateType!]): MessageTemplateConnection
  messagingServices(filter: MessagingServiceFilter): [CompanyMessagingService!]
  metricDefinition(metricId: String!): MetricDefinition
  metrics(filters: [DimensionFilter!], groupings: [DimensionGrouping!], metricIds: [String!]!): [Metric!]
  metricsCatalog: MetricsCatalog
  metricsTable(after: String, before: String, filters: [DimensionFilter!], first: Int, groupings: [DimensionGrouping!], last: Int, metricIds: [String!]!, orderBy: [MetricsOrdering!], requestTimestamp: DateTime): MetricsTableConnection
  mfaEnabled: Boolean
  milestones: ListMilestonesResponse

  # Name of the company.
  name: String!
  nextBestActions(after: String, category: NextBestActionCategory, first: Int): NextBestActionConnection
  notifications(after: String, first: Int, notificationType: NotificationType): NotificationConnection
  onboardingSurveyOptions: ListOnboardingSurveySelectionsResponse
  paginatedProducts(after: String, before: String, filter: PaginatedProductsFilter, first: Int, last: Int): CompanyProductsConnection
  paymentsOffer(messageId: ID!): PaymentsOffer
  polls(after: String, before: String, first: Int, last: Int, paging: PollPaging): PollsConnection
  pollsV2(after: String, before: String, filter: PollsFilterV2, first: Int, last: Int, sorting: PollSorting): PollsConnection
  possibleReportRecipients: [ReportRecipient!]

  # privacy_link should be read from the default region unless a different region is specified. The property on a region is named privacy_url.
  privacyLink: String! @deprecated(reason: "deprecated")
  productCategories(after: String, before: String, filter: ProductCategoriesFilter, first: Int, last: Int): CompanyProductCategoriesConnection
  productCollections(after: String, before: String, filter: ProductCollectionsFilter, first: Int, last: Int): CompanyProductCollectionsConnection
  productPropertyOptions(after: String, before: String, filter: ProductPropertyOptionsFilter, first: Int, input: ProductPropertyOptionsInput!, last: Int): ProductPropertyOptionConnection
  productSegments(after: String, before: String, filter: ProductSegmentsFilter, first: Int, last: Int): ProductSegmentsConnection
  productTags(after: String, before: String, filter: ProductTagsFilter, first: Int, last: Int): CompanyProductTagsConnection
  products(query: String): GraphSearchProductsResponse
  region(regionId: ID!): Region
  regions: [Region!]
  report(reportId: String!): Report
  reports(after: String, before: String, first: Int, last: Int): ReportConnection
  scheduleEligibleReports(after: String, before: String, first: Int, last: Int, searchTerm: String): ReportConnection
  scheduledReports(after: String, before: String, first: Int, last: Int, searchTerm: String, sortOptions: ReportSortOptions): ReportConnection
  searchAttributes(after: String, before: String, filter: ListSearchAttributesFilter, first: Int, last: Int): SearchAttributeConnection
  segmentableProductProperties: GetSegmentableProductPropertiesResponse
  segments(after: String, before: String, filter: ListSegmentsFilter, first: Int, last: Int, orderBy: ListSegmentsOrderBy): SegmentConnection
  selectedBrandFontFamilies: [SelectedBrandFontFamily!]
  selectedBrandFonts(placement: Placement): GetSelectedBrandFontsResponse
  sendingDomains: [SendingDomain!]
  settings: CompanySettings!
  settingsUrl: String!
  sftpAccountStatus: SftpCognitoUserStatusResponse
  sharedMessageTemplates(after: String, before: String, channel: Channel!, first: Int, last: Int, templateTypes: [MessagingTemplateType!]): MessageTemplateConnection

  # state should be read from the default region unless a different region is specified. The property on a region is named region
  state: String! @deprecated(reason: "deprecated")
  status: String!

  # stop_to_stop_phrase should be read from automated messages on the default region's primary localized settings unless a different region is specified. The message type is AUTOMATED_MESSAGE_TYPE_UNSUBSCRIBE
  stopToStopPhrase: String @deprecated(reason: "deprecated")
  subscriberUploads(after: String, first: Int, jobTypeFilter: [SubscriberUploadJobType!]): SubscriberUploadJobConnection
  tagConfigReleases: [TagConfigRelease!] @deprecated(reason: "deprecated")
  tagConfigReleasesPaginated(after: String, before: String, first: Int, last: Int): TagConfigReleasesConnection
  tagHealthAutoInstallStatus: AutoInstallTagStatus
  tagHealthEventStats(entryDate: String): TagEventStats
  tags(after: String, before: String, filter: TagFilter!, first: Int, last: Int): TagsConnection
  templatizedPostback(companyVendorIntegrationId: ID!): [TemplatizedPostback!]

  # terms_link should be read from the default region unless a different region is specified. The property on a region is named terms_url
  termsLink: String! @deprecated(reason: "deprecated")
  textToBuyConfig: TextToBuyConfig

  # timezone should be read from the default region unless a different region is specified.
  timezone: String! @deprecated(reason: "deprecated")
  type: CompanyType!
  unifiedAutoJourney: AutoJourney

  # User associated with this company.
  user(email: String, phone: String): User
  userPropertyDefinitions(after: String, before: String, first: Int, includeDeleted: Boolean!, includeInternalProperties: Boolean!, last: Int): UserPropertyDefinitionConnection @deprecated(reason: "deprecated")
  userPropertyDefinitionsV2(after: String, before: String, filter: ListUserPropertyDefinitionFilter, first: Int, last: Int): UserPropertyDefinitionConnectionV2
  userSearchCounts(userSearchCountRequests: [UserSearchCountsRequest!]): BulkUserSearchCountsResponse
  vendorCompanyCards(after: String, before: String, filter: MobileWalletSearchFilter, first: Int, last: Int, sorting: MobileWalletSorting): VendorCompanyCardConnection
  warmupSeriesSizeAndDuration: WarmupSeriesSizeAndDuration

  # zipcode should be read from the default region unless a different region is specified. The property on a region is named postal_code
  zipcode: String! @deprecated(reason: "deprecated")
}

# Generated from com/attentivemobile/contracts/gateway/service/RateLimiting.proto
type CompanyApplicationRouteRateLimitsPayload {
  companyApplicationRouteRateLimits: [UpdateCompanyApplicationRouteRateLimitSuccess!]!
}

# Generated from com/attentivemobile/contracts/concierge/service/ConversationManager.proto
type CompanyBrandEmojiSettings {
  areEmojisEnabled: Boolean!
  restrictedEmojiIds: [String!]!
}

input CompanyBrandEmojiSettingsInput {
  areEmojisEnabled: Boolean!
  restrictedEmojiIds: [String!]
}

# Generated from com/attentivemobile/contracts/concierge/service/ConversationManager.proto
type CompanyBrandVoice {
  agentName: String
  blocklistedWords: [String!]!
  emojiSettings: CompanyBrandEmojiSettings!
  escalationTopics: [String!]!
  messageTone: MessageTone!
}

input CompanyBrandVoiceInput {
  agentName: String
  blocklistedWords: [String!]
  emojiSettings: CompanyBrandEmojiSettingsInput!
  escalationTopics: [String!]
  messageTone: MessageTone!
}

# Generated from com/attentivemobile/contracts/creative-service/companycodes/CompanyCode.proto
type CompanyCode {
  code: String!
  id: String!
}

# Generated from com/attentivemobile/contracts/creative-service/companycodes/CompanyCodesGraphService.proto
type CompanyCodeConnection {
  codes: [CompanyCodeEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/creative-service/companycodes/CompanyCodesGraphService.proto
type CompanyCodeEdge {
  cursor: String!
  node: CompanyCode!
}

# Generated from com/attentivemobile/contracts/company-service/Contract.proto
type CompanyConnection {
  edges: [CompanyEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/company-service/onboarding/Company.proto
type CompanyContactCard implements Node {
  active: Boolean!
  address: Address!
  company: Company
  companyLegalName: String!
  companyUrl: Url!
  displayName: String!
  email: String!
  id: ID!
  imageUrl: Url!
  inheritAddressFromCompany: Boolean!
  mediaUrl: Url!
  phones: [String!]!
  secondaryUrl: Url!
  title: String!
}

input CompanyContactCardInput {
  active: Boolean!
  address: AddressInput!
  companyId: ID!
  companyLegalName: String!
  companyUrl: UrlInput!
  displayName: String!
  email: String!
  imageUrl: UrlInput!
  inheritAddressFromCompany: Boolean!
  mediaUrl: UrlInput!
  phones: [String!]
  secondaryUrl: UrlInput!
  title: String!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto
type CompanyCurrenciesPayload {
  currencies: [String!]!
}

# Generated from com/attentivemobile/contracts/company-service/Contract.proto
type CompanyEdge {
  cursor: String!
  node: Company!
}

# Generated from com/attentivemobile/contracts/company-service/Contract.proto
type CompanyEmailFatigueRule {
  duration: Int!
  frequency: Int!
  id: String
}

# Generated from com/attentivemobile/contracts/gateway/service/RateLimiting.proto
type CompanyGlobalRateLimitsPayload {
  companyCompanyGlobalRateLimitResponses: [UpdateCompanyGlobalRateLimitSuccess!]!
}

# Generated from com/attentivemobile/contracts/company-service/companylink/Model.proto
type CompanyLink {
  company: Company
  externalId: Long!
  id: String!
  shortLink: String!
  target: String!
}

input CompanyLinkInput {
  companyId: ID!
  target: String!
}

# Generated from com/attentivemobile/contracts/composition/Contract.proto
type CompanyLinkParameter {
  company: Company
  isActive: Boolean!
  isCustom: Boolean!
  key: String!
  messageChannel: CompanyLinkParameterMessageChannel!
  messageType: CompanyLinkParameterMessageType!
  value: String!
}

input CompanyLinkParameterInput {
  isActive: Boolean!
  isCustom: Boolean!
  key: String!
  messageChannel: CompanyLinkParameterMessageChannel!
  messageType: CompanyLinkParameterMessageType!
  value: String!
}

enum CompanyLinkParameterMessageChannel {
  COMPANY_LINK_PARAMETERS_MESSAGE_CHANNEL_EMAIL
  COMPANY_LINK_PARAMETERS_MESSAGE_CHANNEL_GLOBAL
  COMPANY_LINK_PARAMETERS_MESSAGE_CHANNEL_TEXT
  COMPANY_LINK_PARAMETERS_MESSAGE_CHANNEL_UNKNOWN
}

enum CompanyLinkParameterMessageType {
  COMPANY_LINK_PARAMETERS_MESSAGE_TYPE_CAMPAIGN
  COMPANY_LINK_PARAMETERS_MESSAGE_TYPE_JOURNEY
  COMPANY_LINK_PARAMETERS_MESSAGE_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/composition/Contract.proto
type CompanyLinkParametersResponse {
  company: Company
  companyLinkParameter: [CompanyLinkParameter!]!
}

# Generated from com/attentivemobile/contracts/phonenumbers/external/Contract.proto
type CompanyMessagingService {
  active: Boolean!
  company: Company
  id: String!
  messagingServiceProviderId: String!
  outboundRateLimitWeight: Long!
  phoneNumber: PhoneNumber
  primary: Boolean!
  status: MessagingServiceStatus!
  subscriberAssignmentWeight: Long!
}

# Generated from com/attentivemobile/contracts/concierge/service/Notes.proto
type CompanyNotes {
  notes: String!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto
type CompanyProductCategoriesConnection {
  edges: [ProductCategoryEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto
type CompanyProductCollectionsConnection {
  edges: [ProductCollectionEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto
type CompanyProductSummaryEdge {
  cursor: String!
  node: ProductSummary!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto
type CompanyProductTagsConnection {
  edges: [ProductTagEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto
type CompanyProductsConnection {
  edges: [CompanyProductSummaryEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/company-service/region/Model.proto
type CompanyRegionFatigueRule {
  companyRegion: Region

  # Duration in seconds.
  duration: Int!
  fatigueRuleType: CompanyRegionFatigueRuleType!
  frequency: Int!
}

input CompanyRegionFatigueRuleInput {
  companyRegionId: ID!

  # Duration in seconds.
  duration: Int!
  fatigueRuleType: CompanyRegionFatigueRuleType!
  frequency: Int!
}

enum CompanyRegionFatigueRuleType {
  COMPANY_REGION_FATIGUE_RULE_TYPE_EMAIL
  COMPANY_REGION_FATIGUE_RULE_TYPE_MESSAGE
  COMPANY_REGION_FATIGUE_RULE_TYPE_UNKNOWN
}

input CompanySearchFilter {
  includeReadonly: Boolean
  searchTerm: String
}

# Generated from com/attentivemobile/contracts/company-service/companysetting/Model.proto
type CompanySettings {
  googleAnalytics: GoogleAnalytics! @deprecated(reason: "deprecated")
  linkParameters: [LinkParameter!]!
}

input CompanySettingsInput {
  # google analytics
  googleAnalytics: GoogleAnalyticsInput!

  # link parameters
  linkParameters: [LinkParameterInput!]
}

# Generated from com/attentivemobile/contracts/concierge/service/Template.proto
type CompanySettingsSource {
  company: Company
}

# Generated from com/attentivemobile/contracts/company-service/Contract.proto
type CompanySmsFatigueRule {
  duration: Int!
  frequency: Int!
  id: String
}

# Generated from com/attentivemobile/contracts/concierge/service/Template.proto
type CompanyTemplateConnection {
  edges: [TemplateEdge!]!
  pageInfo: PageInfo!
}

type CompanyTransition {
  companyId: String
}

enum CompanyType {
  COMPANY_TYPE_ATTENTIVE
  COMPANY_TYPE_CLIENT
  COMPANY_TYPE_NON_STANDARD
  COMPANY_TYPE_TEST
  COMPANY_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto
type CompanyVendorIntegration implements Node {
  company: Company
  companyVendorIntegrationFeatures: [CompanyVendorIntegrationFeature!]
  discount(couponCode: String!): Discount
  externalVendorApi: ExternalVendorApi
  externalVendorData(facebookFilter: FacebookAdsExternalVendorFilter, fields: [ExternalVendorDataField!]): ExternalVendorDataWrapper
  id: ID!
  integrationErrors(companyVendorIntegrationFeatureId: ID, externalVendorApiId: ID, vendor: String): [IntegrationError!]
  metadata: CompanyVendorIntegrationMetadata
  status: CompanyVendorIntegrationStatus!
}

# Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto
type CompanyVendorIntegrationFeature implements Node {
  company: Company
  companyVendorIntegration: CompanyVendorIntegration
  created: DateTime!
  enabled: Boolean!
  errorState: String!
  externalVendorFeature: ExternalVendorFeature
  id: ID!
  integrationErrors(companyVendorIntegrationId: ID, externalVendorApiId: ID, vendor: String): [IntegrationError!]
  metadata: CompanyVendorIntegrationFeatureMetadata
  triggers: [CompanyVendorIntegrationFeatureTrigger!]!
}

union CompanyVendorIntegrationFeatureMetadata = CvifGenericMetadata

# Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto
type CompanyVendorIntegrationFeatureTrigger {
  companyVendorIntegrationFeatureId: String!
  conditions: [CompanyVendorIntegrationFeatureTriggerCondition!]!
  enabled: Boolean!
  eventType: EventType!
  id: String!
}

# Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto
type CompanyVendorIntegrationFeatureTriggerCondition {
  companyVendorIntegrationFeatureTriggerId: String!
  enabled: Boolean!
  eventFilterField: CompanyVendorIntegrationFeatureTriggerConditionEventFilterField
  id: String!
}

union CompanyVendorIntegrationFeatureTriggerConditionEventFilterField = ConditionMessageChannel | ConditionSubscriptionChannel | ConditionSubscriptionType

union CompanyVendorIntegrationMetadata = CviGenericMetadata

enum CompanyVendorIntegrationStatus {
  COMPANY_VENDOR_INTEGRATION_STATUS_UNKNOWN
  CONNECTED
  DISABLED
  ENABLED
}

# Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/SearchDefinitionOutput.proto
type Comparison {
  field: SearchFieldIdentifier!
  typeComparison: TypeComparison!
}

enum ComparisonDateFilterPeriodType {
  COMPARISON_DATE_FILTER_CUSTOM_PERIOD
  COMPARISON_DATE_FILTER_PREVIOUS_PERIOD
  COMPARISON_DATE_FILTER_PREVIOUS_YEAR
  COMPARISON_DATE_FILTER_UNKNOWN
}

input ComparisonInput {
  field: SearchFieldInput!
  typeComparison: TypeComparisonInput!
}

# Generated from com/attentivemobile/contracts/reports/api/Contract.proto
type ComparisonPeriod {
  comparisonPeriodType: ComparisonDateFilterPeriodType
  endDate: DateTime
  startDate: DateTime
}

input ComparisonPeriodInput {
  comparisonPeriodType: ComparisonDateFilterPeriodType
  endDate: DateTime
  startDate: DateTime
}

# Generated from com/attentivemobile/contracts/composition/MessageAttachment.proto
type CompatibleContent {
  composeContent: [ComposeContent!]!
  contentCategory: ContentCategory
  contentMessageType: ContentMessageType
}

# Generated from com/attentivemobile/contracts/company-service/onboarding/Company.proto
type CompleteCompanyCreationPayload {
  company: Company
}

input CompleteCompanyCreationRequest {
  addressCountry: String!
  addressLine1: String!
  addressLine2: String!

  # the company's unique attentive domain e.g. attentive.attn.tv
  attentiveDomain: String!
  city: String!
  companyId: ID!

  # the company's name as it will appear in the UI
  displayName: String!

  # the company's domain i.e. attentive.com
  domain: String

  # a url pointing to the company's privacy policy
  privacyLink: String!
  salesforceOpportunityId: String
  state: String!

  # a url pointing to the company's terms and conditions
  termsLink: String!
  timezone: String!
  zipcode: String!
}

input CompleteMilestonesRequest {
  companyId: ID!
  milestoneKeys: [MilestoneKey!]
}

# Generated from com/attentivemobile/contracts/company-service/onboarding/Milestone.proto
type CompleteMilestonesResponse {
  company: Company
}

# Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto
type ComposeCampaign implements Node {
  campaignId: String!
  campaignMessageGroupIdOrder: [ID!]!
  campaignMessageGroups(after: String, before: String, filter: ComposeCampaignsFilter, first: Int, last: Int): CampaignMessageGroupsConnection
  campaignStatus: ComposeCampaignStatus!
  campaignType: ComposeCampaignType!
  company: Company
  created: DateTime!
  id: ID!
  isHidden: Boolean!
  name: String!
  rawUuid: String!
  updated: DateTime!
  validationErrors: [CampaignCompositionValidationError!]!
}

# Generated from com/attentivemobile/contracts/campaigns/service/Contract.proto
type ComposeCampaignEdge {
  cursor: String!
  node: ComposeCampaign!
}

# Generated from com/attentivemobile/contracts/campaigns/service/Contract.proto
type ComposeCampaignMessageGroupPayload {
  campaign: ComposeCampaign!
  campaignMessageGroup: CampaignMessageGroup!
}

# Generated from com/attentivemobile/contracts/campaigns/service/Contract.proto
type ComposeCampaignPayload {
  campaign: ComposeCampaign!
}

# Generated from com/attentivemobile/protobuf/common/compose/Message.proto
type ComposeCampaignReference {
  campaign: ComposeCampaign
  campaignMessageGroup: CampaignMessageGroup
}

enum ComposeCampaignStatus {
  COMPOSE_CAMPAIGN_STATUS_DRAFT
  COMPOSE_CAMPAIGN_STATUS_SCHEDULED
  COMPOSE_CAMPAIGN_STATUS_SENDING_STARTED
  COMPOSE_CAMPAIGN_STATUS_SENT
  COMPOSE_CAMPAIGN_STATUS_UNKNOWN
}

enum ComposeCampaignType {
  COMPOSE_CAMPAIGN_TYPE_MULTI_MESSAGE
  COMPOSE_CAMPAIGN_TYPE_SINGLE_MESSAGE
  COMPOSE_CAMPAIGN_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/campaigns/service/Contract.proto
type ComposeCampaignsConnection {
  edges: [ComposeCampaignEdge!]!
  pageInfo: PageInfo!
  totalCount: Long!
}

# Filters to apply to ComposeCampaign queries. Note that currently some of these filters are also applied directly to CampaignMessageGroups.
input ComposeCampaignsFilter {
  autoCampaignFilter: AutoCampaignFilter
  campaignId: [ID!]
  campaignMessageGroupIds: [ID!]
  campaignMessageGroupStatuses: [CampaignMessageGroupStatus!] @deprecated(reason: "deprecated")
  composeMessageIds: [ID!]
  dateRange: DateTimeRange
  isHidden: Boolean
  listComposeUiFilter: [ListComposeCampaignUiFilter!]
  messageChannels: [ComposeMessageChannel!]

  # The name of the CC/CMG. Applies case insensitive searching and matches on partials.
  name: String
  tagUuids: [String!]
  textMessagingService: TextMessagingService
  types: [CampaignMessageGroupType!]
}

# Generated from com/attentivemobile/contracts/composition/MessageAttachment.proto
type ComposeContent {
  attachmentType: AttachmentType!
  macroConfigurations: MacroConfiguration!
  macroRoot: MacroAttribute!
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type ComposeMessage implements Node {
  attachments: [Attachment!]!
  channel: ComposeMessageChannel!
  channelProperties: ChannelProperties!
  company: Company
  compatibleContent: CompatibleContent!
  created: DateTime!
  id: ID!
  ignoreFatigue: Boolean!
  internalId: Long!
  mediaType: String!
  mediaUrls: [Url!]!
  messageTags: [ComposeMessageTag!]!
  name: String!
  publishMessageValidationErrors: [PublishMessageValidationError!]!
  status: ComposeMessageStatus!
  subtype: String!
  type: ComposeMessageType!
  updated: DateTime!
  utmParameters: [UtmParametersEntry!]!
  validationErrors: [CompositionValidationError!]! @deprecated(reason: "deprecated")
}

enum ComposeMessageChannel {
  COMPOSE_MESSAGE_CHANNEL_EMAIL
  COMPOSE_MESSAGE_CHANNEL_PUSH
  COMPOSE_MESSAGE_CHANNEL_RBM
  COMPOSE_MESSAGE_CHANNEL_TEXT
  COMPOSE_MESSAGE_CHANNEL_UNKNOWN
}

# Generated from com/attentivemobile/protobuf/common/compose/Message.proto
type ComposeMessageReference {
  composeMessage: ComposeMessage
}

enum ComposeMessageStatus {
  COMPOSE_MESSAGE_STATUS_ACTIVE
  COMPOSE_MESSAGE_STATUS_DRAFT
  COMPOSE_MESSAGE_STATUS_UNKNOWN
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type ComposeMessageTag {
  tag: String!
  type: ComposeMessageTagType!
}

input ComposeMessageTagInput {
  tag: String!
  type: ComposeMessageTagType!
}

enum ComposeMessageTagType {
  COMPOSE_MESSAGE_TAG_INTERNAL
  COMPOSE_MESSAGE_TAG_PUBLIC
}

# Generated from com/attentivemobile/protobuf/common/compose/Message.proto
type ComposeMessageTemplate implements Node {
  channel: Channel!
  company: Company
  created: DateTime!
  deleted: DateTime
  id: ID!
  metadata: ComposeMessageTemplateMetadata
  syncedReferences: MessageTemplateReferences!
  templateName: String!
  updated: DateTime!
}

# Generated from com/attentivemobile/contracts/compose/api/Contract.proto
type ComposeMessageTemplateEdge {
  cursor: String!
  node: ComposeMessageTemplate!
}

input ComposeMessageTemplateMetaDataInput {
  emailTemplate: EmailTemplateInput
  textTemplate: TextTemplateInput
}

union ComposeMessageTemplateMetadata = EmailTemplate | TextTemplate

# Generated from com/attentivemobile/protobuf/common/compose/Message.proto
type ComposeMessageTemplateReference {
  template: ComposeMessageTemplate
}

enum ComposeMessageType {
  COMPOSE_MESSAGE_TYPE_AI_JOURNEY
  COMPOSE_MESSAGE_TYPE_CAMPAIGN
  COMPOSE_MESSAGE_TYPE_CONCIERGE
  COMPOSE_MESSAGE_TYPE_CONVERSATION
  COMPOSE_MESSAGE_TYPE_JOURNEY
  COMPOSE_MESSAGE_TYPE_PAYMENT
  COMPOSE_MESSAGE_TYPE_TRANSACTIONAL
  COMPOSE_MESSAGE_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type CompositionValidationError {
  message: String!
  validationErrorInformation: ValidationErrorInformation!
}

union CompressMMSImageForUIPayload = CompressMMSImageForUISuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/composition/media/gif/Contract.proto
type CompressMMSImageForUISuccess {
  url: String!
}

input CompressMMSImageInput {
  companyId: ID!
  url: String!
}

# Generated from com/attentivemobile/contracts/concierge/service/Contract.proto
type Concierge {
  averageResponseTime: GetAverageResponseTimeResponse
  companyMacros(companyId: ID): GetCompanyMacrosResponse
  specializations(after: String, first: Int, searchTerm: String, sort: ConciergeSpecializationsSort): ConciergeSpecializationsConnection
  unhandledUsers: UnhandledUsersConnection!
}

# Generated from com/attentivemobile/contracts/concierge/service/Contract.proto
type ConciergeAgent {
  account: Account
  capacity: Long!
  chats: [ConciergeChat!]!
  isAvailable: Boolean!
  isEnrolledIntoChats: Boolean!
}

# Generated from com/attentivemobile/contracts/concierge/service/ShoppingCart.proto
type ConciergeCartCoupon {
  couponCode: String!
  isCouponCodeEnteredManually: Boolean!
}

input ConciergeCartCouponInput {
  couponCode: String!
  isCouponCodeEnteredManually: Boolean!
}

input ConciergeCartItemInput {
  lastUpdated: DateTime!
  productVariantId: ID!
  quantity: Int!
}

# Generated from com/attentivemobile/contracts/concierge/service/Contract.proto
type ConciergeChat {
  id: ID!
  latestInbound: DateTime
  latestOutbound: DateTime
  user: User
}

# Generated from com/attentivemobile/contracts/concierge/service/Company.proto
type ConciergeCompanyDetails {
  escalationEmails: GetEscalationEmailsResponse
  notes: CompanyNotes
  promotions: [ConciergeCompanyPromotion!]!
  specialization: ConciergeSpecialization
}

# Generated from com/attentivemobile/contracts/concierge/service/CompanyPromotions.proto
type ConciergeCompanyPromotion {
  end: DateTime
  id: ID!
  limitedTime: Boolean!
  note: String!
  start: DateTime
  startPromoting: DateTime
  title: String!
  type: ConciergeCompanyPromotionType!
}

enum ConciergeCompanyPromotionType {
  COUPON
  EVENT
}

# Generated from com/attentivemobile/contracts/concierge/service/CompanyPromotions.proto
type ConciergeCompanyPromotionsResponse {
  promotions: [ConciergeCompanyPromotion!]!
}

# Generated from com/attentivemobile/contracts/concierge/service/ConversationManager.proto
type ConciergeIncentiveConfiguration {
  isOfferEnabled: Boolean!
  offer: CouponSet
}

input ConciergeIncentiveConfigurationInput {
  isOfferEnabled: Boolean!
  offerDomainId: String
}

# Generated from com/attentivemobile/contracts/concierge/service/ConversationManager.proto
type ConciergeJourneyControls {
  incentiveConfiguration: ConciergeIncentiveConfiguration!
  isConciergeEnabled: Boolean!
}

enum ConciergeLinkType {
  CONCIERGE_LINK_TYPE_AGENT_CLICK_TO_BUY_CHECKOUT
  CONCIERGE_LINK_TYPE_ML_PRODUCT_RECOMMENDATION
}

# Generated from com/attentivemobile/contracts/concierge/service/SelfServe.proto
type ConciergeSelfServeSettings {
  additionalUrls: [String!]!
  agentName: String
  companyId: String!
  conciergeRegistrationName: String
  conciergeSelfServeStatus: ConciergeSelfServeStatus!
  couponSet: CouponSet
  customUrlParameters: [CustomUrlParametersEntry!]!
  customerServiceEmail: String
  domesticShippingTimeframe: String
  escalationEmails: [String!]!
  faqUrl: String
  freeShippingPricePoint: String
  internationalShippingTimeframe: String
  isUtmTrackingEnabled: Boolean!
  journeyTemplateSiteVisitorId: Long!
  messageTone: MessageTone
  processingTimeBeforeShipping: String
  returnPolicyUrl: String
  shippingProviderOther: String
  shippingProviders: [ShippingProvider!]!
  siteVisitorJourneyId: Long
  utmCampaign: String
}

enum ConciergeSelfServeStatus {
  CONCIERGE_SELF_SERVE_STATUS_AGENT_PREFERENCES
  CONCIERGE_SELF_SERVE_STATUS_COMPLETED
  CONCIERGE_SELF_SERVE_STATUS_CUSTOMER_SUPPORT
  CONCIERGE_SELF_SERVE_STATUS_SHIPPING
  CONCIERGE_SELF_SERVE_STATUS_SIGN_UP
  CONCIERGE_SELF_SERVE_STATUS_UNKNOWN
}

# Generated from com/attentivemobile/contracts/concierge/service/Message.proto
type ConciergeSession {
  cart: SavedConciergeCart
  end: DateTime
  sessionId: String!
  source: ConciergeSessionSource
  start: DateTime
}

union ConciergeSessionSource = CampaignSource | JourneySource | LegalMessageSource | SubscriberInitiated

# Generated from com/attentivemobile/contracts/concierge/service/ConversationManager.proto
type ConciergeSettings {
  isConciergeEnabled: Boolean!
  isRouteAllInboundsEnabled: Boolean!
}

# Generated from com/attentivemobile/contracts/concierge/service/Specialization.proto
type ConciergeSpecialization implements Node {
  agentCount: Long!
  agents: [ConciergeSpecializedAgent!]!
  companies: [ConciergeSpecializedCompany!]!
  description: String
  id: ID!
  name: String!
  strictRouting: Boolean!
}

# Generated from com/attentivemobile/contracts/concierge/service/Specialization.proto
type ConciergeSpecializationEdge {
  cursor: String!
  node: ConciergeSpecialization!
}

# Generated from com/attentivemobile/contracts/concierge/service/Specialization.proto
type ConciergeSpecializationsConnection {
  edges: [ConciergeSpecializationEdge!]!
  pageInfo: PageInfo!
  totalCount: Long!
}

input ConciergeSpecializationsSort {
  column: ConciergeSpecializationsSortColumn!
  direction: ConciergeSpecializationsSortDirection!
}

enum ConciergeSpecializationsSortColumn {
  CONCIERGE_SPECIALIZATIONS_SORT_COLUMN_NAME
}

enum ConciergeSpecializationsSortDirection {
  CONCIERGE_SPECIALIZATIONS_SORT_DIRECTION_ASC
  CONCIERGE_SPECIALIZATIONS_SORT_DIRECTION_DESC
}

# Generated from com/attentivemobile/contracts/concierge/service/Specialization.proto
type ConciergeSpecializedAgent {
  account: Account
}

# Generated from com/attentivemobile/contracts/concierge/service/Specialization.proto
type ConciergeSpecializedCompany {
  company: Company
}

# Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto
type ConditionMessageChannel {
  messageChannel: MessagingChannel!
}

# Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto
type ConditionSubscriptionChannel {
  subscriptionChannel: SubscriptionChannel!
}

# Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto
type ConditionSubscriptionType {
  subscriptionType: SubscriptionType!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type ConfigurationNotificationConnection {
  edges: [AdminNotificationConfigurationEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto
type ConfiguredIntegrationPayload {
  companyVendorIntegration: CompanyVendorIntegration
  configuredParameters: [ConfiguredParametersEntry!]!
  editable: Boolean!
  externalVendorApi: ExternalVendorApi
  features: [Feature!]!
  status: CompanyVendorIntegrationStatus!
}

# Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto
type ConfiguredParametersEntry {
  key: String!
  value: String!
}

input ConfirmResetSftpUserPasswordInput {
  companyId: ID!
  confirmationCode: String!
  password: String!
}

# Generated from com/attentivemobile/contracts/solutions/sftp/SftpUser.proto
type ConfirmResetSftpUserPasswordPayload {
  responseMsg: String!
}

input ConfirmSftpSettingsInput {
  sftpSettings: SftpSettingsInput!
}

union ConfirmSftpSettingsPayload = ConfirmSftpSettingsSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type ConfirmSftpSettingsSuccess {
  responseCode: Int!
  sftpConnectionStatus: SftpConnectionStatus!
  success: Boolean!
}

input ConfirmSnowflakeSettingsInput {
  companyId: ID
  snowflakeSettings: SnowflakeSecureDataSharingInput!
}

union ConfirmSnowflakeSettingsPayload = ConfirmSnowflakeSettingsSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type ConfirmSnowflakeSettingsSuccess {
  result: SnowflakeSettingsConfirmationResult
  snowflakeSettings: SnowflakeSecureDataSharingSettings!
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type ConflictingAttachments {
  attachments: [Attachment!]!
}

enum ContentCategory {
  CONTENT_CATEGORY_MARKETING
  CONTENT_CATEGORY_TRANSACTIONAL
  CONTENT_CATEGORY_UNSPECIFIED
  CONTENT_CATEGORY_WELCOME
}

enum ContentClass {
  CONTENT_CLASS_FEATURE_ANNOUNCEMENT_MODAL
  CONTENT_CLASS_MODAL
  CONTENT_CLASS_NEXT_BEST_ACTIONS
  CONTENT_CLASS_UNKNOWN
}

enum ContentMessageType {
  CONTENT_MESSAGE_TYPE_CAMPAIGN
  CONTENT_MESSAGE_TYPE_JOURNEY
  CONTENT_MESSAGE_TYPE_UNSPECIFIED
}

input ContextEvaluationItem {
  key: String!
  value: String!
}

# Generated from com/attentivemobile/contracts/conversations/v1/Conversation.proto
type Conversation {
  isUnread: Boolean!
  phoneNumber: String!
  previewText: String!
  timestamp: DateTime!
  user: User
}

# Generated from com/attentivemobile/contracts/conversations/v1/Conversation.proto
type ConversationConnection {
  edges: [ConversationEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/conversations/v1/Conversation.proto
type ConversationEdge {
  cursor: String!
  node: Conversation!
}

enum ConversationSort {
  LATEST
  UNREAD
}

# Generated from com/attentivemobile/contracts/concierge/service/Template.proto
type ConversationSource {
  user: User
}

# Generated from com/attentivemobile/protobuf/common/targeting/UserProperty.proto
type ConversationalState {
  conversationId: String!
  isEngagedWithAgent: Boolean!
}

# Generated from com/attentivemobile/contracts/concierge/service/ConversationManager.proto
type ConversationsSettings {
  conciergeJourneyControls: [ConciergeJourneyControls!]!
  escalationEmails: [String!]!
  incentiveConfiguration: ConciergeIncentiveConfiguration!
  isConciergeForCampaignsEnabled: Boolean!
  isRouteAllInboundsEnabled: Boolean!
  utmConfiguration: UtmConfiguration!
}

enum ConversionsFeedCols {
  AMOUNT
  CLIENT_ID_CONVERSIONS
  CLIENT_USER_ID_CONVERSIONS
  COMPANY_ID_CONVERSIONS
  COUPON
  EMAIL_CONVERSIONS
  EVENT_UUID
  EXTERNAL_ID_CONVERSIONS
  MESSAGE_ID_CONVERSIONS
  MESSAGE_NAME_CONVERSIONS
  MESSAGE_SUBTYPE_CONVERSIONS
  MESSAGE_TEXT_CONVERSIONS
  MESSAGE_TYPE_CONVERSIONS
  ORDER_ID_CONVERSIONS
  PARENT_MESSAGE_NAME_CONVERSIONS
  PHONE_CONVERSIONS
  PRODUCT_SKUS
  QUANTITY_CONVERSIONS
  TIMESTAMP_CONVERSIONS
  UUID_CONVERSIONS
}

input CopyMessageInput {
  companyId: ID!
  excludeContent: Boolean
  sourceMessageId: ID!
  targetMessagesToOverwrite: [TargetMessageInput!]
}

# Generated from com/attentivemobile/contracts/composition/Contract.proto
type CopyMessagePayload {
  copiedMessages: [ComposeMessage!]!
}

enum CopyRecipientType {
  COPY_RECIPIENT_TYPE_BCC
  COPY_RECIPIENT_TYPE_CC
}

# Generated from com/attentivemobile/contracts/message/explorer/Contract.proto
type CountResponse {
  totalCount: Long
}

# Generated from com/attentivemobile/contracts/geography/Location.proto
type Country implements Node {
  countrySubdivisions: [CountrySubdivision!]

  # ISO 3166 country code
  id: ID!
  isoCode: String!
  name: String!
  postalCodes(adjacent: ListAdjacentPostalCodesInput, after: String, first: Int): PostalCodeConnection
}

# Generated from com/attentivemobile/contracts/geography/Location.proto
type CountrySubdivision implements Node {
  country: Country

  # ISO 3166 country code
  id: ID!
  isoCode: String!
  name: String!
}

# Generated from com/attentivemobile/contracts/solutions/couponallocator/CouponAllocator.proto
type CouponAllocationJob {
  company: Company
  companyName: String! @deprecated(reason: "deprecated")
  dateRange: AllocationDateRange!
  endTime: String! @deprecated(reason: "deprecated")
  jobId: String!
  progress: String!
  startTime: String! @deprecated(reason: "deprecated")
  status: String!
}

input CouponAssignmentInput {
  couponSetId: ID!
  userId: ID!
}

# Generated from com/attentivemobile/contracts/coupon/Contract.proto
type CouponAssignmentPayload {
  couponCode: String!
}

# Generated from com/attentivemobile/contracts/messaging/automation/aijourneys/onboarding/AIJourneyLinkConfiguration.proto
type CouponDisplayScreenConfig {
  # This field is ignored. Included to maintain compatibility with the graph.
  emptyUrl: String
}

input CouponDisplayScreenConfigInput {
  # This field is ignored. Included to maintain compatibility with the graph.
  emptyUrl: String
}

# Generated from com/attentivemobile/contracts/coupon/CouponContract.proto
type CouponSet implements Node {
  autoRefresh: Boolean!
  codeTemplate: String
  company: Company
  countAssignable: Int
  couponUploads(after: String, first: Int): CouponUploadConnection
  couponUrlTemplate: String
  couponValue: CouponValue
  created: DateTime!
  description: String
  distributionTtlInSeconds: Long
  distributionTtlUnit: DistributionUnit
  externalId: Long
  id: ID!
  internalId: Long!
  name: String!
  staticCode: String
  status: CouponSetStatus!
  totalCount: Int
  type: CouponSetType!
  updated: DateTime!
  uploadStatus: CouponUploadStatus!
}

# Generated from com/attentivemobile/contracts/coupon/CouponContract.proto
type CouponSetConnection {
  edges: [CouponSetEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/coupon/CouponContract.proto
type CouponSetEdge {
  cursor: String!
  node: CouponSet!
}

enum CouponSetStatus {
  ACTIVE
  COUPON_SET_STATUS_UNKNOWN
  INACTIVE
}

enum CouponSetType {
  COUPON_SET_TYPE_UNKNOWN
  DYNAMIC
  STATIC
}

enum CouponType {
  CODE
  URL
}

# Generated from com/attentivemobile/contracts/coupon/Contract.proto
type CouponUpload implements Node {
  countCreated: Long!
  countDuplicate: Long!
  countUploaded: Long!
  couponSet: CouponSet
  created: DateTime!
  distributionStart: DateTime
  distributionStop: DateTime
  filename: String!
  id: ID!
  results: String!
  updated: DateTime!
  uploadStatus: CouponUploadStatus!
}

# Generated from com/attentivemobile/contracts/coupon/Contract.proto
type CouponUploadConnection {
  edges: [CouponUploadEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/coupon/Contract.proto
type CouponUploadEdge {
  cursor: String!
  node: CouponUpload!
}

enum CouponUploadStatus {
  COUPON_UPLOAD_STATUS_UNKNOWN
  COUPON_UPLOAD_STATUS_UPLOAD_ACTIVE
  COUPON_UPLOAD_STATUS_UPLOAD_ERROR
  COUPON_UPLOAD_STATUS_UPLOAD_PENDING
  COUPON_UPLOAD_STATUS_UPLOAD_SYNCING
}

# Generated from com/attentivemobile/contracts/coupon/Contract.proto
type CouponValue {
  currencyCode: String
  value: String!
  valueType: CouponValueType!
}

input CouponValueInput {
  currencyCode: String
  value: String!
  valueType: CouponValueType!
}

enum CouponValueType {
  COUPON_VALUE_TYPE_FLAT_FIXED_CURRENCY
  COUPON_VALUE_TYPE_PERCENT
  COUPON_VALUE_TYPE_UNKNOWN
}

input CreateAIBrandVoiceSettingsInput {
  aiBrandVoiceSettings: AIBrandVoiceSettingsInput!
  companyId: ID!
}

union CreateAIBrandVoiceSettingsPayload = CreateAIBrandVoiceSettingsSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/messaging/automation/brandvoice/AIBrandVoice.proto
type CreateAIBrandVoiceSettingsSuccess {
  aiBrandVoiceSettings: AIBrandVoiceSettings!
  statusText: String!
}

input CreateAdminNotificationConfigurationInput {
  adminNotificationConfiguration: AdminNotificationConfigurationInput!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type CreateAdminNotificationConfigurationPayload {
  adminNotificationConfiguration: AdminNotificationConfiguration!
}

input CreateAgentTemplateInput {
  companyId: Long
  shortcut: String
  sourceLinkId: ID
  sourceType: SourceType
  text: String!
  title: String!
}

input CreateAgentTemplateRequestInput {
  companyId: Long
  notes: String
  requestedBy: ID
  shortcut: String
  sourceLinkId: ID!
  sourceType: SourceType!
  text: String!
  title: String!
}

input CreateAiGrowTrialEligibilityInput {
  companyId: ID!
  eligibilityStatus: AiGrowTrialEligibilityStatus!
  formSubmittedDate: DateTime
  signupFormLink: String
}

union CreateAiGrowTrialEligibilityPayload = CreateAiGrowTrialEligibilitySuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/multiproductenablement/service/AiGrowTrialStatusGraphService.proto
type CreateAiGrowTrialEligibilitySuccess {
  clientEligibility: ClientEligibility!
}

input CreateAiProTrialSubscriptionInput {
  companyId: ID!
  formSubmittedDate: DateInput
  startDate: DateInput
}

union CreateAiProTrialSubscriptionPayload = CreateAiProTrialSubscriptionSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/multiproductenablement/service/aipro/Contract.proto
type CreateAiProTrialSubscriptionSuccess {
  eligibilityStatus: AiProEligibilityStatus!
  reason: AiProIneligibleReason
  success: Boolean!
}

input CreateAndReleaseTagConfigInput {
  companyId: ID!
  description: String
  dtagConfig: JsonStringInput!
  enrichers: [String!]
  preset: String!
  tagVersion: String!
  transformers: [String!]
  triggers: [String!]
}

union CreateAndReleaseTagConfigPayload = CreateAndReleaseTagConfigSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/tagbuildservice/Contract.proto
type CreateAndReleaseTagConfigSuccess {
  releasedSuccessfully: Boolean!
  tagConfig: TagConfig!
}

input CreateApiSignUpCreativeInput {
  companyId: ID!
  externalName: String
  language: String!
  name: String!
  smsBody: String!
  subscriptionType: CreativeSubscriptionType
}

union CreateApiSignUpCreativePayload = CreateApiSignUpCreativeSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/creative-service/creative/CreativeGraphService.proto
type CreateApiSignUpCreativeSuccess {
  apiSignupCreative: ApiSignupCreative!
}

input CreateAutoJourneyInput {
  autoJourneyType: AutoJourneyType
  clientSettings: AutoJourneyClientSettingsInput!
  companyId: ID!
  config: AutoJourneyConfigInput!
  messageSequencesCount: Int @deprecated(reason: "deprecated")
  name: String!
  type: JourneyTriggerType!
}

input CreateAutoJourneyMessageIdInput {
  autoJourneyId: ID!
  messageConfigs: [MessageConfig!]
}

union CreateAutoJourneyMessageIdPayload = CreateAutoJourneyMessageIdSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/messaging/automation/autojourneys/AutoJourneysContract.proto
type CreateAutoJourneyMessageIdSuccess {
  success: Boolean!
}

# Generated from com/attentivemobile/contracts/messaging/automation/autojourneys/AutoJourneysContract.proto
type CreateAutoJourneyPayload {
  journey: AutoJourney!
}

input CreateAutomationProgramsInput {
  companyId: ID!
}

# Generated from com/attentivemobile/contracts/messaging/automation/Contract.proto
type CreateAutomationProgramsPayload {
  edges: [AutomationProgramEdge!]!
  pageInfo: PageInfo!
}

input CreateBlockCompanyInfoInput {
  blockCompanyInfo: [BlockCompanyInfoInput!]
  companyId: ID!
}

union CreateBlockCompanyInfoPayload = CreateBlockCompanyInfoSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/personalization/service/aijourneys/onboarding/companyinfo/BlockCompanyInfo.proto
type CreateBlockCompanyInfoSuccess {
  blockCompanyInfo: [BlockCompanyInfo!]!
  success: Boolean!
}

input CreateCampaignMessageGroupExperimentInput {
  campaignMessageGroupExperimentSubtype: CampaignMessageGroupExperimentSubtype
  campaignMessageGroupExperimentType: CampaignMessageGroupExperimentType!
  numberOfVariants: Int @deprecated(reason: "deprecated")
}

input CreateCompanyConfigInput {
  attributeName: String!
  companyId: ID!
  config: String!
}

union CreateCompanyConfigPayload = CreateCompanyConfigSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/messaging/automation/aijourneys/configs/Contract.proto
type CreateCompanyConfigSuccess {
  id: String!
}

input CreateCompanyInput {
  # the company's unique attentive domain e.g. attentive.attn.tv
  attentiveDomain: String!
  companyEmail: String
  companyType: CompanyType!
  country: String!
  name: String!
  salesforceAccountId: String!
  userId: String
}

input CreateCompanyLinkInput {
  companyLink: CompanyLinkInput!
}

# Generated from com/attentivemobile/contracts/company-service/companylink/CompanyLink.proto
type CreateCompanyLinkPayload {
  companyLink: CompanyLink!
}

# Generated from com/attentivemobile/contracts/company-service/Contract.proto
type CreateCompanyPayload {
  company: Company
}

input CreateCompanySettingsInput {
  companyId: ID!
  companySettings: CompanySettingsInput!
}

# Generated from com/attentivemobile/contracts/company-service/companysetting/CompanySetting.proto
type CreateCompanySettingsPayload {
  successful: Boolean!
}

input CreateComposeCampaignInput {
  campaignMessageGroupName: String!
  companyId: ID!
  experimentMetadata: CreateCampaignMessageGroupExperimentInput
  messageChannel: ComposeMessageChannel!
  name: String!
  type: ComposeCampaignType!
  userId: ID!
}

input CreateCouponAllocatorUploadInput {
  companyId: ID!
}

# Generated from com/attentivemobile/contracts/solutions/couponallocator/CouponAllocator.proto
type CreateCouponAllocatorUploadPayload {
  filename: String!
  signedUrl: String!
}

input CreateCouponSetInput {
  autoRefresh: Boolean!
  codeTemplate: String
  companyId: ID!
  couponUrlTemplate: String
  couponValueInput: CouponValueInput
  description: String
  distributionTtlInSeconds: Long
  distributionTtlUnit: DistributionUnit
  externalId: Long
  name: String!
  staticCode: String
  status: CouponSetStatus!
  type: CouponSetType!
}

# Generated from com/attentivemobile/contracts/coupon/CouponContract.proto
type CreateCouponSetPayload {
  created: CouponSet!
}

input CreateCreativeExperimentGroupInput {
  creativeIds: [ID!]
  percent: String!
}

input CreateCreativeExperimentInput {
  companyId: ID!
  description: String
  experimentGroups: [CreateCreativeExperimentGroupInput!]
  name: String!
  status: CreativeExperimentStatus!
}

# Generated from com/attentivemobile/contracts/creative-service/experiment/CreativeExperimentService.proto
type CreateCreativeExperimentPayload {
  experiment: CreativeExperiment!
}

input CreateCreativeRuleAssociationInput {
  creativeId: ID!
  ruleId: ID!
}

union CreateCreativeRuleAssociationPayload = CreateCreativeRuleAssociationSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/creative-service/rules/CreativeRuleAssociationService.proto
type CreateCreativeRuleAssociationSuccess {
  creativeRuleAssociation: CreativeRuleAssociation!
}

input CreateCreativeRuleInput {
  companyId: ID!
  definitionSource: CreativeRuleDefinitionSource!
  description: String!
  name: String!
  rule: JSON!
}

union CreateCreativeRulePayload = CreateCreativeRuleSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/creative-service/rules/CreativeRuleService.proto
type CreateCreativeRuleSuccess {
  rule: CreativeRule!
}

input CreateCreativeScheduleAndMembersInput {
  members: [CreateOrUpdateCreativeScheduleMemberInput!]
  schedule: CreateCreativeScheduleInput!
}

input CreateCreativeScheduleInput {
  companyId: ID!
  completed: DateTime
  description: String
  endTime: DateTime
  name: String!
  scheduledEvent: Boolean!
  startTime: DateTime!
  timezone: String
  userId: String
}

input CreateCreativeScheduleWorkflowInput {
  companyId: ID!
  endTime: DateTime
  externalScheduleId: ID @deprecated(reason: "deprecated")
  name: String!
  scheduleMembers: [CreativeScheduleMemberDetails!]
  startTime: DateTime!
  timezone: String
}

union CreateCreativeScheduleWorkflowPayload = CreateCreativeScheduleWorkflowSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/creative/workflow/service/workflows/CreativeSchedulesWorkflowService.proto
type CreateCreativeScheduleWorkflowSuccess {
  schedule: CreativeWorkflowSchedule!
}

input CreateCustomCompanyKeywordInput {
  attributeName: String
  companyId: ID!
  enabled: Boolean!
  forceSend: Boolean!
  keyword: String!
  keywordOrdinal: Int
  mediaUrl: String
  messageText: String!
  shortenLink: Boolean!
  subscriptionType: String!
}

union CreateCustomCompanyKeywordPayload = CreateCustomCompanyKeywordSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/keywords/KeywordService.proto
type CreateCustomCompanyKeywordSuccess {
  keyword: CustomCompanyKeyword!
}

# Request message for creating a custom event.
input CreateCustomEventInput {
  companyId: ID

  # A unique identifier representing this specific event. A UUID is recommended.
  externalEventId: String

  # Timestamp of when the action occurred in [ISO 8601 format](https://xml2rfc.tools.ietf.org/public/rfc/html/rfc3339.html#date.and.time.format.examples). If the timestamp is older than 12 hours, it will not trigger any relevant Journeys. If no timestamp is provided, it is assumed the event occurred when the endpoint is called.
  occurredAt: DateTime

  # Any metadata associated with the event. Object keys are expected to be strings, and cannot contain any of the following special characters: single quote ', double quote ", curly braces { }, square brackets [ ], backslash \, comma ,. Object values can be any type. Note that both object keys and object values are case sensitive. For example, "deliverydate" and "DeliveryDate" would be considered different event properties. For URLs that are provided as a value, Attentive will automatically shorten the link with your brand's link shortener. For example, https://mysite.com/. Note that Attentive can only shorten URLs that are 1,024 characters or fewer.
  properties: JSON!

  # The type of event. This name is case sensitive. "Order shipped" and "Order Shipped" would be considered different event types.
  type: String!

  # User associated with the action. Note that this is a visitor to the site and does not need to be actively subscribed to Attentive.
  user: EventUserInput!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
# Response message to a request for creating a custom event.
type CreateCustomEventPayload {
  # A unique identifier representing this specific event.
  externalEventId: String!

  # Timestamp of when the action occurred.
  occurredAt: DateTime!

  # Any metadata associated with the event.
  properties: JSON!

  # The type of event.
  type: String!

  # User associated with the action.
  user: EventUser!
}

input CreateCustomJourneyInput {
  customJourneyTriggerRequest: CustomJourneyTriggerInput!
  eligibleSubscriptionTypes: [SubscriptionType!]
}

# Generated from com/attentivemobile/contracts/journeys/api/Contract.proto
type CreateCustomJourneyPayload {
  journey: Journey!
}

input CreateCustomKeywordInput {
  companyId: ID!
  isTransactional: Boolean
  keyword: String!
  response: String!
  subscriberTag: String!
}

# Generated from com/attentivemobile/contracts/tactical/companycustomkeywords/CompanyCustomKeywordService.proto
type CreateCustomKeywordPayload {
  customKeyword: CustomKeyword!
}

input CreateCustomPropertyDefinitionInput {
  companyId: ID!
  newFields: PostCustomPropertyDefinitionInput!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type CreateCustomPropertyDefinitionPayload {
  customPropertyDefinition: CustomPropertyDefinition!
}

input CreateCustomReportInput {
  companyId: ID!
  deliveryFrequencies: [ReportDeliveryFrequency!]
  filters: [ReportFilterInput!]
  groupings: [ReportGroupingInput!]
  metricIds: [String!]
  name: String!
  recipients: [ID!]
  reportClassId: ID
  selectedChart: ReportChart
  selectedMetricId: String
  sftpEnabled: Boolean
  summaryMetricIds: [String!] @deprecated(reason: "deprecated")
  summaryMetrics: SummaryMetricsInput
  tableSortOrders: [ReportMetricsOrderingInput!]
}

# Generated from com/attentivemobile/contracts/reports/api/Contract.proto
type CreateCustomReportPayload {
  report: Report!
}

input CreateDefaultWelcomeJourneyInput {
  companyId: ID!
  templateId: ID
}

# Generated from com/attentivemobile/contracts/journeys/api/Contract.proto
type CreateDefaultWelcomeJourneyPayload {
  journey: Journey!
}

input CreateDismissibleModalInput {
  description: String
  endTime: DateTime!
  featureFlags: [String!]
  imageUrl: String
  modalId: String!
  primaryButtonAction: ButtonAction!
  primaryButtonText: String!
  primaryButtonUrl: String
  secondaryButtonAction: ButtonAction
  secondaryButtonText: String
  secondaryButtonUrl: String
  startTime: DateTime!
  title: String
  url: String!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type CreateDismissibleModalPayload {
  modal: DismissibleModal!
}

input CreateDomainSuppressionInput {
  companyId: Long
  description: String
  domain: String!
  reason: String!
}

union CreateDomainSuppressionPayload = CreateDomainSuppressionSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type CreateDomainSuppressionSuccess {
  suppression: DomainSuppression!
}

input CreateEdsJobInput {
  companyId: ID!
  description: String
  ingestionFormat: EdsJobIngestionFormat
  name: String!
  sync: EdsJobSync
  syncMethod: EdsJobSyncMethod
}

input CreateEdsJobMappingInput {
  authDetails: String!
  companyId: ID!
  responseDetails: String!
  sourceDetails: String!
  sourceUuid: String!
  targetDetails: String!
  targetUuid: String!
  taskId: String!
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type CreateEdsJobMappingPayload {
  errorMsg: String
  taskUuid: String
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type CreateEdsJobPayload {
  taskUuid: String
}

input CreateEmailIPAddressInput {
  companyId: Long!
}

union CreateEmailIPAddressPayload = CreateEmailIPAddressSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type CreateEmailIPAddressSuccess {
  ipAddress: EmailIPAddress!
}

input CreateEmailIPPoolInput {
  companyId: Long
  name: String!
  shared: Boolean
  type: IPPoolType!
}

union CreateEmailIPPoolPayload = CreateEmailIPPoolSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type CreateEmailIPPoolSuccess {
  ipPool: EmailIPPool!
}

input CreateEntityTagAssignmentInput {
  campaignMessageGroupId: ID
  tagId: ID!
  taggableEntityType: TaggableEntityType!
}

input CreateEventReplayInput {
  companyId: ID!
  description: String!
  eventRangeEnd: DateTime!
  eventRangeStart: DateTime!
  eventTypes: [EventReplayEventType!]
  title: String!
}

# Generated from com/attentivemobile/contracts/tactical/eventreplay/EventReplayService.proto
type CreateEventReplayPayload {
  eventReplay: EventReplay!
}

input CreateEventReplayV2Input {
  companyId: [ID!]
  description: String!
  eventRangeEnd: DateTime!
  eventRangeStart: DateTime!
  eventTypes: [EventReplayEventType!]
  title: String!
}

# Generated from com/attentivemobile/contracts/tactical/eventreplay/EventReplayService.proto
type CreateEventReplayV2Payload {
  eventReplay: [EventReplay!]!
  eventReplayFailures: [EventReplayFailures!]!
}

input CreateFeatureFlagInput {
  description: String!
  name: String!
}

# Generated from com/attentivemobile/contracts/featureflag/service/Contract.proto
type CreateFeatureFlagPayload {
  featureFlag: FeatureFlag!
}

input CreateFolderInput {
  companyId: ID!
  displayName: String!
  parentId: ID
  proceedOnDuplicate: Boolean
}

union CreateFolderPayload = CreateFolderSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/asset/library/service/Contract.proto
type CreateFolderSuccess {
  company: Company
  folder: Folder!
}

input CreateGameTimeInput {
  companyId: ID!
  json: String!
}

union CreateGameTimePollPayload = CreateGameTimePollSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/tactical/gametime/GameTimeService.proto
type CreateGameTimePollSuccess {
  json: String!
}

input CreateGapStoresCouponInput {
  brandCode: String!
  companyId: ID!
  dollarValue: Int!
  earn12DigitCode: String!
  earn16DigitCode: String!
  earnEnd: String!
  earnStart: String!
  redeem12DigitCode: String
  redeem16DigitCode: String
  redeemEnd: String
  redeemStart: String
}

# Generated from com/attentivemobile/contracts/mobilewalletservice/CouponsService.proto
type CreateGapStoresCouponPayload {
  isSuccess: Boolean!
}

input CreateImageInput {
  companyId: ID!
  displayName: String!
  fileType: String!
  heightPixels: Int!
  parentId: ID
  proceedOnDuplicate: Boolean
  sizeBytes: Long!
  storageUrl: String!
  widthPixels: Int!
}

union CreateImagePayload = CreateImageSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/asset/library/service/Contract.proto
type CreateImageSuccess {
  company: Company
  image: Image!
}

input CreateIntegrationApplicationInput {
  category: String!
  displayName: String!
  name: String!
}

union CreateIntegrationApplicationPayload = CreateIntegrationApplicationSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/integrations/applications/Applications.proto
type CreateIntegrationApplicationSuccess {
  application: IntegrationApplication!
}

input CreateJourneyFromTemplateInput {
  companyId: ID!
  couponPoolId: ID
  isCouponPoolSelected: Boolean
  isVisible: Boolean
  templateId: ID!
}

# Generated from com/attentivemobile/contracts/journeys/api/Contract.proto
type CreateJourneyFromTemplatePayload {
  journey: Journey!
}

input CreateJourneyInput {
  companyId: ID!
  description: String
  isDefault: Boolean
  name: String
  triggerId: ID!
}

# Generated from com/attentivemobile/contracts/journeys/api/Contract.proto
type CreateJourneyPayload {
  journey: Journey!
}

input CreateJourneyRevisionInput {
  companyId: ID!
  journeyId: ID!
  revisionIdToClone: ID
}

# Generated from com/attentivemobile/contracts/journeys/api/Contract.proto
type CreateJourneyRevisionPayload {
  journey: Journey!
}

input CreateJourneyScheduleInput {
  companyId: ID!
  evictPreviousRevisionSubscribersActivateStart: Boolean
  journeyId: ID!
  journeyRevisionIdActivateStart: ID!
  name: String!
  startTime: DateTime
}

# Generated from com/attentivemobile/contracts/journeys/api/Contract.proto
type CreateJourneySchedulePayload {
  journeySchedule: JourneySchedule!
}

input CreateJourneyTriggerInput {
  description: String!
  eligibleSubscriptionTypes: [SubscriptionType!]
  eventOrigin: JourneyEventOrigin!
  maxJourneyLimit: Long
  name: String!
  source: TriggerSourceInput!
  type: JourneyTriggerType!
}

# Generated from com/attentivemobile/contracts/journeys/api/JourneyTrigger.proto
type CreateJourneyTriggerPayload {
  journeyTrigger: JourneyTrigger!
}

input CreateMessageInput {
  attachmentsToAdd: [NewAttachmentRequest!]
  channel: ComposeMessageChannel!
  companyId: ID!
  emailProperties: EmailPropertiesRequest
  ignoreFatigue: Boolean
  initialUtmParameters: UtmParametersInput
  mediaUrls: [UrlInput!]
  messageTags: [ComposeMessageTagInput!]
  name: String!
  prebuiltMessageId: String
  pushProperties: PushPropertiesInput
  rbmProperties: RbmPropertiesInput @deprecated(reason: "deprecated")
  rcsProperties: RcsPropertiesInput
  status: ComposeMessageStatus!
  subtype: String!
  textMessageProperties: TextMessagePropertiesRequest
  type: ComposeMessageType!
}

input CreateMessageTemplateInput {
  channel: Channel!
  companyId: Long!
  templateMetadata: ComposeMessageTemplateMetaDataInput!
  templateName: String!
  templateType: MessagingTemplateType!
}

# Generated from com/attentivemobile/contracts/compose/api/Contract.proto
type CreateMessageTemplatePayload {
  messageTemplate: ComposeMessageTemplate!
}

input CreateMetadataEntry {
  key: String!
  value: String!
}

input CreateMobileWalletCardAndSendInput {
  companyId: ID!
  createMetadata: [CreateMetadataEntry!]
  mediaUrl: String
  messageText: String!
  mobileWalletCard: MobileWalletCardInput!
  phone: String!
}

input CreateMobileWalletCardInput {
  companyId: ID!
  mobileWalletCard: MobileWalletCardInput!
  subscriberExternalId: String!
}

input CreateNBAConfigInput {
  nextBestActionConfiguration: NextBestActionConfigurationInput!
  notificationId: String
  notificationName: String
  notificationSlug: String
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type CreateNBAConfigPayload {
  nextBestActionConfiguration: NextBestActionConfiguration!
}

input CreateNotificationBannerInput {
  callToAction: String!
  description: String!
  eventDate: String!
  startTime: DateTime!
  stopTime: DateTime!
  title: String!
  url: String!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type CreateNotificationBannerPayload {
  banner: NotificationBanner!
}

input CreateNotificationEmailTemplateInput {
  emailTemplate: NotificationEmailTemplateInput!
  notificationId: String
  notificationName: String
  notificationSlug: String
  templatingSyntaxType: TemplatingSyntaxType
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type CreateNotificationEmailTemplatePayload {
  emailTemplate: NotificationEmailTemplate!
}

input CreateOrUpdateCreativeScheduleMemberInput {
  creativeId: ID!
  status: String @deprecated(reason: "deprecated")
  statusV2: CreativeScheduleMemberStatus!
}

# Generated from com/attentivemobile/contracts/creative-service/schedule/CreativeScheduleService.proto
type CreateOrUpdateCreativeSchedulePayload {
  scheduleOrError: CreateOrUpdateCreativeSchedulePayloadScheduleOrError
}

union CreateOrUpdateCreativeSchedulePayloadScheduleOrError = CreativeSchedule | CreativeScheduleMutationError

input CreateOrUpdateUserContentSettingInput {
  companyId: ID
  objectClass: ContentClass!
  objectId: String
  preferenceType: PreferenceType!
  preferenceValue: String!
  userId: ID
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type CreateOrUpdateUserContentSettingPayload {
  preferenceId: String!
}

input CreateOutboundSftpUserInput {
  companyId: ID!
}

# Generated from com/attentivemobile/contracts/solutions/sftp/SftpUser.proto
type CreateOutboundSftpUserPayload {
  publicSshKey: String!
}

input CreatePaymentMessagesInput {
  companyId: ID!
  messageId: ID!
  paymentMessageType: TextToBuyMessageType!
  paymentsOfferId: ID!
}

# Generated from com/attentivemobile/contracts/payments/v2/Contract.proto
type CreatePaymentMessagesPayload {
  status: CreatePaymentsMessageStatus!
}

enum CreatePaymentsMessageStatus {
  CREATE_PAYMENT_MESSAGE_STATUS_FAIL
  CREATE_PAYMENT_MESSAGE_STATUS_SUCCESS
  CREATE_PAYMENT_MESSAGE_STATUS_UNKNOWN
}

input CreatePaymentsOfferInput {
  companyId: ID!
  discount: PaymentsOfferDiscountInput!
  expiresAt: DateTime
  keyword: String!
  messageId: ID @deprecated(reason: "deprecated")
  paymentOfferStatus: PaymentOfferStatus
  paymentOfferType: PaymentOfferType!
  productName: String
  shopifyProductId: String
  shopifyProductPrice: MoneyInput
  shopifyProductVariantId: String
}

# Generated from com/attentivemobile/contracts/payments/v2/Contract.proto
type CreatePaymentsOfferPayload {
  offer: PaymentsOffer
  status: CreatePaymentsOfferStatus!
}

enum CreatePaymentsOfferStatus {
  CREATE_OFFER_STATUS_FAIL_COMPANY_NOT_CONFIGURED
  CREATE_OFFER_STATUS_FAIL_KEYWORD_IN_USE
  CREATE_OFFER_STATUS_FAIL_KEYWORD_NOT_ACCEPTABLE
  CREATE_OFFER_STATUS_FAIL_RESERVED_KEYWORD
  CREATE_OFFER_STATUS_SUCCESS
  CREATE_OFFER_STATUS_UNKNOWN
}

input CreatePollInput {
  companyId: ID!
  description: String
  name: String!
}

# Generated from com/attentivemobile/contracts/survey/poll/Contract.proto
type CreatePollPayload {
  error: String
  poll: Poll
}

input CreatePrebuiltContentInput {
  name: String!
  orchestrationContent: [OrchestrationContentInput!]
  orchestrationType: OrchestrationType!
  prebuiltProgramId: ID!
}

# Generated from com/attentivemobile/contracts/messaging/automation/prebuilt/Contract.proto
type CreatePrebuiltContentPayload {
  prebuiltContent: PrebuiltContent!
}

input CreatePrebuiltProgramInput {
  description: String!
  scheduleType: ScheduleType!
  thumbnailUrl: String!
  title: String!
}

# Generated from com/attentivemobile/contracts/messaging/automation/prebuilt/Contract.proto
type CreatePrebuiltProgramPayload {
  prebuiltProgram: PrebuiltProgram!
}

input CreateProductSegmentInput {
  companyId: ID!
  filters: [ProductSegmentFilterInput!]
  name: String!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto
type CreateProductSegmentPayload {
  productSegment: ProductSegment
}

input CreateProviderMigrationInput {
  carrier: Carrier
  endDate: DateTime
  newProvider: Provider!
  oldProvider: Provider!
  phoneNumber: String!
  startDate: DateTime
  status: ProviderMigrationStatus
}

# Generated from com/attentivemobile/contracts/providermigration/Contract.proto
type CreateProviderMigrationPayload {
  migrations: [ProviderMigration!]!
}

input CreateProviderMigrationsInput {
  carrierDateMapping: [CarrierDateMapping!]
  newProvider: Provider
  oldProvider: Provider
  phoneNumbers: [String!]
  status: ProviderMigrationStatus
}

union CreateProviderMigrationsPayload = CreateProviderMigrationsSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/providermigration/Contract.proto
type CreateProviderMigrationsSuccess {
  migrations: [ProviderMigration!]!
}

input CreateQueryParameterJobInput {
  companyId: String!
  payload: CreateQueryParameterJobInputPayload
}

input CreateQueryParameterJobInputPayload {
  applyIdentityAiParameterPayload: ApplyIdentityAIParameterPayload
}

union CreateQueryParameterJobPayload = CreateQueryParameterJobSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/composition/Contract.proto
type CreateQueryParameterJobSuccess {
  jobId: String!
}

input CreateRegionInput {
  accountConsolidation: Boolean
  region: RegionInput!
}

# Generated from com/attentivemobile/contracts/company-service/region/Region.proto
type CreateRegionPayload {
  error: String
  region: Region!
}

input CreateSegmentEnhancementsWorkflowInput {
  audienceId: ID
  baseAudience: BaseAudience
  callbackId: CreateSegmentEnhancementsWorkflowInputCallbackId
  companyId: ID!
  filter: SegmentEnhancementsFilter
  messageChannel: Channel!
  productFilter: AudienceAiProductFilterInput
}

input CreateSegmentEnhancementsWorkflowInputCallbackId {
  campaignMessageGroupId: ID
}

union CreateSegmentEnhancementsWorkflowPayload = CreateSegmentEnhancementsWorkflowSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/segment/recommendation/service/Contract.proto
type CreateSegmentEnhancementsWorkflowSuccess {
  isSuccess: Boolean!
  workflowId: String!
}

input CreateSegmentFromSearchDefinitionInput {
  companyId: ID!
  description: String
  legacySegmentId: ID
  metadata: [CreateSegmentMetadataInput!]
  name: String!
  searchDefinition: AudienceSearchDefinitionInput!
}

union CreateSegmentFromSearchDefinitionPayload = CreateSegmentFromSearchDefinitionSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/segmentation/service/segment/contract.proto
type CreateSegmentFromSearchDefinitionSuccess {
  segment: SegmentV2
}

input CreateSegmentFromSearchTemplateInput {
  companyId: ID!
  metadata: [CreateSegmentMetadataInput!]
  segmentDescription: String
  segmentName: String!
  template: SegmentTemplateInput!
}

union CreateSegmentFromSearchTemplatePayload = CreateSegmentFromSearchTemplateSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/segmentation/service/segment/contract.proto
type CreateSegmentFromSearchTemplateSuccess {
  segment: Segment
}

input CreateSegmentMetadataInput {
  tag: String!
  value: String!
}

input CreateSftpUserInput {
  companyId: ID!
  email: String
}

# Generated from com/attentivemobile/contracts/solutions/sftp/SftpUser.proto
type CreateSftpUserPayload {
  email: String
  password: String
}

input CreateShopifyAutoApplyFormatsInput {
  companyId: ID!
  discountLinkFormat: DiscountLinkFormat!
}

union CreateShopifyAutoApplyFormatsPayload = CreateShopifyAutoApplyFormatsSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/personalization/service/aijourneys/onboarding/autoapply/ShopifyAutoApplyFormatsContract.proto
type CreateShopifyAutoApplyFormatsSuccess {
  discountLinkFormat: DiscountLinkFormat!
  success: Boolean!
}

input CreateShortLinkInput {
  companyId: ID!
  isOpenInBrowser: Boolean
  messageId: ID!
  redirectTargetUrl: String!
  videoLinkProperties: VideoLinkPropertiesInput
}

union CreateShortLinkPayload = CreateShortLinkSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/composition/Contract.proto
type CreateShortLinkSuccess {
  shortLink: ShortLink!
}

# Generated from com/attentivemobile/contracts/journeys/api/RevisionStep.proto
type CreateSupportTicketStep {
  childrenSteps: [AdderStep!]!
  stepParameters: CreateSupportTicketStepParameters!
}

# Generated from com/attentivemobile/contracts/journeys/api/RevisionStepParameters.proto
type CreateSupportTicketStepParameters {
  customerServiceGroupId: String!
  vendor: CreateSupportTicketVendor!
}

# Generated from com/attentivemobile/contracts/journeys/api/RevisionStepCommon.proto
type CreateSupportTicketVendor {
  vendorId: String!
  vendorName: String!
}

input CreateSyncInput {
  companyId: ID!
  connectionSettings: CreateSyncInputConnectionSettings
  description: String!
  fetchOptions: FetchOptionsInput!
  fileSettings: FileSettingsInput!
  fileType: EdsJobIngestionFormat!
  name: String!
  schedule: SyncScheduleInput!
  syncMethod: EdsJobSyncMethod!
  syncType: EdsJobSync!
  targetConfig: JSON!
}

input CreateSyncInputConnectionSettings {
  attentiveS3Settings: AttentiveS3SettingsInput
  sftpSettings: SftpSettingsInput
  snowflakeSecureDataShareSettings: SnowflakeSecureDataSharingInput
}

union CreateSyncPayload = CreateSyncSuccess | DefaultErrorFailure

union CreateSyncPayloadResult = Sync | SyncError

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type CreateSyncSuccess {
  result: CreateSyncPayloadResult
  success: Boolean!
}

input CreateTagInput {
  companyId: ID!
  name: String!
}

input CreateUpdatePollOptionInput {
  keyword: String!
  pollId: ID!
  response: String!
}

# Generated from com/attentivemobile/contracts/survey/poll/Contract.proto
type CreateUpdatePollOptionPayload {
  error: String
  pollOption: PollOption
}

input CreateVendorCompanyCardInput {
  companyId: ID!
  couponDisplayRegex: String!
  failRedirectUrl: String!
  requireExpiryOnIssue: Boolean!
  vendorCampaignId: String!
  vendorOfferId: String!
}

union CreateVendorCompanyCardPayload = CreateVendorCompanyCardSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/mobilewalletservice/Contract.proto
type CreateVendorCompanyCardSuccess {
  vendorCompanyCard: VendorCompanyCard!
}

input CreateVideoInput {
  companyId: ID!
  displayName: String!
  fileType: String!
  heightPixels: Int!
  parentId: ID
  proceedOnDuplicate: Boolean
  sizeBytes: Long!
  storageUrl: String!
  widthPixels: Int!
}

union CreateVideoPayload = CreateVideoSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/asset/library/service/Contract.proto
type CreateVideoSuccess {
  company: Company
  video: Video!
}

input CreateWebTemplateInput {
  notificationId: String
  notificationName: String
  notificationSlug: String
  webTemplate: WebTemplateInput!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type CreateWebTemplatePayload {
  webTemplate: WebTemplate!
}

# Create webhook request input.
input CreateWebhookInput {
  companyId: String

  # The list of events this webhook is subscribed to.
  events: [String!]

  # The destination url of the webhook.
  url: String!
}

# Generated from com/attentivemobile/contracts/externaltokens/Webhooks.proto
# Response to createWebhook mutation.
type CreateWebhookPayload {
  # Webhook details.
  webhook: Webhook!
}

input CreateWorkloadConfigInput {
  workloadConfig: WorkloadConfigInput!
}

union CreateWorkloadConfigPayload = CreateWorkloadConfigSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/workload/management/service/configuration/Contract.proto
type CreateWorkloadConfigSuccess {
  workloadName: String!
}

# Generated from com/attentivemobile/contracts/creative-service/creative/Creative.proto
type Creative implements Node {
  archived: DateTime
  company: Company
  companyIdOld: String! @deprecated(reason: "deprecated")
  composeMessage: ComposeMessage
  countryCode: String
  couponSet: CouponSet
  created: DateTime!
  creativeCompositeStats: CreativeCompositeStatistics
  creativeContent: CreativeContent
  creativeExperimentGroups(after: String, before: String, first: Int, last: Int): CreativeExperimentGroupConnection

  # This relationship is deprecated.
  creativeFormat: CreativeFormat

  # This relationship is deprecated.
  creativePreset: CreativePreset

  # Provides a collection of RuleAssociations to this Creative.
  creativeRuleAssociations(after: String, before: String, first: Int, last: Int): CreativeRuleAssociationConnection
  creativeScheduleWorkflows(after: String, before: String, filter: GetCreativeSchedulesRequestFilter, first: Int, last: Int, sort: [GetCreativeSchedulesRequestSort!]): CreativeWorkflowScheduleConnection

  # Use raw_status instead.
  creativeStatus: String! @deprecated(reason: "deprecated")
  creativeTemplate: CreativeTemplate

  # The creative name shown in client-ui.
  displayName: String!
  emailWelcomeJourney: Journey
  externalName: String!
  giveawayWinners(creativeIds: [ID!], endDate: String!, limit: Int!, startDate: String!): GetGiveawayWinnersPayload
  id: ID!
  idOld: String @deprecated(reason: "deprecated")
  internalId: String!
  landingPageUrl: String
  language: String!
  lastEditedBy: Account

  # The status of the Creative regardless of ongoing schedules.Usually statusLabel is what you want.
  rawStatus: CreativeStatus!
  rules: JSON
  smsBody: String
  smsWelcomeJourney: Journey

  # Use raw_status instead.
  status: String! @deprecated(reason: "deprecated")

  # Use raw_status instead.
  statusEnum: CreativeStatus! @deprecated(reason: "deprecated")

  # The status of the Creative as shown in the UI.
  # This status takes into consideration schedules and metrics.
  # Shows a Creative as 'DRAFT' if the Creative (factoring in any ongoing schedules) resolves to INACTIVE and has no recorded metrics.
  statusLabel: CreativeStatusLabel!
  subscriptionType: CreativeSubscriptionType
  subtype: String! @deprecated(reason: "deprecated")
  subtypeEnum: CreativeSubtype!

  # The creative config.
  templateConfig: JSON
  ttjCode: String
  type: String! @deprecated(reason: "deprecated")
  typeEnum: CreativeType!
  updated: DateTime!
  visibility: CreativeVisibility!
}

# Generated from com/attentivemobile/contracts/creative-service/assets/CreativeAssets.proto
type CreativeAssetColor {
  id: String!
  type: String!
  value: String!
}

# Generated from com/attentivemobile/contracts/creative-service/assets/CreativeAssets.proto
type CreativeAssetFont {
  deleted: DateTime!
  family: String!
  id: String!
  source: String!
  style: String!
  type: String!
  url: String!
  weight: String!
}

# Generated from com/attentivemobile/contracts/creative-service/assets/CreativeAssets.proto
type CreativeAssetImage {
  deleted: DateTime!
  id: String!
  type: String!
  url: String!
}

# Generated from com/attentivemobile/contracts/creative-service/assets/CreativeAssets.proto
type CreativeAssets {
  colors: [CreativeAssetColor!]!
  fonts: [CreativeAssetFont!]!
  images: [CreativeAssetImage!]!
}

# Generated from com/attentivemobile/contracts/creative-service/creative/Creative.proto
type CreativeCompositeStatistics {
  aggregateStats: CreativeStatistics!
  creativePageStats: [CreativePageStatistic!]!
}

# Generated from com/attentivemobile/contracts/creative-service/creative/CreativeGraphService.proto
type CreativeConnection {
  edges: [CreativeEdge!]!
  pageInfo: PageInfo!
  totalCount: Long!
}

# Generated from com/attentivemobile/contracts/creative-service/creative/CreativeContentService.proto
type CreativeContent implements Node {
  company: Company
  content: JSON!
  created: DateTime!
  id: ID!
  internalId: String!
  updated: DateTime!
}

input CreativeCustomPropertyInput {
  name: String!
  options: [String!]
  type: CreativeCustomPropertyType!
}

enum CreativeCustomPropertyType {
  CREATIVE_CUSTOM_PROPERTY_TYPE_BOOL
  CREATIVE_CUSTOM_PROPERTY_TYPE_CHECKBOX
  CREATIVE_CUSTOM_PROPERTY_TYPE_DATE
  CREATIVE_CUSTOM_PROPERTY_TYPE_INPUT
  CREATIVE_CUSTOM_PROPERTY_TYPE_RADIO
  CREATIVE_CUSTOM_PROPERTY_TYPE_SELECT
  CREATIVE_CUSTOM_PROPERTY_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/creative-service/creative/CreativeGraphService.proto
type CreativeEdge {
  cursor: String!
  node: Creative!
}

# Generated from com/attentivemobile/contracts/creative-service/experiment/CreativeExperiment.proto
type CreativeExperiment implements Node {
  company: Company
  created: DateTime!
  creativeExperimentGroups(after: String, before: String, first: Int, last: Int): CreativeExperimentGroupConnection
  description: String!
  id: ID!
  internalId: String!
  name: String!
  status: CreativeExperimentStatus!
  updated: DateTime!
  visibility: CreativeExperimentVisibility!
}

# Generated from com/attentivemobile/contracts/creative-service/experiment/CreativeExperimentService.proto
type CreativeExperimentConnection {
  edges: [CreativeExperimentEdge!]!
  pageInfo: PageInfo!
  totalCount: Long!
}

# Generated from com/attentivemobile/contracts/creative-service/experiment/CreativeExperimentService.proto
type CreativeExperimentEdge {
  cursor: String!
  node: CreativeExperiment!
}

# Generated from com/attentivemobile/contracts/creative-service/experiment/CreativeExperiment.proto
type CreativeExperimentGroup {
  creativeExperiment: CreativeExperiment
  creatives: CreativeConnection
  id: String!
  percent: String!
}

# Generated from com/attentivemobile/contracts/creative-service/experiment/CreativeExperimentService.proto
type CreativeExperimentGroupConnection {
  edges: [CreativeExperimentGroupEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/creative-service/experiment/CreativeExperimentService.proto
type CreativeExperimentGroupEdge {
  cursor: String!
  node: CreativeExperimentGroup!
}

enum CreativeExperimentStatus {
  CREATIVE_EXPERIMENT_STATUS_ACTIVE
  CREATIVE_EXPERIMENT_STATUS_INACTIVE
  CREATIVE_EXPERIMENT_STATUS_UNKNOWN
}

enum CreativeExperimentVisibility {
  CREATIVE_EXPERIMENT_VISIBILITY_EVERYONE
  CREATIVE_EXPERIMENT_VISIBILITY_SUPERUSER
  CREATIVE_EXPERIMENT_VISIBILITY_UNKNOWN
}

# Generated from com/attentivemobile/contracts/creative-service/format/CreativeFormat.proto
type CreativeFormat implements Node {
  creativePresets(after: String, before: String, companyId: ID!, first: Int, last: Int, readyToLaunch: Boolean): CreativePresetConnection
  deleted: DateTime!
  id: ID!
  internalId: String!
  name: String!
  rendererAppUrl: String!
  subtype: String!
  type: String!
}

# Generated from com/attentivemobile/contracts/creative-service/format/CreativeFormatService.proto
type CreativeFormatConnection {
  edges: [CreativeFormatEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/creative-service/format/CreativeFormatService.proto
type CreativeFormatEdge {
  cursor: String!
  node: CreativeFormat!
}

# Generated from com/attentivemobile/contracts/creative-service/creative/CreativeGraphService.proto
type CreativeMutationError {
  message: String
  type: CommonCreativeErrors!
}

# Generated from com/attentivemobile/contracts/creative-service/creative/Creative.proto
type CreativePageStatistic {
  pageType: String!
  stats: CreativeStatistics!
}

# Generated from com/attentivemobile/contracts/creative-service/preset/CreativePreset.proto
type CreativePreset implements Node {
  creativeEditorConfig: JsonString!
  creativeFormat: CreativeFormat
  defaultCreativeConfig: JsonString!
  defaultCreativeRules: JsonString!
  deleted: DateTime!
  description: String!
  id: ID!
  internalId: String!
  name: String!
  readyToLaunch: Boolean!
  subtype: String!
  type: String!
  visibility: String!
}

# Generated from com/attentivemobile/contracts/creative-service/preset/CreativePresetService.proto
type CreativePresetConnection {
  edges: [CreativePresetEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/creative-service/preset/CreativePresetService.proto
type CreativePresetEdge {
  cursor: String!
  node: CreativePreset!
}

# Generated from com/attentivemobile/contracts/creative-service/rules/CreativeRule.proto
type CreativeRule implements Node {
  company: Company
  created: DateTime!

  # Provides a collection of RuleAssociations to this Creative.
  creativeRuleAssociations(after: String, before: String, first: Int, last: Int): CreativeRuleAssociationConnection
  definitionSource: CreativeRuleDefinitionSource!
  deleted: DateTime
  description: String!
  id: ID!
  name: String!
  rule: JSON!
  updated: DateTime!
  userId: String!
}

# Generated from com/attentivemobile/contracts/creative-service/rules/CreativeRuleAssociation.proto
type CreativeRuleAssociation implements Node {
  created: DateTime!
  creative: Creative
  deleted: DateTime
  id: ID!
  rule: CreativeRule
  updated: DateTime!
  userId: String!
}

# Generated from com/attentivemobile/contracts/creative-service/rules/CreativeRuleAssociationService.proto
type CreativeRuleAssociationConnection {
  edges: [CreativeRuleAssociationEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/creative-service/rules/CreativeRuleAssociationService.proto
type CreativeRuleAssociationEdge {
  cursor: String!
  node: CreativeRuleAssociation!
}

# Generated from com/attentivemobile/contracts/creative-service/rules/CreativeRuleService.proto
type CreativeRuleConnection {
  edges: [CreativeRuleEdge!]!
  pageInfo: PageInfo!
}

enum CreativeRuleDefinitionSource {
  CREATIVE_RULE_DEFINITION_SOURCE_ATTENTIVE
  CREATIVE_RULE_DEFINITION_SOURCE_CREATIVE_LOCAL
  CREATIVE_RULE_DEFINITION_SOURCE_CREATIVE_SHARED
  CREATIVE_RULE_DEFINITION_SOURCE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/creative-service/rules/CreativeRuleService.proto
type CreativeRuleEdge {
  cursor: String!
  node: CreativeRule!
}

# Generated from com/attentivemobile/contracts/creative-service/schedule/CreativeSchedule.proto
type CreativeSchedule implements Node {
  company: Company
  completed: DateTime!
  created: DateTime!
  deleted: DateTime
  description: String!
  endTime: DateTime!
  id: ID!
  internalId: String! @deprecated(reason: "deprecated")
  lastEditedBy: Account
  name: String!
  scheduledEvent: Boolean!
  startTime: DateTime!
  status: String! @deprecated(reason: "deprecated")
  statusV2: CreativeScheduleStatus!
  timezone: String!
  type: CreativeScheduleType!
  updated: DateTime!
}

# Generated from com/attentivemobile/contracts/creative-service/schedule/CreativeScheduleService.proto
type CreativeScheduleConnection {
  edges: [CreativeScheduleEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/creative-service/schedule/CreativeScheduleService.proto
type CreativeScheduleEdge {
  cursor: String!
  node: CreativeSchedule!
}

# Generated from com/attentivemobile/contracts/creative-service/schedule/CreativeScheduleService.proto
type CreativeScheduleMember implements Node {
  creative: Creative
  creativeSchedule: CreativeSchedule @deprecated(reason: "deprecated")
  id: ID!
  internalId: String! @deprecated(reason: "deprecated")
  status: String! @deprecated(reason: "deprecated")
  statusV2: CreativeScheduleMemberStatus!
}

input CreativeScheduleMemberDetails {
  creativeSubtype: CreativeSubtype!
  id: ID!
  targetStatus: TargetCreativeStatus!
}

enum CreativeScheduleMemberStatus {
  CREATIVE_SCHEDULE_MEMBER_STATUS_ACTIVE
  CREATIVE_SCHEDULE_MEMBER_STATUS_INACTIVE
}

# Generated from com/attentivemobile/contracts/creative-service/schedule/CreativeScheduleService.proto
type CreativeScheduleMutationError {
  message: String
  type: CommonCreativeScheduleMutationErrors!
}

enum CreativeScheduleStatus {
  CREATIVE_SCHEDULE_STATUS_COMPLETED
  CREATIVE_SCHEDULE_STATUS_NEXT_UP
  CREATIVE_SCHEDULE_STATUS_RUNNING
}

enum CreativeScheduleType {
  CREATIVE_SCHEDULE_TYPE_ACTIVATE
  CREATIVE_SCHEDULE_TYPE_DEACTIVATE
  CREATIVE_SCHEDULE_TYPE_SWAP
  CREATIVE_SCHEDULE_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/creative/workflow/service/workflows/CreativeSchedulesWorkflowService.proto
type CreativeScheduleWorkflowMember implements Node {
  company: Company
  creative: Creative
  externalSchedule: CreativeSchedule @deprecated(reason: "deprecated")
  id: ID!
  schedule: CreativeWorkflowSchedule
  targetStatus: TargetCreativeStatus!
}

# Generated from com/attentivemobile/contracts/creative/workflow/service/workflows/CreativeSchedulesWorkflowService.proto
type CreativeSchedulesMemberEdge {
  cursor: String!
  node: CreativeScheduleWorkflowMember!
}

# Generated from com/attentivemobile/contracts/creative-service/creative/Creative.proto
type CreativeStatistics {
  clickThroughRate: Float
  clicks: Long
  emailConversionRate: Float
  emailSubscriberCount: Long
  impressions: Long
  smsConversionRate: Float
  smsSubscriberCount: Long
}

enum CreativeStatus {
  CREATIVE_STATUS_ACTIVE
  CREATIVE_STATUS_DRAFT
  CREATIVE_STATUS_INACTIVE
  CREATIVE_STATUS_UNKNOWN
}

enum CreativeStatusLabel {
  CREATIVE_STATUS_LABEL_ACTIVE
  CREATIVE_STATUS_LABEL_DRAFT
  CREATIVE_STATUS_LABEL_INACTIVE
  CREATIVE_STATUS_LABEL_UNKNOWN
}

enum CreativeSubscriptionType {
  CREATIVE_SUBSCRIPTION_TYPE_CHECKOUT_ABANDONED
  CREATIVE_SUBSCRIPTION_TYPE_MARKETING
  CREATIVE_SUBSCRIPTION_TYPE_TRANSACTIONAL
  CREATIVE_SUBSCRIPTION_TYPE_UNKNOWN
}

enum CreativeSubtype {
  CREATIVE_SUBTYPE_AFFILIATE_ENDPOINT
  CREATIVE_SUBTYPE_BUBBLE
  CREATIVE_SUBTYPE_BUBBLE_FULL_SCREEN
  CREATIVE_SUBTYPE_BUBBLE_PARTIAL
  CREATIVE_SUBTYPE_CODE
  CREATIVE_SUBTYPE_CONVERSATIONAL
  CREATIVE_SUBTYPE_COUPON_DISPLAY_SCREEN
  CREATIVE_SUBTYPE_DYNAMIC
  CREATIVE_SUBTYPE_EMAIL_BUBBLE_FULL_SCREEN
  CREATIVE_SUBTYPE_EMAIL_BUBBLE_PARTIAL
  CREATIVE_SUBTYPE_EMAIL_FULL_SCREEN
  CREATIVE_SUBTYPE_EMAIL_LIGHTBOX
  CREATIVE_SUBTYPE_EMAIL_PARTIAL
  CREATIVE_SUBTYPE_EMAIL_VENDOR_USER_PROFILE_SYNC
  CREATIVE_SUBTYPE_EMAIL_WIZARD
  CREATIVE_SUBTYPE_FULL_SCREEN
  CREATIVE_SUBTYPE_LANDING_PAGE
  CREATIVE_SUBTYPE_LIGHTBOX
  CREATIVE_SUBTYPE_LIGHTBOX_BUBBLE
  CREATIVE_SUBTYPE_LOYALTY
  CREATIVE_SUBTYPE_MANUAL_ENTRY
  CREATIVE_SUBTYPE_NO_SHOW
  CREATIVE_SUBTYPE_OFFER_DISPLAY
  CREATIVE_SUBTYPE_PARTIAL
  CREATIVE_SUBTYPE_PRIVACY
  CREATIVE_SUBTYPE_PRODUCT_DISPLAY
  CREATIVE_SUBTYPE_RECHARGE_CHECKOUT
  CREATIVE_SUBTYPE_RECHARGE_CHECKOUT_TRANSACTIONAL
  CREATIVE_SUBTYPE_SHOPIFY_CHECKOUT
  CREATIVE_SUBTYPE_SHOPIFY_CHECKOUT_EMAIL
  CREATIVE_SUBTYPE_SHOPIFY_CHECKOUT_TRANSACTIONAL
  CREATIVE_SUBTYPE_TAG_ADD_TO_CART
  CREATIVE_SUBTYPE_TAG_PAGE_VIEW
  CREATIVE_SUBTYPE_TAG_PRODUCT_VIEW
  CREATIVE_SUBTYPE_TERMS
  CREATIVE_SUBTYPE_UNKNOWN
  CREATIVE_SUBTYPE_UNSUBSCRIBE
  CREATIVE_SUBTYPE_USER
}

# Generated from com/attentivemobile/contracts/creative-service/template/CreativeTemplate.proto
type CreativeTemplate implements Node {
  archived: DateTime
  creativePresetId: String!

  # Creates the default display rules for the given template, country, and company.
  defaultDisplayRules(companyId: ID!, country: String!): GetTemplateCreativeRuleDefaultsResponse
  defaults: JSON!
  formConfig: JSON!
  id: ID!
  internalId: String!
  location: String!
  name: String!
  ssrEnabled: String!
  subtype: String! @deprecated(reason: "deprecated")
  subtypeEnum: CreativeSubtype!
  type: String! @deprecated(reason: "deprecated")
  typeEnum: CreativeType!
  visibility: String!
}

# Generated from com/attentivemobile/contracts/creative-service/template/TemplateService.proto
type CreativeTemplateConnection {
  edges: [CreativeTemplateEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/creative-service/template/TemplateService.proto
type CreativeTemplateEdge {
  cursor: String!
  node: CreativeTemplate!
}

enum CreativeType {
  CREATIVE_TYPE_ABANDONMENT_AUTO_OPT_IN
  CREATIVE_TYPE_DESKTOP
  CREATIVE_TYPE_INTEGRATION
  CREATIVE_TYPE_LANDING_PAGE
  CREATIVE_TYPE_LEGAL
  CREATIVE_TYPE_MANUAL_UPLOAD
  CREATIVE_TYPE_MOBILE_PAYMENT
  CREATIVE_TYPE_ON_SITE
  CREATIVE_TYPE_TEXT_TO_JOIN
  CREATIVE_TYPE_UNKNOWN
}

enum CreativeVisibility {
  CREATIVE_VISIBILITY_EVERYONE
  CREATIVE_VISIBILITY_SUPERUSER
  CREATIVE_VISIBILITY_UNKNOWN
}

# Generated from com/attentivemobile/contracts/creative/workflow/service/workflows/CreativeSchedulesWorkflowService.proto
type CreativeWorkflowSchedule implements Node {
  company: Company
  created: DateTime!
  creativeWorkflowScheduleMembers(after: String, before: String, first: Int, last: Int): CreativeWorkflowScheduleMemberConnection
  deleted: DateTime
  endDate: DateTime
  externalSchedule: CreativeSchedule @deprecated(reason: "deprecated")
  id: ID!
  name: String!
  startDate: DateTime!
  status: CreativeScheduleStatus!
  timezone: String!
  type: CreativeScheduleType!
  updated: DateTime!
}

# Generated from com/attentivemobile/contracts/creative/workflow/service/workflows/CreativeSchedulesWorkflowService.proto
type CreativeWorkflowScheduleConnection {
  edges: [CreativeWorkflowScheduleEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/creative/workflow/service/workflows/CreativeSchedulesWorkflowService.proto
type CreativeWorkflowScheduleEdge {
  cursor: String!
  node: CreativeWorkflowSchedule!
}

# Generated from com/attentivemobile/contracts/creative/workflow/service/workflows/CreativeSchedulesWorkflowService.proto
type CreativeWorkflowScheduleMemberConnection {
  edges: [CreativeSchedulesMemberEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/creative-service/experiment/CreativeExperimentService.proto
type CreativesAndExperimentsConnection {
  edges: [CreativesAndExperimentsEdge!]!
  pageInfo: PageInfo!
  totalCount: Long!
}

# Generated from com/attentivemobile/contracts/creative-service/experiment/CreativeExperimentService.proto
type CreativesAndExperimentsEdge {
  cursor: String!
  node: CreativesAndExperimentsEdgeNode
}

union CreativesAndExperimentsEdgeNode = Creative | CreativeExperiment

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type Cron {
  cronString: String!
  timezone: String!
}

input CronInput {
  cronString: String!
  timezone: String!
}

input CronJobExecutionInput {
  taskId: String!
}

# Generated from com/attentivemobile/contracts/eventdestination/cron/service/CronJobExecution.proto
type CronJobExecutionPayload {
  isSuccess: Boolean!
}

# Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto
type CsvFormatting {
  mapColName: [MapColNameEntry!]!
  mapEventName: [MapEventNameEntry!]!
  pipeDelimited: Boolean
  timestampFormat: String
  writeFooter: Boolean
  writeHeader: Boolean
}

input CsvFormattingInput {
  mapColNameInput: [MapColNameInputEntry!]
  mapEventNameInput: [MapEventNameInputEntry!]
  pipeDelimited: Boolean
  timestampFormat: String
  writeFooter: Boolean
  writeHeader: Boolean
}

enum CsvSortByField {
  CSV_SORT_BY_FIELD_UNKNOWN
}

# The currency in ISO 4217 format.
enum CurrencyCode {
  AED
  AFN
  ALL
  AMD
  ANG
  AOA
  ARS
  AUD
  AWG
  AZN
  BAM
  BBD
  BDT
  BGN
  BHD
  BIF
  BMD
  BND
  BOB
  BOV
  BRL
  BSD
  BTN
  BWP
  BYN
  BZD
  CAD
  CDF
  CHE
  CHF
  CHW
  CLF
  CLP
  CNY
  COP
  COU
  CRC
  CUC
  CUP
  CURRENCY_UNKNOWN
  CVE
  CZK
  DJF
  DKK
  DOP
  DZD
  EGP
  ERN
  ETB
  EUR
  FJD
  FKP
  GBP
  GEL
  GHS
  GIP
  GMD
  GNF
  GTQ
  GYD
  HKD
  HNL
  HRK
  HTG
  HUF
  IDR
  ILS
  INR
  IQD
  IRR
  ISK
  JMD
  JOD
  JPY
  KES
  KGS
  KHR
  KMF
  KPW
  KRW
  KWD
  KYD
  KZT
  LAK
  LBP
  LKR
  LRD
  LSL
  LYD
  MAD
  MDL
  MGA
  MKD
  MMK
  MNT
  MOP
  MRU
  MUR
  MVR
  MWK
  MXN
  MXV
  MYR
  MZN
  NAD
  NGN
  NIO
  NOK
  NPR
  NZD
  OMR
  PAB
  PEN
  PGK
  PHP
  PKR
  PLN
  PYG
  QAR
  RON
  RSD
  RUB
  RWF
  SAR
  SBD
  SCR
  SDG
  SEK
  SGD
  SHP
  SLE
  SLL
  SOS
  SRD
  SSP
  STN
  SVC
  SYP
  SZL
  THB
  TJS
  TMT
  TND
  TOP
  TRY
  TTD
  TWD
  TZS
  UAH
  UGX
  USD
  USN
  UYI
  UYU
  UYW
  UZS
  VED
  VES
  VND
  VUV
  WST
  XAF
  XAG
  XAU
  XBA
  XBB
  XBC
  XBD
  XCD
  XDR
  XOF
  XPD
  XPF
  XPT
  XSU
  XTS
  XUA
  XXX
  YER
  ZAR
  ZMW
  ZWL
}

# Generated from com/attentivemobile/contracts/keywords/KeywordRuleService.proto
type CustomAttributeRule {
  created: DateTime!
  expression: String!
  id: Int!
  updated: DateTime!
}

# Generated from com/attentivemobile/contracts/keywords/KeywordService.proto
type CustomCompanyKeyword implements Node {
  created: DateTime!
  id: ID!
  keywordInfo: KeywordInfo!
  messageInfo: MessageInfo!
  subscriberInfo: SubscriberInfo!
  updated: DateTime!
}

# Generated from com/attentivemobile/contracts/keywords/KeywordService.proto
type CustomCompanyKeywordEdge {
  cursor: String!
  node: CustomCompanyKeyword!
}

# Generated from com/attentivemobile/contracts/keywords/KeywordService.proto
type CustomCompanyKeywordsConnection {
  edges: [CustomCompanyKeywordEdge!]!
  pageInfo: PageInfo!
  totalCount: Int!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type CustomEvent {
  created: DateTime! @deprecated(reason: "deprecated")
  customEventType: String!
  id: String! @deprecated(reason: "deprecated")
  properties: JsonString!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type CustomEventDefinition {
  eventOrigin: EventOrigin!
  keys(includeInactive: Boolean): CustomEventKeyConnection
  type: String!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type CustomEventDefinitionConnection {
  edges: [CustomEventDefinitionEdge!]!
  pageInfo: PageInfo!
  totalCount: Long!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type CustomEventDefinitionEdge {
  cursor: String!
  node: CustomEventDefinition!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
# A property key of a Custom Event
type CustomEventKey implements Node {
  active: Boolean!
  companyId: ID!
  createdAt: DateTime!
  customEventType: String!
  eventOrigin: EventOrigin
  id: ID!
  keyType: CustomEventKeyType!
  propertyKey: String!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type CustomEventKeyConnection {
  edges: [CustomEventKeyEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type CustomEventKeyEdge {
  cursor: String!
  node: CustomEventKey!
}

enum CustomEventKeyType {
  KEY_TYPE_ARRAY
  KEY_TYPE_BOOL
  KEY_TYPE_HYPERLINK
  KEY_TYPE_IMAGE
  KEY_TYPE_LOCAL_DATE
  KEY_TYPE_NUMBER
  KEY_TYPE_OBJECT
  KEY_TYPE_STRING
  KEY_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type CustomEventsConnection {
  edges: [UserEventsEdge!]!
  pageInfo: PageInfo!
}

input CustomJourneyTriggerInput {
  applicationId: ID
  companyId: ID!
  customEventType: String!
  displayName: String!
  eventType: String!
  modalDescription: String!
  triggerId: ID
  type: JourneyTriggerType!
}

# Generated from com/attentivemobile/contracts/tactical/companycustomkeywords/CompanyCustomKeywordService.proto
type CustomKeyword implements Node {
  company: Company
  id: ID!
  isActive: Boolean
  isTransactional: Boolean
  keyword: String!
  response: String!
  subscriberTag: String!
}

# Generated from com/attentivemobile/contracts/messaging/automation/aijourneys/onboarding/AIJourneyLinkConfiguration.proto
type CustomLinkConfig {
  url: String!
}

input CustomLinkConfigInput {
  url: String!
}

# Generated from com/attentivemobile/contracts/messaging/automation/autojourneys/AutoJourneysModel.proto
type CustomParamsEntry {
  key: String!
  value: String!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto
type CustomProductProperty {
  customProductPropertyType: CustomProductPropertyType!
  productPropertyName: String!
}

input CustomProductPropertyInput {
  customProductPropertyType: CustomProductPropertyType!
  productPropertyName: String!
}

enum CustomProductPropertyType {
  CUSTOM_PRODUCT_PROPERTY_TYPE_PRODUCT_VARIANT
  CUSTOM_PRODUCT_PROPERTY_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type CustomPropertyDefinition implements Node {
  created: DateTime!
  customPropertyId: CustomPropertyID! @deprecated(reason: "deprecated")
  id: ID!
  isDeleted: Boolean!
  name: String!
  propertyType: CustomPropertyType!
  typeDefinition: CustomPropertyDefinitionTypeDefinition
  updated: DateTime!

  # Segments that use this custom property.
  usages: [Segment!]!
}

input CustomPropertyDefinitionInput {
  name: String!
  propertyType: CustomPropertyType!
}

union CustomPropertyDefinitionTypeDefinition = EnumeratedPropertyDefinition

# Generated from com/attentivemobile/protobuf/common/targeting/UserProperty.proto
type CustomPropertyID {
  id: String!
}

enum CustomPropertyType {
  TYPE_ARRAY_OBJECT
  TYPE_BOOL
  TYPE_DATE
  TYPE_DATETIME
  TYPE_ENUM
  TYPE_LONG
  TYPE_NUMBER
  TYPE_OBJECT
  TYPE_STRING
  TYPE_TIMESTAMP
  TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/journeys/api/JourneyTrigger.proto
type CustomTriggerSource {
  company: Company
  customEventType: String!
}

input CustomTriggerSourceInput {
  companyId: ID!
  customEventType: String!
}

input CustomUrlInputParamsEntry {
  key: String!
  value: String!
}

# Generated from com/attentivemobile/contracts/concierge/service/SelfServe.proto
type CustomUrlParametersEntry {
  key: String!
  value: String!
}

input CustomUrlParametersInputEntry {
  key: String!
  value: String!
}

# Generated from com/attentivemobile/contracts/concierge/service/ConversationManager.proto
type CustomUrlParamsEntry {
  key: String!
  value: String!
}

input CustomUrlParamsInputEntry {
  key: String!
  value: String!
}

# Generated from com/attentivemobile/contracts/integrations/discount/Contract.proto
type CustomerReceives {
  applicableProduct: String
  applicableProductCategory: String
  applicableProductType: ApplicableProductType!
  applicableVariant: String
  appliesOnEachItem: Boolean
  discountAmount: Money
  discountPercentage: Float
  discountedQuantity: Long
  freeShipping: Boolean
}

# Generated from com/attentivemobile/contracts/integrations/discount/Contract.proto
type CustomerRequirements {
  applicableProduct: String
  applicableProductCategory: String
  applicableProductType: ApplicableProductType!
  applicableVariant: String
  maximumShippingPrice: Money
  purchaseAmount: Money
  purchaseQuantity: Long
}

# Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto
type CviGenericMetadata {
  integrationMetadataMap: [IntegrationMetadataMapEntry!]!
}

# Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto
type CvifGenericMetadata {
  metadata: String!
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Transformation.proto
type DataSource {
  endpoint: String!
  name: String!
}

# Generated from com/attentivemobile/contracts/metricsapi/DatasetsContract.proto
type DatasetConnection {
  edges: [GroupedDatasetEdge!]!
  pageInfo: PageInfo!
  totalCount: Long!
}

input DatasetFilter {
  column: String!
  filter: DatasetFilterFilter
  type: DatasetFilterType!
}

input DatasetFilterFilter {
  boolean: BooleanDatasetFilter
  numeric: NumericDatasetFilter
  string: StringDatasetFilter
  time: TimeDatasetFilter
}

enum DatasetFilterType {
  DATASET_FILTER_TYPE_BOOLEAN
  DATASET_FILTER_TYPE_NUMERIC
  DATASET_FILTER_TYPE_STRING
  DATASET_FILTER_TYPE_TIME
  DATASET_FILTER_TYPE_UNKNOWN
}

enum DatasetOrderByDirection {
  DATASET_ORDER_BY_DIRECTION_ASC
  DATASET_ORDER_BY_DIRECTION_DESC
  DATASET_ORDER_BY_DIRECTION_UNKNOWN
}

input DatasetOrdering {
  column: String!
  direction: DatasetOrderByDirection!
}

# Generated from com/attentivemobile/contracts/metricsapi/DatasetsContract.proto
type DatasetValue {
  columnDisplayName: String!
  value: DatasetValueValue
}

union DatasetValueValue = BooleanDatasetValue | NumericDatasetValue | StringDatasetValue | TimeDatasetValue

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Datastream.proto
type Datastream {
  contactName: String!
  datastreamName: String!
  eventFilter: DatastreamEventFilter!
  eventTypeFilters: [EventType!]! @deprecated(reason: "deprecated")
  impactMetadata: DatastreamImpactMetadata!
  sinks: [DatastreamSink!]!
  sources: [DatastreamSource!]!
  state: DatastreamStatus!
  teamName: String!
  transformations: [DatastreamTransformation!]!
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Datastream.proto
type DatastreamEventFilter {
  eventTypeFilters: [EventType!]!
  parallelism: Int
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Datastream.proto
type DatastreamImpactMetadata {
  impact: String
  priority: DatastreamPriority
  sloAlertS: Int
  sloWarnS: Int
}

input DatastreamImpactMetadataInput {
  impact: String
  priority: DatastreamPriority
  sloAlertS: Int
  sloWarnS: Int
}

enum DatastreamPriority {
  DATASTREAM_PRIORITY_P0
  DATASTREAM_PRIORITY_P1
  DATASTREAM_PRIORITY_P2
  DATASTREAM_PRIORITY_P3
  DATASTREAM_PRIORITY_UNKNOWN
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Datastream.proto
type DatastreamSink {
  maxBufferTimeMillis: Int
  parallelism: Int
  rateLimitPercentage: Int
  type: DatastreamSinkType
}

union DatastreamSinkType = EventBackup | GenericBackup | HttpEndpoint | KinesisStream | PulsarTopic | S3EventSink | S3GenericSink

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Datastream.proto
type DatastreamSource {
  parallelism: Int
  topicToMessageOffsets: [TopicToMessageOffsetsEntry!]!
  type: DatastreamSourceType
}

union DatastreamSourceType = KinesisStream | PulsarTopic | S3Location

enum DatastreamStatus {
  DATASTREAM_STATUS_OFF
  DATASTREAM_STATUS_ON
  DATASTREAM_STATUS_UNKNOWN
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Contract.proto
type DatastreamStatusInfo {
  datastreamDetails: GetDatastreamDetailsResponse
  datastreamName: String!
  health: JobHealth!
  jobUrl: String!
  status: JobStatus!
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Datastream.proto
type DatastreamTeam {
  datastreams(cluster: Cluster): ListDatastreams
  name: String!
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Datastream.proto
type DatastreamTransformation {
  operator: DatastreamTransformationOperator
  parallelism: Int
}

union DatastreamTransformationOperator = Enricher | EventTypeSplitter | GenericEventRateLimiter | PiiScrubber | ProtoEventJoiner | ProtoEventRateLimiter

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Contract.proto
type DatastreamWithRuntime {
  datastream: Datastream!
  runtime: DatastreamWithRuntimeRuntime
}

union DatastreamWithRuntimeRuntime = FlinkJob

# Generated from com/attentivemobile/protobuf/common/targeting/UserPropertyValue.proto
type Date {
  day: Int
  month: Int
  year: Int
}

# Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/ComparisonTypes.proto
type DateComponentComparison {
  comparator: SearchDateComponentComparator!
  values: [Long!]!
}

input DateComponentComparisonInput {
  comparator: SearchDateComponentComparator!
  values: [Long!]
}

enum DateFilterPeriod {
  DATE_FILTER_PERIOD_LAST_7_DAYS
  DATE_FILTER_PERIOD_LAST_30_DAYS
  DATE_FILTER_PERIOD_LAST_90_DAYS
  DATE_FILTER_PERIOD_LAST_MONTH
  DATE_FILTER_PERIOD_LAST_QUARTER
  DATE_FILTER_PERIOD_LAST_YEAR
  DATE_FILTER_PERIOD_MONTH_TO_DATE
  DATE_FILTER_PERIOD_QUARTER_TO_DATE
  DATE_FILTER_PERIOD_TODAY
  DATE_FILTER_PERIOD_UNKNOWN
  DATE_FILTER_PERIOD_WEEK_TO_DATE
  DATE_FILTER_PERIOD_YEAR_TO_DATE
  DATE_FILTER_PERIOD_YESTERDAY
}

input DateInput {
  day: Int!
  month: Int!
  year: Int!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type DatePropertyValue {
  value: DateTime!
}

input DateRange {
  end: DateTime!
  start: DateTime!
}

input DateRangeFilter {
  end: DateTime!
  start: DateTime!
}

# A slightly refined version of RFC-3339 compliant DateTime Scalar
scalar DateTime

input DateTimeRange {
  end: DateTime!
  start: DateTime!
}

# Generated from com/attentivemobile/contracts/user/profile/property/UserPropertyV2.proto
type DateTimeValueV2 {
  dateTimeValue: DateTime!
}

# Generated from com/attentivemobile/contracts/user/profile/property/UserPropertyV2.proto
type DateValueV2 {
  day: Int
  month: Int
  year: Int
}

input DeactivateSyncInput {
  syncId: String!
}

union DeactivateSyncPayload = DeactivateSyncSuccess | DefaultErrorFailure

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type DeactivateSyncSuccess {
  success: Boolean!
}

# Generated from com/attentivemobile/contracts/common/Error.proto
type DefaultErrorFailure implements StandardError {
  message: String!
  status: StandardErrorStatus!
  title: String!
  traceId: String!
}

# Generated from com/attentivemobile/protobuf/common/graphql/error.proto
type DefaultStandardError {
  message: String!
  status: StandardErrorStatus!
  title: String!
  traceId: String!
}

# Generated from com/attentivemobile/contracts/journeys/api/RevisionStep.proto
type DelayStep {
  childrenSteps: [AdderStep!]!
  stepParameters: DelayStepParameters!
}

# Generated from com/attentivemobile/contracts/journeys/api/RevisionStepParameters.proto
type DelayStepParameters {
  durationSeconds: Long!
  durationUnit: JourneyDurationUnit!
}

input DeleteAccountInput {
  accountId: ID!
}

# Generated from com/attentivemobile/contracts/account-service/Contract.proto
type DeleteAccountPayload {
  accountId: ID!
}

input DeleteAdminNotificationConfigurationInput {
  notificationId: String
  notificationName: String
  notificationSlug: String
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type DeleteAdminNotificationConfigurationPayload {
  notificationId: String!
}

input DeleteAffiliateUserInput {
  affiliateUserId: String!
}

# Generated from com/attentivemobile/contracts/externaltokens/AffiliateUsers.proto
type DeleteAffiliateUserPayload {
  affiliateUserId: String!
}

input DeleteAssetsInput {
  assetIds: [AssetId!]
}

union DeleteAssetsPayload = DefaultErrorFailure | DeleteAssetsSuccess

# Generated from com/attentivemobile/contracts/asset/library/service/Contract.proto
type DeleteAssetsSuccess {
  deletedFolderIds: [ID!]!
  deletedImageIds: [ID!]!
}

# This request will be for security users only
input DeleteAuthenticatorEnrollmentByEmailInput {
  email: String!
}

union DeleteAuthenticatorEnrollmentByEmailPayload = DefaultErrorFailure | DeleteAuthenticatorEnrollmentByEmailSuccess

# Generated from com/attentivemobile/contracts/account-service/AuthenticatorContract.proto
# If success is true, the authenticator has been deleted
type DeleteAuthenticatorEnrollmentByEmailSuccess {
  success: Boolean!
}

input DeleteBlockCompanyInfoInput {
  companyId: ID!
  companyInfoType: [BlockCompanyInfoType!]
}

union DeleteBlockCompanyInfoPayload = DefaultErrorFailure | DeleteBlockCompanyInfoSuccess

# Generated from com/attentivemobile/contracts/personalization/service/aijourneys/onboarding/companyinfo/BlockCompanyInfo.proto
type DeleteBlockCompanyInfoSuccess {
  success: Boolean!
}

input DeleteBrandImagesInput {
  companyId: ID!
  fileUuid: [String!]
}

# Generated from com/attentivemobile/contracts/brand-kit/BrandImage.proto
type DeleteBrandImagesPayload {
  company: Company
}

input DeleteCampaignMessageGroupInput {
  campaignMessageGroupId: ID!
  companyId: ID!
  userId: ID!
}

input DeleteCircuitBreakerRateLimitInput {
  id: Long!
}

union DeleteCircuitBreakerRateLimitsPayload = DefaultErrorFailure | DeleteCircuitBreakerRateLimitsSuccess

# Generated from com/attentivemobile/contracts/gateway/service/RateLimiting.proto
type DeleteCircuitBreakerRateLimitsSuccess {
  successful: Boolean!
}

input DeleteCompanyApplicationRouteRateLimitInput {
  id: Long!
}

union DeleteCompanyApplicationRouteRateLimitPayload = DefaultErrorFailure | DeleteCompanyApplicationRouteRateLimitSuccess

# Generated from com/attentivemobile/contracts/gateway/service/RateLimiting.proto
type DeleteCompanyApplicationRouteRateLimitSuccess {
  successful: Boolean!
}

input DeleteCompanyCodesInput {
  code: [String!]
  companyId: ID!
  handler: String!
}

# Generated from com/attentivemobile/contracts/creative-service/creative/LegacyCreativeService.proto
type DeleteCompanyCodesPayload {
  code: [CompanyCode!]!
}

input DeleteCompanyGlobalRateLimitInput {
  id: Long!
}

union DeleteCompanyGlobalRateLimitPayload = DefaultErrorFailure | DeleteCompanyGlobalRateLimitSuccess

# Generated from com/attentivemobile/contracts/gateway/service/RateLimiting.proto
type DeleteCompanyGlobalRateLimitSuccess {
  successful: Boolean!
}

input DeleteComposeCampaignInput {
  campaignId: ID!
  companyId: ID!
  userId: ID!
}

# Generated from com/attentivemobile/contracts/campaigns/service/Contract.proto
type DeleteComposeCampaignPayload {
  campaignId: ID!
}

input DeleteConciergeSpecializationInput {
  id: ID!
}

# Generated from com/attentivemobile/contracts/concierge/service/Specialization.proto
type DeleteConciergeSpecializationPayload {
  id: ID!
}

input DeleteCouponSetInput {
  couponSetId: ID!
}

# Generated from com/attentivemobile/contracts/coupon/CouponContract.proto
type DeleteCouponSetPayload {
  deleted: CouponSet!
}

input DeleteCouponUploadInput {
  couponSetId: ID!
  couponUploadId: ID!
}

# Generated from com/attentivemobile/contracts/coupon/Contract.proto
type DeleteCouponUploadPayload {
  deleted: CouponUpload!
}

# This message type is for explicitly deleting properties that are nullable on a creative
input DeleteCreativePropertiesInput {
  alreadySubscribedMessageId: Boolean
  couponSetId: Boolean
}

input DeleteCreativeRuleAssociationInput {
  creativeRuleAssociationId: ID!
}

union DeleteCreativeRuleAssociationPayload = DefaultErrorFailure | DeleteCreativeRuleAssociationSuccess

# Generated from com/attentivemobile/contracts/creative-service/rules/CreativeRuleAssociationService.proto
type DeleteCreativeRuleAssociationSuccess {
  creativeRuleAssociation: CreativeRuleAssociation!
}

input DeleteCreativeRuleInput {
  ruleId: ID!
}

union DeleteCreativeRulePayload = DefaultErrorFailure | DeleteCreativeRuleSuccess

# Generated from com/attentivemobile/contracts/creative-service/rules/CreativeRuleService.proto
type DeleteCreativeRuleSuccess {
  rule: CreativeRule!
}

input DeleteCreativeScheduleInput {
  applyPermanently: Boolean!
  companyId: ID!
  scheduleId: ID!
}

# Generated from com/attentivemobile/contracts/creative-service/schedule/CreativeScheduleService.proto
type DeleteCreativeSchedulePayload {
  schedule: CreativeSchedule!
  scheduleId: String! @deprecated(reason: "deprecated")
}

input DeleteCreativeScheduleWorkflowInput {
  companyId: ID!
  scheduleId: ID @deprecated(reason: "deprecated")
  workflowId: ID
}

union DeleteCreativeScheduleWorkflowPayload = DefaultErrorFailure | DeleteCreativeScheduleWorkflowSuccess

# Generated from com/attentivemobile/contracts/creative/workflow/service/workflows/CreativeSchedulesWorkflowService.proto
type DeleteCreativeScheduleWorkflowSuccess {
  schedule: CreativeSchedule @deprecated(reason: "deprecated")
  workflow: CreativeWorkflowSchedule
}

input DeleteCustomCompanyKeywordInput {
  companyId: ID!
  id: ID!
}

union DeleteCustomCompanyKeywordPayload = DefaultErrorFailure | DeleteCustomCompanyKeywordSuccess

# Generated from com/attentivemobile/contracts/keywords/KeywordService.proto
type DeleteCustomCompanyKeywordSuccess {
  deleted: Boolean!
}

input DeleteCustomKeywordInput {
  companyId: ID!
  customKeywordId: ID!
}

# Generated from com/attentivemobile/contracts/tactical/companycustomkeywords/CompanyCustomKeywordService.proto
type DeleteCustomKeywordPayload {
  deleted: Boolean!
}

input DeleteCustomPropertyDefinitionInput {
  companyId: ID!
  customPropertyDefinitionId: ID!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type DeleteCustomPropertyDefinitionPayload {
  customPropertyDefinition: CustomPropertyDefinition!
}

input DeleteCustomReportInput {
  companyId: ID!
  reportId: String!
}

# Generated from com/attentivemobile/contracts/reports/api/Contract.proto
type DeleteCustomReportPayload {
  reportId: String!
}

input DeleteDismissibleModalInput {
  id: ID!
  modalId: String!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type DeleteDismissibleModalPayload {
  id: ID!
}

input DeleteEdsCronScheduleInput {
  jobType: String!
  taskId: String!
}

# Generated from com/attentivemobile/contracts/eventdestination/cron/service/CronScheduler.proto
type DeleteEdsCronSchedulePayload {
  msg: String!
}

input DeleteEdsJobInput {
  taskId: String!
}

union DeleteEdsJobPayload = DefaultErrorFailure | DeleteEdsJobSuccess

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type DeleteEdsJobSuccess {
  taskUuid: String
}

input DeleteJourneyInput {
  journeyId: ID!
}

# Generated from com/attentivemobile/contracts/journeys/api/Contract.proto
type DeleteJourneyPayload {
  deletedJourney: Journey!
}

input DeleteJourneyScheduleInput {
  companyId: ID!
  journeyScheduleId: ID!
}

# Generated from com/attentivemobile/contracts/journeys/api/JourneySchedule.proto
type DeleteJourneySchedulePayload {
  deletedJourneySchedule: JourneySchedule!
}

union DeleteKeywordRulePayload = DefaultErrorFailure | DeleteKeywordRuleSuccess

input DeleteKeywordRuleRequestInput {
  companyId: ID!
  id: ID!
}

# Generated from com/attentivemobile/contracts/keywords/KeywordRuleService.proto
type DeleteKeywordRuleSuccess {
  deleted: Boolean!
}

input DeleteMessageTemplateInput {
  channel: Channel!
  id: ID!
}

# Generated from com/attentivemobile/contracts/compose/api/Contract.proto
type DeleteMessageTemplatePayload {
  messageTemplate: ComposeMessageTemplate!
}

input DeletePollOptionInput {
  companyId: ID!
  pollOptionId: ID!
}

# Generated from com/attentivemobile/contracts/survey/poll/Contract.proto
type DeletePollOptionPayload {
  deleted: Boolean!
  error: String
}

# Generated from com/attentivemobile/contracts/privacy-service/Contract.proto
type DeleteRequest implements Node {
  affiliateUserId: Long
  clientUserId: String
  company: Company
  id: ID!
  metadata: String
  processed: Boolean!
  requestDateTime: DateTime!
  status: String
  subjectEmail: String
  subjectPhone: String
  updated: DateTime
}

# Generated from com/attentivemobile/contracts/privacy-service/Contract.proto
type DeleteRequestConnection {
  edges: [DeleteRequestEdge!]!
  pageInfo: PageInfo!
  totalCount: Int!
}

# Generated from com/attentivemobile/contracts/privacy-service/Contract.proto
type DeleteRequestEdge {
  cursor: String!
  node: DeleteRequest!
}

input DeleteRouteRateLimitInput {
  id: Long!
}

union DeleteRouteRateLimitPayload = DefaultErrorFailure | DeleteRouteRateLimitSuccess

# Generated from com/attentivemobile/contracts/gateway/service/RateLimiting.proto
type DeleteRouteRateLimitSuccess {
  successful: Boolean!
}

input DeleteSegmentsInput {
  companyId: ID!
  segmentIds: [SegmentId!]
}

union DeleteSegmentsPayload = DefaultErrorFailure | DeleteSegmentsSuccess

# Generated from com/attentivemobile/contracts/segmentation/service/segment/contract.proto
type DeleteSegmentsSuccess {
  legacyIds: [ID!]!
  segmentIds: [ID!]!
}

input DeleteShopifyAutoApplyFormatsInput {
  companyId: ID!
}

union DeleteShopifyAutoApplyFormatsPayload = DefaultErrorFailure | DeleteShopifyAutoApplyFormatsSuccess

# Generated from com/attentivemobile/contracts/personalization/service/aijourneys/onboarding/autoapply/ShopifyAutoApplyFormatsContract.proto
type DeleteShopifyAutoApplyFormatsSuccess {
  success: Boolean!
}

input DeleteSignupInput {
  companyId: ID!
  signupId: String!
}

# Generated from com/attentivemobile/contracts/creative-service/signup/SignupService.proto
type DeleteSignupPayload {
  signupId: String!
}

input DeleteSyncInput {
  syncId: String!
}

union DeleteSyncPayload = DefaultErrorFailure | DeleteSyncSuccess

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type DeleteSyncSuccess {
  success: Boolean!
}

input DeleteTagInput {
  companyId: ID!
  tagId: ID!
}

input DeleteUserContentSettingInput {
  companyId: ID
  objectClass: ContentClass!
  objectId: String
  preferenceType: PreferenceType!
  userId: ID
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type DeleteUserContentSettingPayload {
  preferenceId: String!
}

# Delete webhook request input.
input DeleteWebhookInput {
  companyId: String

  # Id of the webhook to delete.
  id: ID!
}

# Generated from com/attentivemobile/contracts/externaltokens/Webhooks.proto
# Response to deleteWebhook mutation.
type DeleteWebhookPayload {
  # True if webhook was deleted.
  deleted: Boolean!
}

input DeleteWorkloadConfigInput {
  workloadName: String!
}

union DeleteWorkloadConfigPayload = DefaultErrorFailure | DeleteWorkloadConfigSuccess

# Generated from com/attentivemobile/contracts/workload/management/service/configuration/Contract.proto
type DeleteWorkloadConfigSuccess {
  status: String!
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Transformation.proto
type DependentField {
  fieldName: String!
  sourceFieldPath: String!
}

input DeployServerSideTagInput {
  companyId: ID!
}

union DeployServerSideTagPayload = DefaultErrorFailure | DeployServerSideTagSuccess

# Generated from com/attentivemobile/contracts/integrations/serversidetag/Contract.proto
type DeployServerSideTagSuccess {
  success: Boolean!
}

input DescheduleWorkloadInput {
  workloadName: String!
}

union DescheduleWorkloadPayload = DefaultErrorFailure | DescheduleWorkloadSuccess

# Generated from com/attentivemobile/contracts/workload/management/service/runner/Contract.proto
type DescheduleWorkloadSuccess {
  status: String!
}

union DestinationAddress = DestinationEmail | DestinationPhone

# Generated from com/attentivemobile/contracts/subscriptions/api/v2/Model.proto
# Destination email.
type DestinationEmail {
  # Value of destination email.
  value: String!
}

# Generated from com/attentivemobile/contracts/subscriptions/api/v2/Model.proto
# Destination phone.
type DestinationPhone {
  # Value of destination phone.
  value: String!
}

type DeveloperBanner {
  showDeveloperBanner: Boolean!
}

union Dimension = StringDimension | TimeDimension

input DimensionFilter {
  dimensionId: String!
  endDate: DateTime
  list: DimensionFilterValuesList
  nodeId: ID
  nodeIds: [ID!]
  operator: FilterOperator
  startDate: DateTime
  value: String
}

input DimensionFilterValuesList {
  values: [String!]!
}

input DimensionGrouping {
  dimensionId: String!
  granularity: TimeDimensionGranularity
}

# Generated from com/attentivemobile/contracts/metricsapi/Contract.proto
type DimensionValue {
  key: String!
  value: String!
}

# Generated from com/attentivemobile/contracts/metricsapi/Contract.proto
type DimensionValueConnection {
  edges: [DimensionValueEdge!]!
  pageInfo: PageInfo!
  totalCount: Long!
}

# Generated from com/attentivemobile/contracts/metricsapi/Contract.proto
type DimensionValueEdge {
  cursor: String!
  node: DimensionValue!
}

# Generated from com/attentivemobile/contracts/metricsapi/Contract.proto
type DimensionWrapper {
  dimension: Dimension!
}

input DisableAllLegacyScheduledReportsInput {
  companyId: ID!
}

union DisableAllLegacyScheduledReportsPayload = DefaultErrorFailure | DisableAllLegacyScheduledReportsSuccess

# Generated from com/attentivemobile/contracts/reports/api/Contract.proto
type DisableAllLegacyScheduledReportsSuccess {
  message: String!
}

input DisableAutomationProgramInput {
  automationProgramId: ID!
  companyId: ID!
}

# Generated from com/attentivemobile/contracts/messaging/automation/Contract.proto
type DisableAutomationProgramPayload {
  automationProgram: AutomationProgram!
}

# Generated from com/attentivemobile/contracts/integrations/discount/Contract.proto
type Discount {
  allCountriesValid: Boolean
  applicableCustomers: ApplicableCustomers!
  appliesOnOneTimePurchase: Boolean
  appliesOnSubscription: Boolean
  appliesOncePerCustomer: Boolean
  company: Company
  couponCode: String!
  customerReceives: CustomerReceives!
  customerRequirements: CustomerRequirements!
  discountStatus: DiscountStatus!
  discountType: DiscountType!
  ends: DateTime
  externalVendorApi: ExternalVendorApi
  starts: DateTime
  summary: String!
  validCountries: [String!]!
  vendor: Vendor!
  vendorDiscountId: String!
}

enum DiscountLinkFormat {
  DISCOUNT_LINK_FORMAT_CODE_IN_PATH
  DISCOUNT_LINK_FORMAT_CODE_IN_QUERY
  DISCOUNT_LINK_FORMAT_NONE
}

enum DiscountStatus {
  ACTIVE
  EXPIRED
  SCHEDULED
  UNKNOWN_STATUS
}

enum DiscountType {
  BUY_X_GET_Y
  FREE_SHIPPING
  PERCENTAGE_OFF_QUANTITY
  PERCENTAGE_OFF_TOTAL
  PER_ITEM_DISCOUNT
  PER_TOTAL_DISCOUNT
  UNKNOWN_DISCOUNT_TYPE
}

input DismissBannerInput {
  bannerId: ID!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type DismissBannerPayload {
  banner: NotificationBanner!
}

input DismissNotificationInput {
  notificationId: ID!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type DismissNotificationPayload {
  notification: Notification!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type DismissibleModal implements Node {
  description: String
  endTime: DateTime!
  featureFlags: [String!]!
  id: ID!
  imageUrl: String
  modalId: String!
  primaryButtonAction: ButtonAction!
  primaryButtonText: String!
  primaryButtonUrl: String
  secondaryButtonAction: ButtonAction
  secondaryButtonText: String
  secondaryButtonUrl: String
  startTime: DateTime!
  title: String
  url: String!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type DismissibleModalConnection {
  edges: [DismissibleModalEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type DismissibleModalEdge {
  cursor: String!
  node: DismissibleModal!
}

input DismissibleModalFilter {
  activeOnly: Boolean
  companyId: ID
}

input Distance {
  distanceUnit: DistanceUnit!
  value: Float!
}

# Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/ComparisonTypes.proto
type DistanceComparison {
  comparator: SearchDistanceComparator!
  distanceAmount: Long!
  distanceUnit: SearchDistanceUnit!
  origin: String!
}

input DistanceComparisonInput {
  comparator: SearchDistanceComparator!
  distanceAmount: Long!
  distanceUnit: SearchDistanceUnit!
  origin: String!
}

enum DistanceUnit {
  DISTANCE_UNIT_KILOMETER
  DISTANCE_UNIT_METER
  DISTANCE_UNIT_MILE
  DISTANCE_UNIT_UNKNOWN
}

enum DistributionStatus {
  BETA
  DISTRIBUTION_STATUS_UNKNOWN
  PRIVATE
  PUBLIC
  UNLISTED
}

enum DistributionUnit {
  DISTRIBUTION_UNIT_DAY
  DISTRIBUTION_UNIT_HOUR
  DISTRIBUTION_UNIT_MONTH
  DISTRIBUTION_UNIT_UNKNOWN
  DISTRIBUTION_UNIT_WEEK
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type DnsRecord {
  aliasDomainName: String!
  sendgridDomainName: String!
  type: DnsRecordType!
  valid: Boolean!
}

enum DnsRecordType {
  DNS_RECORD_TYPE_CNAME
  DNS_RECORD_TYPE_MX
  DNS_RECORD_TYPE_TXT
  DNS_RECORD_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type DnsRecordValidationResult {
  invalidReason: String
  isValid: Boolean!
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type DnsRecords {
  dkim: DnsRecord!
  dkim1: DnsRecord!
  dkim2: DnsRecord!
  mailCname: DnsRecord!
  mailServer: DnsRecord!
  subdomainSpf: DnsRecord!
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type DnsRecordsValidationResult {
  dkim1: DnsRecordValidationResult!
  dkim2: DnsRecordValidationResult!
  mailCname: DnsRecordValidationResult!
}

# Generated from com/attentivemobile/contracts/externaltokens/Contract.proto
type DocumentationLink {
  text: String!
  url: Url!
}

# Generated from com/attentivemobile/contracts/account/permission/service/AccountPermissionDomainService.proto
type DomainFeature implements Node {
  accountPermissionFeatureName: AccountPermissionFeatureName!
  createdAt: DateTime!
  description: String!
  displayName: String!
  domain: AccountPermissionsDomain
  id: ID!
  updatedAt: DateTime!
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type DomainSuppression {
  companyId: Long
  created: DateTime
  description: String
  domain: String!
  reason: String!
  userEmail: String!
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type DomainSuppressionConnection {
  edges: [DomainSuppressionEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type DomainSuppressionEdge {
  cursor: String!
  node: DomainSuppression!
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type DomainValidationResult {
  dnsRecordsValidation: DnsRecordsValidationResult!
  domainId: Long!
  isValid: Boolean!
}

# Generated from com/attentivemobile/contracts/user/profile/property/UserPropertyV2.proto
type DoubleValueV2 {
  doubleValue: Float!
}

input DownloadUsersFilterInput {
  status: UserStatus
}

input DownloadUsersInput {
  companyId: ID!
  filter: DownloadUsersFilterInput
  userFields: [UserDownloadField!]
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type DownloadUsersPayload {
  company: Company
  downloadUrl: String!
  failureCount: Long
  listSize: Long
}

# Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto
type DraftProductSegment {
  productCount: Long!
  productOriginIds: [String!]!
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type DragAndDropEditorProperties {
  htmlContent: String!
  jsonContent: JSON!
}

input DragAndDropEditorPropertiesInput {
  jsonContent: JSON!
}

# Generated from google/protobuf/duration.proto
type Duration {
  nanos: Int!
  seconds: Long!
}

# Generated from com/attentivemobile/contracts/segmentation/data/service/Contract.proto
type DynamicOption {
  name: String!
}

# Generated from com/attentivemobile/contracts/segmentation/data/service/Contract.proto
type DynamicOptionConnection {
  edges: [DynamicOptionEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/segmentation/data/service/Contract.proto
type DynamicOptionEdge {
  cursor: String!
  node: DynamicOption!
}

enum E2ETestMessageType {
  E2E_TEST_MESSAGE_TYPE_BROWSE_ABANDONMENT
  E2E_TEST_MESSAGE_TYPE_CART_ABANDONMENT
  E2E_TEST_MESSAGE_TYPE_LEGAL
  E2E_TEST_MESSAGE_TYPE_SESSION_ABANDONMENT
  E2E_TEST_MESSAGE_TYPE_UNKNOWN
  E2E_TEST_MESSAGE_TYPE_WELCOME
  E2E_TEST_MESSAGE_TYPE_WELCOME_FOLLOWUP_1
  E2E_TEST_MESSAGE_TYPE_WELCOME_FOLLOWUP_2
  E2E_TEST_MESSAGE_TYPE_WELCOME_FOLLOWUP_3
  E2E_TEST_MESSAGE_TYPE_WELCOME_FOLLOWUP_4
}

# Generated from com/attentivemobile/contracts/eventdestination/report/service/Contract.proto
type EDSJobHistoryConnection {
  edges: [EDSJobHistoryEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/eventdestination/report/service/Contract.proto
type EDSJobHistoryEdge {
  cursor: String!
  node: EDSJobReport!
}

# Generated from com/attentivemobile/contracts/eventdestination/report/service/Contract.proto
type EDSJobReport {
  duration: String!
  endTime: DateTime
  failure: Long!
  rate: String!
  requests: Long!
  startTime: DateTime
  status: String
  success: Long!
  successRate: String!
  taskUuid: String!
}

# Generated from com/attentivemobile/contracts/analytics/event/handler/Contract.proto
# Product details.
type EcommerceProduct {
  # A unique identifier for the product (i.e. "T-Shirt"). If you are providing a [Google Shopping Feed](https://support.google.com/merchants/answer/7052112?hl=en), this should be [item_group_id](https://support.google.com/merchants/answer/6324507). If you don't have an item_group_id in your feed, use [id](https://support.google.com/merchants/answer/6324405). If you are using [Shopify](https://help.shopify.com/en/manual/products), this should be Shopify product ID.
  productId: String!

  # A link to the image of the product. The image should not be larger than 500kb. This image will be used when sending MMS text messages.
  productImage: String

  # The name of the product. This should be in a format that could be used directly in a message.
  productName: String

  # The prices and currencies of the product.
  productPrices: [Money!]!

  # A unique identifier for the product variant (i.e. "Medium Blue T-Shirt"). If you are providing a [Google Shopping Feed](https://support.google.com/merchants/answer/7052112?hl=en), this should be [id](https://support.google.com/merchants/answer/6324405). If you are using Shopify, this should be Shopify product Variant ID.
  productVariantId: String!

  # The number of products.
  quantity: Long
}

# Product details.
input EcommerceProductInput {
  # ID for the product.
  productId: String!

  # Image for the product.
  productImage: String

  # Name for the product.
  productName: String

  # Prices for the product.
  productPrices: [MoneyInput!]

  # ID for the product variant.
  productVariantId: String!

  # Quantity of the product.
  quantity: Long
}

input EditEdsJobInput {
  description: String!
  name: String!
  taskId: String!
}

union EditEdsJobPayload = DefaultErrorFailure | EditEdsJobSuccess

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type EditEdsJobSuccess {
  description: String!
  name: String!
  taskUuid: String!
}

input EditFeatureFlagInput {
  description: String!
  id: ID!
  rolloutVariants: [RolloutInput!]
}

# Generated from com/attentivemobile/contracts/featureflag/service/Contract.proto
type EditFeatureFlagPayload {
  featureFlag: FeatureFlag!
}

enum EditorSource {
  EDITOR_SOURCE_DRAG_AND_DROP
  EDITOR_SOURCE_HTML
  EDITOR_SOURCE_UNKNOWN
}

enum EdsJobIngestionFormat {
  EDS_JOB_INGESTION_FORMAT_CSV
  EDS_JOB_INGESTION_FORMAT_NDJSON
  EDS_JOB_INGESTION_FORMAT_PSV
  EDS_JOB_INGESTION_FORMAT_TSV
  EDS_JOB_INGESTION_FORMAT_UNKNOWN
  EDS_JOB_INGESTION_FORMAT_XML
}

enum EdsJobSync {
  EDS_JOB_SYNC_CUSTOM_ATTRIBUTES
  EDS_JOB_SYNC_CUSTOM_EVENTS
  EDS_JOB_SYNC_ECOMMERCE_ADD_TO_CARTS
  EDS_JOB_SYNC_ECOMMERCE_PRODUCT_VIEWS
  EDS_JOB_SYNC_ECOMMERCE_PURCHASES
  EDS_JOB_SYNC_MOBILE_WALLET
  EDS_JOB_SYNC_PRODUCT_CATALOG
  EDS_JOB_SYNC_SUBSCRIBER_OPT_INS
  EDS_JOB_SYNC_SUBSCRIBER_OPT_OUTS
  EDS_JOB_SYNC_TEXT_SEND
  EDS_JOB_SYNC_TEXT_SEND_BULK
  EDS_JOB_SYNC_UNKNOWN
  EDS_JOB_SYNC_USER_IDENTIFIERS
}

enum EdsJobSyncMethod {
  EDS_JOB_SYNC_METHOD_AMAZON_S3
  EDS_JOB_SYNC_METHOD_ATTENTIVE_SFTP
  EDS_JOB_SYNC_METHOD_CLIENT_HOSTED_SFTP
  EDS_JOB_SYNC_METHOD_MANUAL_UPLOAD
  EDS_JOB_SYNC_METHOD_S3
  EDS_JOB_SYNC_METHOD_SFTP
  EDS_JOB_SYNC_METHOD_SNOWFLAKE_DATA_SHARE
  EDS_JOB_SYNC_METHOD_UNKNOWN
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type EdsTaskProcessingConfig {
  endpoint: String!
  id: String
  intervalBetweenRetries: Int!
  limitPerSec: Int!
  metadata: String!
  retryMultiplier: Int!
  retryTimes: Int!
  taskId: String
  threads: Int!
  timeoutInSec: Int!
}

enum Element {
  BRANDKIT_ELEMENT_BACKGROUND
  BRANDKIT_ELEMENT_BUTTON
  BRANDKIT_ELEMENT_BUTTON_TEXT
  BRANDKIT_ELEMENT_TEXT
}

# Generated from com/attentivemobile/contracts/brand-kit/BrandElementStyles.proto
type ElementProperty {
  name: String!
}

input ElementPropertyInput {
  name: String!
}

# Generated from com/attentivemobile/contracts/subscriptions/api/v2/Model.proto
# Eligibility information.
type Eligibility {
  # Reason for ineligibility.
  ineligibilityReason: IneligibilityReason

  # True if eligible, false if not eligible.
  isEligible: Boolean!
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type EmailAccount implements Node {
  company: Company
  emailProvider: EmailProvider!
  fromAddress: String
  id: ID!
  isReadyToSend: Boolean
  messageTypePools: EmailMessagingTypeIPPools!
  name: String!
  sendingDomain: String
  warmupEndDate: DateTime
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type EmailAccountConnection {
  edges: [EmailAccountEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type EmailAccountEdge {
  cursor: String!
  node: EmailAccount!
}

input EmailAccountFilterInput {
  companyId: ID
  ipPoolId: ID
  unscheduledWarmup: Boolean
}

enum EmailAccountSort {
  EMAIL_ACCOUNT_SORT_LATEST
  EMAIL_ACCOUNT_SORT_OLDEST
  EMAIL_ACCOUNT_SORT_UNKNOWN
}

# Generated from com/attentivemobile/contracts/solutions/bulkoptout/SolutionsOptout.proto
type EmailBulkOptOutJob {
  companyName: String!
  dateRange: SolutionsDateRange
  jobId: String!
  results: String!
}

# Generated from com/attentivemobile/contracts/emailwarmups/Contract.proto
type EmailCampaignSchedulabilityStatus {
  canSchedule: Boolean!
  warningMessage: ScheduleWarnings!
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type EmailCopyRecipient {
  emailAddress: String!
  recipientType: CopyRecipientType!
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type EmailIPAddress implements Node {
  assignedEmailIpPools(after: String, before: String, companyId: ID, filter: EmailIPPoolFilterInput, first: Int, ipPoolId: ID, ipPoolName: String, last: Int, poolType: IPPoolType, shared: Boolean): EmailIPPoolsConnection
  assignedIpPool: EmailIPPool! @deprecated(reason: "deprecated")
  assignedIpPools: [EmailIPPool!]! @deprecated(reason: "deprecated")
  domain: String
  id: ID!
  ipAddress: String!
  verified: Boolean!
  warmupDate: DateTime
  warmupStatus: EmailIPAddressWarmupStatus!
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type EmailIPAddressEdge {
  cursor: String!
  node: EmailIPAddress!
}

input EmailIPAddressFilterInput {
  available: Boolean
  companyId: Long
  domains: [String!]
  emailAccountId: Long
  ipAddress: String
  ipPoolId: Long
  ipPoolNames: [String!]
  verified: Boolean
  warmupStatus: EmailIPAddressWarmupStatus
}

enum EmailIPAddressWarmupStatus {
  EMAIL_IP_ADDRESS_WARMUP_STATUS_DONE
  EMAIL_IP_ADDRESS_WARMUP_STATUS_IN_PROGRESS
  EMAIL_IP_ADDRESS_WARMUP_STATUS_NOT_STARTED
  EMAIL_IP_ADDRESS_WARMUP_STATUS_UNKNOWN
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type EmailIPAddressesConnection {
  edges: [EmailIPAddressEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type EmailIPAssignment {
  ip: EmailIPAddress
  ipAddress: String!
  ipPool: EmailIPPool
}

input EmailIPAssignmentInput {
  ipAddress: String!
  ipPoolId: ID!
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type EmailIPPool implements Node {
  assignedIpAddresses(after: String, available: Boolean, before: String, companyId: ID, domains: [String!], emailAccountId: ID, filter: EmailIPAddressFilterInput, first: Int, ipAddress: String, ipPoolNames: [String!], last: Int, verified: Boolean, warmupStatus: EmailIPAddressWarmupStatus): EmailIPAddressesConnection
  companies(after: String, before: String, first: Int, last: Int): CompanyConnection
  id: ID!
  ipAddresses: [EmailIPAddress!]! @deprecated(reason: "deprecated")
  name: String!
  shared: Boolean!
  type: IPPoolType!
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type EmailIPPoolEdge {
  cursor: String!
  node: EmailIPPool!
}

input EmailIPPoolFilterInput {
  companyId: ID
  ipAddress: String
  ipPoolId: ID
  ipPoolName: String
  poolType: IPPoolType
  shared: Boolean
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type EmailIPPoolsConnection {
  edges: [EmailIPPoolEdge!]!
  pageInfo: PageInfo!
}

enum EmailIntegrationLevel {
  OFF
  TRIGGERED_AND_CAMPAIGN_EMAIL
  TRIGGERED_EMAIL_ONLY
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type EmailManagementCsvResponse {
  csvUrl: String!
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type EmailMessagingTypeIPPools {
  campaignsPool: EmailIPPool!
  transactionalPool: EmailIPPool!
  triggeredPool: EmailIPPool!
}

# Generated from com/attentivemobile/contracts/messaging/automation/Contract.proto
type EmailNotificationSettings {
  emailRecipients: [EmailRecipient!]!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type EmailOpened {
  campaignName: String!
  created: DateTime! @deprecated(reason: "deprecated")
  emailId: EmailOpenedEmailId
  id: String! @deprecated(reason: "deprecated")
  vendor: String!
}

union EmailOpenedEmailId = MessageID | ThirdPartyMessageId

input EmailPoolAssignedCompanies {
  companyInternalIds: [Long!]
}

input EmailPoolAssignedIPAddresses {
  ips: [String!]
}

input EmailPreviewInput {
  body: String!
  preheaderText: String
  subject: String!
}

input EmailPreviewPropertiesInput {
  fromEmail: String!
  fromName: String!
  htmlContent: String!
  preheader: String
  replyToEmail: String!
  subject: String!
}

input EmailPreviewRecipientsInput {
  emailAddresses: [String!]
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type EmailProperties {
  beeEditorJson: JsonString @deprecated(reason: "deprecated")
  editorSource: EditorSource!
  emailTemplateInstanceId: String!
  fromEmail: String!
  fromName: String!
  gmailOfferAnnotation: GmailOfferAnnotation
  htmlContent: String @deprecated(reason: "deprecated")
  htmlContentUrl: Url!
  messageEditorProperties: MessageEditorProperties!
  preheaderText: String
  replyToEmail: String!
  subject: String!
}

input EmailPropertiesRequest {
  beeEditorJson: JsonStringInput @deprecated(reason: "deprecated")
  dragAndDropEditorPropertiesRequest: DragAndDropEditorPropertiesInput
  editorSource: EditorSource!
  fromEmail: String
  fromName: String
  gmailOfferAnnotation: GmailOfferAnnotationRequest
  htmlEditorPropertiesRequest: HtmlEditorPropertiesInput
  preheaderText: String
  replyToEmail: String
  subject: String
}

enum EmailProvider {
  EMAIL_PROVIDER_SENDGRID
  EMAIL_PROVIDER_SFMC
  EMAIL_PROVIDER_UNKNOWN
}

# Generated from com/attentivemobile/contracts/messaging/automation/Contract.proto
type EmailRecipient {
  email: String!
}

enum EmailRowModuleTypes {
  EMAIL_ROW_MODULE_TYPES_FOOTER
  EMAIL_ROW_MODULE_TYPES_HEADER
  EMAIL_ROW_MODULE_TYPES_SOCIAL
  EMAIL_ROW_MODULE_TYPES_VALUE_PROP
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type EmailSent {
  campaignName: String!
  created: DateTime! @deprecated(reason: "deprecated")
  emailId: EmailSentEmailId
  id: String! @deprecated(reason: "deprecated")
  vendor: String!
}

union EmailSentEmailId = MessageID | ThirdPartyMessageId

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type EmailSettings {
  emailCopyRecipients: [EmailCopyRecipient!]!
  enableCopyRecipientsForCampaigns: Boolean
  enableCopyRecipientsForJourneys: Boolean
  linkSubdomain: String!
}

input EmailSubjectAndPreviewContentInput {
  previewText: String
  subjectLine: String!
}

# Generated from com/attentivemobile/protobuf/common/compose/Message.proto
type EmailTemplate {
  htmlContentUrl: String!
  htmlPreviewUrl: String!
  jsonContent: String!
  jsonContentUrl: String! @deprecated(reason: "deprecated")
  subject: String!
}

input EmailTemplateInput {
  htmlContent: String!
  jsonContent: JsonStringInput!
  templateType: MessagingTemplateType!
}

# An empty object with no fields, mapping to com.google.protobuf.Empty
scalar Empty

input EmptyCartInput {
  sessionId: String!
}

# Generated from com/attentivemobile/contracts/concierge/service/ShoppingCart.proto
type EmptyConciergeCartPayload {
  cart: SavedConciergeCart!
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type EmptyEmailSubject {
  message: String!
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type EmptyMessageBody {
  body: String!
}

input EnableAutomationProgramInput {
  automationProgramId: ID!
  companyId: ID!
}

# Generated from com/attentivemobile/contracts/messaging/automation/Contract.proto
type EnableAutomationProgramPayload {
  automationProgram: AutomationProgram!
}

input EnableCustomCompanyKeywordInput {
  companyId: ID!
  id: ID!
  shouldEnable: Boolean!
}

union EnableCustomCompanyKeywordPayload = DefaultErrorFailure | EnableCustomCompanyKeywordSuccess

# Generated from com/attentivemobile/contracts/keywords/KeywordService.proto
type EnableCustomCompanyKeywordSuccess {
  keyword: CustomCompanyKeyword!
}

input EnableSendScheduledReportOverSftpInput {
  active: Boolean
  companyId: ID!
  convertCsv: Boolean
  filename: String
  reportSystem: String
  s3Path: String @deprecated(reason: "deprecated")
  scheduledReportId: Long!
  scheduledReportIds: [Long!]
  sftpHost: String
  sftpPassword: String
  sftpPath: String
  sftpUser: String
  sharedS3Sftp: String @deprecated(reason: "deprecated")
}

# Generated from com/attentivemobile/contracts/solutions/sftp/Sftp.proto
type EnableSendScheduledReportOverSftpPayload {
  company: Company
  unscheduledReportIds: [Long!]!
}

input EnableStandardSftpReportsInput {
  companyId: ID!
  features: [SftpReportFeature!]
  fileName: String
  fileS3Path: String
  isTripleWhale: Boolean
  sftpHost: String
  sftpPassword: String
  sftpPath: String
  sftpUser: String
  typeFilter: [EventType!]
  useSftpKey: Boolean
}

# Generated from com/attentivemobile/contracts/solutions/sftp/Sftp.proto
type EnableStandardSftpReportsPayload {
  company: Company
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type Encryption {
  passphrase: String
  privateKey: PrivateKey!
  type: EncryptionType!
}

input EncryptionInput {
  passphrase: String
  privateKey: PrivateKeyInput!
  type: EncryptionType!
}

enum EncryptionType {
  ENCRYPTION_TYPE_PGP
  ENCRYPTION_TYPE_UNKNOWN
}

input EndCreativeExperimentInput {
  companyId: ID!
  experimentId: ID!
}

# Generated from com/attentivemobile/contracts/creative-service/experiment/CreativeExperimentService.proto
type EndCreativeExperimentPayload {
  experiment: CreativeExperiment!
}

# Generated from com/attentivemobile/protobuf/common/Effect.proto
type EnhancementEffect {
  change: EnhancementMetricChange!
  metric: EnhancementMetric!
}

enum EnhancementMetric {
  ENHANCEMENT_METRIC_REV
  ENHANCEMENT_METRIC_ROI
  ENHANCEMENT_METRIC_UNKNOWN
}

enum EnhancementMetricChange {
  ENHANCEMENT_METRIC_CHANGE_HIGHER
  ENHANCEMENT_METRIC_CHANGE_LOWER
  ENHANCEMENT_METRIC_CHANGE_MAXIMIZED
  ENHANCEMENT_METRIC_CHANGE_SIMILAR
  ENHANCEMENT_METRIC_CHANGE_UNKNOWN
}

enum EnhancementType {
  ENHANCEMENT_TYPE_BLENDED
  ENHANCEMENT_TYPE_EXCLUSION
  ENHANCEMENT_TYPE_REVENUE_BOOSTING
  ENHANCEMENT_TYPE_REVENUE_MAXIMIZING
  ENHANCEMENT_TYPE_ROI_BOOSTING
  ENHANCEMENT_TYPE_ROI_MAXIMIZING
  ENHANCEMENT_TYPE_UNKNOWN
}

input EnqueueSuppressionJobInput {
  fileId: ID!
  removeSuppression: Boolean!
}

# Generated from com/attentivemobile/contracts/subscriptions/batch/Contract.proto
type EnqueueSuppressionJobPayload {
  fileId: String!
}

input EnqueueUserFileDownloadInput {
  companyId: ID!
  filter: UserFileDownloadFilterInput!
  userFields: [UserFileDownloadField!]
}

# Generated from com/attentivemobile/contracts/user/profile/download/Contract.proto
type EnqueueUserFileDownloadPayload {
  file: UserFileDownload
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Transformation.proto
type EnrichedField {
  fieldName: String!
  targetFieldPath: String!
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Transformation.proto
type Enricher {
  enrichment: Enrichment!
  eventTypes: [EventType!]!
  needEnrichment: NeedEnrichment
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Transformation.proto
type Enrichment {
  dataSource: DataSource!
  dependentFields: [DependentField!]!
  enrichedFields: [EnrichedField!]!
}

# Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto
type EnrichmentTriggers {
  channelData: [ChannelDataEntry!]!
  doubleoptSignupurl: Boolean @deprecated(reason: "deprecated")
  exportAnonId: Boolean
  filterKlaviyo: Boolean
  localeUpdatedEvents: Boolean
  timezone: String
  useAttributableConversions: Boolean
  useFilteredEvents: Boolean
}

input EnrichmentTriggersInput {
  channelDataInput: [ChannelDataInputEntry!]
  doubleoptSignupurl: Boolean @deprecated(reason: "deprecated")
  exportAnonId: Boolean
  filterKlaviyo: Boolean
  localeUpdatedEvents: Boolean
  timezone: String
  useAttributableConversions: Boolean
  useFilteredEvents: Boolean
}

input EnterConciergeChatInput {
  chatId: ID!
}

# Generated from com/attentivemobile/contracts/concierge/service/Chat.proto
type EnterConciergeChatPayload {
  chatId: ID!
}

# Generated from com/attentivemobile/contracts/tag/service/Contract.proto
type EntityTagAssigmentPayload {
  taggableEntity: TaggableEntity!
}

enum EntityType {
  ENTITY_TYPE_COMPANY_APPLICATION
  ENTITY_TYPE_COMPANY_UPLOAD
  ENTITY_TYPE_COMPANY_VENDOR_INTEGRATION
  ENTITY_TYPE_CONVERSATIONAL
  ENTITY_TYPE_CREATIVE
  ENTITY_TYPE_JOURNEYS
  ENTITY_TYPE_PUBLIC_API_APPLICATION
  ENTITY_TYPE_SEND_SERVICE
  ENTITY_TYPE_SHOPIFY_SERVER_PIXEL
  ENTITY_TYPE_TAG
  ENTITY_TYPE_UNKNOWN
}

input EntryClassArgsEntry {
  key: String!
  value: String!
}

# Generated from com/attentivemobile/contracts/composition/MessageAttachment.proto
type EnumMacroBehaviorConfig {
  enumeratedValues: [String!]!
}

# Generated from com/attentivemobile/protobuf/common/targeting/UserProperty.proto
type EnumeratedPropertyDefinition {
  enumeratedValues: [EnumeratedValue!]!
}

# Generated from com/attentivemobile/protobuf/common/targeting/UserProperty.proto
type EnumeratedPropertyValueID {
  id: String!
}

# Generated from com/attentivemobile/protobuf/common/targeting/UserProperty.proto
type EnumeratedValue {
  isDeleted: Boolean!
  value: String!
  valueId: EnumeratedPropertyValueID!
}

# Generated from com/attentivemobile/contracts/user/profile/property/UserPropertyV2.proto
type EnumeratedValueV2 {
  id: String!
  name: String!
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type ErrorReport {
  count: Long!
  error: String!
  statusCode: Long!
  timestamp: String!
}

# Generated from com/attentivemobile/contracts/event/platform/batchjobs/service/Contract.proto
type ErroredJob {
  cluster: Cluster!
  datastreamName: String!
  errorMessage: String!
  jobId: String!
  latestCheckpoint: Checkpoint!
}

# Generated from com/attentivemobile/contracts/concierge/service/Escalation.proto
type EscalationConnection {
  edges: [EscalationEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/concierge/service/Escalation.proto
type EscalationEdge {
  cursor: String!
  node: UserConversationEscalation!
}

input EscalationsFilter {
  end: DateTime
  start: DateTime
}

input EscalationsSort {
  sortColumn: EscalationsSortColumn!
  sortDirection: EscalationsSortDirection!
}

enum EscalationsSortColumn {
  ESCALATIONS_SORT_COLUMN_DATE_TIMESTAMP
  ESCALATIONS_SORT_COLUMN_UNKNOWN_COLUMN
}

enum EscalationsSortDirection {
  ESCALATIONS_SORT_DIRECTION_ASC
  ESCALATIONS_SORT_DIRECTION_DESC
  ESCALATIONS_SORT_UNKNOWN_DIRECTION
}

# Generated from com/attentivemobile/contracts/campaigns/service/Stats.proto
type EstimatedRecipientCount {
  count: Int!
  countNotFatigued: Int!
}

# Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto
type EtlConfig {
  associatedCompanies: [AssociatedCompanies!]!
  company: Company
  enabled: EtlEnabled
  etlConfigId: String!
  etlConfigStaticValue: [EtlConfigStaticValue!]!
  etlReportMetadata: EtlReportMetadata
  eventTypes: [EventType!]!
  externalSftpConfig: ExternalSftpConfig
  features: [EtlConfigFeature!]!
}

# Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto
type EtlConfigConnection {
  edges: [EtlConfigEdge!]!
  pageInfo: PageInfo!
  totalCount: Int!
}

# Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto
type EtlConfigEdge {
  cursor: String!
  node: EtlConfig!
}

enum EtlConfigFeature {
  ETL_CONFIG_FEATURE_CONVERSIONS
  ETL_CONFIG_FEATURE_GENERAL_DATA_FEED
}

# Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto
type EtlConfigStaticValue {
  columnName: String!
  isEnabled: Boolean!
  staticValueId: String
  valueToPopulate: String!
}

input EtlConfigStaticValueInput {
  columnName: String!
  isEnabled: Boolean!
  staticValueId: String
  valueToPopulate: String!
}

enum EtlEnabled {
  CUSTOM_DAG
  DISABLED
  ENABLED
}

# Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto
type EtlReportMetadata {
  autoIngested: Boolean
  columns: Columns
  csvFormatting: CsvFormatting
  enrichmentTriggers: EnrichmentTriggers
  isEncrypted: Boolean
  paths: Paths
  publicKeyFingerprint: String @deprecated(reason: "deprecated")
  rollup: Boolean
  rollupAndIndividualFiles: Boolean
  runEtl: String @deprecated(reason: "deprecated")
}

input EtlReportMetadataInput {
  autoIngested: Boolean
  columns: ColumnsInput
  csvFormatting: CsvFormattingInput
  enrichmentTriggers: EnrichmentTriggersInput
  isEncrypted: Boolean
  paths: PathsInput
  pgpKeyString: String @deprecated(reason: "deprecated")
  publicKeyFingerprint: String @deprecated(reason: "deprecated")
  rollup: Boolean
  rollupAndIndividualFiles: Boolean
  runEtl: String @deprecated(reason: "deprecated")
}

# Generated from com/attentivemobile/contracts/composition/Contract.proto
type EvaluateMessagePreviewPayload {
  evaluatedChannelResponse: EvaluatedChannelResponse!
}

union EvaluatedChannelResponse = EvaluatedEmailResponse | EvaluatedRcsResponse | EvaluatedTextResponse

# Generated from com/attentivemobile/contracts/composition/Contract.proto
type EvaluatedEmailResponse {
  evaluatedBody: String!
  evaluatedPreheaderText: String
  evaluatedSubject: String!
  macrosWithoutContent: [String!]!
}

# Generated from com/attentivemobile/contracts/composition/Contract.proto
type EvaluatedRcsResponse {
  fallback: RcsFallback!
  rcsMessageParts: [RcsMessagePart!]!
  suggestions: [RcsSuggestion!]!
}

# Generated from com/attentivemobile/contracts/composition/Contract.proto
type EvaluatedTextResponse {
  evaluatedBody: String!
  evaluatedMediaUrl: String
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Datastream.proto
type EventBackup {
  s3Location: S3Location!
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type EventDestinationNode {
  authorId: Long!
  collection: NodeCollection
  collectionId: Long!
  created: DateTime!
  description: String!
  enabled: Boolean!
  jobCount: Long
  metadata: JSON!
  name: String!
  nodeType: String
  nodeTypeId: Long!
  tags: [String!]!
  updated: DateTime!
  uuid: String!
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type EventDestinationNodeConnection {
  edges: [EventDestinationNodeEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type EventDestinationNodeEdge {
  cursor: String!
  node: EventDestinationNode!
}

# Generated from com/attentivemobile/contracts/eventdestination/task/service/Contract.proto
type EventDestinationTask {
  company: Company
  created: DateTime
  description: String
  id: String!
  jobType: String
  lastExecutedRun: EventDestinationTaskHistory
  name: String
  nextRun: DateTime
  previousRun: DateTime
  sourceInfo: String
  sourceType: String
  status: String
  targetInfo: String
  targetType: String
  userId: String!
}

# Generated from com/attentivemobile/contracts/eventdestination/task/service/Contract.proto
type EventDestinationTaskEdge {
  cursor: String!
  node: EventDestinationTask!
}

# Generated from com/attentivemobile/contracts/eventdestination/task/service/Contract.proto
type EventDestinationTaskHistory {
  context: JSON
  parentTaskId: String
  runDate: DateTime
  scheduledDate: DateTime!
  status: String!
  taskId: String!
  uuid: String!
}

input EventDestinationTasksFilter {
  companyId: ID
  search: String
}

# Generated from com/attentivemobile/contracts/eventdestination/task/service/Contract.proto
type EventDestinationTasksPayload {
  edges: [EventDestinationTaskEdge!]!
  pageInfo: PageInfo!
  totalCount: Long!
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type EventDestinations {
  destinations(after: String, before: String, first: Int, last: Int): NodeCollectionConnection
  errorReportsByRun(end: String, jobUuid: String!, start: String): JobErrorReportResponse
  job(jobRecordUuid: String!): JobRecord
  jobHistory(after: String, before: String, first: Int, last: Int, taskId: String!): JobHistoryConnection
  jobHistoryV2(after: String, before: String, first: Int, last: Int, taskId: String!): JobHistoryConnection
  jobHistoryV3(after: String, before: String, end: DateTime!, excludeNoop: Boolean, first: Int, last: Int, start: DateTime!, taskId: String!): EDSJobHistoryConnection
  jobReport(end: DateTime, runId: String, start: DateTime, taskId: String!): GetJobReportResponse
  jobReports(after: String, before: String, end: DateTime!, first: Int, last: Int, start: DateTime!, taskIds: [String!]): JobReportsConnection
  jobs(after: String, before: String, filter: GetJobRecordsFilter, first: Int, last: Int, nodeUuid: String): JobRecordConnection
  node(nodeUuid: String!): EventDestinationNode
  nodes(after: String, before: String, collectionId: String, first: Int, last: Int): EventDestinationNodeConnection
  nodesByTag(after: String, before: String, first: Int, last: Int, tag: String!): EventDestinationNodeConnection
  runReportsV2(companyId: ID!, lastXOffset: Int, lastXRuns: Int!, taskUuid: String!, topicType: String!): GetRunReportsResponse
}

# Generated from com/attentivemobile/contracts/composition/MessageAttachment.proto
type EventFilter {
  timeCondition: TimeConditionOutput!
}

# Generated from com/attentivemobile/contracts/composition/MessageAttachment.proto
type EventLookupAttachment {
  application: Application
  entityToken: String!
  eventFilter: EventFilter
  eventLookupType: EventLookupType!
  eventType: String!
  isCustom: Boolean
}

input EventLookupAttachmentProperties {
  applicationId: ID
  entityToken: String!
  eventLookupType: EventLookupType!
  eventType: String!
  isCustom: Boolean
  timeCondition: TimeCondition
}

enum EventLookupType {
  EVENT_LOOKUP_TYPE_BY_UUID
  EVENT_LOOKUP_TYPE_DYNAMIC_FILTERS
  EVENT_LOOKUP_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/protobuf/common/EventOrigin.proto
type EventOrigin {
  entity: EventOriginEntity
}

union EventOriginEntity = ApplicationOrigin | VendorOrigin

input EventPropertiesFilter {
  propertyName: String!
  stringCondition: StringCondition
}

# Generated from com/attentivemobile/contracts/tactical/eventreplay/EventReplayService.proto
type EventReplay implements Node {
  company: Company
  created: DateTime!
  description: String
  estimatedEventCounts: String!
  eventRangeEnd: DateTime!
  eventRangeStart: DateTime!
  eventReplayRunDetails: EventReplayRunDetails
  eventTypes: [EventReplayEventType!]!
  id: ID!
  status: EventReplayStatus!
  title: String
  updated: DateTime!
  user: Account
}

enum EventReplayEventType {
  EMAIL_SAVE
  JOIN
  JOURNEY_POSTBACK
  MESSAGE_LINK_CLICK
  MESSAGE_RECEIPT
  SUBSCRIPTION_ALREADY_SUBSCRIBED
  SUBSCRIPTION_OPTED_OUT
  SUBSCRIPTION_OPT_IN_COMPLETED
  SUBSCRIPTION_OPT_IN_STARTED
  USER_PROPERTY_SET
}

# Generated from com/attentivemobile/contracts/tactical/eventreplay/EventReplayService.proto
type EventReplayFailures {
  company: Company
  errorMessage: String
  eventReplay: EventReplay
}

# Generated from com/attentivemobile/contracts/tactical/eventreplay/EventReplayService.proto
type EventReplayRunDetails {
  eventCounts: String!
  id: String!
  runDateEnd: DateTime!
  runDateStart: DateTime!
}

enum EventReplayStatus {
  ANALYZING
  COMPLETED
  FAILED
  IN_PROGRESS
  PENDING
  QUEUED
  READY
}

# Generated from com/attentivemobile/contracts/tactical/eventreplay/EventReplayService.proto
type EventReplaysResponse {
  eventReplay: [EventReplay!]!
}

# Generated from com/attentivemobile/contracts/tagbuildservice/taghealth/Contract.proto
type EventStats {
  categoryCount: Long!
  emailCount: Long!
  entryDate: String!
  eventCount: Long!
  matchesProductDataCount: Long!
  nameCount: Long!
  phoneCount: Long!
  productMatchRate: Float!
  skuCount: Long!
  subscriberCount: Long!
  updated: DateTime!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto
type EventSummariesForProductsPayload {
  productEventSummaries: [ProductEventSummary!]!
}

enum EventType {
  ADD_TO_CART
  ALERT
  ATTENTIVE_CDC
  CAMPAIGN_CANCELLED
  CAMPAIGN_CREATED
  CAMPAIGN_DELETED
  CAMPAIGN_DOWNGRADED
  CAMPAIGN_DRAFTED
  CAMPAIGN_MMS_RATE_LIMIT_UPDATED
  CAMPAIGN_NEEDS_COUPONS
  CAMPAIGN_PAUSED
  CAMPAIGN_PAW_AUDIENCE_SIZE_ZERO
  CAMPAIGN_PICK_A_WINNER_FAILED
  CAMPAIGN_RATE_LIMIT_UPDATED
  CAMPAIGN_REPLAY_CONFIRMED
  CAMPAIGN_REPLAY_REQUESTED
  CAMPAIGN_RESCHEDULED
  CAMPAIGN_RESUMED
  CAMPAIGN_SEND_FINISHED
  CAMPAIGN_SEND_STARTED
  CAMPAIGN_SMS_RATE_LIMIT_UPDATED
  CAMPAIGN_SUBSCRIBER_QUALIFICATION_EVALUATED
  CAMPAIGN_TIMEZONE_SEND_FINISHED
  CAMPAIGN_TIMEZONE_SEND_STARTED
  CAMPAIGN_UPDATED
  CARRIER_DATA_RECEIVED
  CART_UPDATED
  CART_VIEWED
  CHECKOUT
  CHECKOUT_ABANDONED
  CHECKOUT_STARTED
  CHECKOUT_UPDATED
  CLICK
  COLLECTION_VIEWED
  COMPANY_LINK_CLICK
  CONVERSION
  CONVERSION_FOR_MESSAGE
  COUPON_ASSIGNED
  COUPON_SCREEN_LOAD
  CREATIVE_INTERACTION
  CUSTOMER_SERVICE_TICKET_CLOSED
  CUSTOMER_SERVICE_TICKET_OPENED
  CUSTOM_EVENT
  DLR_MESSAGE_RECEIPT
  DRUID_MESSAGE_EXPLORER_DATA
  EDS_TELEMETRY_TRACER
  EMAIL_ACTIVITY
  EMAIL_MESSAGE_OPENED
  EMAIL_MESSAGE_SENT
  EMAIL_SAVE
  EMAIL_SPAM_REPORTED
  EMAIL_STATUS_UPDATED
  ENRICHED_DLR
  EVENT_CONSUMED
  FULFILLMENT_AVAILABLE_FOR_PICKUP
  FULFILLMENT_CANCELLED
  FULFILLMENT_CREATED
  FULFILLMENT_DELIVERED
  FULFILLMENT_DELIVERY_ATTEMPT_FAILURE
  FULFILLMENT_DELIVERY_EXCEPTION
  FULFILLMENT_IN_TRANSIT
  FULFILLMENT_OUT_FOR_DELIVERY
  FULFILLMENT_PENDING
  IMPRESSION
  INVENTORY_LISTING
  JOIN
  JOURNEY_POSTBACK
  JOURNEY_REVISION_PUBLISHED
  JOURNEY_TASK_EXECUTED
  JOURNEY_TRIGGERED
  LAST_CLICK_CONVERSION
  LOYALTY_OPTED_IN
  LOYALTY_OPTED_OUT
  LOYALTY_POINTS_CHANGED
  LOYALTY_POINT_BALANCE_REMINDER_ISSUED
  LOYALTY_REFERRAL_COMPLETED
  LOYALTY_REWARD_REDEEMED
  LOYALTY_REWARD_REDEMPTION_REMINDER_ISSUED
  LOYALTY_TIER_CHANGED
  LOYALTY_TIER_PROGRESS_UPDATED
  LOYALTY_USER_SPECIAL_DAY_REACHED
  MESSAGE_EXPLORER_CONTENT_CREATED
  MESSAGE_EXPLORER_CREATED
  MESSAGE_EXPLORER_UPDATED
  MESSAGE_FATIGUE
  MESSAGE_LINK_CLICK
  MESSAGE_LINK_CREATED
  MESSAGE_LINK_PREFETCH
  MESSAGE_METADATA_CONTENT
  MESSAGE_METADATA_FINALIZED
  MESSAGE_RECEIPT
  MESSAGE_STATUS_DETAILS
  MOBILE_WALLET_COUPON_INSTALLED
  MOBILE_WALLET_COUPON_UNINSTALLED
  MULTI_PROPERTY_SET
  OPT_OUT
  OPT_OUT_SUBSCRIPTION
  ORDER
  ORDER_CANCELLED
  ORDER_CONFIRMED
  ORDER_SUBSCRIPTION_CHARGE_FAILED
  ORDER_SUBSCRIPTION_CHARGE_PAID
  ORDER_SUBSCRIPTION_CHARGE_SCHEDULED
  ORDER_SUBSCRIPTION_CREATED
  ORDER_SUBSCRIPTION_ONE_TIME_PRODUCT_ADD_FAILED
  ORDER_SUBSCRIPTION_ONE_TIME_PRODUCT_ADD_SUCCEEDED
  ORDER_SUBSCRIPTION_PROCESSED
  ORDER_SUBSCRIPTION_SKIPPED
  PAGE_LEAVE
  PAGE_VIEW
  PAYMENTS_BUY_RESPONSE_RECEIVED
  PAYMENTS_CALL_TO_ACTION_SENT
  PAYMENTS_CARD_VAULTED
  PAYMENTS_ORDER_CANCELLED
  PAYMENTS_ORDER_CANCEL_REQUESTED
  PAYMENTS_PURCHASE_COMPLETED
  PAYMENTS_PURCHASE_REFUNDED
  PAYMENTS_SESSION_FAILED
  PAYMENTS_SESSION_STARTED
  PRINCIPAL_ACTION_OCCURRED
  PROBE
  PRODUCT_INVENTORY_CHANGE
  PRODUCT_RECOMMENDATION
  PRODUCT_VIEW
  PUBLIC_API_REQUEST
  PURCHASE
  RCS_CAPABILITY_UPDATED
  REFUND_FAILED
  REFUND_SUCCEEDED
  REMOVED_FROM_CART
  REMOVE_FROM_CART
  REPLENISHMENT
  RESUBSCRIBE
  REVIEW_SUBMITTED
  SEARCH_ATTRIBUTE_SET
  SEARCH_SUBMITTED
  SEGMENT_ENHANCEMENT_SUBSCRIBER_METADATA_PROCESSED
  SEGMENT_JOINED
  SEND_METADATA_CREATED
  SEND_METADATA_UPDATED
  SEND_STATUS_UPDATED
  SHARED_IP_EVENT_SNAPSHOT
  SUBSCRIBER_DOUBLE_OPT_IN_STARTED
  SUBSCRIBER_FATIGUED
  SUBSCRIBER_LIST
  SUBSCRIBER_PURCHASE_KEYWORD_RECEIVED
  SUBSCRIBER_TAG
  SUBSCRIPTION_ALREADY_SUBSCRIBED
  SUBSCRIPTION_CONTACT_INFO_REASSIGNED
  SUBSCRIPTION_DEACTIVATED
  SUBSCRIPTION_DEACTIVATED_SKIPPED
  SUBSCRIPTION_OPTED_OUT
  SUBSCRIPTION_OPT_IN_COMPLETED
  SUBSCRIPTION_OPT_IN_DECLINED
  SUBSCRIPTION_OPT_IN_STARTED
  SUBSCRIPTION_POINT_IN_TIME_METRIC
  SUBSCRIPTION_SUPPRESSED
  SUBSCRIPTION_UNSUPPRESSED
  SURVEY_KEYWORD_RECEIVED
  TEXT_MESSAGE_RECEIVED
  TRACE_EVENT
  TRINO_QUERY
  USER_IDENTIFIER_COLLECTED
  USER_PROPERTY_BATCH
  USER_PROPERTY_DEFINITION_CHANGE
  USER_PROPERTY_MUTATE
  USER_PROPERTY_SET
  USER_PROPERTY_VALUE_CHANGE
  USER_RELEVANT_VARIANT_PRICE_DROP
  VARIANT_INVENTORY_CHANGE
  VARIANT_PRICE_CHANGE
  VISITOR_EXPERIMENT_ASSIGNMENT
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Transformation.proto
type EventTypeSplitter {
  dlqTopic: PulsarTopic
  rules: [EventTypeSplitterRule!]!
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Transformation.proto
type EventTypeSplitterRule {
  disabled: Boolean
  eventTypes: [EventType!]!
  parallelism: Int!
  pulsarTopic: PulsarTopic!
}

# Generated from com/attentivemobile/contracts/user/profile/EventUser.proto
type EventUser {
  email: String
  externalIdentifiers: EventUserExternalIdentifiers
  phone: String
}

# Generated from com/attentivemobile/contracts/user/profile/EventUser.proto
type EventUserCustomIdentifier {
  name: String!
  value: String!
}

input EventUserCustomIdentifierInput {
  name: String!
  value: String!
}

# Generated from com/attentivemobile/contracts/user/profile/EventUser.proto
type EventUserExternalIdentifiers {
  clientUserId: String
  customIdentifiers: [EventUserCustomIdentifier!]!
}

input EventUserExternalIdentifiersInput {
  clientUserId: String
  customIdentifiers: [EventUserCustomIdentifierInput!]
}

input EventUserInput {
  email: String
  externalIdentifiers: EventUserExternalIdentifiersInput
  phone: String
}

# Generated from com/attentivemobile/contracts/common/EventVisitor.proto
type EventVisitor {
  # Email of the user associated with the action. This field is required if phone is not provided.
  email: String

  # Phone number of the user associated with the action. E.164 format is required. This field is required if email is not provided.
  phone: String
}

input EventVisitorInput {
  # Email of the user associated with the action. This field is required if phone is not provided.
  email: String

  # Phone number of the user associated with the action. E.164 format is required. This field is required if email is not provided.
  phone: String
}

# Generated from com/attentivemobile/protobuf/common/Chunk.proto
type ExclusionHeuristicChunk {
  dummyField: Empty!
}

# Generated from com/attentivemobile/protobuf/common/Chunk.proto
type ExclusionInvalidSubscribersChunk {
  dummyField: Empty!
}

# Generated from com/attentivemobile/protobuf/common/Chunk.proto
type ExclusionProductAffinityAlphaChunk {
  productOriginIds: [ProductOriginID!]!
  purchasePropensityModelVersion: String!
}

# Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/ComparisonTypes.proto
type ExistenceComparison {
  isNot: Boolean
}

input ExistenceComparisonInput {
  isNot: Boolean
}

# Generated from com/attentivemobile/contracts/journeys/api/RevisionStep.proto
type ExperimentStep {
  childrenSteps: [VariantStep!]!
  stepConfig: ExperimentStepConfig!
  stepParameters: ExperimentStepParameters!
}

# Generated from com/attentivemobile/contracts/journeys/api/RevisionStepConfig.proto
type ExperimentStepConfig {
  branchMax: Int!
  branchMin: Int!
}

# Generated from com/attentivemobile/contracts/journeys/api/RevisionStepParameters.proto
type ExperimentStepParameters {
  trafficSplitType: TrafficSplitType!
}

enum ExperimentType {
  EXPERIMENT_EMAIL_CONTENT
  EXPERIMENT_EMAIL_SUBJECT_LINE_AND_PREVIEW_TEXT
  EXPERIMENT_SEND_TIME
  EXPERIMENT_SUBSCRIBER_OPTIMIZED_SEND_TIME
  EXPERIMENT_TEXT_CONTENT
}

# Generated from com/attentivemobile/contracts/metricsapi/DatasetsContract.proto
type ExportDatasetResponse {
  s3Urls: [String!]!
}

input ExportSegmentInput {
  baseAudienceDefinition: BaseAudienceDefinitionInput!
  companyId: ID!
  recipientEmails: [String!]!
  segmentId: ID!
}

union ExportSegmentPayload = DefaultErrorFailure | ExportSegmentSuccess

# Generated from com/attentivemobile/contracts/segmentation/service/segment/contract.proto
type ExportSegmentSuccess {
  segmentId: ID!
}

# Generated from com/attentivemobile/contracts/subscriptions/api/v2/Contract.proto
# External Identifiers for a user.
type ExternalCustomIdentifiers {
  # External identifier name.
  name: String!

  # External identifier value.
  value: String!
}

# External Identifiers for a user.
input ExternalCustomIdentifiersInput {
  # External identifier name.
  name: String!

  # External identifier value.
  value: String!
}

# Generated from com/attentivemobile/contracts/subscriptions/api/v2/Contract.proto
# External Identifiers for a user.
type ExternalIdentifiersForSubscribe {
  # Client user ID.
  clientUserId: String

  # Custom identifiers.
  customIdentifiers: [ExternalCustomIdentifiers!]!

  # Klaviyo ID.
  klaviyoId: String

  # Shopify ID.
  shopifyId: String
}

# External Identifiers for a user.
input ExternalIdentifiersForSubscribeInput {
  # Client user ID.
  clientUserId: String

  # Custom identifiers.
  customIdentifiers: [ExternalCustomIdentifiersInput!]

  # Klaviyo ID.
  klaviyoId: String

  # Shopify ID.
  shopifyId: String
}

# Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto
type ExternalSftpConfig {
  description: String!
  externalSftConfigId: String!
  sftpHost: String!
  sftpPassword: String @deprecated(reason: "deprecated")
  sftpPath: String
  sftpUser: String!
  useKey: Boolean
}

input ExternalSftpConfigInput {
  description: String!
  externalSftConfigId: String!
  sftpHost: String!
  sftpPassword: String
  sftpPath: String
  sftpUser: String!
  useKey: Boolean
}

# Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto
type ExternalVendorApi implements Node {
  description: String!
  displayName: String!
  externalVendorFeatures: [ExternalVendorFeature!]
  id: ID!
  integrationGuideUrl: String!
  validationUrl: String!
  vendor: Vendor!
  visibility: VisibilityType!
}

union ExternalVendorData = FacebookAdsExternalVendorData

enum ExternalVendorDataField {
  EXTERNAL_DATA_FIELD_FACEBOOK_ADS_AD_ACCOUNTS
  EXTERNAL_DATA_FIELD_FACEBOOK_ADS_CUSTOM_AUDIENCES
  EXTERNAL_DATA_FIELD_UNKNOWN
}

# Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto
type ExternalVendorDataWrapper {
  data: ExternalVendorData!
}

# Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto
type ExternalVendorFeature implements Node {
  deciderName: String!
  description: String!
  displayName: String!
  enabledByDefault: Boolean!
  externalVendorApi: ExternalVendorApi
  id: ID!
  metadata: ExternalVendorFeatureMetadata
  type: Feature!
}

# Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto
type ExternalVendorFeatureGenericMetadata {
  metadata: String!
}

union ExternalVendorFeatureMetadata = ExternalVendorFeatureGenericMetadata

# Generated from com/attentivemobile/contracts/integrations/v2/externalvendordata/Contract.proto
type FacebookAdAccount {
  id: String!
  name: String!
  tosAccepted: Boolean!
}

# Generated from com/attentivemobile/contracts/integrations/v2/externalvendordata/Contract.proto
type FacebookAdsExternalVendorData {
  adAccounts: [FacebookAdAccount!]!
  customAudiences: [FacebookCustomAudience!]!
}

input FacebookAdsExternalVendorFilter {
  adAccountId: String!
}

# Generated from com/attentivemobile/contracts/integrations/v2/externalvendordata/Contract.proto
type FacebookCustomAudience {
  id: String!
  name: String!
}

# Generated from com/attentivemobile/contracts/segment/recommendation/service/Contract.proto
type FailureSegmentEnhancementWorkflowStatus {
  reason: String!
}

enum Feature {
  BRIDGE_IDENTIFIERS
  CUSTOMER_SERVICE_OPT_IN
  DYNAMIC_COUPON_GENERATION
  FEATURE_ADDITIONAL_SETUP_LINK
  FEATURE_ATTENTIVE_LOYALTY_SIGNUP
  FEATURE_AUTO_INSTALL_TAG
  FEATURE_CREATE_MARKETING_EMAIL_SUBSCRIPTIONS
  FEATURE_CREATE_MARKETING_SMS_SUBSCRIPTIONS
  FEATURE_CREATE_TRANSACTIONAL_SUBSCRIPTIONS
  FEATURE_EMAIL_OPT_OUT_SYNC
  FEATURE_EXPORT_SEGMENTS
  FEATURE_GENERATE_HOOK
  FEATURE_INGEST_AD_DATA
  FEATURE_INGEST_LEADS
  FEATURE_INGEST_TAG_EVENTS
  FEATURE_INGEST_USER_DATA
  FEATURE_MARKETING_EMAIL_MESSAGE_SEND
  FEATURE_POSTBACK_META_ADS
  FEATURE_POSTBACK_USER_ATTRIBUTES
  FEATURE_POSTBACK_USER_DATA
  FEATURE_POST_SUBSCRIBER_OPT_IN
  FEATURE_POST_SUBSCRIBER_OPT_OUT
  FEATURE_PURCHASE_PRODUCT
  FEATURE_REFUND_SYNC
  FEATURE_SETUP_REDIRECT_SOURCE
  FEATURE_SUBSCRIPTION_MESSAGING
  FEATURE_SURVEY_MESSAGE
  FEATURE_SYNC_ABANDONED_CHECKOUTS
  FEATURE_SYNC_ACCOUNT_PROPERTIES
  FEATURE_SYNC_ATTENTIVE_TO_ESP_EMAIL_OPT_OUTS_VIA_SFTP
  FEATURE_SYNC_EMAIL_CONSENT
  FEATURE_SYNC_EMAIL_EVENTS
  FEATURE_SYNC_EMAIL_SUBSCRIBERS
  FEATURE_SYNC_ESP_TO_ATTENTIVE_EMAIL_OPT_OUTS_VIA_SFTP
  FEATURE_SYNC_EVENTS_OUTBOUND
  FEATURE_SYNC_LIST_COUNT
  FEATURE_SYNC_LIST_IDENTIFIERS
  FEATURE_SYNC_LOYALTY_EVENTS
  FEATURE_SYNC_ORDER_EVENTS
  FEATURE_SYNC_PRODUCT_COLLECTIONS
  FEATURE_SYNC_PURCHASES
  FEATURE_SYNC_RECURRING_PURCHASES
  FEATURE_SYNC_REVIEWS
  FEATURE_SYNC_SMS_CONSENT
  FEATURE_SYNC_THIRD_PARTY_CAMPAIGNS
  FEATURE_SYNC_THIRD_PARTY_JOURNEYS
  FEATURE_SYNC_USER_PROFILES
  FEATURE_TEMPLATIZED_POSTBACK
  FEATURE_TRIGGER_VENDOR_EMAIL_SEND
  FEATURE_UNKNOWN
  FORWARD_MESSAGE_CUSTOMER_SERVICE
  INGEST_TRANSACTIONAL_EVENTS
  POSTBACK_EMAILS
  POST_WITH_PHONE
  SCRUB_MESSAGE_SIGNATURE
  SYNC_HISTORICAL_PURCHASES
  SYNC_PRODUCTS
  SYNC_THIRD_PARTY_LISTS
  SYNC_THIRD_PARTY_SEGMENTS
  THIRD_PARTY_PROCESSOR_FORWARDER
}

input FeatureFieldsEntry {
  key: String!
  value: String!
}

# Generated from com/attentivemobile/contracts/featureflag/service/Contract.proto
type FeatureFlag implements Node {
  created: DateTime!
  description: String!
  id: ID!
  legacyId: String! @deprecated(reason: "deprecated")
  name: String!
  rolloutVariants: [Rollout!]!
  targetingData(after: String, companyId: ID, contextKey: String, contextValue: String, first: Int): TargetingConnection
  updated: DateTime!
}

# Generated from com/attentivemobile/contracts/featureflag/service/Contract.proto
type FeatureFlagConnection {
  edges: [FeatureFlagEdge!]!
  pageInfo: PageInfo!
  totalCount: Long!
}

# Generated from com/attentivemobile/contracts/featureflag/service/Contract.proto
type FeatureFlagEdge {
  cursor: String!
  node: FeatureFlag!
}

input FeatureMapInput {
  featureSettingPair: [FeatureSettingConfigurationInput!]
}

# Generated from com/attentivemobile/contracts/account/permission/service/AccountPermissionsService.proto
type FeatureOperations {
  create: Boolean!
  delete: Boolean!
  read: Boolean!
  update: Boolean!
}

# Generated from com/attentivemobile/contracts/account/permission/service/AccountPermissionsService.proto
type FeaturePermissions {
  createdAt: DateTime!
  feature: DomainFeature
  featureOperations: FeatureOperations!
  id: ID!
  updatedAt: DateTime!
}

input FeatureSettingConfigurationInput {
  key: Feature!
  value: FeatureSettingInput!
}

input FeatureSettingInput {
  enabled: Boolean!
  featureFields: [FeatureFieldsEntry!]
}

# Generated from com/attentivemobile/contracts/concierge/service/Template.proto
type FeedbackSource {
  feedback: MessageFeedback
  feedbackId: String! @deprecated(reason: "deprecated")
}

# Generated from com/attentivemobile/contracts/personalization/service/aijourneys/defaultmessage/AIJourneyDefaultMessageServiceContract.proto
type FetchGenerateMessageWorkflowStatusPayload {
  status: GenerateMessageWorkflowStatus!
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type FetchOptions {
  matchPrefix: Boolean!
  maxFiles: Int!
}

input FetchOptionsInput {
  matchPrefix: Boolean!
  maxFiles: Int!
}

input FieldMask {
  paths: [String!]
}

input FieldsEntry {
  key: String!
  value: String!
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type FileSettings {
  delimiter: String
  encryption: Encryption
  headerSettings: HeaderSettings!
  legacyFileType: String
  preprocess: Boolean!
}

input FileSettingsInput {
  delimiter: String
  encryption: EncryptionInput
  headerSettings: HeaderSettingsInput!
  legacyFileType: String
  preprocess: Boolean!
}

enum FilterOperation {
  FILTER_OPERATION_AND
  FILTER_OPERATION_OR
}

enum FilterOperator {
  # Deprecated, use FILTER_OPERATOR_IN instead
  FILTER_OPERATOR_EQUALS
  FILTER_OPERATOR_IN

  # Deprecated, use FILTER_OPERATOR_NOT_IN instead
  FILTER_OPERATOR_NOT_EQUALS
  FILTER_OPERATOR_NOT_IN
  FILTER_OPERATOR_UNKNOWN
}

input FindMessagesFilter {
  carriers: [Carrier!]
  companyId: ID
  countryCodes: [String!]
  dateRange: DateRange!
  errorCode: String
  fromPhoneNumber: String @deprecated(reason: "deprecated")
  fromPhoneNumbers: [String!]
  messageId: Long
  providers: [Provider!]
  status: MessageStatus
  toPhoneNumber: String @deprecated(reason: "deprecated")
  toPhoneNumbers: [String!]
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type FindSpecificUserContentSettingConnection {
  contentSetting: UserContentSetting!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type FindUserContentSettingsConnection {
  edges: [UserContentSettingsEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type FindUserContentVisibilityResponse {
  isVisible: Boolean!
  preferenceValue: String!
}

input FinishAuthenticatorEnrollmentInput {
  credentialName: String!
  publicKeyCredentialJson: String!
}

union FinishAuthenticatorEnrollmentPayload = DefaultErrorFailure | FinishAuthenticatorEnrollmentSuccess

# Generated from com/attentivemobile/contracts/account-service/AuthenticatorContract.proto
# If success is true, the user has passed authorization and enrollment is complete
type FinishAuthenticatorEnrollmentSuccess {
  success: Boolean!
}

# Generated from com/attentivemobile/contracts/featureflag/service/Contract.proto
type FlagEnabled {
  enabled: Boolean!
  featureFlagName: String!
}

# Generated from com/attentivemobile/contracts/featureflag/service/Contract.proto
type FlagEnabledConnection {
  edges: [FlagEnabledEdge!]!
  pageInfo: PageInfo!
  totalCount: Long!
}

# Generated from com/attentivemobile/contracts/featureflag/service/Contract.proto
type FlagEnabledEdge {
  cursor: String!
  node: FlagEnabled!
}

enum FlinkDeploymentMode {
  FLINK_DEPLOYMENT_MODE_APPLICATION_MODE
  FLINK_DEPLOYMENT_MODE_SESSION_MODE
  FLINK_DEPLOYMENT_MODE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Flink.proto
type FlinkJob {
  applicationId: String!
  cluster: Cluster!
  dedupStateLocation: String!
  flinkDeploymentMode: FlinkDeploymentMode!
  jobId: String!
  jobManagerUrl: String!
  processedEventsLocation: String!
  region: AttentiveAwsRegion!
  status: String!
}

input FlinkJobWrite {
  cluster: Cluster!
  entryClass: String!
  flinkDeploymentMode: FlinkDeploymentMode!
  jarName: String!
  jobId: String
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type FloatPropertyValue {
  value: Float!
}

enum FloridaSubscribersAuditStatusV2 {
  FLORIDA_SUBSCRIBERS_AUDIT_STATUS_AUDIT_REPORT_RUNNING
  FLORIDA_SUBSCRIBERS_AUDIT_STATUS_FAILURE
  FLORIDA_SUBSCRIBERS_AUDIT_STATUS_UNKNOWN
}

# Generated from com/attentivemobile/contracts/asset/library/service/Assets.proto
type Folder implements Node {
  # The child assets of the folder
  childAssets(after: String, before: String, first: Int, last: Int, orderByAndDirection: AssetsOrderByAndDirection): FolderChildrenConnection
  company: Company
  createdAt: DateTime!
  displayName: String!
  id: ID!
  parent: Folder
  updatedAt: DateTime!
}

# Generated from com/attentivemobile/contracts/asset/library/service/Contract.proto
type FolderChildEdge {
  cursor: String!
  node: Asset!
}

# Generated from com/attentivemobile/contracts/asset/library/service/Contract.proto
type FolderChildrenConnection {
  edges: [FolderChildEdge!]!
  pageInfo: PageInfo!
  totalCount: Long!
}

enum FontType {
  BRANDKIT_FONT_TYPE_CUSTOM
  BRANDKIT_FONT_TYPE_GOOGLE
  BRANDKIT_FONT_TYPE_TYPEKIT
  BRANDKIT_FONT_TYPE_WEBSAFE
}

# Generated from com/attentivemobile/contracts/journeys/api/RevisionStep.proto
type ForwardToConciergeStep {
  childrenSteps: [AdderStep!]!
  stepParameters: ForwardToConciergeStepParameters!
}

# Generated from com/attentivemobile/contracts/journeys/api/RevisionStepParameters.proto
type ForwardToConciergeStepParameters {
  couponPoolId: Long
  couponPoolName: String
  messageId: Long!
  messageLinkId: Long!
  shouldIncludeUtmParams: Boolean!
  utmCampaign: String
}

# Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/ComparisonTypes.proto
type FrequencyComparison {
  comparator: SearchFrequencyComparator!
  endValue: Int
  value: Int!
}

input FrequencyComparisonInput {
  comparator: SearchFrequencyComparator!
  endValue: Int
  value: Int!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type FulfillmentAvailableForPickupEvent {
  company: Company
  fulfillment: FulfillmentInfo!
  order: OrderInfo
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type FulfillmentCancelledEvent {
  company: Company
  fulfillment: FulfillmentInfo!
  order: OrderInfo
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type FulfillmentCreatedEvent {
  company: Company
  fulfillment: FulfillmentInfo!
  order: OrderInfo
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type FulfillmentDeliveredEvent {
  company: Company
  fulfillment: FulfillmentInfo!
  order: OrderInfo
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type FulfillmentDeliveryAttemptFailureEvent {
  company: Company
  fulfillment: FulfillmentInfo!
  order: OrderInfo
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type FulfillmentDeliveryExceptionEvent {
  company: Company
  fulfillment: FulfillmentInfo!
  order: OrderInfo
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type FulfillmentInTransitEvent {
  company: Company
  fulfillment: FulfillmentInfo!
  order: OrderInfo
}

# Generated from com/attentivemobile/contracts/event/ecommerce/Fulfillment.proto
type FulfillmentInfo {
  deliveryDate: DateTime!
  externalOrderId: String!
  fulfillmentId: String!
  orderProducts: [OrderProduct!]!
  orderRecipient: OrderRecipient!
  orderStatusUrl: String!
  tracking: [Tracking!]!
  userOrderNumber: String!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type FulfillmentOutForDeliveryEvent {
  company: Company
  fulfillment: FulfillmentInfo!
  order: OrderInfo
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type FulfillmentPendingEvent {
  company: Company
  fulfillment: FulfillmentInfo!
  order: OrderInfo
}

input FullListSearch {
  dummy: Int!
}

enum GeneralDataFeedCols {
  CAMPAIGN_NAME
  CAMPAIGN_TYPE
  CLIENT_ID_GENERAL_DATA_FEED
  CLIENT_USER_ID_GENERAL_DATA_FEED
  COMPANY_ID_GENERAL_DATA_FEED
  COUPONS
  CREATIVE_ID
  CREATIVE_NAME
  CREATIVE_SUBTYPE
  CREATIVE_TYPE
  DATE
  EMAIL
  EXTERNAL_ID_GENERAL_DATA_FEED
  HAS_MEDIA
  HOLDOUT_GROUP
  JOIN_SOURCE
  MESSAGE_GID
  MESSAGE_ID_GENERAL_DATA_FEED
  MESSAGE_LINK_TARGET_GENERAL_DATA_FEED
  MESSAGE_NAME_GENERAL_DATA_FEED
  MESSAGE_SID
  MESSAGE_START
  MESSAGE_SUBTYPE_GENERAL_DATA_FEED
  MESSAGE_TEXT_GENERAL_DATA_FEED
  MESSAGE_TYPE_GENERAL_DATA_FEED
  OPT_IN_FLAG
  OPT_IN_SMS
  ORDER_ID_GENERAL_DATA_FEED
  PARENT_MESSAGE_NAME
  PHONE_COUNTRY_GENERAL_DATA_FEED
  PHONE_GENERAL_DATA_FEED
  PRODUCT_ID_GENERAL_DATA_FEED
  PRODUCT_NAME
  PRODUCT_PRICE
  PRODUCT_SKU
  PROPERTY_ID
  PROPERTY_NAME
  PROPERTY_VALUE
  PROPERTY_VALUE_ID
  QUANTITY_GENERAL_DATA_FEED
  RAW_EXTERNAL_ID_GENERAL_DATA_FEED
  REGION_GENERAL_DATA_FEED
  SENDER_MESSAGE_ID_GENERAL_DATA_FEED
  SIGNUP_URL_GENERAL_DATA_FEED
  SMS_ACQ_SOURCE
  SOURCE
  STATUS_GENERAL_DATA_FEED
  SUBSCRIBER_ID
  SUBSCRIBER_KEY
  SUBSCRIBER_VAL
  SUBSCRIPTION_CHANNEL
  SUBSCRIPTION_TYPE
  TIMESTAMP_GENERAL_DATA_FEED
  TOTAL
  TTJ_SOURCE
  TYPE
  USER_ID
  UUID_GENERAL_DATA_FEED
  VISITOR_ID
  VISITOR_PHONE
  ZIPCODE
}

input GenerateAIImageInput {
  sceneConstructionInput: SceneConstructionInput
  transformationType: AIImageGenerationType!
}

# Generated from com/attentivemobile/contracts/generativeaiservice/image/ImageGenerationContract.proto
type GenerateAIImagePayload {
  transformedImageUrls: [Url!]!
}

input GenerateAIJourneyTextInput {
  brandVoiceSettings: AIBrandVoiceSettingsInputType
  companyId: ID!
  inputs: [ChatMessageAdminToolInput!]
  metadata: GenerateAIJourneyTextMetadata!
  useMessageEvaluation: Boolean
}

input GenerateAIJourneyTextMetadata {
  n: Int
  origin: String
  templateInput: GenerativeAITextTemplateInput!
  vendorOptions: TextGenVendorOptions
}

union GenerateAIJourneyTextPayload = DefaultErrorFailure | GenerateAIJourneyTextSuccess

# Generated from com/attentivemobile/contracts/messaging/automation/autojourneys/preview/GenerateAIJourneyTextContract.proto
type GenerateAIJourneyTextSuccess {
  generatedTextResponses: [AIGeneratedText!]!
}

input GenerateAITextInput {
  companyId: ID!

  # Number of responses.
  n: Int
  origin: AIGeneratedTextOrigin!
  prompt: [String!]
  promptRefinementOptions: [PromptRefinementOption!]
  subscriberId: Long
  template: GenerativeAITextTemplateInput
  useMessageEvaluation: Boolean
  vendorOptions: TextGenVendorOptions
}

# Generated from com/attentivemobile/contracts/generativeaiservice/text/TextGenerationContract.proto
type GenerateAITextPayload {
  generatedTextResponses: [AIGeneratedText!]!
}

input GenerateAITextV2Input {
  brandVoiceSettings: AIBrandVoiceSettingsInputType
  companyId: ID!
  messages: [ChatMessageInput!]
  metadata: GeneratedTextInputMetadata!
  useMessageEvaluation: Boolean
}

union GenerateAITextV2Payload = DefaultErrorFailure | GenerateAITextV2Success

# Generated from com/attentivemobile/contracts/generativeaiservice/text/TextGenerationContract.proto
type GenerateAITextV2Success {
  generatedTextResponses: [AIGeneratedText!]!
}

input GenerateChallengeForAdminAccessInput {
  email: String!
}

union GenerateChallengeForAdminAccessPayload = DefaultErrorFailure | GenerateChallengeForAdminAccessSuccess

# Generated from com/attentivemobile/contracts/account-service/AuthenticatorContract.proto
# This will be the challenge for the user to pass to get a new JWT token for admin access
type GenerateChallengeForAdminAccessSuccess {
  nonce: String!
}

input GenerateEmailRowDesignElementProperties {
  designElementType: GenerateEmailRowDesignElementTypes!
}

enum GenerateEmailRowDesignElementTypes {
  GENERATE_EMAIL_ROW_DESIGN_ELEMENT_TYPES_DIVIDER
  GENERATE_EMAIL_ROW_DESIGN_ELEMENT_TYPES_SPACER
}

input GenerateEmailRowInput {
  properties: GenerateEmailRowInputProperties
}

input GenerateEmailRowInputProperties {
  designElementProperties: GenerateEmailRowDesignElementProperties
  libraryProperties: GenerateEmailRowLibraryProperties
  promptProperties: GenerateEmailRowPromptProperties
}

input GenerateEmailRowLibraryProperties {
  moduleType: EmailRowModuleTypes!
}

input GenerateEmailRowPromptProperties {
  prompt: String!
}

input GenerateEmailTemplateInput {
  companyId: ID!
  eventQualifier: EventType
  requiredFeature: GenerateEmailTemplateRequiredFeature
  rows: [GenerateEmailRowInput!]
}

union GenerateEmailTemplatePayload = DefaultErrorFailure | GenerateEmailTemplateSuccess

enum GenerateEmailTemplateRequiredFeature {
  GENERATE_EMAIL_TEMPLATE_REQUIRED_FEATURE_MULTIPLE_DYNAMIC_PRODUCT_LIST
  GENERATE_EMAIL_TEMPLATE_REQUIRED_FEATURE_PRODUCT_RECOMMENDATIONS
  GENERATE_EMAIL_TEMPLATE_REQUIRED_FEATURE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/messagetemplategeneration/Contract.proto
type GenerateEmailTemplateSuccess {
  json: String!
}

input GenerateImageInput {
  sceneCreationInput: SceneCreationInput
  transformationType: ImageGenerationType!
}

# Generated from com/attentivemobile/contracts/composition/generation/Contract.proto
type GenerateImagePayload {
  transformedImageUrls: [Url!]!
}

input GenerateLayoutInput {
  companyId: ID!
  promptText: String!
}

union GenerateLayoutPayload = DefaultErrorFailure | GenerateLayoutSuccess

# Generated from com/attentivemobile/contracts/composition/Contract.proto
type GenerateLayoutSuccess {
  columns: [BEEColumn!]!
  promptText: String!
}

enum GenerateMessageWorkflowStatus {
  GENERATE_MESSAGE_WORKFLOW_STATUS_FAILURE
  GENERATE_MESSAGE_WORKFLOW_STATUS_IN_PROGRESS
  GENERATE_MESSAGE_WORKFLOW_STATUS_PARTIAL_FAILURE
  GENERATE_MESSAGE_WORKFLOW_STATUS_SUCCESS
  GENERATE_MESSAGE_WORKFLOW_STATUS_UNSPECIFIED
}

input GenerateMobileWalletAnalyticsReportInput {
  campaignId: String!
  companyId: ID!
  endDate: DateTime!
  startDate: DateTime!
}

# Generated from com/attentivemobile/contracts/mobilewalletservice/Contract.proto
type GenerateMobileWalletAnalyticsReportPayload {
  status: String!
}

input GeneratePreSignedUrlForBulkOptOutJobInput {
  companyId: ID!
  jobType: String!
}

# Generated from com/attentivemobile/contracts/solutions/bulkoptout/SolutionsOptout.proto
type GeneratePreSignedUrlForBulkOptOutJobPayload {
  filename: String!
  presignedUrl: String!
}

input GeneratePresignedS3UrlInput {
  companyId: ID!
  contentMd5: String!
  contentType: String!
  fileName: String!
}

# Generated from com/attentivemobile/contracts/brand-kit/FileUpload.proto
type GeneratePresignedS3UrlPayload {
  fileUuid: String!
  url: Url!
}

input GeneratePresignedUrlInput {
  companyId: ID!
  externalId: String!
  filename: String!
}

union GeneratePresignedUrlPayload = DefaultErrorFailure | GeneratePresignedUrlSuccess

# Generated from com/attentivemobile/contracts/segmentation/service/ManualSegmentUpload.proto
type GeneratePresignedUrlSuccess {
  presignedUrl: String!
  s3FilePath: String!
}

input GenerateZendeskJwtInput {
  type: ZendeskJwtType!
}

# Generated from com/attentivemobile/contracts/account-service/ZendeskContract.proto
type GenerateZendeskJwtPayload {
  jwt: String!
}

input GeneratedTextInputMetadata {
  maxTokens: Int
  n: Int
  origin: String
  temperature: Float
  template: GenerativeAITextTemplateInput
  topP: Float
  vendorOptions: TextGenVendorOptions
}

# Generated from com/attentivemobile/contracts/generativeaiservice/template/GenerativeAITemplateContract.proto
type GenerativeAICopyAssistantTemplate {
  allVersions: [String!]
  created: DateTime!
  liveVersion: String
  roleBasedContents(role: String, version: String): GetTemplateContentOutput
  templateName: String!
  updated: DateTime!
}

enum GenerativeAITextFeedbackCategory {
  GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_BAD_GRAMMAR
  GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_BRAND_VOICE_MISMATCH
  GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_CONTENT_PROMPT_MISMATCH
  GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_INCLUDES_PII
  GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_OFFENSIVE_LANGUAGE
  GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_OTHER
  GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_UNKNOWN
}

input GenerativeAITextFeedbackInput {
  feedbackCategory: GenerativeAITextFeedbackCategory
  feedbackType: GenerativeAITextFeedbackType!
  generatedTextId: ID!
  groupId: String!
  messageId: ID
  userId: ID!
}

enum GenerativeAITextFeedbackType {
  GENERATIVE_AI_TEXT_FEEDBACK_TYPE_A_B_WINNER
  GENERATIVE_AI_TEXT_FEEDBACK_TYPE_FLAGGED
  GENERATIVE_AI_TEXT_FEEDBACK_TYPE_GENERATE_MORE_SIMILAR
  GENERATIVE_AI_TEXT_FEEDBACK_TYPE_RANKED_WINNER
  GENERATIVE_AI_TEXT_FEEDBACK_TYPE_UNKNOWN
}

input GenerativeAITextTemplateInput {
  templateName: String!
  version: String!
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Datastream.proto
type GenericBackup {
  s3Location: S3Location!
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Transformation.proto
type GenericEventRateLimiter {
  desiredRps: Int!
}

# Generated from com/attentivemobile/contracts/messaging/automation/aijourneys/onboarding/AIJourneyLinkConfigurationServiceContract.proto
type GetAIJourneyLinkConfigurationResponse {
  linkConfiguration: [AIJourneyLinkConfiguration!]!
}

# Generated from com/attentivemobile/contracts/messaging/automation/aijourneys/onboarding/AIJourneyLinkConfigServiceContract.proto
type GetAIJourneyLinkTrackingParamsResponse {
  aiJourneyTrackingParams: [AIJourneyTrackingParams!]!
}

# Generated from com/attentivemobile/contracts/account-service/Contract.proto
type GetAccountAssignedCompaniesResponse {
  companies: [AccountAssignedCompany!]!
}

# Generated from com/attentivemobile/contracts/account/permission/service/AccountPermissionsService.proto
type GetAccountPermissionsByCompanyAndAccountPayload {
  payload: GetAccountPermissionsByCompanyAndAccountPayloadPayload
}

union GetAccountPermissionsByCompanyAndAccountPayloadPayload = AccountPermissions | DefaultStandardError

# Generated from com/attentivemobile/contracts/creative-service/creative/CreativeGraphService.proto
type GetActiveCouponDisplayScreenCreativePayload {
  # Active coupon display screen creative for a company, is not required. Only one can be active at a time.
  couponDisplayScreenCreative: Creative
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type GetActiveNotificationBannerResponse {
  banner: NotificationBanner
}

# Generated from com/attentivemobile/contracts/multiproductenablement/service/aipro/Contract.proto
type GetAiProSubscriptionStatusPayload {
  isActiveEmail: Boolean!
  isActiveSms: Boolean!
  status: AiProSubscriptionStatus!
}

# Generated from com/attentivemobile/contracts/multiproductenablement/service/aipro/Contract.proto
type GetAiProTrialEligibilityStatusPayload implements Node {
  company: Company
  eligibilityStatus: AiProEligibilityStatus!
  id: ID!
  reason: AiProIneligibleReason
}

# Generated from com/attentivemobile/contracts/generativeaiservice/template/GenerativeAITemplateContract.proto
type GetAllVersionsForTemplatePayload {
  versions: [String!]!
}

# Generated from com/attentivemobile/contracts/reports/api/CustomReport.proto
type GetAllowedCompanyReportRecipientsResponse {
  recipients: [ReportRecipient!]!
}

# Generated from com/attentivemobile/contracts/phonenumbers/external/Contract.proto
type GetApplicationStatusHistoryByPhoneIdResponse {
  phoneNumberApplicationStatus: PhoneNumberApplicationStatusHistory!
}

# Generated from com/attentivemobile/contracts/externaltokens/Contract.proto
type GetApplicationWebhookEventTypesResponse {
  eventTypes: [WebhookEventType!]!
}

# Generated from com/attentivemobile/contracts/externaltokens/Contract.proto
type GetApplicationWebhookExamplePayloadsResponse {
  examplePayloads: [JSON!]!
}

# Generated from com/attentivemobile/contracts/phonenumbers/external/Contract.proto
type GetAssignedCompaniesResponse {
  assignedCompanies: [ID!]!
}

# Generated from com/attentivemobile/contracts/survey/poll/Contract.proto
type GetAudienceCountResponse {
  count: Int!
}

# Generated from com/attentivemobile/contracts/survey/poll/Contract.proto
type GetAudienceParticipantsResponse {
  participants: [AudienceParticipant!]!
}

# Generated from com/attentivemobile/contracts/account-service/AuthenticatorContract.proto
type GetAuthenticatorsPayload {
  authenticators: [Authenticator!]!
}

# Generated from com/attentivemobile/contracts/brand-kit/BrandFont.proto
type GetAvailableBrandFontsResponse {
  availableBrandFonts: [AvailableBrandFont!]!
}

# Generated from com/attentivemobile/contracts/concierge/service/Analytics.proto
type GetAverageResponseTimeResponse {
  averageResponseTimeSeconds: Long!
}

# Generated from com/attentivemobile/contracts/event/platform/batchjobs/service/Contract.proto
type GetBatchMigrationStatusResponse {
  jobsCompleted: [ValidatedMigratedJob!]!
  jobsInError: [ErroredJob!]!
  jobsToMigrate: [String!]!
}

# Generated from com/attentivemobile/contracts/personalization/service/aijourneys/onboarding/companyinfo/BlockCompanyInfo.proto
type GetBlockCompanyInfoPayload {
  blockCompanyInfo: [BlockCompanyInfo!]!
}

# Generated from com/attentivemobile/contracts/brand-kit/BrandColorSchemeService.proto
type GetBrandColorSchemeResponse {
  colors: [BrandColor!]!
}

# Generated from com/attentivemobile/contracts/brand-kit/BrandElementStyles.proto
type GetBrandElementStylesResponse {
  styles: [BrandElementStyle!]!
}

# Generated from com/attentivemobile/contracts/brand-kit/BrandImage.proto
type GetBrandImagesResponse {
  images: [BrandImage!]!
}

# Generated from com/attentivemobile/contracts/company-service/Contract.proto
type GetCompaniesByInternalIdResponse {
  companies: [Company]!
}

# Generated from com/attentivemobile/contracts/company-service/companylink/CompanyLink.proto
type GetCompanyLinksPayload {
  CompanyLinks: [CompanyLink!]!
}

# Generated from com/attentivemobile/contracts/concierge/service/Macro.proto
type GetCompanyMacrosResponse {
  macros: [UserConversationMacro!]!
}

# Generated from com/attentivemobile/contracts/messaging/automation/aijourneys/configs/Contract.proto
type GetConfigResponse {
  config: String!
}

# Generated from com/attentivemobile/contracts/generativeaiservice/template/GenerativeAITemplateContract.proto
type GetCopyAssistantTemplateResponse {
  template: GenerativeAICopyAssistantTemplate!
}

# Generated from com/attentivemobile/contracts/creative-service/creative/CreativeGraphService.proto
type GetCreativeByNameResponse {
  creative: Creative
}

input GetCreativeSchedulesRequestFilter {
  endOfDateRange: DateTime
  startOfDateRange: DateTime
  status: CreativeScheduleStatus
  type: CreativeScheduleType
}

input GetCreativeSchedulesRequestSort {
  sortDirection: GetCreativeSchedulesSortDirection!
  sortField: GetCreativeSchedulesSortField!
}

enum GetCreativeSchedulesSortDirection {
  CREATIVE_SCHEDULE_SORT_DIRECTION_ASC
  CREATIVE_SCHEDULE_SORT_DIRECTION_DESC
  CREATIVE_SCHEDULE_SORT_DIRECTION_UNKNOWN
}

enum GetCreativeSchedulesSortField {
  CREATIVE_SCHEDULE_SORT_FIELD_END_TIME
  CREATIVE_SCHEDULE_SORT_FIELD_NAME
  CREATIVE_SCHEDULE_SORT_FIELD_START_TIME
  CREATIVE_SCHEDULE_SORT_FIELD_STATUS
  CREATIVE_SCHEDULE_SORT_FIELD_UNKNOWN
}

input GetCronExpressionInput {
  customExpression: String
  daysOfWeek: [Int!]
  hourEnd: Int
  hourStart: Int
  hoursOfDay: [Int!]
}

# Generated from com/attentivemobile/contracts/eventdestination/cron/service/CronExpression.proto
type GetCronExpressionPayload {
  cronExpression: String!
  readableDisplay: String!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type GetCsTierResponse {
  csTier: String!
}

input GetCustomCompanyKeywordsFilter {
  enabled: Boolean
  search: String
}

input GetCustomCompanyKeywordsSort {
  direction: SortDirection
  sortBy: String
}

# Generated from com/attentivemobile/contracts/tactical/companycustomkeywords/CompanyCustomKeywordService.proto
type GetCustomKeywordsForCompanyResponse {
  customKeywords: [CustomKeyword!]!
}

# Generated from com/attentivemobile/contracts/user/profile/api/Contract.proto
type GetCustomPropertySegmentsResponse {
  segments: [Segment!]!
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Contract.proto
type GetDatastreamDetailsResponse {
  datastreamRuntime: DatastreamWithRuntime!
}

# Generated from com/attentivemobile/contracts/company-service/region/Region.proto
type GetDefaultRegionResponse {
  defaultRegion: Region!
}

# Generated from com/attentivemobile/contracts/survey/poll/PollKeywordService.proto
type GetDuplicateKeywordsResponse {
  keywordPollsMap: [KeywordPollsMap!]!
}

# Generated from com/attentivemobile/contracts/solutions/bulkoptout/SolutionsOptout.proto
type GetEmailBulkOptOutJobsResponse {
  selectedJobs: [EmailBulkOptOutJob!]!
}

# Generated from com/attentivemobile/contracts/composition/Contract.proto
type GetEnabledAttachmentTypesResponse {
  attachmentTypes: [AttachmentType!]!
}

# Generated from com/attentivemobile/contracts/concierge/service/Escalation.proto
type GetEscalationEmailsResponse {
  emails: [String!]!
}

input GetFilePublicUrlInput {
  uuid: String!
}

# Generated from com/attentivemobile/contracts/brand-kit/FileUpload.proto
type GetFilePublicUrlPayload {
  url: Url!
}

# Generated from com/attentivemobile/contracts/tactical/gametime/GameTimeService.proto
type GetGameTimePayload {
  json: String!
}

# Generated from com/attentivemobile/contracts/generativeaiservice/legal/GenerativeAILegalContract.proto
type GetGenerativeAIOptInStatusResponse {
  optedIn: Boolean!
}

# Generated from com/attentivemobile/contracts/solutions/subscribers/SolutionsSubscribers.proto
type GetGiveawayWinnersPayload {
  giveawayWinnerConfigs: [GiveawayWinnerConfig!]!
}

input GetJobRecordsFilter {
  companyId: ID
  search: String
  status: String
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type GetJobReportResponse {
  report: JobReport
}

# Generated from com/attentivemobile/contracts/tagbuildservice/Contract.proto
type GetLegacyTagConfigResponse {
  tagConfig: [LegacyTagConfig!]!
}

# Generated from com/attentivemobile/contracts/segmentation/materialization/service/Contract.proto
type GetMaterializationStatusResponse {
  status: SegmentMaterializationStatus!
}

# Generated from com/attentivemobile/contracts/phonenumbers/external/Contract.proto
type GetMessagingServiceByPhoneIdResponse {
  companyMessagingServices: [CompanyMessagingService!]!
}

# Generated from com/attentivemobile/contracts/survey/poll/PollKeywordService.proto
type GetPollKeywordsResponse {
  pollKeywords: [PollKeyword!]!
}

# Generated from com/attentivemobile/contracts/survey/poll/Contract.proto
type GetPollStatsResponse {
  stats: PollStats!
}

# Generated from com/attentivemobile/contracts/company-service/region/Region.proto
type GetRegionsResponse {
  regions: [Region!]!
}

# Generated from com/attentivemobile/contracts/account-service/AuthorizationContract.proto
type GetRolesResponse {
  roles: [Role!]!
}

# Generated from com/attentivemobile/contracts/eventdestination/telemetry/service/Contract.proto
type GetRunReportsResponse {
  reports: [RunReport!]!
}

# Generated from com/attentivemobile/contracts/subscriptions/batch/Contract.proto
type GetSampleDataResponse {
  columns: [SubscriberUploadFileColumn!]!
}

# Generated from com/attentivemobile/contracts/eventdestination/cron/service/CronScheduler.proto
type GetScheduleResponse {
  cronExpression: String!
  readableDisplay: String!
  timeZone: String!
}

# Generated from com/attentivemobile/contracts/authorization/Contract.proto
type GetScopesResponse {
  scopes: [Scope!]!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto
type GetSegmentableProductPropertiesResponse {
  productProperties: [ProductProperty!]!
}

# Generated from com/attentivemobile/contracts/brand-kit/BrandFont.proto
type GetSelectedBrandFontFamiliesResponse {
  selectedBrandFontFamilies: [SelectedBrandFontFamily!]!
}

# Generated from com/attentivemobile/contracts/brand-kit/BrandFont.proto
type GetSelectedBrandFontsResponse {
  selectedBrandFonts: [SelectedBrandFont!]!
}

# Generated from com/attentivemobile/contracts/personalization/service/aijourneys/onboarding/autoapply/ShopifyAutoApplyFormatsContract.proto
type GetShopifyAutoApplyFormatsPayload {
  discountLinkFormat: DiscountLinkFormat!
}

# Generated from com/attentivemobile/contracts/survey/poll/Contract.proto
type GetSweepstakesFilterResponse {
  id: Int!
  numToSelect: Int!
  poll: Poll
  pollKeywords: [PollOptionEdge!]!
  selectorType: SweepstakesSelectorType!
  subscriberType: SweepstakesSubscriberType!
}

# Generated from com/attentivemobile/contracts/tagbuildservice/Contract.proto
type GetTagConfigReleasesForCompanyResponse {
  releases: [TagConfigRelease!]!
}

# Generated from com/attentivemobile/contracts/generativeaiservice/template/GenerativeAITemplateContract.proto
type GetTemplateContentOutput {
  contents: [RoleBasedTemplateContent!]!
}

# Generated from com/attentivemobile/contracts/creative-service/template/TemplateService.proto
type GetTemplateCreativeRuleDefaultsResponse {
  rules: String!
}

# Generated from com/attentivemobile/contracts/integrations/templatizedpostbacks/Contract.proto
type GetTemplatizedPostbackResponse {
  templatizedPostbacks: [TemplatizedPostback!]!
}

# Generated from com/attentivemobile/contracts/messaging/automation/autojourneys/AutoJourneysContract.proto
type GetUnifiedAutoJourneyResponse {
  autoJourney: AutoJourney!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type GetUserEventsResponse {
  edges: [UserEventsEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/externaltokens/Contract.proto
type GetWebhookEventsForWebhookResponse {
  events: [WebhookEventType!]!
}

# Generated from com/attentivemobile/contracts/workload/management/service/configuration/Contract.proto
type GetWorkloadConfigResponse {
  workloadConfig: WorkloadConfig!
}

# Generated from com/attentivemobile/contracts/workload/management/service/runner/Contract.proto
type GetWorkloadHistoryResponse {
  apps: [WorkloadRun!]!
}

# Generated from com/attentivemobile/contracts/workload/management/service/runner/Contract.proto
type GetWorkloadStatesResponse {
  jobStates: [WorkloadJobState!]!
}

input GifRequestMetadata {
  # Time delay between images measured in hundredths of a second.
  delay: Int

  # Height of the final GIF in pixels
  height: Int

  # Desired overall image shape
  orientation: ImageOrientation

  # Width of the final GIF in pixels
  width: Int
}

input GiveMessageFeedbackInput {
  companyId: ID!
  feedback: String!
  feedbackType: MessageFeedbackType!
  givenBy: MessageFeedbackGivenBy!
  messageId: ID!
  subject: String!
}

# Generated from com/attentivemobile/contracts/concierge/service/Feedback.proto
type GiveMessageFeedbackPayload {
  message: Message
}

input GiveawayTermsUploadInput {
  companyId: ID!
  filename: String
}

# Generated from com/attentivemobile/contracts/solutions/companyconfiguration/GiveawayTerms.proto
type GiveawayTermsUploadPayload {
  companyName: String
  presignedUrl: String!
  uploadedFile: String
}

# Generated from com/attentivemobile/contracts/solutions/subscribers/SolutionsSubscribers.proto
type GiveawayWinnerConfig {
  email: String
  phone: String
}

input GlobalMessageTemplatesFilterInput {
  templateTypes: [MessagingTemplateType!]
}

# Generated from com/attentivemobile/contracts/journeys/api/JourneyTrigger.proto
type GlobalTriggerSource {
  eventType: EventType!
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type GmailOfferAnnotation {
  code: String
  description: String
  endDateTime: DateTime
  startDateTime: DateTime
}

input GmailOfferAnnotationRequest {
  code: String
  description: String
  endDateTime: DateTime
  startDateTime: DateTime
}

# Generated from com/attentivemobile/contracts/company-service/companysetting/Model.proto
type GoogleAnalytics {
  isEnabled: Boolean!
  utmMedium: String!
  utmSource: String!
}

input GoogleAnalyticsInput {
  # whether google analytics is enabled or not.
  isEnabled: Boolean

  # utm medium.
  utmMedium: String

  # utm address
  utmSource: String
}

# Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto
type GraphGetBestsellingProductsForCompanyResponse {
  products: [Product!]!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto
type GraphSearchProductsResponse {
  products: [Product!]!
}

# Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/SearchDefinitionOutput.proto
type GroupedComparison {
  comparisons: [Comparison!]!
  operator: SearchOperator!
}

input GroupedComparisonInput {
  comparisons: [ComparisonInput!]
  operator: SearchOperator!
}

# Generated from com/attentivemobile/contracts/metricsapi/DatasetsContract.proto
type GroupedDatasetEdge {
  cursor: String!
  values: [DatasetValue!]!
}

# Generated from com/attentivemobile/contracts/metricsapi/Contract.proto
type GroupedMetricValue {
  errors: [MetricDataError!]!

  # Deprecated, use the groupingDimensions field instead
  groupingDimensionValues: [String!]! @deprecated(reason: "deprecated")

  # Dimension values are listed in the same order as the grouping dimensions given in the metrics query
  groupingDimensions: [DimensionValue!]!

  # Not present when any errors are encountered calculating the grouped value
  value: Float
}

# Generated from com/attentivemobile/contracts/metricsapi/Contract.proto
type GroupedMetrics {
  # Deprecated, use the grouping_dimensions field instead
  dimensionValues: [String!]! @deprecated(reason: "deprecated")
  groupingDimensions: [DimensionValue!]!
  values: [MetricValue!]!
}

# Generated from com/attentivemobile/contracts/metricsapi/Contract.proto
type GroupedMetricsEdge {
  cursor: String!
  node: GroupedMetrics!
}

input HTMLPreviewInput {
  beeEditorJson: JsonStringInput!
  companyId: ID!
}

union HTMLPreviewPayload = DefaultErrorFailure | HTMLPreviewSuccess

# Generated from com/attentivemobile/contracts/composition/Contract.proto
type HTMLPreviewSuccess {
  htmlContent: String!
}

enum HasVerb {
  HAS_VERB_HAS
  HAS_VERB_HAS_NOT
  HAS_VERB_UNKNOWN
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type HeaderSettings {
  expectedColumnNum: Int!
  hasHeader: Boolean!
  rowStartNum: Int!
}

input HeaderSettingsInput {
  expectedColumnNum: Int!
  hasHeader: Boolean!
  rowStartNum: Int!
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type HeadingContents {
  level: Int!
  styleOverrides: BeeModuleStyleOverrides
  text: String!
}

input HideComposeCampaignInput {
  campaignId: ID!
  companyId: ID!
}

# Generated from com/attentivemobile/contracts/campaigns/service/Contract.proto
type HideComposeCampaignPayload {
  campaign: ComposeCampaign!
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type HtmlEditorProperties {
  htmlContent: String!
}

input HtmlEditorPropertiesInput {
  htmlContent: String!
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Datastream.proto
type HttpEndpoint {
  uri: String!
}

enum HttpRequestType {
  DELETE
  GET
  HTTP_REQUEST_TYPE_UNKNOWN
  PATCH
  POST
  PUT
}

enum IPPoolType {
  IP_POOL_TYPE_CAMPAIGN
  IP_POOL_TYPE_TRANSACTIONAL
  IP_POOL_TYPE_TRIGGERED
  IP_POOL_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/asset/library/service/Assets.proto
type Image implements Node {
  company: Company
  createdAt: DateTime!
  displayName: String!
  fileType: String!
  heightPixels: Int!
  id: ID!
  parent: Folder
  sizeBytes: Long!
  storageUrl: String!
  updatedAt: DateTime!
  widthPixels: Int!
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type ImageContents {
  alt: String!
  href: String!
  src: String!
}

# Generated from com/attentivemobile/contracts/composition/generation/Contract.proto
type ImageGenerationOptionsPayload {
  sceneCreationOptions: [SceneCreationOption!]!
}

enum ImageGenerationType {
  IMAGE_GENERATION_TYPE_SCENE_CREATION
  IMAGE_GENERATION_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto
type ImageLink {
  created: DateTime!
  height: Int
  position: Int!
  updated: DateTime!
  url: String!
  width: Int
}

enum ImageOrientation {
  IMAGE_ORIENTATION_AUTO
  IMAGE_ORIENTATION_LANDSCAPE
  IMAGE_ORIENTATION_PORTRAIT
  IMAGE_ORIENTATION_SQUARE
}

enum ImageStyle {
  IMAGE_STYLE_DARK
  IMAGE_STYLE_LIGHT
  IMAGE_STYLE_UNKNOWN
}

enum ImageType {
  IMAGE_TYPE_GENERIC
  IMAGE_TYPE_LOGO
  IMAGE_TYPE_UNKNOWN
}

input ImportCompanyFromSalesforceInput {
  opportunityId: String!
  primaryContactEmail: String!
  primaryContactFirstName: String!
  primaryContactLastName: String!
}

# Generated from com/attentivemobile/contracts/company-service/onboarding/Company.proto
type ImportCompanyFromSalesforcePayload {
  company: Company
}

# Generated from com/attentivemobile/contracts/segment/recommendation/service/Contract.proto
type InProgressSegmentEnhancementWorkflowStatus {
  startTime: DateTime!
}

input IncludeExcludeSearch {
  excludeAudienceSearchDefinition: [UserSearchDefinition!]
  includeAudienceSearchDefinition: [UserSearchDefinition!]
}

# Generated from com/attentivemobile/protobuf/common/Chunk.proto
type InclusionProductAffinityAlphaChunk {
  mlCutoffPercentile: Int!
  productOriginIds: [ProductOriginID!]!
  purchasePropensityModelVersion: String!
}

# Generated from com/attentivemobile/protobuf/common/Chunk.proto
type InclusionUserPurchasePropensityChunk {
  mlCutoffPercentile: Int!
}

# Generated from com/attentivemobile/contracts/journeys/api/RevisionStep.proto
type IncomingTextStep {
  stepConfig: IncomingTextStepConfig!
}

# Generated from com/attentivemobile/contracts/journeys/api/RevisionStepConfig.proto
type IncomingTextStepConfig {
  reservedKeywords: [ReservedKeyword!]!
}

enum IneligibilityReason {
  INELIGIBILITY_REASON_AUTO_SUPPRESSED
  INELIGIBILITY_REASON_KNOWN_LITIGATOR
  INELIGIBILITY_REASON_NOT_FULLY_OPTED_IN
  INELIGIBILITY_REASON_OPTED_OUT
  INELIGIBILITY_REASON_UNKNOWN
  INELIGIBILITY_REASON_USER_SUPPRESSED
}

input InitializeAuthenticatorEnrollmentInput {
  email: String!
}

union InitializeAuthenticatorEnrollmentPayload = DefaultErrorFailure | InitializeAuthenticatorEnrollmentSuccess

# Generated from com/attentivemobile/contracts/account-service/AuthenticatorContract.proto
type InitializeAuthenticatorEnrollmentSuccess {
  nonce: String!
}

input InitiateCognitoAuthInput {
  companyId: ID!
  temporaryPassword: String!
  username: String!
}

# Generated from com/attentivemobile/contracts/solutions/sftp/SftpUser.proto
type InitiateCognitoAuthPayload {
  sessiontoken: String!
}

input InsertCompanyCodesInput {
  code: [String!]
  companyId: ID!
  handler: String!
  phone: String!
}

# Generated from com/attentivemobile/contracts/creative-service/creative/LegacyCreativeService.proto
type InsertCompanyCodesPayload {
  code: [CompanyCode!]!
}

input InsertCreativeAuditInput {
  auditTimestamp: String!
  creativeId: String!
}

# Generated from com/attentivemobile/contracts/creative-service/audit/CreativeAuditService.proto
type InsertCreativeAuditPayload {
  inserted: Boolean!
}

# Generated from com/attentivemobile/contracts/externaltokens/Contract.proto
# Installed application details.
type InstalledApplication implements Node {
  # The application which was installed
  application: Application

  # The ID of the installed Application.
  id: ID!

  # The company this application was installed in
  installerCompany: Company
  needsUpdate: Boolean!

  # Webhook details for this installed application.
  webhooks: [Webhook!]
}

# Generated from com/attentivemobile/contracts/externaltokens/Contract.proto
type InstalledApplicationConnection {
  edges: [InstalledApplicationEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/externaltokens/Contract.proto
type InstalledApplicationEdge {
  cursor: String!
  node: InstalledApplication!
}

# Generated from com/attentivemobile/contracts/integrations/applications/Applications.proto
type IntegrationApplication implements Node {
  category: String!
  displayName: String!
  id: ID!
  name: String!
}

# Generated from com/attentivemobile/contracts/integrations/applications/Applications.proto
type IntegrationApplicationEdge {
  cursor: String!
  node: IntegrationApplication!
}

# Generated from com/attentivemobile/contracts/integrations/applications/Applications.proto
type IntegrationApplicationsConnection {
  edges: [IntegrationApplicationEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto
type IntegrationError implements Node {
  company: Company
  companyVendorIntegration: CompanyVendorIntegration
  companyVendorIntegrationFeature: CompanyVendorIntegrationFeature
  created: DateTime!
  externalVendorApi: ExternalVendorApi
  id: ID!
  resolved: DateTime
  type: IntegrationErrorType!
  updated: DateTime!
}

enum IntegrationErrorType {
  INTEGRATION_ERROR_TYPE_AUTHENTICATION_BAD_ATTENTIVE_CREDENTIALS
  INTEGRATION_ERROR_TYPE_AUTHENTICATION_BAD_VENDOR_CREDENTIALS
  INTEGRATION_ERROR_TYPE_AUTHORIZATION_PERMISSIONS_INSUFFICIENT
  INTEGRATION_ERROR_TYPE_CONFIGURATION_SHOPIFY_PLAN_STATUS_MISMATCH
  INTEGRATION_ERROR_TYPE_EMAIL_LIST_NOT_FOUND
  INTEGRATION_ERROR_TYPE_LOW_PRODUCT_ID_MATCH_RATE
  INTEGRATION_ERROR_TYPE_SHOPIFY_DISCOUNT_UNAVAILABLE_ERROR
  INTEGRATION_ERROR_TYPE_SHOPIFY_UNAVAILABLE_SHOP_NAME
  INTEGRATION_ERROR_TYPE_UNKNOWN
}

input IntegrationEventTypeInput {
  integrationEvent: EventType!
  messageChannel: MessagingChannel
  subscriptionChannel: SubscriptionChannel
}

# Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto
type IntegrationMetadataMapEntry {
  key: String!
  value: String!
}

enum IntegrationType {
  CUSTOM_INTEGRATION
  INTEGRATION_NOT_SPECIFIED
  OVERRIDE_INTEGRATION
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type InvalidCouponCodeUsage {
  couponCode: String!
}

# Generated from com/attentivemobile/contracts/subscriptions/batch/Model.proto
type InvalidEnumValuesEntry {
  key: String!
  value: Long!
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type InvalidLink {
  invalidLink: String!
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type InvalidLiquidSyntax {
  liquidExpression: String!
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type InvalidMacroUsages {
  macro: [String!]!
}

# Generated from com/attentivemobile/contracts/geography/Location.proto
type InvalidPostalCode {
  postalCode: String!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto
type InventoryAttribute {
  name: String!
  value: String!
}

enum InventoryComparator {
  INVENTORY_COMPARATOR_GREATER_THAN
  INVENTORY_COMPARATOR_LESS_THAN
}

enum IsVerb {
  IS_VERB_IS
  IS_VERB_IS_NOT
  IS_VERB_UNKNOWN
}

# A JSON scalar
scalar JSON

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type JobErrorReport {
  errors: [ErrorReport!]!
  jobRunUuid: String!
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type JobErrorReportResponse {
  runs: [RunsEntry!]!
}

enum JobHealth {
  JOBHEALTH_HEALTHY
  JOBHEALTH_UNHEALTHY_JOB_BACKPRESSURE
  JOBHEALTH_UNHEALTHY_RECENT_ERROR
  JOBHEALTH_UNHEALTHY_YARN_APP_FAILURE
  JOBHEALTH_UNKNOWN
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type JobHistory {
  context: JSON
  parentTaskId: String
  runDate: DateTime
  scheduledDate: DateTime!
  status: String!
  taskId: String!
  uuid: String!
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type JobHistoryConnection {
  edges: [JobHistoryEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type JobHistoryEdge {
  cursor: String!
  node: JobHistory!
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type JobRecord implements Node {
  company: Company
  created: DateTime
  description: String!
  id: ID!
  ingestionFormat: EdsJobIngestionFormat
  internalId: String!
  jobType: String!
  lastExecutedRun: JobHistory
  name: String!
  nextRun: DateTime
  previousRun: DateTime
  sourceInfo: String
  sourceNodeUuid: String
  sourceType: String
  status: String
  sync: EdsJobSync
  syncMethod: EdsJobSyncMethod
  targetInfo: String
  targetNodeUuid: String
  targetType: String
  userId: String!
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type JobRecordConnection {
  edges: [JobRecordEdge!]!
  pageInfo: PageInfo!
  totalCount: Long!
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type JobRecordEdge {
  cursor: String!
  node: JobRecord!
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type JobReport {
  endTime: String!
  errorMsg: String!
  failure: Long!
  requests: Long!
  startTime: String!
  success: Long!
  taskId: String!
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type JobReportEdge {
  cursor: String!
  node: JobReport!
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type JobReportsConnection {
  edges: [JobReportEdge!]!
  pageInfo: PageInfo!
}

enum JobStatus {
  JOBSTATUS_FLAPPING
  JOBSTATUS_RUNNING
  JOBSTATUS_STARTING
  JOBSTATUS_STOPPED
  JOBSTATUS_UNKNOWN
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type JoinEvent {
  created: DateTime! @deprecated(reason: "deprecated")
  id: String! @deprecated(reason: "deprecated")
  subscription: Subscription!
}

# Generated from com/attentivemobile/contracts/journeys/api/Contract.proto
type Journey implements Node {
  approvalHash: String!
  approvalStatus: ApprovalStatus!
  company: Company
  conciergeJourneyControls: ConciergeJourneyControls
  currentRevisionId: String! @deprecated(reason: "deprecated")
  description: String!
  draftRevision: JourneyRevision
  eligibleSubscriptionTypes: [SubscriptionType!]!
  errors: [JourneyRuntimeErrorType!]!
  id: ID!
  internalId: String!
  isActive: Boolean!
  journeyRevisions: [JourneyRevision!]
  journeySchedule: JourneySchedule
  journeyTags: [JourneyTag!]!
  journeyTrigger: JourneyTrigger
  name: String!
  publishedRevision: JourneyRevision
  publishedRevisionInternalId: String @deprecated(reason: "deprecated")
  stats: JourneyStats
  subscriptionCategory: SubscriptionType
  template: JourneyTemplate
}

enum JourneyChannel {
  JOURNEY_CHANNEL_EMAIL
  JOURNEY_CHANNEL_MULTI
  JOURNEY_CHANNEL_SMS
  JOURNEY_CHANNEL_UNKNOWN
}

# Generated from com/attentivemobile/contracts/journeys/api/Contract.proto
type JourneyConnection {
  edges: [JourneyEdge!]!
  pageInfo: PageInfo!
  totalCount: Long!
}

enum JourneyDurationUnit {
  JOURNEY_DURATION_UNIT_DAYS
  JOURNEY_DURATION_UNIT_HOURS
  JOURNEY_DURATION_UNIT_MINUTES
  JOURNEY_DURATION_UNIT_UNKNOWN
  JOURNEY_DURATION_UNIT_WEEKS
}

# Generated from com/attentivemobile/contracts/journeys/api/Contract.proto
type JourneyEdge {
  cursor: String!
  node: Journey!
}

# Generated from com/attentivemobile/contracts/journeys/api/JourneyErrors.proto
type JourneyErrors {
  errors: [JourneyRuntimeErrorType!]!
}

enum JourneyEventOrigin {
  JOURNEY_EVENT_ORIGIN_ATTENTIVE_TAG
  JOURNEY_EVENT_ORIGIN_INTEGRATIONS
  JOURNEY_EVENT_ORIGIN_SMS
  JOURNEY_EVENT_ORIGIN_UNKNOWN
}

# Generated from com/attentivemobile/contracts/journeys/api/JourneyRevision.proto
type JourneyMessage {
  composeMessage: ComposeMessage
}

enum JourneyNodeType {
  JOURNEY_NODE_TYPE_CREATE_SUPPORT_TICKET
  JOURNEY_NODE_TYPE_FORWARD_TO_CONCIERGE
  JOURNEY_NODE_TYPE_INCOMING_TEXT
  JOURNEY_NODE_TYPE_KEYWORD_RESPONSE
  JOURNEY_NODE_TYPE_OPEN_RESPONSE
  JOURNEY_NODE_TYPE_SAVE_USER_ATTRIBUTE
  JOURNEY_NODE_TYPE_SEND_CONVERSATION_MESSAGE
  JOURNEY_NODE_TYPE_SEND_EMAIL
  JOURNEY_NODE_TYPE_SEND_LEGAL_WELCOME
  JOURNEY_NODE_TYPE_SEND_MESSAGE
  JOURNEY_NODE_TYPE_SEND_PAYMENT_MESSAGE
  JOURNEY_NODE_TYPE_TRIGGER
  JOURNEY_NODE_TYPE_TRIGGER_INTEGRATION_POSTBACK
  JOURNEY_NODE_TYPE_TWO_WAY_MESSAGE
  JOURNEY_NODE_TYPE_UNKNOWN
  JOURNEY_NODE_TYPE_VARIANT
}

# Generated from com/attentivemobile/contracts/journeys/api/JourneyRevision.proto
type JourneyRevision implements Node {
  approvalFlowRequired: Boolean!
  createdTime: DateTime!
  eligibilityDurationSeconds: Long!
  eligibilityDurationUnit: JourneyDurationUnit!
  entryCooldownSeconds: Long!
  entryCooldownUnit: JourneyDurationUnit!
  hasChanges: Boolean!
  hasValidationErrors: Boolean!
  id: ID!
  internalId: Long!
  journey: Journey
  journeySchedule: JourneySchedule
  lastUpdatedTime: DateTime!
  messages: [JourneyMessage!]!
  publishEndTime: DateTime
  publishStartTime: DateTime
  publishedStatus: JourneyRevisionPublishedStatus!
}

enum JourneyRevisionPublishedStatus {
  JOURNEY_REVISION_PUBLISHED_STATUS_ACTIVE
  JOURNEY_REVISION_PUBLISHED_STATUS_DRAFT
  JOURNEY_REVISION_PUBLISHED_STATUS_INACTIVE
  JOURNEY_REVISION_PUBLISHED_STATUS_SCHEDULED
  JOURNEY_REVISION_PUBLISHED_STATUS_UNKNOWN
}

# Generated from com/attentivemobile/protobuf/common/compose/Message.proto
type JourneyRevisionReference {
  journeyRevision: JourneyRevision
}

# Generated from com/attentivemobile/contracts/journeys/api/JourneyRevision.proto
type JourneyRevisions {
  journeyRevisions: [JourneyRevision!]!
}

enum JourneyRuntimeErrorType {
  JOURNEY_RUNTIME_ERROR_TYPE_COUPON_POOL_EXHAUSTED
  JOURNEY_RUNTIME_ERROR_TYPE_REPLY_TO_BUY_API_KEY_INVALID
  JOURNEY_RUNTIME_ERROR_TYPE_UNKNOWN
  JOURNEY_RUNTIME_ERROR_TYPE_USER_PROFILE_ATTRIBUTE_DELETED
}

# Generated from com/attentivemobile/contracts/journeys/api/JourneySchedule.proto
type JourneySchedule implements Node {
  assignedJourney: ScheduleAssignedJourney!
  company: Company
  id: ID!
  name: String!
  startTime: DateTime
  status: JourneyScheduleStatus!
}

enum JourneyScheduleStatus {
  JOURNEY_SCHEDULE_STATUS_CANCELLED
  JOURNEY_SCHEDULE_STATUS_COMPLETED
  JOURNEY_SCHEDULE_STATUS_SCHEDULED
  JOURNEY_SCHEDULE_STATUS_UNKNOWN
}

# Generated from com/attentivemobile/contracts/concierge/service/Message.proto
type JourneySource {
  journey: Journey
}

enum JourneyState {
  JOURNEY_STATE_ACTIVE
  JOURNEY_STATE_DRAFT
  JOURNEY_STATE_INACTIVE
  JOURNEY_STATE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/journeys/api/JourneyStats.proto
type JourneyStats {
  attributedRevenue: Money!
  attributedRevenueByCurrency: [Money!]!
  clicks: Long!
  conversions: Long!
  entered: Long!
  messagesSent: Long!
}

# Generated from com/attentivemobile/contracts/journeys/api/Contract.proto
type JourneyTag {
  displayName: String!
  type: JourneyTagDisplayType!
}

enum JourneyTagDisplayType {
  JOURNEY_TAG_DISPLAY_TYPE_DEFAULT
  JOURNEY_TAG_DISPLAY_TYPE_UNSPECIFIED
}

# Generated from com/attentivemobile/contracts/journeys/api/JourneyTemplate.proto
type JourneyTemplate implements Node {
  channel: JourneyChannel!
  description: String!
  id: ID!
  internalId: Long!
  language: String!
  name: String!
  trigger: JourneyTrigger
}

# Generated from com/attentivemobile/contracts/journeys/api/JourneyTemplate.proto
type JourneyTemplates {
  journeyTemplates: [JourneyTemplate!]!
}

# Generated from com/attentivemobile/contracts/journeys/api/JourneyTrigger.proto
type JourneyTrigger implements Node {
  companyLimit: Long
  count(companyId: ID!, journeyState: JourneyState): Long
  description: String!
  eligibleSubscriptionTypes: [SubscriptionType!]!
  id: ID!
  internalId: Long!
  isActive: Boolean!
  name: String!
  source: TriggerSource!
  templates: [JourneyTemplate!]
  type: JourneyTriggerType!
}

enum JourneyTriggerType {
  JOURNEY_TRIGGER_TYPE_ADD_TO_CART
  JOURNEY_TRIGGER_TYPE_BACK_IN_STOCK
  JOURNEY_TRIGGER_TYPE_CUSTOM_EVENT
  JOURNEY_TRIGGER_TYPE_INTEGRATION_EVENT
  JOURNEY_TRIGGER_TYPE_JOINED_A_SEGMENT
  JOURNEY_TRIGGER_TYPE_LOW_INVENTORY
  JOURNEY_TRIGGER_TYPE_OPT_IN_BACK_IN_STOCK
  JOURNEY_TRIGGER_TYPE_ORDER_CREATED
  JOURNEY_TRIGGER_TYPE_ORDER_FULFILLED
  JOURNEY_TRIGGER_TYPE_ORDER_SUBSCRIPTION_CHARGE_FAILED
  JOURNEY_TRIGGER_TYPE_ORDER_SUBSCRIPTION_CREATED
  JOURNEY_TRIGGER_TYPE_ORDER_SUBSCRIPTION_SKIPPED
  JOURNEY_TRIGGER_TYPE_ORDER_SUBSCRIPTION_UPCOMING_CHARGE
  JOURNEY_TRIGGER_TYPE_PAGE_VIEW
  JOURNEY_TRIGGER_TYPE_PRICE_DROP
  JOURNEY_TRIGGER_TYPE_PRODUCT_VIEW
  JOURNEY_TRIGGER_TYPE_PURCHASE
  JOURNEY_TRIGGER_TYPE_SIGNED_UP
  JOURNEY_TRIGGER_TYPE_SIGNED_UP_FOR_EMAIL
  JOURNEY_TRIGGER_TYPE_TEXTED_A_KEYWORD
  JOURNEY_TRIGGER_TYPE_UNKNOWN
  JOURNEY_TRIGGER_TYPE_USER_WIN_BACK
}

# Generated from com/attentivemobile/contracts/journeys/api/JourneyTrigger.proto
type JourneyTriggers {
  journeyTriggers: [JourneyTrigger!]!
}

input JourneysFilter {
  journeyTriggerId: ID
  nodeType: [JourneyNodeType!]
  searchTerm: String
  status: JourneyRevisionPublishedStatus
  templateIds: [ID!]
  triggerType: JourneyTriggerType @deprecated(reason: "deprecated")
}

# Generated from com/attentivemobile/contracts/journeys/api/Contract.proto
type JourneysWithNodeTypeResponse {
  names: [String!]!
}

# Generated from com/attentivemobile/contracts/user/profile/property/UserPropertyV2.proto
type JsonPropertyValueV2 {
  jsonValue: JSON!
}

# Generated from com/attentivemobile/protobuf/common/Json.proto
type JsonString {
  json: String!
}

input JsonStringInput {
  json: String!
}

# Generated from com/attentivemobile/contracts/keywords/KeywordService.proto
type KeywordInfo {
  attributeName: String
  company: Company
  enabled: Boolean!
  hasSiblings: Boolean
  keyword: String!
  keywordOrdinal: Int
  ruleCount: Int
}

# Generated from com/attentivemobile/contracts/survey/poll/PollKeywordService.proto
type KeywordPollBannerInfo {
  poll: Poll
}

# Generated from com/attentivemobile/contracts/survey/poll/PollKeywordService.proto
type KeywordPollsMap {
  keyword: String!
  pollInfos: [KeywordPollBannerInfo!]!
}

# Generated from com/attentivemobile/contracts/keywords/KeywordRuleService.proto
type KeywordRule implements Node {
  areaCodeRule: AreaCodeRule
  company: Company
  created: DateTime
  customAttributeRule: CustomAttributeRule
  id: ID!
  keyword: CustomCompanyKeyword
  marketingSubscriberRule: Boolean
  ruleExpression: String
  timeWindowRule: TimeWindowRule
  updated: DateTime
}

# Generated from com/attentivemobile/contracts/keywords/KeywordRuleService.proto
type KeywordRulesInfoPayload {
  companyKeywordId: Int!
  ruleCount: Int!
  rulesExpression: String!
  rulesLegend: [RulesLegendEntry!]!
}

# Generated from com/attentivemobile/contracts/survey/poll/Contract.proto
type KeywordStats {
  count: Int!
  formattedPercentage: String
  keyword: String!
  percentage: Float!
  uuid: String!
}

# Generated from com/attentivemobile/contracts/survey/poll/Contract.proto
type KeywordsTimeline {
  timelineEntries: [TimelineEntry!]!
}

input KillWorkloadInput {
  appId: String!
  workloadName: String!
}

union KillWorkloadPayload = DefaultErrorFailure | KillWorkloadSuccess

# Generated from com/attentivemobile/contracts/workload/management/service/runner/Contract.proto
type KillWorkloadSuccess {
  workloadName: String!
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/DatastreamModel.proto
type KinesisStream {
  streamName: String!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto
type KnownProductProperty {
  knownProductPropertyType: KnownProductPropertyType!
}

input KnownProductPropertyInput {
  knownProductPropertyType: KnownProductPropertyType!
}

enum KnownProductPropertyType {
  KNOWN_PRODUCT_PROPERTY_TYPE_BRAND
  KNOWN_PRODUCT_PROPERTY_TYPE_CATEGORY
  KNOWN_PRODUCT_PROPERTY_TYPE_COLLECTION
  KNOWN_PRODUCT_PROPERTY_TYPE_COLOR
  KNOWN_PRODUCT_PROPERTY_TYPE_NAME
  KNOWN_PRODUCT_PROPERTY_TYPE_PRICE
  KNOWN_PRODUCT_PROPERTY_TYPE_SIZE
  KNOWN_PRODUCT_PROPERTY_TYPE_TAG
  KNOWN_PRODUCT_PROPERTY_TYPE_UNKNOWN
}

input LeaveConciergeChatInput {
  chatId: ID!
}

# Generated from com/attentivemobile/contracts/concierge/service/Chat.proto
type LeaveConciergeChatPayload {
  chatId: ID!
}

# Generated from com/attentivemobile/contracts/reports/api/Contract.proto
type LegacyReport {
  active: Boolean!
  company: Company
  created: DateTime!
  description: String!
  name: String!
  reportId: String!
  updated: DateTime!
}

# Generated from com/attentivemobile/contracts/reports/api/Contract.proto
type LegacyReportConnection {
  edges: [LegacyReportEdge!]!
  pageInfo: PageInfo!
  totalCount: Int!
}

# Generated from com/attentivemobile/contracts/reports/api/Contract.proto
type LegacyReportEdge {
  cursor: String!
  node: LegacyReport!
}

# Generated from com/attentivemobile/contracts/reports/api/Contract.proto
type LegacyScheduledReport {
  active: Boolean!
  company: Company
  created: DateTime!
  id: String!
  name: String!
  recipients: String!
  reportId: String!
  reportInterval: ReportDeliveryFrequency!
  schedulingInterval: ReportDeliveryFrequency!
  startDate: DateTime!
  updated: DateTime!
  userId: String!
}

# Generated from com/attentivemobile/contracts/reports/api/Contract.proto
type LegacyScheduledReportConnection {
  edges: [LegacyScheduledReportEdge!]!
  pageInfo: PageInfo!
  totalCount: Int!
}

# Generated from com/attentivemobile/contracts/reports/api/Contract.proto
type LegacyScheduledReportEdge {
  cursor: String!
  node: LegacyScheduledReport!
}

# Generated from com/attentivemobile/contracts/tagbuildservice/Contract.proto
type LegacyTagConfig {
  configId: Long!
  keyId: Int!
  keyName: String!
  value: String!
}

input LegacyTagConfigUpdateInput {
  keyId: Int!
  value: String!
}

# Generated from com/attentivemobile/contracts/concierge/service/Message.proto
type LegalMessageSource {
  isLegalMessageSource: Boolean!
}

# Generated from com/attentivemobile/contracts/company-service/companysetting/Model.proto
type LinkParameter {
  isActive: Boolean!
  isCustom: Boolean!
  key: String!
  messageChannel: LinkParameterMessageChannel!
  messageType: LinkParameterMessageType!
  value: String!
}

input LinkParameterInput {
  isActive: Boolean
  isCustom: Boolean
  key: String
  messageChannel: LinkParameterMessageChannel
  messageType: LinkParameterMessageType
  value: String
}

enum LinkParameterMessageChannel {
  LINK_PARAMETER_MESSAGE_CHANNEL_EMAIL
  LINK_PARAMETER_MESSAGE_CHANNEL_GLOBAL
  LINK_PARAMETER_MESSAGE_CHANNEL_TEXT
  LINK_PARAMETER_MESSAGE_CHANNEL_UNKNOWN
}

enum LinkParameterMessageType {
  LINK_PARAMETER_MESSAGE_TYPE_CAMPAIGN
  LINK_PARAMETER_MESSAGE_TYPE_JOURNEY
  LINK_PARAMETER_MESSAGE_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/personalization/service/aijourneys/defaultmessage/AIJourneyDefaultMessageServiceContract.proto
type ListAIJourneyDefaultMessagesResponse {
  defaultMessages: [AIJourneyDefaultMessage!]!
}

# Generated from com/attentivemobile/contracts/account/permission/service/AccountPermissionDomainService.proto
type ListAccountPermissionDomainsPayload {
  domains: [AccountPermissionsDomain!]!
}

# Generated from com/attentivemobile/contracts/account/permission/service/AccountPermissionsService.proto
type ListAccountsForPermissionsPayload {
  edges: [AssignedAccountEdge!]!
}

input ListAdjacentPostalCodesInput {
  distance: Distance!
  origin: PostalCodeInput!
}

# Generated from com/attentivemobile/contracts/concierge/service/Template.proto
type ListAgentTemplatesResponse {
  recommendedTemplateRequestId: String
  templates: [AgentTemplate!]!
}

# Generated from com/attentivemobile/contracts/messaging/automation/autojourneys/AutoJourneysContract.proto
type ListAutoJourneysResponse {
  journeys: [AutoJourney!]!
}

# Generated from com/attentivemobile/contracts/solutions/clientsegmentimports/ClientSegmentImports.proto
type ListClientSegmentImportsResponse {
  clientSegmentImportsRows: [ClientSegmentImportsRow!]!
}

input ListCompanyCreativeRulesFilter {
  definitionSource: [CreativeRuleDefinitionSource!]
}

# Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto
type ListCompanyVendorIntegrationFeaturesResponse {
  companyVendorIntegrationFeatures: [CompanyVendorIntegrationFeature!]!
}

# Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto
type ListCompanyVendorIntegrationsResponse {
  companyVendorIntegrations: [CompanyVendorIntegration!]!
}

# Proxies filtering by status, by using the groupings exposed to our clients in the UI
enum ListComposeCampaignUiFilter {
  LIST_COMPOSE_CAMPAIGN_UI_FILTER_DELIVERED
  LIST_COMPOSE_CAMPAIGN_UI_FILTER_DRAFT
  LIST_COMPOSE_CAMPAIGN_UI_FILTER_NONE
  LIST_COMPOSE_CAMPAIGN_UI_FILTER_PAUSED
  LIST_COMPOSE_CAMPAIGN_UI_FILTER_SCHEDULED
  LIST_COMPOSE_CAMPAIGN_UI_FILTER_UNKNOWN
}

# Generated from com/attentivemobile/contracts/generativeaiservice/template/GenerativeAITemplateContract.proto
type ListCopyAssistantTemplatesPayload {
  templates: [GenerativeAICopyAssistantTemplate!]!
}

# Generated from com/attentivemobile/contracts/geography/Location.proto
type ListCountriesResponse {
  countries: [Country!]!
}

# Generated from com/attentivemobile/contracts/geography/Location.proto
type ListCountrySubdivisionsResponse {
  countrySubdivisions: [CountrySubdivision!]!
}

input ListCreativeExperimentsFilter {
  status: [CreativeExperimentStatus!]
}

input ListCreativeExperimentsFilterProperties {
  exclude: ListCreativeExperimentsFilter
  include: ListCreativeExperimentsFilter
  searchTerm: String
}

input ListCreativeExperimentsSort {
  sortDirection: ListCreativeExperimentsSortDirection!
  sortField: ListCreativeExperimentsSortField!
}

enum ListCreativeExperimentsSortDirection {
  CREATIVE_EXPERIMENTS_SORT_DIRECTION_ASC
  CREATIVE_EXPERIMENTS_SORT_DIRECTION_DESC
  CREATIVE_EXPERIMENTS_SORT_DIRECTION_UNKNOWN
}

enum ListCreativeExperimentsSortField {
  CREATIVE_EXPERIMENTS_SORT_FIELD_CLICKS
  CREATIVE_EXPERIMENTS_SORT_FIELD_CREATED
  CREATIVE_EXPERIMENTS_SORT_FIELD_CTR
  CREATIVE_EXPERIMENTS_SORT_FIELD_CVR
  CREATIVE_EXPERIMENTS_SORT_FIELD_IMPRESSIONS
  CREATIVE_EXPERIMENTS_SORT_FIELD_INTERNAL_ID
  CREATIVE_EXPERIMENTS_SORT_FIELD_NAME
  CREATIVE_EXPERIMENTS_SORT_FIELD_STATUS
  CREATIVE_EXPERIMENTS_SORT_FIELD_STATUS_LABEL
  CREATIVE_EXPERIMENTS_SORT_FIELD_SUBSCRIBERS
  CREATIVE_EXPERIMENTS_SORT_FIELD_UNKNOWN
  CREATIVE_EXPERIMENTS_SORT_FIELD_UPDATED
}

input ListCreativesAndExperimentsFilter {
  archived: Boolean
  canEditRules: Boolean
  countryCode: [String!]
  couponSetId: [ID!]
  customAttributeName: [String!]
  experimentStatus: [CreativeExperimentStatus!]
  hasActiveExperiment: Boolean
  status: [String!] @deprecated(reason: "deprecated")
  statusLabel: [CreativeStatusLabel!]
  statusV2: [CreativeStatus!]
  subtype: [String!] @deprecated(reason: "deprecated")
  subtypeV2: [CreativeSubtype!]
  type: [String!] @deprecated(reason: "deprecated")
  typeV2: [CreativeType!]
  userView: ListCreativesAndExperimentsFilterUserView
}

input ListCreativesAndExperimentsFilterProperties {
  exclude: ListCreativesAndExperimentsFilter
  include: ListCreativesAndExperimentsFilter
  searchTerm: String
}

enum ListCreativesAndExperimentsFilterUserView {
  LIST_CREATIVES_AND_EXPERIMENTS_FILTER_USER_VIEW_SUPERUSER
  LIST_CREATIVES_AND_EXPERIMENTS_FILTER_USER_VIEW_UNKNOWN
}

input ListCreativesAndExperimentsSort {
  sortDirection: ListCreativesAndExperimentsSortDirection!
  sortField: ListCreativesAndExperimentsSortField!
}

enum ListCreativesAndExperimentsSortDirection {
  CREATIVES_AND_EXPERIMENTS_SORT_DIRECTION_ASC
  CREATIVES_AND_EXPERIMENTS_SORT_DIRECTION_DESC
  CREATIVES_AND_EXPERIMENTS_SORT_DIRECTION_UNKNOWN
}

enum ListCreativesAndExperimentsSortField {
  CREATIVES_AND_EXPERIMENTS_SORT_FIELD_CLICKS
  CREATIVES_AND_EXPERIMENTS_SORT_FIELD_COUNTRY_CODE
  CREATIVES_AND_EXPERIMENTS_SORT_FIELD_CREATED
  CREATIVES_AND_EXPERIMENTS_SORT_FIELD_CTR
  CREATIVES_AND_EXPERIMENTS_SORT_FIELD_CVR
  CREATIVES_AND_EXPERIMENTS_SORT_FIELD_IMPRESSIONS
  CREATIVES_AND_EXPERIMENTS_SORT_FIELD_INTERNAL_ID
  CREATIVES_AND_EXPERIMENTS_SORT_FIELD_LANGUAGE
  CREATIVES_AND_EXPERIMENTS_SORT_FIELD_NAME
  CREATIVES_AND_EXPERIMENTS_SORT_FIELD_RULES
  CREATIVES_AND_EXPERIMENTS_SORT_FIELD_STATUS
  CREATIVES_AND_EXPERIMENTS_SORT_FIELD_STATUS_LABEL
  CREATIVES_AND_EXPERIMENTS_SORT_FIELD_SUBSCRIBERS
  CREATIVES_AND_EXPERIMENTS_SORT_FIELD_UNKNOWN
  CREATIVES_AND_EXPERIMENTS_SORT_FIELD_UPDATED
}

input ListCreativesFilter {
  archived: Boolean
  canEditRules: Boolean
  countryCode: [String!]
  couponSetId: [ID!]
  customAttributeName: [String!]
  hasActiveExperiment: Boolean
  status: [String!] @deprecated(reason: "deprecated")
  statusLabel: [CreativeStatusLabel!]
  statusV2: [CreativeStatus!]
  subscriptionType: CreativeSubscriptionType
  subtype: [String!] @deprecated(reason: "deprecated")
  subtypeV2: [CreativeSubtype!]
  type: [String!] @deprecated(reason: "deprecated")
  typeV2: [CreativeType!]
  userView: ListCreativesFilterUserView
}

input ListCreativesFilterProperties {
  exclude: ListCreativesFilter
  include: ListCreativesFilter
  searchTerm: String
}

enum ListCreativesFilterUserView {
  LIST_CREATIVES_FILTER_USER_VIEW_SUPERUSER
  LIST_CREATIVES_FILTER_USER_VIEW_UNKNOWN
}

input ListCreativesSort {
  sortDirection: ListCreativesSortDirection!
  sortField: ListCreativesSortField!
}

enum ListCreativesSortDirection {
  CREATIVES_SORT_DIRECTION_ASC
  CREATIVES_SORT_DIRECTION_DESC
  CREATIVES_SORT_DIRECTION_UNKNOWN
}

enum ListCreativesSortField {
  CREATIVES_SORT_FIELD_ARCHIVED
  CREATIVES_SORT_FIELD_CLICKS
  CREATIVES_SORT_FIELD_COUNTRY_CODE
  CREATIVES_SORT_FIELD_CREATED
  CREATIVES_SORT_FIELD_CTR
  CREATIVES_SORT_FIELD_CVR
  CREATIVES_SORT_FIELD_DISPLAY_RULES
  CREATIVES_SORT_FIELD_HAS_COUPON_SET
  CREATIVES_SORT_FIELD_IMPRESSIONS
  CREATIVES_SORT_FIELD_INTERNAL_ID
  CREATIVES_SORT_FIELD_LANGUAGE
  CREATIVES_SORT_FIELD_NAME
  CREATIVES_SORT_FIELD_STATUS
  CREATIVES_SORT_FIELD_STATUS_LABEL
  CREATIVES_SORT_FIELD_SUBSCRIBERS
  CREATIVES_SORT_FIELD_SUBTYPE
  CREATIVES_SORT_FIELD_TYPE
  CREATIVES_SORT_FIELD_UNKNOWN
  CREATIVES_SORT_FIELD_UPDATED
}

input ListCustomEventKeysRequestFilter {
  customEventType: String
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type ListCustomEventTypesByApplicationResponse {
  customEventDefinitions: [CustomEventDefinition!]!
}

input ListCustomEventsRequestFilter {
  customEventType: String
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Contract.proto
type ListDatastreams {
  statusInfo: [DatastreamStatusInfo!]!
}

input ListDimensionValuesSearchInput {
  keys: [String!]
  values: [String!]
}

# Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto
type ListExternalVendorFeaturesResponse {
  externalVendorFeatures: [ExternalVendorFeature!]!
}

# Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto
type ListIntegrationErrorsResponse {
  integrationErrors: [IntegrationError!]!
}

# Generated from com/attentivemobile/contracts/concierge/service/Feedback.proto
type ListMessageFeedbackResponse {
  messageFeedback: [MessageFeedback!]!
}

# Generated from com/attentivemobile/contracts/phonenumbers/external/Contract.proto
type ListMessagingServiceResponse {
  messagingServices: [CompanyMessagingService!]!
}

# Generated from com/attentivemobile/contracts/metricsapi/Contract.proto
type ListMetricsResponse {
  metrics: [Metric!]!
}

# Generated from com/attentivemobile/contracts/company-service/onboarding/Milestone.proto
type ListMilestonesResponse {
  # milestones represent steps an attentive client goes through in their onboarding process
  milestones: [Milestone!]!
}

# Generated from com/attentivemobile/contracts/company-service/onboarding/Survey.proto
type ListOnboardingSurveySelectionsResponse {
  selections: [OnboardingSurveySelection!]!
}

input ListPhoneNumbersFilterInput {
  applicationStatus: PhoneApplicationStatus
  assignedCompany: String
  assignmentStatus: PhoneNumberCompanyAssignmentStatus
  dateRange: DateRangeFilter
  messagingServiceCompany: String
  messagingServiceStatus: MessagingServiceStatus
  phoneNumber: String
  phoneNumberStatus: PhoneNumberStatus
  provider: Provider
}

input ListSearchAttributeFieldIdentifier {
  identifier: ListSearchAttributeFieldIdentifierIdentifier
}

input ListSearchAttributeFieldIdentifierIdentifier {
  searchAttributeFieldAlias: String
  searchAttributeFieldId: ID
}

input ListSearchAttributeFieldsFilter {
  identifiers: [ListSearchAttributeFieldIdentifier!]
}

input ListSearchAttributeIdentifier {
  identifier: ListSearchAttributeIdentifierIdentifier
}

input ListSearchAttributeIdentifierIdentifier {
  searchAttributeAlias: String
  searchAttributeId: ID
}

input ListSearchAttributesFilter {
  identifiers: [ListSearchAttributeIdentifier!]
}

input ListSegmentsFilter {
  createdBefore: DateTime
  migrationStatus: MigrationStatus
  name: StringCondition
  source: SegmentSource
  statusFilter: SegmentStatusFilter
  tagFilter: SegmentTagFilter
}

input ListSegmentsOrderBy {
  column: OrderColumn!
  direction: OrderDirection!
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type ListSendingDomainsResponse {
  sendingDomains: [SendingDomain!]!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type ListSupportedCustomPropertyDataTypesResponse {
  types: [CustomPropertyType!]!
}

input ListSyncsFilter {
  syncMethod: EdsJobSyncMethod
  syncStatus: SyncStatus
  syncType: EdsJobSync
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Contract.proto
type ListTeamsResponse {
  teams: [DatastreamTeam!]!
}

# Generated from com/attentivemobile/contracts/concierge/service/Macro.proto
type ListUserConversationMacrosResponse {
  macros: [UserConversationMacro!]!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type ListUserPropertiesResponse {
  propertyData: [UserPropertyDefinitionValue!]!
}

input ListUserPropertyDefinitionFilter {
  customPropertyTypes: [CustomPropertyType!]
  includeDeleted: Boolean
  includeInternalProperties: Boolean @deprecated(reason: "deprecated")
  source: UserPropertySource
}

input ListUsersClickEventFilter {
  hasVerb: HasVerb!
  timeCondition: TimeCondition!
}

input ListUsersFilter {
  clickEventFilter: ListUsersClickEventFilter
  location: ListUsersLocationFilter
  purchaseEventFilter: PurchaseEventFilter
  searchFilter: UserSearchFilter
  segmentId: ID
  segmentInternalId: String @deprecated(reason: "deprecated")
  status: UserStatus
  subscriptionOptInCompletedFilter: ListUsersSubscriptionOptInCompletedFilter
}

input ListUsersLocationFilter {
  isVerb: IsVerb!
  isoCodes: [String!]
}

input ListUsersSubscriptionOptInCompletedFilter {
  hasVerb: HasVerb!
  timeCondition: TimeCondition!
}

# Generated from com/attentivemobile/contracts/externaltokens/Webhooks.proto
# Make an API call to this mutation to list existing webhooks.
type ListWebhookResponse {
  # Webhook details.
  webhooks: [Webhook!]!
}

# Generated from com/attentivemobile/contracts/workload/management/service/configuration/Contract.proto
type ListWorkloadConfigsResponse {
  workloadConfigs: [WorkloadConfig!]!
}

# Generated from com/attentivemobile/protobuf/common/targeting/UserProperty.proto
type LocalDateFields {
  hasDay: Boolean!
  hasMonth: Boolean!
  hasYear: Boolean!
}

# Generated from com/attentivemobile/contracts/keywords/KeywordRuleService.proto
type LocalTimeMessage {
  hour: Int!
  minute: Int!
  second: Int!
}

# Locale of the user.
input LocaleForSubscribeInput {
  # Two-letter country code of the locale. (ISO 3166-1 alpha 2)[https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2] is required. Example=US
  country: String!

  # Two-letter language code of the locale. (ISO 639-1 alpha 2)[https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes] is required. Example=en
  language: String!
}

input LocaleInput {
  languageCode: String!
  regionCode: String!
  variant: String!
}

# Generated from com/attentivemobile/contracts/company-service/region/Model.proto
type LocalizedSettings {
  autoResponseEnabled: Boolean!
  autoUnsubscribeMessageEnabled: Boolean!
  automatedMessages: [AutomatedMessage!]!
  doubleOptInMessageEnabled: Boolean!
  isPrimary: Boolean!

  # IETF BCP 47 language tag. e.g. en-US, en-CA, en-GB, etc.
  languageTag: String!
  legalMessageEnabled: Boolean!
}

input LocalizedSettingsInput {
  autoResponseEnabled: Boolean
  autoUnsubscribeMessageEnabled: Boolean
  automatedMessages: [AutomatedMessageInput!]
  doubleOptInMessageEnabled: Boolean
  isPrimary: Boolean

  # IETF BCP 47 language tag. e.g. en-US, en-CA, en-GB, etc.
  languageTag: String!
  legalMessageEnabled: Boolean
}

input LoginWithMfaTokenInput {
  mfaToken: String!
}

# Generated from com/attentivemobile/contracts/account-service/MFAContract.proto
type LoginWithMfaTokenPayload {
  jwtToken: String!
}

# Generated from com/attentivemobile/contracts/account-service/AuthenticationContract.proto
type LoginWithPasswordFailureResponse {
  code: String!
  message: String!
}

input LoginWithPasswordInput {
  deviceId: String
  email: String!
  password: String!
}

# Generated from com/attentivemobile/contracts/account-service/AuthenticationContract.proto
type LoginWithPasswordMfaResponse {
  mfaEmailHint: String

  # If true, the user already has MFA configured. If false they must set it up.
  mfaEnabled: Boolean!
  mfaPhoneNumberHint: String
  mfaToken: String!
  preferredMfaMethod: PreferredMfaMethod!
}

# Generated from com/attentivemobile/contracts/account-service/AuthenticationContract.proto
type LoginWithPasswordPayload {
  response: LoginWithPasswordPayloadResponse
}

union LoginWithPasswordPayloadResponse = LoginWithPasswordFailureResponse | LoginWithPasswordMfaResponse | LoginWithPasswordSuccessResponse

# Generated from com/attentivemobile/contracts/account-service/AuthenticationContract.proto
type LoginWithPasswordSuccessResponse {
  jwtToken: String!
}

# A 64-bit signed integer
scalar Long

# Generated from com/attentivemobile/contracts/user/profile/property/UserPropertyV2.proto
type LongValueV2 {
  longValue: Long!
}

# Generated from com/attentivemobile/contracts/composition/MessageAttachment.proto
type MacroAttribute {
  behavior: MacroBehavior!
  displayName: String!
  extension: String!
  macroAttributes: [MacroAttribute!]!
  macroBehaviorConfig: MacroAttributeMacroBehaviorConfig
}

union MacroAttributeMacroBehaviorConfig = EnumMacroBehaviorConfig

enum MacroBehavior {
  MACRO_BEHAVIOR_BOOLEAN
  MACRO_BEHAVIOR_DATE
  MACRO_BEHAVIOR_ENUM
  MACRO_BEHAVIOR_IMAGE
  MACRO_BEHAVIOR_LINK
  MACRO_BEHAVIOR_NUMBER
  MACRO_BEHAVIOR_OBJECT
  MACRO_BEHAVIOR_OBJECT_LIST
  MACRO_BEHAVIOR_PRIMITIVE_LIST
  MACRO_BEHAVIOR_TEXT
  MACRO_BEHAVIOR_UNKNOWN
  MACRO_BEHAVIOR_UNKNOWN_TEXT
}

enum MacroCategory {
  COMPANY
  COUPON
  EVENT
  PERSONALIZATION
}

# Generated from com/attentivemobile/contracts/composition/MessageAttachment.proto
type MacroConfiguration {
  entityToken: String!
  macroAttributes: [MacroAttribute!]!
}

enum MacroType {
  LINK
  TEXT
}

input MakeCreativeInput {
  alreadySubscribedMessageId: ID
  companyId: ID!
  config: JsonStringInput
  couponSetId: ID
  customProperties: [CreativeCustomPropertyInput!]
  externalName: String
  language: String!
  name: String!
  rules: JsonStringInput
  smsBody: String!
  src: String @deprecated(reason: "deprecated")
  status: CreativeStatus!
  subscriptionType: CreativeSubscriptionType
  subtype: CreativeSubtype!
  templateId: String
  type: CreativeType!
}

# Generated from com/attentivemobile/contracts/creative-service/creative/CreativeGraphService.proto
type MakeCreativePayload {
  creativeOrError: MakeCreativePayloadCreativeOrError
}

union MakeCreativePayloadCreativeOrError = Creative | CreativeMutationError

enum ManualSegmentSource {
  MANUAL_SEGMENT_SOURCE_INTERNAL
  MANUAL_SEGMENT_SOURCE_THIRD_PARTY
  MANUAL_SEGMENT_SOURCE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto
type MapColNameEntry {
  key: String!
  value: String!
}

input MapColNameInputEntry {
  key: String!
  value: String!
}

# Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto
type MapEventNameEntry {
  key: String!
  value: String!
}

input MapEventNameInputEntry {
  key: String!
  value: String!
}

input MarkAITemplateAsLiveInput {
  templateName: String!
  version: String!
}

union MarkAITemplateAsLivePayload = DefaultErrorFailure | MarkAITemplateAsLiveSuccess

# Generated from com/attentivemobile/contracts/generativeaiservice/template/GenerativeAITemplateContract.proto
type MarkAITemplateAsLiveSuccess {
  isSuccess: Boolean!
}

input MarkClientConversationReadInput {
  userId: ID!
}

# Generated from com/attentivemobile/contracts/conversations/v1/Conversation.proto
type MarkClientConversationReadPayload {
  user: User
}

input MarkFeedbackViewedInput {
  messageFeedbackId: ID!
}

# Generated from com/attentivemobile/contracts/concierge/service/Feedback.proto
type MarkFeedbackViewedPayload {
  messageFeedback: MessageFeedback!
}

input MarkMilestonesSkippedRequest {
  companyId: ID!
  milestoneKeys: [MilestoneKey!]
}

# Generated from com/attentivemobile/contracts/company-service/onboarding/Milestone.proto
type MarkMilestonesSkippedResponse {
  company: Company
}

# Generated from com/attentivemobile/contracts/message/history/Contract.proto
type Message implements Node {
  agent: SendingAgent
  aggregator: Aggregator
  body: String!
  carrier: String!
  company: Company
  composeMessage: ComposeMessage
  conciergeSession: ConciergeSession
  countryCode: String
  created: DateTime!
  direction: MessageDirection!
  errorCode: String
  externalId: String
  fromPhone: String!
  id: ID!
  isSentByAgent: Boolean
  mediaUrl: String @deprecated(reason: "deprecated")
  mediaUrls: [String!]!
  messageFeedbackList(isClientView: Boolean!): ListMessageFeedbackResponse
  messageId: Long
  provider: Provider!
  segmentCount: Int!
  sendId: String
  status: MessageStatus!
  statusDetails: [MessageStatusDetail!]!
  toPhone: String!
  user: User
}

input MessageConfig {
  messageSequenceType: MessageSequenceType!
  sequenceNumber: Int
  utmSettingsInput: UtmSettingsInput!
}

# Generated from com/attentivemobile/contracts/message/history/Contract.proto
type MessageConnection {
  edges: [MessageEdge!]!
  pageInfo: PageInfo!
  totalCount: Long
}

enum MessageDirection {
  MESSAGE_DIRECTION_INBOUND
  MESSAGE_DIRECTION_OUTBOUND
  MESSAGE_DIRECTION_UNKNOWN
}

# Generated from com/attentivemobile/contracts/message/history/Contract.proto
type MessageEdge {
  cursor: String!
  node: Message!
}

union MessageEditorProperties = DragAndDropEditorProperties | HtmlEditorProperties

# Generated from com/attentivemobile/contracts/concierge/service/Feedback.proto
type MessageFeedback implements Node {
  company: Company
  created: DateTime!
  feedback: String!
  feedbackType: MessageFeedbackType!
  givenBy: MessageFeedbackGivenBy!
  givenByAccount: Account
  handledBy: Account
  handledTimestamp: DateTime
  id: ID!
  message: Message
  outcome: MessageFeedbackOutcome
  subject: String!
  updated: DateTime!
  viewedBy: [AccountItem!]!
}

# Generated from com/attentivemobile/contracts/concierge/service/Feedback.proto
type MessageFeedbackConnection {
  edges: [MessageFeedbackEdge!]!
  pageInfo: PageInfo!
  totalCount: Long
}

# Generated from com/attentivemobile/contracts/concierge/service/Feedback.proto
type MessageFeedbackEdge {
  cursor: String!
  node: MessageFeedback!
}

enum MessageFeedbackGivenBy {
  AGENT
  CLIENT
  UNKNOWN_GIVEN_BY
}

enum MessageFeedbackOutcome {
  NOTES
  NO_ACTION
  TEMPLATE
  UNKNOWN_OUTCOME
}

enum MessageFeedbackSortColumn {
  MESSAGE_FEEDBACK_SORT_COLUMN_DATE_GIVEN
  MESSAGE_FEEDBACK_SORT_COLUMN_DATE_RESOLVED
  MESSAGE_FEEDBACK_SORT_COLUMN_UNKNOWN_COLUMN
}

enum MessageFeedbackSortDirection {
  MESSAGE_FEEDBACK_SORT_DIRECTION_ASC
  MESSAGE_FEEDBACK_SORT_DIRECTION_DESC
  MESSAGE_FEEDBACK_SORT_UNKNOWN_DIRECTION
}

input MessageFeedbackSortInput {
  sortColumn: MessageFeedbackSortColumn!
  sortDirection: MessageFeedbackSortDirection!
}

enum MessageFeedbackStatusFilter {
  MESSAGE_FEEDBACK_STATUS_HANDLED
  MESSAGE_FEEDBACK_STATUS_NOT_HANDLED
  MESSAGE_FEEDBACK_STATUS_UNKNOWN_HANDLED
}

enum MessageFeedbackType {
  NEGATIVE
  NEUTRAL
  POSITIVE
  UNKNOWN_TYPE
}

input MessageFeedbackTypeFilter {
  feedbackTypes: [MessageFeedbackType!]
}

# Generated from com/attentivemobile/protobuf/common/Identifiers.proto
type MessageID {
  id: Long!
}

# Generated from com/attentivemobile/contracts/keywords/KeywordService.proto
type MessageInfo {
  forceSend: Boolean!
  mediaUrl: String
  messageText: String!
  shortenLink: Boolean!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type MessageLinkClick {
  campaignName: String!
  channel: Channel!
  created: DateTime! @deprecated(reason: "deprecated")
  id: String! @deprecated(reason: "deprecated")
  messageId: MessageLinkClickMessageId
  vendor: String!
}

union MessageLinkClickMessageId = MessageID | ThirdPartyMessageId

enum MessageSequenceType {
  MESSAGE_SEQUENCE_TYPE_BROWSE_ABANDONMENT_MESSAGE
  MESSAGE_SEQUENCE_TYPE_CART_ABANDONMENT_FOLLOWUP_MESSAGE
  MESSAGE_SEQUENCE_TYPE_CART_ABANDONMENT_MESSAGE
  MESSAGE_SEQUENCE_TYPE_CONTACT_CARD_MESSAGE
  MESSAGE_SEQUENCE_TYPE_CONTROL_FOLLOWUP_MESSAGE
  MESSAGE_SEQUENCE_TYPE_CONTROL_WELCOME_MESSAGE
  MESSAGE_SEQUENCE_TYPE_FOLLOWUP_MESSAGE
  MESSAGE_SEQUENCE_TYPE_INITIAL_MESSAGE
  MESSAGE_SEQUENCE_TYPE_LEGAL_MESSAGE
  MESSAGE_SEQUENCE_TYPE_PRICE_DROP_MESSAGE
  MESSAGE_SEQUENCE_TYPE_SESSION_ABANDONMENT_MESSAGE
  MESSAGE_SEQUENCE_TYPE_UNKNOWN
  MESSAGE_SEQUENCE_TYPE_WELCOME_MESSAGE
}

enum MessageSortField {
  MESSAGE_SORT_FIELD_CREATED_AT
  MESSAGE_SORT_FIELD_UNKNOWN
}

enum MessageStage {
  STAGE_ATTENTIVE_PLATFORM
  STAGE_CARRIER_NETWORK
  STAGE_SENDING_PARTNER
  STAGE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/campaigns/service/Stats.proto
type MessageStats {
  clicks: Int!
  conversions: Int!
  messageId: String!
  optOuts: Int!
  revenue: Float!
  sends: Int!
  totalOpens: Int!
  uniqueClicks: Int!
  uniqueOpens: Int!
}

enum MessageStatus {
  STATUS_CREATED
  STATUS_DELIVERED
  STATUS_FAILED
  STATUS_FATIGUED
  STATUS_INCOMPLETE
  STATUS_MITIGATED
  STATUS_RECEIVED
  STATUS_SEEN
  STATUS_SENT
  STATUS_UNDELIVERED
  STATUS_UNKNOWN
}

# Generated from com/attentivemobile/contracts/message/explorer/Contract.proto
type MessageStatusDetail {
  details: String!
  errorCode: String!
  stage: MessageStage!
  step: MessageStep!
  timestamp: DateTime!
}

# Generated from com/attentivemobile/contracts/message/explorer/Contract.proto
type MessageStatusDetailsResponse {
  details: [MessageStatusDetail!]!
}

enum MessageStep {
  STEP_ACCEPTED
  STEP_CANCELLED
  STEP_CREATED
  STEP_DELIVERED
  STEP_DOWNGRADED
  STEP_FAILED
  STEP_FATIGUED
  STEP_PAUSED
  STEP_PROBLEM
  STEP_QUEUED
  STEP_RECEIVED
  STEP_RESUMED
  STEP_SEEN
  STEP_SENDING
  STEP_SENT
  STEP_UNDELIVERED
  STEP_UNDETERMINED
  STEP_UNKNOWN
}

# Generated from com/attentivemobile/contracts/compose/api/Contract.proto
type MessageTemplateConnection {
  edges: [ComposeMessageTemplateEdge!]!
  pageInfo: PageInfo!
  totalCount: Int!
}

# Generated from com/attentivemobile/protobuf/common/compose/Message.proto
type MessageTemplateReferences {
  campaigns: [ComposeCampaignReference!]!
  journeyRevisions: [JourneyRevisionReference!]!
  messages: [ComposeMessageReference!]!
  templates: [ComposeMessageTemplateReference!]!
}

enum MessageTone {
  MESSAGE_TONE_CASUAL
  MESSAGE_TONE_FORMAL
  MESSAGE_TONE_POLISHED
  MESSAGE_TONE_SUPER_CASUAL
}

enum MessageType {
  MESSAGE_TYPE_BROWSE_ABANDONMENT
  MESSAGE_TYPE_CART_ABANDONMENT
  MESSAGE_TYPE_CART_ABANDONMENT_FOLLOWUP_1
  MESSAGE_TYPE_CONTACT_CARD
  MESSAGE_TYPE_PRICE_DROP
  MESSAGE_TYPE_SESSION_ABANDONMENT
  MESSAGE_TYPE_UNKNOWN
  MESSAGE_TYPE_WELCOME
  MESSAGE_TYPE_WELCOME_FOLLOWUP_1
  MESSAGE_TYPE_WELCOME_FOLLOWUP_2
  MESSAGE_TYPE_WELCOME_FOLLOWUP_3
  MESSAGE_TYPE_WELCOME_FOLLOWUP_4
}

# Generated from com/attentivemobile/contracts/message/explorer/Contract.proto
type MessagesCsvResponse {
  csv: String!
}

enum MessagingChannel {
  MESSAGING_CHANNEL_EMAIL
  MESSAGING_CHANNEL_TEXT
  MESSAGING_CHANNEL_UNKNOWN
}

input MessagingServiceFilter {
  countryCode: String
  includeInactive: Boolean
}

enum MessagingServiceProvider {
  MESSAGING_SERVICE_PROVIDER_OPEN_MARKET
  MESSAGING_SERVICE_PROVIDER_SINCH
  MESSAGING_SERVICE_PROVIDER_TWILIO
  MESSAGING_SERVICE_PROVIDER_UNKNOWN
  MESSAGING_SERVICE_PROVIDER_ZIPWHIP
}

enum MessagingServiceStatus {
  MESSAGING_SERVICE_STATUS_ACTIVE
  MESSAGING_SERVICE_STATUS_INACTIVE
  MESSAGING_SERVICE_STATUS_TESTING
  MESSAGING_SERVICE_STATUS_UNKNOWN
}

enum MessagingTemplateType {
  MESSAGING_TEMPLATE_TYPE_CAMPAIGN
  MESSAGING_TEMPLATE_TYPE_COMPANY_EMAIL_COMMON
  MESSAGING_TEMPLATE_TYPE_EMAIL_PREVIEW
  MESSAGING_TEMPLATE_TYPE_JOINED_A_SEGMENT
  MESSAGING_TEMPLATE_TYPE_JOURNEYS_BACK_IN_STOCK
  MESSAGING_TEMPLATE_TYPE_JOURNEYS_BROWSE_ABANDONER
  MESSAGING_TEMPLATE_TYPE_JOURNEYS_CART_ABANDONER
  MESSAGING_TEMPLATE_TYPE_JOURNEYS_CHECKOUT_ABANDONER
  MESSAGING_TEMPLATE_TYPE_JOURNEYS_POST_PURCHASE
  MESSAGING_TEMPLATE_TYPE_JOURNEYS_WELCOME_EMAIL
  MESSAGING_TEMPLATE_TYPE_JOURNEYS_WINBACK
  MESSAGING_TEMPLATE_TYPE_LOW_INVENTORY
  MESSAGING_TEMPLATE_TYPE_PRICE_DROP
  MESSAGING_TEMPLATE_TYPE_ROW
  MESSAGING_TEMPLATE_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/metricsapi/Contract.proto
type Metric {
  # Not present when any errors are encountered calculating the aggregate
  aggregateValue: Float
  definition: MetricDefinition!

  # List of data level errors detected when computing metric values, such as divisions by zero
  errors: [MetricDataError!]!
  groupedValues: [GroupedMetricValue!]!
}

enum MetricAggregationType {
  METRIC_AGGREGATION_TYPE_AVERAGE
  METRIC_AGGREGATION_TYPE_COUNT
  METRIC_AGGREGATION_TYPE_COUNT_DISTINCT
  METRIC_AGGREGATION_TYPE_POINT_IN_TIME_COUNT
  METRIC_AGGREGATION_TYPE_POINT_IN_TIME_SUM
  METRIC_AGGREGATION_TYPE_SUM
  METRIC_AGGREGATION_TYPE_UNKNOWN
}

enum MetricConnotation {
  METRIC_CONNOTATION_NEGATIVE
  METRIC_CONNOTATION_NEUTRAL
  METRIC_CONNOTATION_POSITIVE
  METRIC_CONNOTATION_UNKNOWN
}

# Generated from com/attentivemobile/contracts/metricsapi/Contract.proto
type MetricDataError {
  message: String!
  type: MetricDataErrorType!
}

enum MetricDataErrorType {
  METRIC_DATA_ERROR_TYPE_DIVIDE_BY_ZERO
  METRIC_DATA_ERROR_TYPE_NO_DATA
  METRIC_DATA_ERROR_TYPE_UNKNOWN
}

enum MetricDataType {
  METRIC_DATA_TYPE_CURRENCY
  METRIC_DATA_TYPE_NUMBER
  METRIC_DATA_TYPE_PERCENT
  METRIC_DATA_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/metricsapi/Contract.proto
type MetricDefinition {
  aggregationType: MetricAggregationType!
  connotation: MetricConnotation!
  dataType: MetricDataType!
  description: String!
  dimensionWrappers: [DimensionWrapper!]!

  # This field is not yet functional - use the dimensionWrappers field instead. Blocked by AP-1039
  dimensions: [Dimension!]! @deprecated(reason: "deprecated")
  domain: String!
  metricId: String!
  name: String!
}

# Generated from com/attentivemobile/contracts/metricsapi/Contract.proto
type MetricValue {
  errors: [MetricDataError!]!
  value: Float
}

# Generated from com/attentivemobile/contracts/metricsapi/Contract.proto
type MetricsCatalog {
  metrics: [MetricDefinition!]!
}

enum MetricsOrderByDirection {
  METRICS_ORDER_BY_DIRECTION_ASC
  METRICS_ORDER_BY_DIRECTION_DESC
  METRICS_ORDER_BY_DIRECTION_UNKNOWN
}

input MetricsOrdering {
  # Must be one of the dimensions provided as a grouping to the metrics table query.
  dimensionId: String
  direction: MetricsOrderByDirection!

  # Must be one of the metrics requested in the metrics table query.
  metricId: String
}

# Generated from com/attentivemobile/contracts/metricsapi/Contract.proto
type MetricsTableConnection {
  aggregateValues: [MetricValue!]!
  definitions: [MetricDefinition!]!
  edges: [GroupedMetricsEdge!]!
  pageInfo: PageInfo!
  totalCount: Long!
}

enum MfaOneTimePasscodeDeliveryMethod {
  MFA_ONE_TIME_PASSCODE_DELIVERY_METHOD_EMAIL
  MFA_ONE_TIME_PASSCODE_DELIVERY_METHOD_SMS
  MFA_ONE_TIME_PASSCODE_DELIVERY_METHOD_UNKNOWN
}

input MigrateCreativeContentAndCloneInput {
  companyId: ID!
  creativeId: ID!
  name: String!
}

union MigrateCreativeContentAndClonePayload = DefaultErrorFailure | MigrateCreativeContentAndCloneSuccess

# Generated from com/attentivemobile/contracts/creative-service/creative/CreativeContentService.proto
type MigrateCreativeContentAndCloneSuccess {
  creative: Creative
}

input MigrateDatastreamInput {
  datastreamName: String!
  pulsarClusterName: String
  runtime: MigrateDatastreamInputRuntime
  startMode: OperationMode!
  stopMode: OperationMode!
}

input MigrateDatastreamInputRuntime {
  newFlinkJob: FlinkJobWrite
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Contract.proto
type MigrateDatastreamPayload {
  newRuntime: MigrateDatastreamPayloadNewRuntime
  oldRuntime: MigrateDatastreamPayloadOldRuntime
  restorePath: String!
  result: OperationResult!
}

union MigrateDatastreamPayloadNewRuntime = MigrateFlinkJobResponse

union MigrateDatastreamPayloadOldRuntime = MigrateFlinkJobResponse

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Flink.proto
type MigrateFlinkJobResponse {
  applicationId: String!
  cluster: Cluster!
  entryClass: String!
  flinkDeploymentMode: FlinkDeploymentMode!
  jarName: String!
  jobId: String!
  jobManagerUrl: String!
}

enum MigrationStatus {
  MIGRATION_STATUS_COMPLETE
  MIGRATION_STATUS_INCOMPLETE
  MIGRATION_STATUS_UNKNOWN
}

# Generated from com/attentivemobile/contracts/company-service/onboarding/Milestone.proto
type Milestone {
  # the timestamp when the milestone was completed, will be null when a milestone is incomplete
  completed: DateTime
  description: String!

  # the unique key name of the milestone
  key: MilestoneKey!

  # the timestamp when the milestone was skipped, can be null if not skipped
  skipped: DateTime
}

enum MilestoneKey {
  # the user has added user accounts
  MILESTONE_KEY_ADDED_USER_ACCOUNTS

  # the user has completed the updating the company's brand styles
  MILESTONE_KEY_COMPLETED_BRAND_STYLES

  # the user has completed the company's onboarding form
  MILESTONE_KEY_COMPLETED_COMPANY_FORM

  # the user has completed the onboarding integrating survey
  MILESTONE_KEY_COMPLETED_INTEGRATION_SURVEY

  # the user has completed the onboarding process
  MILESTONE_KEY_COMPLETED_ONBOARDING

  # the user has completed the "Ready to Launch" creatives
  MILESTONE_KEY_COMPLETED_RTL_CREATIVE

  # the user has created a welcome journey
  MILESTONE_KEY_COMPLETED_WELCOME_JOURNEY

  # the user has reviewed the default offer
  MILESTONE_KEY_DEFAULT_OFFER_REVIEWED

  # the user has completed integrating with their ecommerce platform
  MILESTONE_KEY_ECOMMERCE_PLATFORM_COMPLETED

  # the user has clicked the CTA on the onboarding email
  MILESTONE_KEY_EMAIL_CTA_FIRST_CLICKED

  # an email was sent to the user to start the company onboarding process
  MILESTONE_KEY_EMAIL_FIRST_SENT

  # the user has completed integrating with their email provider platform
  MILESTONE_KEY_ESP_INTEGRATION_COMPLETED

  # the company has been imported from salesforce, represents the start of an onboarding process
  MILESTONE_KEY_IMPORTED_FROM_SALESFORCE

  # the user has installed the attentive tag
  MILESTONE_KEY_INSTALLED_TAG

  # Deprecated: this key is not used
  MILESTONE_KEY_SALESFORCE_CONVERTED_TO_PAYING

  # Deprecated: this key is not currently used
  MILESTONE_KEY_SALESFORCE_GO_LIVE
  MILESTONE_KEY_UNKNOWN
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type MissingEmailMetadata {
  metadata: String!
}

input MobileWalletCardInput {
  cardText: String!
  couponId: ID!
  expirationDate: DateTime!
  externalCampaignId: String
  externalOfferId: String!
  uuid: String
}

# Generated from com/attentivemobile/contracts/mobilewalletservice/Contract.proto
type MobileWalletCardPayload {
  mobileWalletUrl: String!
}

# Generated from com/attentivemobile/contracts/mobilewalletservice/Contract.proto
type MobileWalletChangePayload {
  status: String!
}

input MobileWalletSearchFilter {
  search: String
}

input MobileWalletSorting {
  direction: SortDirection
  sortBy: MobileWalletSortingField!
}

enum MobileWalletSortingField {
  MOBILE_WALLET_SORTING_FIELD_ID
  MOBILE_WALLET_SORTING_FIELD_UPDATED
}

# Generated from com/attentivemobile/contracts/productrecsservice/Contract.proto
type ModelMetadata {
  name: String!
  type: String!
  version: String!
}

# Generated from com/attentivemobile/contracts/common/Money.proto
# The price and currency of the product.
type Money {
  # The price of the product.
  amount: String!

  # The currency used for the price in ISO 4217 format.
  currency: CurrencyCode
}

# The price and currency of the product.
input MoneyInput {
  # The price of the product.
  amount: String!

  # The currency used for the price in ISO 4217 format.
  currency: CurrencyCode
}

input MoveAssetsInput {
  assetIds: [AssetId!]
  proceedOnDuplicate: Boolean
  targetParentId: ID
}

union MoveAssetsPayload = DefaultErrorFailure | MoveAssetsSuccess

# Generated from com/attentivemobile/contracts/asset/library/service/Contract.proto
type MoveAssetsSuccess {
  sourceFolder: Folder
  targetFolder: Folder
}

type Mutation {
  addBrandImage(input: AddBrandImageInput!): AddBrandImagePayload
  addCampaignMessageGroup(input: AddCampaignMessageGroupInput!): ComposeCampaignMessageGroupPayload
  addCompanyApplicationRouteRateLimit(input: UpdateCompanyApplicationRouteRateLimitInput!): UpdateCompanyApplicationRouteRateLimitPayload
  addCompanyGlobalRateLimit(input: UpdateCompanyGlobalRateLimitInput!): UpdateCompanyGlobalRateLimitPayload
  addConciergeCompanyPromotion(input: AddConciergeCompanyPromotionInput!): UpdateConciergeCompanyPromotionPayload
  addEmailIpAssignments(input: AddEmailIPAssignmentsInput!): AddEmailIPAssignmentsPayload
  addNewEtlConfig(input: AddNewEtlConfigInput!): AddNewEtlConfigPayload
  addRouteRateLimit(input: UpdateRouteRateLimitInput!): UpdateRouteRateLimitPayload

  # Make a call to this mutation when a user adds a product to their shopping cart.
  addToCartEvent(input: SendAddProductToCartEventInput!): SendAddProductToCartEventPayload
  addVariantToCampaignMessageGroup(input: AddVariantToCampaignMessageGroupInput!): ComposeCampaignMessageGroupPayload
  adminSetSftpUserPassword(input: AdminSetSftpUserPasswordInput!): AdminSetSftpUserPasswordPayload
  allocateCoupons(input: AllocateCouponsInput!): AllocateCouponsPayload
  applyBrandElementStyles(input: ApplyBrandElementStylesInput!): ApplyBrandElementStylesPayload
  applyBrandStyleRequest(input: ApplyBrandStyleInput!): ApplyBrandStylePayload
  archiveCreative(input: ArchiveCreativeInput!): ArchiveCreativePayload
  askGraphQuestion(input: AskGraphQuestionInput!): AskGraphQuestionPayload
  assignCreativesToJourney(input: AssignCreativesToJourneyInput!): AssignCreativesToJourneyPayload
  auditFloridaSubscribersV2(input: AuditFloridaSubscribersV2Input!): AuditFloridaSubscribersV2Payload
  auditSubscriberV2(input: AuditSubscriberV2Input!): AuditSubscriberV2Payload
  autoInstallTag(input: AutoInstallTagInput!): AutoInstallTagStatusPayload
  batchMigrateJobs(input: BatchMigrateJobsInput!): BatchMigrateJobsPayload
  bulkMigrateTagPresets(input: BulkMigrateTagPresetsInput!): BulkMigrateTagPresetsPayload
  bulkModifyTagExperiments(input: BulkModifyTagExperimentsInput!): BulkModifyTagExperimentsPayload
  bulkUpdateCompositionMessages(input: BulkUpdateCompositionMessagesInput!): BulkUpdateCompositionMessagesPayload
  calculateLiveSegmentCount(input: Empty!): SegmentCountPayload
  cancelSyncRun(input: CancelSyncRunInput!): CancelSyncRunPayload
  cancelUserFileDownload(input: CancelUserFileDownloadInput!): CancelUserFileDownloadPayload
  changeBrandImageName(input: ChangeBrandImageNameInput!): ChangeBrandImageNamePayload
  changeCompanyDomain(input: ChangeCompanyAttnDomainInput!): ChangeCompanyAttnDomainPayload
  churnAiProSubscription(input: ChurnAiProSubscriptionInput!): ChurnAiProSubscriptionPayload
  cloneCampaignMessageGroup(input: CloneCampaignMessageGroupInput!): ComposeCampaignMessageGroupPayload
  cloneComposeCampaign(input: CloneComposeCampaignInput!): ComposeCampaignPayload
  cloneCreative(input: CloneCreativeInput!): CloneCreativePayload
  cloneEdsJob(input: CloneEdsJobInput!): CloneEdsJobPayload
  cloneJourney(input: CloneJourneyInput!): CloneJourneyPayload
  clonePoll(input: ClonePollInput!): ClonePollPayload
  closeConciergeChat(input: CloseConciergeChatInput!): CloseConciergeChatPayload
  combineImages(input: CombineImagesInput!): CombineImagesPayload
  completeCompanyCreation(input: CompleteCompanyCreationRequest!): CompleteCompanyCreationPayload

  # marks a set of milestones as completed
  completeOnboardingMilestones(input: CompleteMilestonesRequest!): CompleteMilestonesResponse
  compressMMSImage(input: CompressMMSImageInput!): CompressMMSImageForUIPayload
  configureIntegrationSettings(input: UpdateIntegrationVendorSettingsInput!): ConfiguredIntegrationPayload
  confirmResetSftpUserPassword(input: ConfirmResetSftpUserPasswordInput!): ConfirmResetSftpUserPasswordPayload
  copyMessageRequest(input: CopyMessageInput!): CopyMessagePayload
  couponAssignment(input: CouponAssignmentInput!): CouponAssignmentPayload
  createAIBrandVoiceSettings(input: CreateAIBrandVoiceSettingsInput!): CreateAIBrandVoiceSettingsPayload @deprecated(reason: "deprecated")
  createAdminNotificationConfiguration(input: CreateAdminNotificationConfigurationInput!): CreateAdminNotificationConfigurationPayload
  createAgentTemplate(input: CreateAgentTemplateInput!): AgentTemplate
  createAgentTemplateRequest(input: CreateAgentTemplateRequestInput!): AgentTemplateRequest
  createAiGrowTrialEligibility(input: CreateAiGrowTrialEligibilityInput!): CreateAiGrowTrialEligibilityPayload
  createAiJourneysCompanyConfig(input: CreateCompanyConfigInput!): CreateCompanyConfigPayload
  createAiProTrialSubscription(input: CreateAiProTrialSubscriptionInput!): CreateAiProTrialSubscriptionPayload
  createAndReleaseTagConfig(input: CreateAndReleaseTagConfigInput!): CreateAndReleaseTagConfigPayload
  createApiSignUpCreative(input: CreateApiSignUpCreativeInput!): CreateApiSignUpCreativePayload
  createAutoJourney(input: CreateAutoJourneyInput!): CreateAutoJourneyPayload
  createAutoJourneyMessageId(input: CreateAutoJourneyMessageIdInput!): CreateAutoJourneyMessageIdPayload @deprecated(reason: "deprecated")
  createAutomationPrograms(input: CreateAutomationProgramsInput!): CreateAutomationProgramsPayload
  createBlockCompanyInfo(input: CreateBlockCompanyInfoInput!): CreateBlockCompanyInfoPayload
  createCompany(input: CreateCompanyInput!): CreateCompanyPayload
  createCompanyLink(input: CreateCompanyLinkInput!): CreateCompanyLinkPayload
  createCompanySettings(input: CreateCompanySettingsInput!): CreateCompanySettingsPayload
  createComposeCampaign(input: CreateComposeCampaignInput!): ComposeCampaignPayload
  createCouponAllocatorUpload(input: CreateCouponAllocatorUploadInput!): CreateCouponAllocatorUploadPayload
  createCouponSet(input: CreateCouponSetInput!): CreateCouponSetPayload
  createCreativeExperiment(input: CreateCreativeExperimentInput!): CreateCreativeExperimentPayload
  createCreativeRule(input: CreateCreativeRuleInput!): CreateCreativeRulePayload
  createCreativeRuleAssociation(input: CreateCreativeRuleAssociationInput!): CreateCreativeRuleAssociationPayload
  createCreativeSchedule(input: CreateCreativeScheduleAndMembersInput!): CreateOrUpdateCreativeSchedulePayload @deprecated(reason: "deprecated")
  createCreativeScheduleWorkflow(input: CreateCreativeScheduleWorkflowInput!): CreateCreativeScheduleWorkflowPayload
  createCustomCompanyKeyword(input: CreateCustomCompanyKeywordInput!): CreateCustomCompanyKeywordPayload

  # Use the Custom Events mutation to send user actions to use in the Attentive Segment Builder and Journey Builder. This data cannot contain any sensitive or special categories of information as defined in applicable data protection and privacy laws, including the California Consumer Privacy Act (CCPA) and California Privacy Rights Act (CPRA). See a list of specific categories of data you cannot share with Attentive [here](https://docs.attentivemobile.com/pages/legal-docs/pi-disclaimer/). Make a call to this mutation for any event-based data representing user actions.
  createCustomEvent(input: CreateCustomEventInput!): CreateCustomEventPayload
  createCustomJourney(input: CreateCustomJourneyInput!): CreateCustomJourneyPayload
  createCustomKeyword(input: CreateCustomKeywordInput!): CreateCustomKeywordPayload
  createCustomPropertyDefinition(input: CreateCustomPropertyDefinitionInput!): CreateCustomPropertyDefinitionPayload
  createCustomReport(input: CreateCustomReportInput!): CreateCustomReportPayload
  createDefaultWelcomeJourney(input: CreateDefaultWelcomeJourneyInput!): CreateDefaultWelcomeJourneyPayload
  createDismissibleModal(input: CreateDismissibleModalInput!): CreateDismissibleModalPayload
  createDomainSuppression(input: CreateDomainSuppressionInput!): CreateDomainSuppressionPayload
  createEdsJob(input: CreateEdsJobInput!): CreateEdsJobPayload
  createEdsJobMapping(input: CreateEdsJobMappingInput!): CreateEdsJobMappingPayload
  createEmailIpAddress(input: CreateEmailIPAddressInput!): CreateEmailIPAddressPayload
  createEmailIpPool(input: CreateEmailIPPoolInput!): CreateEmailIPPoolPayload
  createEntityTagAssignment(input: CreateEntityTagAssignmentInput!): EntityTagAssigmentPayload
  createEventReplay(input: CreateEventReplayInput!): CreateEventReplayPayload
  createEventReplayV2(input: CreateEventReplayV2Input!): CreateEventReplayV2Payload
  createFeatureFlag(input: CreateFeatureFlagInput!): CreateFeatureFlagPayload
  createFolder(input: CreateFolderInput!): CreateFolderPayload
  createGameTimePoll(input: CreateGameTimeInput!): CreateGameTimePollPayload
  createGapStoresCoupon(input: CreateGapStoresCouponInput!): CreateGapStoresCouponPayload
  createImage(input: CreateImageInput!): CreateImagePayload
  createIntegrationApplication(input: CreateIntegrationApplicationInput!): CreateIntegrationApplicationPayload
  createJourney(input: CreateJourneyInput!): CreateJourneyPayload
  createJourneyFromTemplate(input: CreateJourneyFromTemplateInput!): CreateJourneyFromTemplatePayload
  createJourneyRevision(input: CreateJourneyRevisionInput!): CreateJourneyRevisionPayload
  createJourneySchedule(input: CreateJourneyScheduleInput!): CreateJourneySchedulePayload
  createJourneyTrigger(input: CreateJourneyTriggerInput!): CreateJourneyTriggerPayload
  createMessageRequest(input: CreateMessageInput!): ComposeMessage
  createMessageTemplate(input: CreateMessageTemplateInput!): CreateMessageTemplatePayload
  createMobileWalletCard(input: CreateMobileWalletCardInput!): MobileWalletCardPayload
  createMobileWalletCardAndSend(input: CreateMobileWalletCardAndSendInput!): MobileWalletChangePayload
  createNBAConfig(input: CreateNBAConfigInput!): CreateNBAConfigPayload
  createNotificationBanner(input: CreateNotificationBannerInput!): CreateNotificationBannerPayload
  createNotificationEmailTemplate(input: CreateNotificationEmailTemplateInput!): CreateNotificationEmailTemplatePayload
  createOrUpdateUserContentSetting(input: CreateOrUpdateUserContentSettingInput!): CreateOrUpdateUserContentSettingPayload
  createOutboundSftpUser(input: CreateOutboundSftpUserInput!): CreateOutboundSftpUserPayload
  createPaymentMessages(input: CreatePaymentMessagesInput!): CreatePaymentMessagesPayload
  createPaymentsOffer(input: CreatePaymentsOfferInput!): CreatePaymentsOfferPayload
  createPoll(input: CreatePollInput!): CreatePollPayload
  createPrebuiltContent(input: CreatePrebuiltContentInput!): CreatePrebuiltContentPayload
  createPrebuiltProgram(input: CreatePrebuiltProgramInput!): CreatePrebuiltProgramPayload
  createProductSegment(input: CreateProductSegmentInput!): CreateProductSegmentPayload
  createProviderMigration(input: CreateProviderMigrationInput!): CreateProviderMigrationPayload
  createProviderMigrations(input: CreateProviderMigrationsInput!): CreateProviderMigrationsPayload
  createQueryParameterJob(input: CreateQueryParameterJobInput!): CreateQueryParameterJobPayload
  createRegion(input: CreateRegionInput!): CreateRegionPayload
  createSegmentEnhancementsWorkflow(input: CreateSegmentEnhancementsWorkflowInput!): CreateSegmentEnhancementsWorkflowPayload
  createSegmentFromSearchDefinition(input: CreateSegmentFromSearchDefinitionInput!): CreateSegmentFromSearchDefinitionPayload
  createSegmentFromSearchTemplate(input: CreateSegmentFromSearchTemplateInput!): CreateSegmentFromSearchTemplatePayload
  createSftpUser(input: CreateSftpUserInput!): CreateSftpUserPayload
  createShopifyAutoApplyFormat(input: CreateShopifyAutoApplyFormatsInput!): CreateShopifyAutoApplyFormatsPayload
  createShortLink(input: CreateShortLinkInput!): CreateShortLinkPayload
  createSync(input: CreateSyncInput!): CreateSyncPayload
  createTag(input: CreateTagInput!): TagPayload
  createUpdatePollOption(input: CreateUpdatePollOptionInput!): CreateUpdatePollOptionPayload
  createVendorCompanyCard(input: CreateVendorCompanyCardInput!): CreateVendorCompanyCardPayload
  createVideo(input: CreateVideoInput!): CreateVideoPayload
  createWebTemplate(input: CreateWebTemplateInput!): CreateWebTemplatePayload

  # Make an API call to this mutation to subscribe to a webhook. Events are a collection of strings of the following types: \`email.subscribed\`, \`sms.subscribed\`, \`email.unsubscribed\`, \`sms.sent\`, \`sms.message_link_click\`, \`custom_attribute.set\`. Event types are case sensitive. All events included will be sent to the URL.
  createWebhook(input: CreateWebhookInput!): CreateWebhookPayload
  createWorkloadConfig(input: CreateWorkloadConfigInput!): CreateWorkloadConfigPayload
  deactivateSync(input: DeactivateSyncInput!): DeactivateSyncPayload
  deescalateUserConversation(input: UserConversationDeescalationInput!): UserConversationDeescalationPayload
  deleteAccount(input: DeleteAccountInput!): DeleteAccountPayload
  deleteAdminNotificationConfiguration(input: DeleteAdminNotificationConfigurationInput!): DeleteAdminNotificationConfigurationPayload
  deleteAffiliateUser(input: DeleteAffiliateUserInput!): DeleteAffiliateUserPayload
  deleteAsset(input: DeleteAssetsInput!): DeleteAssetsPayload
  deleteAuthenticatorEnrollmentByEmail(input: DeleteAuthenticatorEnrollmentByEmailInput!): DeleteAuthenticatorEnrollmentByEmailPayload
  deleteBlockCompanyInfo(input: DeleteBlockCompanyInfoInput!): DeleteBlockCompanyInfoPayload
  deleteBrandImages(input: DeleteBrandImagesInput!): DeleteBrandImagesPayload
  deleteCampaignMessageGroup(input: DeleteCampaignMessageGroupInput!): ComposeCampaignPayload
  deleteCircuitBreakerRateLimits(input: DeleteCircuitBreakerRateLimitInput!): DeleteCircuitBreakerRateLimitsPayload
  deleteCompanyCodes(input: DeleteCompanyCodesInput!): DeleteCompanyCodesPayload
  deleteComposeCampaign(input: DeleteComposeCampaignInput!): DeleteComposeCampaignPayload
  deleteConciergeSpecialization(input: DeleteConciergeSpecializationInput!): DeleteConciergeSpecializationPayload
  deleteCouponSet(input: DeleteCouponSetInput!): DeleteCouponSetPayload
  deleteCouponUpload(input: DeleteCouponUploadInput!): DeleteCouponUploadPayload
  deleteCreativeRule(input: DeleteCreativeRuleInput!): DeleteCreativeRulePayload
  deleteCreativeRuleAssociation(input: DeleteCreativeRuleAssociationInput!): DeleteCreativeRuleAssociationPayload

  # Soft delete an upcoming schedule or complete a schedule if the schedule is running. A soft delete will return null.
  deleteCreativeSchedule(input: DeleteCreativeScheduleInput!): DeleteCreativeSchedulePayload @deprecated(reason: "deprecated")
  deleteCreativeScheduleWorkflow(input: DeleteCreativeScheduleWorkflowInput!): DeleteCreativeScheduleWorkflowPayload
  deleteCustomCompanyKeyword(input: DeleteCustomCompanyKeywordInput!): DeleteCustomCompanyKeywordPayload
  deleteCustomKeywords(input: DeleteCustomKeywordInput!): DeleteCustomKeywordPayload
  deleteCustomPropertyDefinition(input: DeleteCustomPropertyDefinitionInput!): DeleteCustomPropertyDefinitionPayload
  deleteCustomReport(input: DeleteCustomReportInput!): DeleteCustomReportPayload
  deleteDismissibleModal(input: DeleteDismissibleModalInput!): DeleteDismissibleModalPayload
  deleteEdsCronSchedule(input: DeleteEdsCronScheduleInput!): DeleteEdsCronSchedulePayload
  deleteEdsJob(input: DeleteEdsJobInput!): DeleteEdsJobPayload
  deleteJourney(input: DeleteJourneyInput!): DeleteJourneyPayload
  deleteJourneySchedule(input: DeleteJourneyScheduleInput!): DeleteJourneySchedulePayload
  deleteKeywordRule(input: DeleteKeywordRuleRequestInput!): DeleteKeywordRulePayload
  deleteMessageTemplate(input: DeleteMessageTemplateInput!): DeleteMessageTemplatePayload
  deletePollOption(input: DeletePollOptionInput!): DeletePollOptionPayload
  deleteSegments(input: DeleteSegmentsInput!): DeleteSegmentsPayload
  deleteShopifyAutoApplyFormat(input: DeleteShopifyAutoApplyFormatsInput!): DeleteShopifyAutoApplyFormatsPayload
  deleteSignup(input: DeleteSignupInput!): DeleteSignupPayload
  deleteSync(input: DeleteSyncInput!): DeleteSyncPayload
  deleteTag(input: DeleteTagInput!): TagPayload
  deleteUserContentSetting(input: DeleteUserContentSettingInput!): DeleteUserContentSettingPayload

  # Make an API call to this mutation to remove a webhook.
  deleteWebhook(input: DeleteWebhookInput!): DeleteWebhookPayload
  deleteWorkloadConfig(input: DeleteWorkloadConfigInput!): DeleteWorkloadConfigPayload
  deployServerSideTag(input: DeployServerSideTagInput!): DeployServerSideTagPayload
  descheduleWorkload(input: DescheduleWorkloadInput!): DescheduleWorkloadPayload
  disableAllLegacyScheduledReports(input: DisableAllLegacyScheduledReportsInput!): DisableAllLegacyScheduledReportsPayload
  disableAutomationProgram(input: DisableAutomationProgramInput!): DisableAutomationProgramPayload
  dismissNotification(input: DismissNotificationInput!): DismissNotificationPayload
  dismissNotificationBanner(input: DismissBannerInput!): DismissBannerPayload
  dispatchEdsCronJob(input: CronJobExecutionInput!): CronJobExecutionPayload
  downloadUsers(input: DownloadUsersInput!): DownloadUsersPayload
  editEdsJob(input: EditEdsJobInput!): EditEdsJobPayload
  editFeatureFlag(input: EditFeatureFlagInput!): EditFeatureFlagPayload
  emptyConciergeCart(input: EmptyCartInput!): EmptyConciergeCartPayload
  enableAutomationProgram(input: EnableAutomationProgramInput!): EnableAutomationProgramPayload
  enableCustomCompanyKeyword(input: EnableCustomCompanyKeywordInput!): EnableCustomCompanyKeywordPayload
  enableSendScheduledReportOverSftp(input: EnableSendScheduledReportOverSftpInput!): EnableSendScheduledReportOverSftpPayload
  enableStandardSftpReports(input: EnableStandardSftpReportsInput!): EnableStandardSftpReportsPayload
  endCreativeExperiment(input: EndCreativeExperimentInput!): EndCreativeExperimentPayload
  enqueueSuppressionJob(input: EnqueueSuppressionJobInput!): EnqueueSuppressionJobPayload
  enqueueUserFileDownload(input: EnqueueUserFileDownloadInput!): EnqueueUserFileDownloadPayload
  enterConciergeChat(input: EnterConciergeChatInput!): EnterConciergeChatPayload
  escalateUserConversation(input: UserConversationEscalationInput!): UserConversationEscalationPayload
  eventDestinationSftpSettings(input: ConfirmSftpSettingsInput!): ConfirmSftpSettingsPayload
  eventDestinationSnowflakeSettings(input: ConfirmSnowflakeSettingsInput!): ConfirmSnowflakeSettingsPayload
  exportSegment(input: ExportSegmentInput!): ExportSegmentPayload
  finishEnrollmentOfAuthenticator(input: FinishAuthenticatorEnrollmentInput!): FinishAuthenticatorEnrollmentPayload
  generateAIImage(input: GenerateAIImageInput!): GenerateAIImagePayload
  generateAIJourneyText(input: GenerateAIJourneyTextInput!): GenerateAIJourneyTextPayload
  generateAIText(input: GenerateAITextInput!): GenerateAITextPayload
  generateAITextV2(input: GenerateAITextV2Input!): GenerateAITextV2Payload
  generateChallengeForAdminAccess(input: GenerateChallengeForAdminAccessInput!): GenerateChallengeForAdminAccessPayload
  generateCouponCode(input: CouponAssignmentInput!): CouponAssignmentPayload @deprecated(reason: "deprecated")
  generateEmailTemplate(input: GenerateEmailTemplateInput!): GenerateEmailTemplatePayload
  generateHtmlPreviewRequest(input: HTMLPreviewInput!): HTMLPreviewPayload
  generateImage(input: GenerateImageInput!): GenerateImagePayload
  generateLayout(input: GenerateLayoutInput!): GenerateLayoutPayload
  generateMobileWalletAnalyticsReport(input: GenerateMobileWalletAnalyticsReportInput!): GenerateMobileWalletAnalyticsReportPayload
  generatePreSignedUrlForBulkOptOutJob(input: GeneratePreSignedUrlForBulkOptOutJobInput!): GeneratePreSignedUrlForBulkOptOutJobPayload
  generatePresignedS3Url(input: GeneratePresignedS3UrlInput!): GeneratePresignedS3UrlPayload
  generatePresignedUrl(input: GeneratePresignedUrlInput!): GeneratePresignedUrlPayload
  generateZendeskJwt(input: GenerateZendeskJwtInput!): GenerateZendeskJwtPayload
  getCronExpression(input: GetCronExpressionInput!): GetCronExpressionPayload
  getFilePublicUrl(input: GetFilePublicUrlInput!): GetFilePublicUrlPayload
  getPreSignedUrlForGiveawayTermsUpload(input: GiveawayTermsUploadInput!): GiveawayTermsUploadPayload
  giveMessageFeedback(input: GiveMessageFeedbackInput!): GiveMessageFeedbackPayload
  hideComposeCampaign(input: HideComposeCampaignInput!): HideComposeCampaignPayload
  importCompanyFromSalesforce(input: ImportCompanyFromSalesforceInput!): ImportCompanyFromSalesforcePayload
  initializeAuthenticatorEnrollment(input: InitializeAuthenticatorEnrollmentInput!): InitializeAuthenticatorEnrollmentPayload
  initiateCognitoAuth(input: InitiateCognitoAuthInput!): InitiateCognitoAuthPayload
  insertCompanyCodes(input: InsertCompanyCodesInput!): InsertCompanyCodesPayload
  insertCreativeAudit(input: InsertCreativeAuditInput!): InsertCreativeAuditPayload
  killWorkload(input: KillWorkloadInput!): KillWorkloadPayload
  leaveConciergeChat(input: LeaveConciergeChatInput!): LeaveConciergeChatPayload
  lockUserConversation(input: UserConversationLockInput!): UserConversationLockPayload
  loginWithMfaToken(input: LoginWithMfaTokenInput!): LoginWithMfaTokenPayload
  loginWithPassword(input: LoginWithPasswordInput!): LoginWithPasswordPayload
  makeCreative(input: MakeCreativeInput!): MakeCreativePayload
  markAITemplateAsLive(input: MarkAITemplateAsLiveInput!): MarkAITemplateAsLivePayload
  markClientConversationRead(input: MarkClientConversationReadInput!): MarkClientConversationReadPayload
  markFeedbackViewed(input: MarkFeedbackViewedInput!): MarkFeedbackViewedPayload

  # marks a set of milestones as skipped
  markMilestonesSkipped(input: MarkMilestonesSkippedRequest!): MarkMilestonesSkippedResponse
  migrateCreativeContentAndClone(input: MigrateCreativeContentAndCloneInput!): MigrateCreativeContentAndClonePayload
  migrateDatastream(input: MigrateDatastreamInput!): MigrateDatastreamPayload
  moveAsset(input: MoveAssetsInput!): MoveAssetsPayload
  nextAgentUser(input: NextAgentUserInput!): NextAgentUserPayload
  nudgeUserConversation(input: UserConversationNudgeInput!): UserConversationNudgePayload
  offboardPaymentCompany(input: OffboardPaymentCompanyInput!): OffboardPaymentCompanyPayload
  onboardCompanySendingDomain(input: OnboardCompanySendingDomainInput!): OnboardCompanySendingDomainPayload
  onboardPaymentCompany(input: OnboardPaymentCompanyInput!): OnboardPaymentCompanyPayload
  onboardSharedSendingDomain(input: OnboardSharedSendingDomainInput!): OnboardSharedSendingDomainPayload
  onboardSubdomain(input: OnboardSubdomainInput!): OnboardSubdomainPayload
  optOutUserByClient(input: OptOutUserByClientInput!): OptOutUserByClientPayload
  pauseAiProTrialSubscription(input: PauseAiProTrialSubscriptionInput!): PauseAiProTrialSubscriptionPayload
  pauseComposeCampaign(input: PauseComposeCampaignInput!): ComposeCampaignPayload
  pauseEdsCronJob(input: CronJobExecutionInput!): CronJobExecutionPayload
  pollGraphQuestion(input: PollGraphQuestionInput!): PollGraphQuestionPayload
  preScheduleComposeCampaign(input: PreScheduleComposeCampaignInput!): PreScheduleComposeCampaignPayload
  processCustomFonts(input: ProcessCustomFontsInput!): ProcessCustomFontsPayload
  processTypekit(input: ProcessTypekitInput!): ProcessTypekitPayload

  # Make a call to this mutation when a user generates an order or purchase.
  productPurchaseEvent(input: SendProductPurchaseEventInput!): SendProductPurchaseEventPayload

  # Make a call to this mutation when a user views a product.
  productViewEvent(input: SendProductViewEventInput!): SendProductViewEventPayload
  publishJourneyRevision(input: PublishJourneyRevisionInput!): PublishJourneyRevisionPayload
  queueTagAiStartCollectionEvent(input: QueueTagAiStartCollectionEventInput!): QueueTagAiStartCollectionEventPayload
  readNotification(input: ReadNotificationInput!): ReadNotificationPayload
  recoverDatastream(input: RecoverDatastreamInput!): RecoverDatastreamPayload
  redeemGapStoresCoupon(input: RedeemGapStoresCouponInput!): RedeemGapStoresCouponPayload
  refreshItemListPages(input: RefreshItemListPagesInput!): RefreshItemListPagesPayload
  refreshProgramSendTimes(input: RefreshProgramSendTimesInput!): RefreshProgramSendTimesPayload
  refreshSessionOrChallenge(input: RefreshSessionOrChallengeInput!): RefreshSessionOrChallengePayload
  refreshUserFileDownloadUrl(input: RefreshUserFileDownloadUrlInput!): RefreshUserFileDownloadUrlPayload
  removeAccountFromCompanies(input: RemoveAccountFromCompaniesInput!): RemoveAccountFromCompaniesPayload
  removeAccountFromCompany(input: RemoveAccountFromCompanyInput!): RemoveAccountFromCompanyPayload
  removeCompanyApplicationRouteRateLimit(input: DeleteCompanyApplicationRouteRateLimitInput!): DeleteCompanyApplicationRouteRateLimitPayload
  removeCompanyGlobalRateLimit(input: DeleteCompanyGlobalRateLimitInput!): DeleteCompanyGlobalRateLimitPayload
  removeConciergeCompanyPromotion(input: RemoveConciergeCompanyPromotionInput!): UpdateConciergeCompanyPromotionPayload
  removeDomainSuppression(input: RemoveDomainSuppressionInput!): RemoveDomainSuppressionPayload
  removeEmailIpAssignments(input: RemoveEmailIPAssignmentsInput!): RemoveEmailIPAssignmentsPayload
  removeFeatureFlag(input: RemoveFeatureFlagInput!): RemoveFeatureFlagPayload
  removeRegion(input: RemoveRegionInput!): RemoveRegionPayload
  removeRouteRateLimit(input: DeleteRouteRateLimitInput!): DeleteRouteRateLimitPayload
  removeTargetingData(input: RemoveTargetingDataInput!): RemoveTargetingDataPayload
  removeUserFromAgentQueue(input: RemoveUserFromAgentQueueInput!): RemoveUserFromAgentQueueResponse
  renameAsset(input: RenameAssetInput!): RenameAssetPayload
  replaceCompanyCodes(input: ReplaceCompanyCodesInput!): ReplaceCompanyCodesPayload
  replayByTime(input: ReplayByTimeInput!): ReplayByTimePayload
  replayByUUIDs(input: ReplayByUUIDsInput!): ReplayByUUIDsPayload
  resetAccountMfa(input: ResetAccountMfaInput!): ResetAccountMfaPayload
  resetPoll(input: ResetPollInput!): ResetPollPayload
  resetPrograms(input: ResetProgramsInput!): ResetProgramsPayload
  resetServerSideTagEventsApiKey(input: ResetServerSideTagEventsApiKeyInput!): ResetServerSideTagEventsApiKeyPayload
  resetSftpUserPassword(input: ResetSftpUserPasswordInput!): ResetSftpUserPasswordPayload
  resetSubscriber(input: ResetSubscriberInput!): ResetSubscriberPayload
  respondToCognitoAuthChallenge(input: RespondToCognitoAuthChallengeInput!): RespondToCognitoAuthChallengePayload
  restartAiProTrialSubscription(input: RestartAiProTrialSubscriptionInput!): RestartAiProTrialSubscriptionPayload
  resumeEdsCronJob(input: CronJobExecutionInput!): CronJobExecutionPayload
  revisePaymentsOffer(input: RevisePaymentsOfferInput!): RevisePaymentsOfferPayload
  revokeRolesByEmail(input: RevokeRolesByEmailInput!): RevokeRolesByEmailPayload
  runBackfill(input: RunBackfillInput!): RunBackfillPayload
  runScheduledReportBackfill(input: RunScheduledReportBackfillInput!): RunScheduledReportBackfillPayload
  runSftpReportBackfill(input: RunSftpReportBackfillInput!): RunSftpReportBackfillPayload
  saveAITemplate(input: SaveAITemplateInput!): SaveAITemplatePayload
  saveAutoJourneySettings(input: SaveAutoJourneySettingsInput!): SaveAutoJourneySettingsPayload
  saveAvailableBrandFonts(input: SaveAvailableBrandFontsInput!): SaveAvailableBrandFontsPayload
  saveConciergeCart(input: SaveConciergeCartInput!): SaveConciergeCartPayload
  saveConciergeSpecialization(input: SaveConciergeSpecializationInput!): SaveConciergeSpecializationPayload
  saveContactCard(input: SaveContactCardInput!): SaveContactCardPayload
  saveGeneratedTextAndFeedback(input: SaveGeneratedTextAndFeedbackInput!): SaveGeneratedTextAndFeedbackPayload
  saveMappingAndEnqueueBatchJob(input: SaveMappingAndEnqueueBatchJobInput!): SaveMappingAndEnqueueBatchJobPayload
  saveOnboardingSurveySelections(input: SaveOnboardingSurveySelectionsInput!): OnboardingSurveySelectionsPayload
  saveSelectedBrandFonts(input: SaveSelectedBrandFontsInput!): SaveSelectedBrandFontsPayload
  scheduleCompanyWarmupCampaigns(input: ScheduleWarmupCampaignInput!): ScheduleWarmupCampaignPayload
  scheduleComposeCampaign(input: ScheduleComposeCampaignInput!): ComposeCampaignPayload
  scheduleEdsCronJob(input: ScheduleEdsCronJobInput!): ScheduleEdsCronJobPayload
  scheduleWorkload(input: ScheduleWorkloadInput!): ScheduleWorkloadPayload
  sendAgentHeartbeat(input: Empty!): SendAgentHeartbeatPayload
  sendAgentMessage(input: SendAgentMessageInput!): SendMessagePayload
  sendApplicationWebhookTestEvent(input: SendApplicationWebhookTestEventInput!): SendApplicationWebhookTestEventPayload
  sendClientMessage(input: SendClientMessageInput!): SendClientMessagePayload
  sendEmailMessagePreview(input: SendEmailMessagePreviewRequestInput!): SendEmailMessagePreviewResponsePayload
  sendMessageToAudience(input: SendMessageToAudienceInput!): SendMessageToAudiencePayload
  sendMfaOneTimePasscode(input: SendMfaOneTimePasscodeInput!): SendMfaOneTimePasscodePayload
  sendPasswordResetEmail(input: SendPasswordResetEmailInput!): SendPasswordResetEmailPayload
  sendPhonyInboundMessage(input: SendPhonyInboundMessageInput!): SendPhonyInboundMessagePayload
  sendRbmMessagePreview(input: SendRbmMessagePreviewInput!): SendRbmMessagePreviewPayload
  sendRcsMessagePreview(input: SendRcsMessagePreviewInput!): SendRcsMessagePreviewPayload
  sendTextMessagePreview(input: SendTextMessagePreviewRequestInput!): SendTextMessagePreviewResponsePayload
  setAIBrandVoiceSettings(input: SetAIBrandVoiceSettingsInput!): SetAIBrandVoiceSettingsPayload
  setAIBrandVoiceSettingsV2(input: SetAIBrandVoiceSettingsV2Input!): SetAIBrandVoiceSettingsV2Payload
  setAIJourneyLinkConfiguration(input: SetAIJourneyLinkConfigurationInput!): SetAIJourneyLinkConfigurationPayload
  setAIJourneyLinkTrackingParams(input: SetAIJourneyLinkTrackingParamsInput!): SetAIJourneyLinkTrackingParamsPayload
  setAccountMfaPreference(input: SetAccountMfaPreferencesInput!): AccountMfaPreferencesPayload
  setAiBrandVoiceSummary(input: SetAiBrandVoiceSummaryInput!): SetAiBrandVoiceSummaryPayload
  setBrandColorScheme(input: SetBrandColorSchemeInput!): SetBrandColorSchemePayload
  setConciergeAgentChatAvailability(input: SetConciergeAgentAvailabilityInput!): SetConciergeAgentAvailabilityPayload

  # Use the Custom Attributes mutation to apply customizable data or characteristics to each of your subscribers. You can then build segments based on that information to send targeted campaigns and journeys. Make a call to this endpoint for any attribute-based data. There are no limits to the amount of custom attributes that can be created. Note that you can create net-new properties with this API, however, it cannot be used to create new values for an existing UI-created property name. If a property name is created through the Attentive platform, all possible property values must also be defined in the platform.
  setCustomAttributes(input: SetCustomAttributesInput!): SetCustomAttributesPayload
  setDefaultDomain(input: SetDefaultDomainInput!): SetDefaultDomainPayload
  setDefaultPermissionsForCompany(input: SetDefaultPermissionsForCompanyInput!): SetDefaultPermissionsForCompanyPayload
  setIpWarmupDate(input: SetEmailIPAddressWarmupDateInput!): SetEmailIPAddressWarmupDatePayload
  setPermissionsForAccount(input: SetPermissionsForAccountInput!): SetPermissionsForAccountPayload
  setSubscriberBlocklistStatusByAgent(input: SetSubscriberBlocklistStatusByAgentInput!): SetSubscriberBlocklistStatusByAgentPayload
  setSubscriberOptInStatusByAgent(input: SetSubscriberOptInStatusByAgentInput!): SetSubscriberOptInStatusByAgentPayload
  setSweepstakesFilter(input: SetSweepstakesFilterInput!): SetSweepstakesFilterPayload
  setTargetingData(input: SetTargetingDataInput!): SetTargetingDataPayload
  setupTemplatizedPostback(input: SetupTemplatizedPostbackInput!): SetupTemplatizedPostbackPayload
  setupTemplatizedPostbackSettings(input: TemplatizedPostbackSettingsInput!): TemplatizedPostbackSettingsPayload
  solutionsEmailOptOut(input: SolutionsEmailOptOutInput!): SolutionsEmailOptOutPayload
  solutionsPhoneOptOut(input: SolutionsPhoneOptOutInput!): SolutionsPhoneOptOutPayload

  # Exposes UpdateMessageLinkTarget (owned by Content) to the graph with additional authentication/validation
  solutionsUpdateMessageLinkTarget(input: SolutionsUpdateMessageLinkTargetInput!): SolutionsUpdateMessageLinkTargetPayload
  startAiProTrialSubscription(input: StartAiProTrialSubscriptionInput!): StartAiProTrialSubscriptionPayload
  startDatastream(input: StartDatastreamInput!): StartDatastreamPayload
  stopDatastream(input: StopDatastreamInput!): StopDatastreamPayload
  streamingReplay(input: StreamingReplayInput!): StreamingReplayPayload
  submitGeneratedTextFeedback(input: SubmitGeneratedTextFeedbackInput!): SubmitGeneratedTextFeedbackPayload
  submitGenerativeAIOptIn(input: SubmitGenerativeAIOptInInput!): SubmitGenerativeAIOptInPayload

  # Make a call to this mutation to opt-in a user to a subscription. Note that a legal disclosure is required when a user is opted-in programmatically. Requests must contain either a sign up source id or both a locale and a subscription type. For transactional messages, you must include a transactional opt-in unit. By default, if a subscription already exists, it will try and record the attempt to create the subscription again. For TEXT subscriptions, this will result in a message being sent to the person indicating that they are already subscribed.
  subscribe(input: SubscribeInput!): SubscribePayload
  swapCreativeExternalName(input: SwapCreativeExternalNameInput!): SwapCreativeExternalNamePayload
  takeActionOnFeedback(input: TakeActionOnFeedbackInput!): TakeActionOnFeedbackPayload
  toggleArchivePoll(input: ToggleArchivePollInput!): ToggleArchivePollPayload
  toggleCreativeTemplate(input: ToggleCreativeTemplateInput!): ToggleCreativeTemplatePayload
  toggleLegacyReport(input: ToggleLegacyReportInput!): ToggleLegacyReportPayload
  toggleLegacyScheduledReport(input: ToggleLegacyScheduledReportInput!): ToggleLegacyScheduledReportPayload
  toggleMfaForCompany(input: ToggleMfaForCompanyInput!): ToggleMfaForCompanyPayload
  triggerGenerateMessageWorkflow(input: TriggerGenerateMessageWorkflowInput!): TriggerGenerateMessageWorkflowPayload
  triggerIpDomainVerification(input: TriggerEmailIPAddressDomainVerificationInput!): TriggerEmailIPAddressDomainVerificationPayload
  triggerJourneyPreview(input: TriggerJourneyPreviewInput!): TriggerJourneyPreviewPayload
  triggerWorkload(input: TriggerWorkloadInput!): TriggerWorkloadPayload
  turnOffTagAiCollection(input: TurnOffTagAiCollectionAndReleaseTagConfigInput!): TurnOffTagAiCollectionAndReleaseTagConfigPayload
  turnOnTagAiCollection(input: TurnOnTagAiCollectionAndReleaseTagConfigInput!): TurnOnTagAiCollectionAndReleaseTagConfigPayload
  unarchiveCreative(input: UnarchiveCreativeInput!): UnarchiveCreativePayload
  unassignCreativesFromJourney(input: UnassignCreativesFromJourneyInput!): UnassignCreativesFromJourneyPayload
  uninstallTag(input: UninstallTagInput!): AutoInstallTagStatusPayload
  unlockUserConversation(input: UserConversationUnlockInput!): UserConversationUnlockPayload
  unscheduleEdsCronJob(input: UnscheduleEdsCronJobInput!): UnscheduleEdsCronJobPayload

  # Make a call to this mutation to unsubscribe a user from a subscription type or channel. If no subscriptions are present in the request, the user is unsubscribed from all subscriptions. If subscriptions are present in the request, the user is unsubscribed from the requested type or channel combination. By default, if a subscription exists, but the user is already unsubscribed, it records the attempt to unsubscribe the subscription again. For TEXT subscriptions, a message is sent to the person indicating that they are unsubscribed.
  unsubscribe(input: UnsubscribeInput!): UnsubscribePayload
  updateAIBrandVoiceSettings(input: UpdateAIBrandVoiceSettingsInput!): UpdateAIBrandVoiceSettingsPayload
  updateAIBrandVoiceSettingsV2(input: UpdateAIBrandVoiceSettingsV2Input!): UpdateAIBrandVoiceSettingsV2Payload
  updateAdminNotificationConfiguration(input: UpdateAdminNotificationConfigurationInput!): UpdateAdminNotificationConfigurationPayload
  updateAgentTemplate(input: UpdateRequest!): AgentTemplate
  updateAgentTemplateRequest(input: UpdateAgentTemplateRequestInput!): AgentTemplateRequest
  updateAiGrowTrialEligibility(input: UpdateAiGrowTrialEligibilityInput!): UpdateAiGrowTrialEligibilityPayload
  updateApplicationWebhook(input: UpdateApplicationWebhookInput!): UpdateApplicationWebhookPayload
  updateAttributionSettings(input: UpdateAttributionSettingsInput!): UpdateAttributionSettingsPayload
  updateAutoJourney(input: UpdateAutoJourneyInput!): UpdateAutoJourneysPayload
  updateAutomationProgram(input: UpdateAutomationProgramInput!): UpdateAutomationProgramPayload
  updateBlockCompanyInfo(input: UpdateBlockCompanyInfoInput!): UpdateBlockCompanyInfoPayload
  updateCampaignMessageGroup(input: UpdateCampaignMessageGroupInput!): ComposeCampaignMessageGroupPayload
  updateCircuitBreakerRateLimits(input: UpdateCircuitBreakerRateLimitInput!): UpdateCircuitBreakerRateLimitPayload
  updateCompanyBrandVoice(input: UpdateCompanyBrandVoiceInput!): UpdateCompanyBrandVoicePayload
  updateCompanyEmailIntegration(input: UpdateCompanyEmailIntegrationInput!): UpdateCompanyEmailIntegrationPayload
  updateCompanyLinkParameters(input: UpdateCompanyLinkParametersInput!): UpdateCompanyLinkParametersPayload
  updateCompanyRegionLocalizedSettings(input: UpdateCompanyRegionLocalizedSettingsInput!): UpdateCompanyRegionLocalizedSettingsPayload
  updateCompanySettings(input: UpdateCompanySettingsInput!): UpdateCompanySettingsPayload
  updateCompanyV2(input: UpdateCompanyV2Input!): UpdateCompanyV2Payload
  updateCompanyVendorIntegrationStatus(input: UpdateCompanyVendorIntegrationStatusInput!): UpdateCompanyVendorIntegrationStatusPayload
  updateComposeCampaign(input: UpdateComposeCampaignInput!): ComposeCampaignPayload
  updateCompositionMessageRequest(input: UpdateCompositionMessageInput!): UpdateCompositionMessagePayload
  updateConciergeCompanyNotes(input: UpdateConciergeCompanyNotesInput!): UpdateConciergeCompanyNotesPayload
  updateConciergeCompanyPromotion(input: UpdateConciergeCompanyPromotionInput!): UpdateConciergeCompanyPromotionPayload
  updateConciergeJourneyControls(input: UpdateConciergeJourneyControlsInput!): UpdateConciergeJourneyControlsPayload
  updateConciergeSelfServeSettingsAgentPreferences(input: UpdateConciergeSelfServeSettingsAgentPreferencesInput!): UpdateConciergeSelfServeSettingsPayload
  updateConciergeSelfServeSettingsCustomerSupport(input: UpdateConciergeSelfServeSettingsCustomerSupportInput!): UpdateConciergeSelfServeSettingsPayload
  updateConciergeSelfServeSettingsJourneys(input: UpdateConciergeSelfServeSettingsJourneysInput!): UpdateConciergeSelfServeSettingsJourneysPayload
  updateConciergeSelfServeSettingsShipping(input: UpdateConciergeSelfServeSettingsShippingInput!): UpdateConciergeSelfServeSettingsPayload
  updateConciergeSelfServeSettingsSignUp(input: UpdateConciergeSelfServeSettingsSignUpInput!): UpdateConciergeSelfServeSettingsPayload
  updateConciergeSelfServeSettingsSubmission(input: UpdateConciergeSelfServeSettingsSubmissionInput!): UpdateConciergeSelfServeSettingsPayload
  updateConciergeSettings(input: UpdateConciergeSettingsInput!): UpdateConciergeSettingsPayload
  updateConversationsSettings(input: UpdateConversationsSettingsInput!): UpdateConversationsSettingsPayload
  updateCouponSet(input: UpdateCouponSetInput!): UpdateCouponSetPayload
  updateCouponUpload(input: UpdateCouponUploadInput!): UpdateCouponUploadPayload
  updateCreative(input: UpdateCreativeInput!): UpdateCreativePayload
  updateCreativeContent(input: UpdateCreativeContentInput!): UpdateCreativeContentPayload
  updateCreativeExperimentTrafficSplit(input: UpdateCreativeExperimentTrafficSplitInput!): UpdateCreativeExperimentTrafficSplitPayload
  updateCreativeRule(input: UpdateCreativeRuleInput!): UpdateCreativeRulePayload
  updateCreativeSchedule(input: UpdateCreativeScheduleAndMembersInput!): CreateOrUpdateCreativeSchedulePayload @deprecated(reason: "deprecated")
  updateCreativeScheduleWorkflow(input: UpdateCreativeScheduleWorkflowInput!): UpdateCreativeScheduleWorkflowPayload
  updateCustomCompanyKeyword(input: UpdateCustomCompanyKeywordInput!): UpdateCustomCompanyKeywordPayload
  updateCustomEventKey(input: UpdateCustomEventKeyInput!): UpdateCustomEventKeyPayload
  updateCustomKeyword(input: UpdateCustomKeywordInput!): UpdateCustomKeywordPayload
  updateCustomPropertyDefinition(input: UpdateCustomPropertyDefinitionInput!): UpdateCustomPropertyDefinitionPayload
  updateCustomReport(input: UpdateCustomReportInput!): UpdateCustomReportPayload
  updateDatastreamImpactMetadata(input: UpdateImpactMetadataInput!): UpdateImpactMetadataPayload
  updateDatastreamParallelism(input: UpdateDatastreamParallelismInput!): UpdateDatastreamParallelismPayload
  updateDatastreamsParallelism(input: UpdateDatastreamsParallelismInput!): UpdateDatastreamsParallelismPayload
  updateDismissibleModal(input: UpdateDismissibleModalInput!): UpdateDismissibleModalPayload
  updateEmailAccountPools(input: UpdateEmailAccountPoolsInput!): UpdateEmailAccountPayload
  updateEmailIpAddress(input: UpdateEmailIPAddressInput!): UpdateEmailIPAddressPayload
  updateEmailIpPool(input: UpdateEmailIPPoolInput!): UpdateEmailIPPoolPayload
  updateEmailSettings(input: UpdateEmailSettingsInput!): UpdateEmailSettingsPayload
  updateEncryption(input: UpdateEncryptionInput!): UpdateEncryptionPayload
  updateEscalationEmails(input: UpdateEscalationEmailsInput!): UpdateEscalationEmailsPayload
  updateEtlConfigCompanies(input: UpdateEtlConfigCompaniesInput!): UpdateEtlConfigCompaniesPayload
  updateEventReplayToPending(input: ChangeEventReplayInput!): ChangeEventReplayPayload
  updateEventReplayToPendingV2(input: ChangeEventReplayV2Input!): ChangeEventReplayV2Payload
  updateEventReplayToQueued(input: ChangeEventReplayInput!): ChangeEventReplayPayload
  updateEventReplayToQueuedV2(input: ChangeEventReplayV2Input!): ChangeEventReplayV2Payload
  updateEventReplayToReady(input: ChangeEventReplayInput!): ChangeEventReplayPayload
  updateEventReplayToReadyV2(input: ChangeEventReplayV2Input!): ChangeEventReplayV2Payload
  updateFilter(input: UpdateFilterInput!): UpdateFilterPayload
  updateIntegrationApplication(input: UpdateIntegrationApplicationInput!): UpdateIntegrationApplicationPayload
  updateJourney(input: UpdateJourneyInput!): UpdateJourneyPayload
  updateJourneyDraftRevisionApprovalStatus(input: UpdateJourneyRevisionApprovalStatusInput!): UpdateJourneyRevisionApprovalStatusPayload
  updateJourneyRevision(input: UpdateJourneyRevisionInput!): UpdateJourneyRevisionPayload
  updateJourneySchedule(input: UpdateJourneyScheduleInput!): UpdateJourneySchedulePayload
  updateJourneyStatus(input: UpdateJourneyStatusInput!): UpdateJourneyStatusPayload
  updateJourneyTrigger(input: UpdateJourneyTriggerInput!): UpdateJourneyTriggerPayload
  updateJourneyTriggerStatus(input: UpdateJourneyTriggerStatusInput!): UpdateJourneyTriggerStatusPayload
  updateLegacyScheduledReportRecipient(input: UpdateLegacyScheduledReportRecipientInput!): UpdateLegacyScheduledReportRecipientPayload
  updateLegacyTagConfig(input: UpdateLegacyTagConfigInput!): UpdateLegacyTagConfigPayload
  updateMessageRequest(input: UpdateMessageInput!): ComposeMessage
  updateMessageTemplate(input: UpdateMessageTemplateInput!): UpdateMessageTemplatePayload
  updateMobileWalletCard(input: UpdateMobileWalletCardInput!): MobileWalletChangePayload
  updateNBAConfig(input: UpdateNBAConfigInput!): UpdateNBAConfigPayload
  updateNotificationBanner(input: UpdateNotificationBannerInput!): UpdateNotificationBannerPayload
  updateNotificationEmailTemplate(input: UpdateNotificationEmailTemplateInput!): UpdateNotificationEmailTemplatePayload
  updatePollStatus(input: UpdatePollStatusInput!): UpdatePollStatusPayload
  updatePollV2(input: UpdatePollV2Input!): UpdatePollV2Payload
  updatePrebuiltContent(input: UpdatePrebuiltContentInput!): UpdatePrebuiltContentPayload
  updatePrebuiltProgram(input: UpdatePrebuiltProgramInput!): UpdatePrebuiltProgramPayload
  updateProductSegment(input: UpdateProductSegmentInput!): UpdateProductSegmentPayload
  updateProviderMigration(input: UpdateProviderMigrationInput!): UpdateProviderMigrationPayload
  updateProviderMigrations(input: CreateProviderMigrationsInput!): UpdateProviderMigrationsPayload
  updateRateLimit(input: UpdateRateLimitInput!): UpdateRateLimitPayload
  updateRegion(input: UpdateRegionInput!): UpdateRegionPayload
  updateRevisionStep(input: UpdateRevisionStepInput!): UpdateRevisionStepPayload
  updateSegmentFromSearchDefinition(input: UpdateSegmentFromSearchDefinitionInput!): UpdateSegmentFromSearchDefinitionPayload
  updateSendScheduledReportOverSftp(input: UpdateSendScheduledReportOverSftpInput!): UpdateSendScheduledReportOverSftpPayload
  updateSftpUserEmail(input: UpdateSftpUserEmailInput!): UpdateSftpUserEmailPayload
  updateShopifyAutoApplyFormat(input: UpdateShopifyAutoApplyFormatsInput!): UpdateShopifyAutoApplyFormatsPayload
  updateSync(input: UpdateSyncInput!): UpdateSyncPayload
  updateTag(input: UpdateTagInput!): TagPayload
  updateWebTemplate(input: UpdateWebTemplateInput!): UpdateWebTemplatePayload

  # Make an API call to this endpoint to update a webhook.
  updateWebhook(input: UpdateWebhookInput!): UpdateWebhookPayload
  updateWorkloadConfig(input: UpdateWorkloadConfigInput!): UpdateWorkloadConfigPayload
  uploadManualSegment(input: UploadManualSegmentInput!): UploadManualSegmentPayload
  upsertAIJourneyDefaultMessage(input: UpsertAIJourneyDefaultMessageInput!): UpsertAIJourneyDefaultMessagePayload
  upsertAccount(input: UpsertAccountInput!): UpsertAccountPayload
  upsertAccountPermissions(input: UpsertAccountPermissionsInput!): UpsertAccountPermissionsPayload
  upsertAffiliateUser(input: UpsertAffiliateUserInput!): UpsertAffiliateUserPayload
  upsertClientSegmentImport(input: UpsertClientSegmentImportInput!): UpsertClientSegmentImportPayload
  upsertEdsTaskProcessingConfig(input: UpsertEdsTaskProcessingConfigInput!): UpsertEdsTaskProcessingConfigPayload
  upsertKeywordRule(input: UpsertKeywordRuleRequestInput!): UpsertKeywordRulePayload
  upsertPollKeyword(input: UpsertPollKeywordInput!): UpsertPollKeywordPayload
  validateFieldMappingForSubscriberUploadFile(input: ValidateMappingForFileInput!): ValidateMappingForFilePayload
  verifyChallenge(input: VerifyChallengeInput!): VerifyChallengePayload
  verifyChallengeForAdminAccess(input: VerifyChallengeForAdminAccessInput!): VerifyChallengeForAdminAccessPayload
  verifyMfaOneTimePasscode(input: VerifyMfaOneTimePasscodeInput!): VerifyMfaOneTimePasscodePayload
  verifySendingDomain(input: VerifySendingDomainInput!): VerifySendingDomainPayload
  verifyServerSideTagDnsRecords(input: VerifyServerSideTagDnsRecordsInput!): VerifyServerSideTagDnsRecordsPayload
  verifySubdomain(input: VerifySubdomainInput!): VerifySubdomainPayload
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Transformation.proto
type NeedEnrichment {
  dependentFields: [DependentField!]!
  expression: String!
}

input NewAttachmentRequest {
  attachmentContextRequest: AttachmentBodyProperties!
  attachmentType: AttachmentType!
}

# Generated from com/attentivemobile/contracts/lift/service/Contract.proto
type NewRevenueModel {
  optInTimestamp: DateTime!
  status: NewRevenueModelStatus!
}

input NewRevenueModelInput {
  optInTimestamp: DateTime!
  status: NewRevenueModelStatus!
}

enum NewRevenueModelStatus {
  NEW_REVENUE_MODEL_STATUS_CANDIDATE
  NEW_REVENUE_MODEL_STATUS_DISABLED
  NEW_REVENUE_MODEL_STATUS_ENABLED
  NEW_REVENUE_MODEL_STATUS_UNKNOWN
  NEW_REVENUE_MODEL_STATUS_UNSUPPORTED
}

input NextAgentUserInput {
  userId: ID
}

# Generated from com/attentivemobile/contracts/concierge/service/Contract.proto
type NextAgentUserPayload {
  prevUser: User
  user: User
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type NextBestAction implements Node {
  category: NextBestActionCategory!
  ctaUrl: String!
  description: String!
  externalId: String!
  id: ID!
  imageUrl: String!
  infoUrl: String!
  isFeatured: Boolean!
  name: String!
  pill: String!
  priority: Long!
  title: String!
  type: NextBestActionDisplayType!
}

enum NextBestActionCategory {
  NBA_CATEGORY_DRIVE_ROI
  NBA_CATEGORY_GROW_YOUR_LIST
  NBA_CATEGORY_INTEGRATIONS
  NBA_CATEGORY_MAXIMIZE_REVENUE
  NBA_CATEGORY_UNKNOWN
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type NextBestActionConfiguration implements Node {
  category: NextBestActionCategory!
  ctaUrl: String!
  description: String!
  id: ID!
  imageUrl: String!
  infoUrl: String!
  isFeatured: Boolean!
  priority: Long!
  smartRecommendationKey: String!
  startTime: DateTime!
  title: String!
  type: NextBestActionDisplayType!
}

input NextBestActionConfigurationInput {
  category: NextBestActionCategory!
  ctaUrl: String!
  description: String!
  imageUrl: String!
  infoUrl: String!
  isFeatured: Boolean!
  priority: Long!
  smartRecommendationKey: String
  startTime: DateTime!
  title: String!
  type: NextBestActionDisplayType!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type NextBestActionConnection {
  edges: [NextBestActionEdge!]!
  pageInfo: PageInfo!
}

enum NextBestActionDisplayType {
  NBA_DISPLAY_TYPE_SIMPLE_PROMPT
  NBA_DISPLAY_TYPE_UNKNOWN
  NBA_DISPLAY_TYPE_VIDEO_PROMPT
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type NextBestActionEdge {
  cursor: String!
  node: NextBestAction!
}

interface Node {
  id: ID!
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type NodeCollection implements Node {
  author: Account
  authorId: Long!
  created: DateTime!
  description: String!
  id: ID!
  image: String!
  name: String!
  updated: DateTime!
  uuid: String!
  visibilityTypeId: Long!
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type NodeCollectionConnection {
  edges: [NodeCollectionEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type NodeCollectionEdge {
  cursor: String!
  node: NodeCollection!
}

# Generated from com/attentivemobile/contracts/journeys/api/RevisionStep.proto
type NonAdderStep {
  type: NonAdderStepType
}

union NonAdderStepType = CreateSupportTicketStep | DelayStep | ExperimentStep | ForwardToConciergeStep | IncomingTextStep | SaveUserAttributeStep | VariantStep

# Generated from com/attentivemobile/contracts/segment/recommendation/service/Contract.proto
type NotFoundSegmentEnhancementWorkflowStatus {
  company: Company
  workflowId: String!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type Notification implements Node {
  callToAction: String!
  description: String!
  dismissable: Boolean!
  eventDate: String!
  id: ID!
  name: String!
  read: Boolean!
  severity: String!
  title: String!
  type: String!
  url: String!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type NotificationBanner implements Node {
  callToAction: String!
  description: String!
  eventDate: String!
  id: ID!
  startTime: DateTime!
  stopTime: DateTime!
  title: String!
  url: String!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type NotificationBannerConnection {
  edges: [NotificationBannerEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type NotificationBannerEdge {
  cursor: String!
  node: NotificationBanner!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type NotificationConnection {
  edges: [NotificationEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type NotificationEdge {
  cursor: String!
  node: Notification!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type NotificationEmailTemplate implements Node {
  emailBody: String!
  id: ID!
  messagingTemplateId: String!
  subject: String!
  templatingSyntaxType: TemplatingSyntaxType!
}

input NotificationEmailTemplateInput {
  emailBody: String
  messagingTemplateId: String
  subject: String
  templatingSyntaxType: TemplatingSyntaxType
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type NotificationFilter implements Node {
  cooldownAllInstances: Boolean!
  dismissalCooldownSeconds: Long!
  emailCooldownSeconds: Long!
  emailDelaySeconds: Long
  featureFlag: String!
  id: ID!
  notificationId: String!
  smartRecommendationKey: String!
  startTime: DateTime!
  stopTime: DateTime!
}

input NotificationFilterInput {
  cooldownAllInstances: Boolean
  dismissalCooldownSeconds: Long
  emailCooldownSeconds: Long
  emailDelaySeconds: Long
  featureFlag: String
  filterId: ID!
  notificationId: String
  smartRecommendationKey: String
  startTime: DateTime
  stopTime: DateTime
}

enum NotificationType {
  NOTIFICATION_TYPE_ALERTS
  NOTIFICATION_TYPE_ALL
  NOTIFICATION_TYPE_FEATURED
  NOTIFICATION_TYPE_MARKETING
  NOTIFICATION_TYPE_NEXT_BEST_ACTION
  NOTIFICATION_TYPE_PERFORMANCE
  NOTIFICATION_TYPE_PRODUCT_CONTENT
  NOTIFICATION_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/ComparisonTypes.proto
type NumericComparison {
  comparator: SearchNumericComparator!
  endValue: Float
  isNot: Boolean
  values: [Float!]!
}

input NumericComparisonInput {
  comparator: SearchNumericComparator!
  endValue: Float
  isNot: Boolean
  values: [Float!]
}

input NumericDatasetFilter {
  operator: NumericFilterOperator!
  values: [Float!]
}

# Generated from com/attentivemobile/contracts/metricsapi/DatasetsContract.proto
type NumericDatasetValue {
  value: Float!
}

enum NumericFilterOperator {
  NUMERIC_FILTER_OPERATOR_IN
  NUMERIC_FILTER_OPERATOR_NOT_IN
  NUMERIC_FILTER_OPERATOR_UNKNOWN
}

input OffboardPaymentCompanyInput {
  companyId: ID!
}

# Generated from com/attentivemobile/contracts/payments/v2/Contract.proto
type OffboardPaymentCompanyPayload {
  status: PaymentCompanyStateChangeStatus!
}

# Generated from com/attentivemobile/contracts/composition/MessageAttachment.proto
type OfferAttachment {
  entityToken: String!
  offer: CouponSet
}

input OfferAttachmentProperties {
  couponSetId: ID!
  entityToken: String!
}

enum OfferDiscountType {
  DISCOUNT_TYPE_CODE
  DISCOUNT_TYPE_DOLLAR_AMOUNT
  DISCOUNT_TYPE_PERCENTAGE
  DISCOUNT_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/messaging/automation/aijourneys/onboarding/AIJourneyLinkConfiguration.proto
type OfferLinkConfig {
  # This field is ignored. Included to maintain compatibility with the graph.
  emptyUrl: String
}

input OfferLinkConfigInput {
  # This field is ignored. Included to maintain compatibility with the graph.
  emptyUrl: String
}

input OnboardCompanySendingDomainInput {
  companyId: ID!
  domain: String!
  fromAddress: String!
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type OnboardCompanySendingDomainPayload {
  sendingDomain: SendingDomain!
}

input OnboardPaymentCompanyInput {
  companyId: ID!
  disableOrderConfirmMessages: Boolean!
}

# Generated from com/attentivemobile/contracts/payments/v2/Contract.proto
type OnboardPaymentCompanyPayload {
  status: PaymentCompanyStateChangeStatus!
}

input OnboardSharedSendingDomainInput {
  companyId: ID!
  fromAddress: String!
  sharedSendingDomainName: String
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type OnboardSharedSendingDomainPayload {
  emailAccount: EmailAccount!
}

input OnboardSubdomainInput {
  companyId: ID!
  subdomain: String!
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type OnboardSubdomainPayload {
  sendingDomain: SendingDomain!
}

# Generated from com/attentivemobile/contracts/company-service/onboarding/Survey.proto
type OnboardingSurveySelection {
  displayName: String!
  optionKey: String
  selected: Boolean!
  surveyOptionId: Long!
  type: OnboardingSurveySelectionType!
}

input OnboardingSurveySelectionInput {
  displayName: String!
  optionKey: String
  selected: Boolean!
  surveyOptionId: Long!
  type: OnboardingSurveySelectionType!
}

enum OnboardingSurveySelectionType {
  ONBOARDING_SURVEY_SELECTION_TYPE_ECOMMERCE_PLATFORM
  ONBOARDING_SURVEY_SELECTION_TYPE_EMAIL_PROVIDER
  ONBOARDING_SURVEY_SELECTION_TYPE_OTHER_SERVICES
  ONBOARDING_SURVEY_SELECTION_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/company-service/onboarding/Survey.proto
type OnboardingSurveySelectionsPayload {
  company: Company
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type OneTime {
  runsImmediately: Boolean!
}

input OneTimeInput {
  runsImmediately: Boolean!
}

enum OpenAIModel {
  OPENAI_MODEL_GPT_3_5_TURBO
  OPENAI_MODEL_GPT_4
  OPENAI_MODEL_GPT_4_300_TEST
  OPENAI_MODEL_GPT_4_O
  OPENAI_MODEL_GPT_4_TURBO
  OPENAI_MODEL_UNKNOWN
  OPEN_AI_MODEL_GPT_4_0_MINI
}

input OpenAIOptions {
  model: OpenAIModel!
}

enum OperationMode {
  OPERATION_MODE_CHECKPOINT
  OPERATION_MODE_NONE
  OPERATION_MODE_SAVEPOINT
  OPERATION_MODE_UNKNOWN
}

enum OperationResult {
  OPERATION_RESULT_FAILURE
  OPERATION_RESULT_SUCCESS
  OPERATION_RESULT_UNKNOWN
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type OptOutEvent {
  created: DateTime! @deprecated(reason: "deprecated")
  id: String! @deprecated(reason: "deprecated")
  subscription: Subscription!
}

enum OptOutSource {
  OPT_OUT_SOURCE_ATTENTIVE_SUPPORT_EMAIL
  OPT_OUT_SOURCE_CCPA
  OPT_OUT_SOURCE_CLIENT
  OPT_OUT_SOURCE_EMAIL
  OPT_OUT_SOURCE_KEYWORD
  OPT_OUT_SOURCE_LITIGATOR
  OPT_OUT_SOURCE_SCRIPT
  OPT_OUT_SOURCE_UNKNOWN
}

input OptOutUserByClientInput {
  userId: ID!
}

# Generated from com/attentivemobile/contracts/conversations/v1/Conversation.proto
type OptOutUserByClientPayload {
  user: User
}

# Generated from com/attentivemobile/contracts/messaging/automation/Contract.proto
type OrchestrationConnection {
  edges: [OrchestrationEdge!]!
  pageInfo: PageInfo!
  totalCount: Long!
}

input OrchestrationContentInput {
  prebuiltCampaignMessage: PrebuiltCampaignMessageInput
}

# Generated from com/attentivemobile/contracts/messaging/automation/Contract.proto
type OrchestrationEdge {
  cursor: String!
  node: ProgramOrchestration!
}

enum OrchestrationType {
  ORCHESTRATION_TYPE_CAMPAIGN
  ORCHESTRATION_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type OrderCancelledEvent {
  company: Company
  created: DateTime! @deprecated(reason: "deprecated")
  id: String! @deprecated(reason: "deprecated")
  order: OrderInfo!
}

enum OrderColumn {
  ID
  NAME
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type OrderConfirmedEvent {
  companyProductIds: [ID!]! @deprecated(reason: "deprecated")
  created: DateTime! @deprecated(reason: "deprecated")
  id: String! @deprecated(reason: "deprecated")
  order: OrderInfo!
  orderId: String! @deprecated(reason: "deprecated")
  orderProducts: [ProductInfo!]! @deprecated(reason: "deprecated")
  orderStatusUrl: String! @deprecated(reason: "deprecated")

  # TEMP: A list of products related to this event
  products: [ProductWrapper!]! @deprecated(reason: "deprecated")
}

enum OrderDirection {
  ASC
  DESC
}

# Generated from com/attentivemobile/contracts/event/ecommerce/Order.proto
# Represents an order for a product or service made by a consumer to a producer.
type OrderInfo {
  # The ID of the order, unique with respect to all orders managed by the eCommerce platform handling this order
  externalOrderId: String!

  # The name of the order
  name: String!

  # The list of line items, each detailing an item in the order
  orderProducts: [OrderProduct!]!

  # Information about the customer making this order
  orderRecipient: OrderRecipient!

  # The URL of a webpage detailing the current status of the order
  orderStatusUrl: String!

  # The totals associated with this order
  orderTotals: BillingTotals!

  # The time at which this order was created
  processedAt: DateTime!

  # The number of the order made to the merchant
  userOrderNumber: String!
}

# Generated from com/attentivemobile/contracts/event/ecommerce/Order.proto
type OrderProduct {
  companyProductVariant: ProductVariant

  # The title of the line item
  name: String!

  # The price presented to a customer of a single item, before any discounts are applied
  pricePresented: Money!

  # The quantity of the item purchased
  quantity: Long!
}

# Generated from com/attentivemobile/contracts/event/ecommerce/Order.proto
type OrderRecipient {
  # The customer's billing address
  billingAddress: PostalAddress!

  # The customer's email address
  email: String!

  # The customer's first name
  firstName: String!

  # The customer's last name
  lastName: String!

  # The customer's phone number
  phone: String!

  # The customer's shipping address
  shippingAddress: PostalAddress!
}

# Generated from com/attentivemobile/protobuf/common/Context.proto
type OriginatingEntity {
  entityType: EntityType!
  id: String!
}

# Generated from com/attentivemobile/protobuf/common/graphql/pageinfo.proto
type PageInfo {
  endCursor: String!
  hasNextPage: Boolean!
  hasPreviousPage: Boolean!
  startCursor: String!
}

input PaginatedProductsFilter {
  name: String
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type ParagraphContents {
  text: String!
}

# Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto
type PartnerCampaignAudienceFilter {
  campaignId: String!
  isExcludingSubscribers: Boolean!
}

enum PartnershipTier {
  ELITE
  NONE
  PARTNERSHIP_TIER_UNKNOWN
  PREMIER
}

enum PassedTimezonesStrategy {
  PASSED_TIMEZONES_STRATEGY_DO_NOT_SEND
  PASSED_TIMEZONES_STRATEGY_SEND_NOW
  PASSED_TIMEZONES_STRATEGY_SEND_ONE_DAY_LATER
  PASSED_TIMEZONES_STRATEGY_UNKNOWN
}

# Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto
type Paths {
  allowPathAndFilenameMismatch: Boolean
  conversionsFullPath: String
  generalDataFeedFullPath: String
}

input PathsInput {
  allowPathAndFilenameMismatch: Boolean
  conversionsFullPath: String
  generalDataFeedFullPath: String
}

input PauseAiProTrialSubscriptionInput {
  comment: String
  companyId: ID!
  pauseReason: String!
}

union PauseAiProTrialSubscriptionPayload = AiProTrialActionFailure | PauseAiProTrialSubscriptionSuccess

# Generated from com/attentivemobile/contracts/multiproductenablement/service/aipro/Contract.proto
type PauseAiProTrialSubscriptionSuccess {
  success: Boolean!
}

input PauseComposeCampaignInput {
  campaignMessageGroupId: ID
  companyId: ID!
  composeCampaignId: ID!
  userId: ID!
}

enum PaymentCompanyStateChangeStatus {
  PAYMENT_COMPANY_STATE_CHANGE_STATUS_FAIL
  PAYMENT_COMPANY_STATE_CHANGE_STATUS_SUCCESS
  PAYMENT_COMPANY_STATE_CHANGE_STATUS_UNKNOWN
}

enum PaymentCompanyStatus {
  ACTIVE
  INACTIVE
  NON_EXISTENT
}

enum PaymentOfferStatus {
  PAYMENT_OFFER_STATUS_ACTIVE
  PAYMENT_OFFER_STATUS_DELETED
  PAYMENT_OFFER_STATUS_DRAFT
  PAYMENT_OFFER_STATUS_EXPIRED
  PAYMENT_OFFER_STATUS_UNKNOWN
}

enum PaymentOfferType {
  DYNAMIC
  STATIC
}

# Generated from com/attentivemobile/contracts/payments/v2/Contract.proto
type PaymentsOffer implements Node {
  discount: PaymentsOfferDiscountOutput!
  expiresAt: DateTime
  externalId: String!
  id: ID!
  internalId: Long!
  keyword: String!
  paymentOfferStatus: PaymentOfferStatus!
  productId: String!
  productName: String!
  productPrice: Money!
  productVariantId: String!
}

input PaymentsOfferDiscountInput {
  type: OfferDiscountType!
  value: String!
}

# Generated from com/attentivemobile/contracts/payments/v2/Contract.proto
type PaymentsOfferDiscountOutput {
  type: OfferDiscountType!
  value: String!
}

enum PhoneApplicationStatus {
  PHONE_APPLICATION_STATUS_APPLIED
  PHONE_APPLICATION_STATUS_APPROVED
  PHONE_APPLICATION_STATUS_CANCELLED
  PHONE_APPLICATION_STATUS_PENDING_CARRIER_APPROVAL
  PHONE_APPLICATION_STATUS_PENDING_MOCKUP
  PHONE_APPLICATION_STATUS_UNASSIGNED
  PHONE_APPLICATION_STATUS_UNKNOWN
}

# Generated from com/attentivemobile/contracts/phonenumbers/external/Contract.proto
type PhoneNumber implements Node {
  aggregator: AggregatorInfo!

  # Companies assigned to this phone number.
  assigned_companies: [ID!]

  # Messaging services associated with this phone number.
  company_messaging_services: [CompanyMessagingService!]
  country: String!
  description: String!
  id: ID!
  messagingServiceProviderId: String!
  messagingServiceProviderName: String!
  messagingServiceSid: String!
  mmsVasId: String!
  phoneNumber: String!
  phoneNumberType: String!
  phoneNumberUseCase: PhoneNumberUseCase!

  # The application status for the phone number.
  phone_number_application_status: PhoneNumberApplicationStatusHistory
  shared: Boolean!
  shortCodeLeaseOwner: ShortCodeLeaseOwner!
  status: PhoneNumberStatus!
}

# Generated from com/attentivemobile/contracts/phonenumbers/external/Contract.proto
type PhoneNumberApplicationStatusHistory {
  applicationId: String!
  approvedDate: DateTime!
  cancellationDate: DateTime!
  id: String!
  phoneNumberId: String!
  status: PhoneApplicationStatus!
  submittedDate: DateTime!
}

enum PhoneNumberCompanyAssignmentStatus {
  PHONE_NUMBER_COMPANY_ASSIGNMENT_STATUS_ASSIGNED
  PHONE_NUMBER_COMPANY_ASSIGNMENT_STATUS_NONE
  PHONE_NUMBER_COMPANY_ASSIGNMENT_STATUS_UNKNOWN
}

# Generated from com/attentivemobile/contracts/phonenumbers/external/Contract.proto
type PhoneNumberEdge {
  cursor: String!
  node: PhoneNumber!
}

enum PhoneNumberStatus {
  PHONE_NUMBER_STATUS_ACTIVE
  PHONE_NUMBER_STATUS_INACTIVE
  PHONE_NUMBER_STATUS_UNKNOWN
}

enum PhoneNumberType {
  PHONE_NUMBER_TYPE_LONG_CODE
  PHONE_NUMBER_TYPE_SHORT_CODE
  PHONE_NUMBER_TYPE_TEN_DLC
  PHONE_NUMBER_TYPE_TOLL_FREE
  PHONE_NUMBER_TYPE_UNKNOWN
}

enum PhoneNumberUseCase {
  PHONE_NUMBER_USE_CASE_ATTENTIVE
  PHONE_NUMBER_USE_CASE_CLIENT
  PHONE_NUMBER_USE_CASE_TEST
  PHONE_NUMBER_USE_CASE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/phonenumbers/external/Contract.proto
type PhoneNumbersConnection {
  edges: [PhoneNumberEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Transformation.proto
type PiiScrubber {
  placeholder: String
}

enum Placement {
  BRANDKIT_PLACEMENT_ADDITIONAL
  BRANDKIT_PLACEMENT_DEFAULT
  BRANDKIT_PLACEMENT_SECONDARY
}

# Generated from com/attentivemobile/contracts/survey/poll/Contract.proto
type Poll implements Node {
  allowAltSignupUnit: Boolean
  allowBreakTies: Boolean
  allowMarketingSubmitMessage: Boolean
  allowMultiVote: Boolean!
  altSignupUnitId: Int
  applicationId: String
  audience: AudienceResponse
  captureAll: Boolean
  closedMessage: String
  company: Company
  createdAt: DateTime!
  currentRunId: Int
  decimalPrecision: Int
  description: String
  duplicateVoteMessage: String
  end: DateTime
  gameTimePoll: GetGameTimePayload
  hasWinnerFilter: Boolean
  id: ID!
  isArchived: Boolean
  marketingSubmitMessage: String
  name: String!
  onClosedMessage: String
  options: [PollOption!]!
  participantAttributeName: String
  pollReport(runId: Int!): PollUsageReportResponse
  pollReportHistory(after: String, before: String, first: Int, last: Int): PollUsageHistoryConnection
  poll_keywords: GetPollKeywordsResponse
  poll_stats: GetPollStatsResponse
  start: DateTime
  state: PollState!
  status: PollStatus! @deprecated(reason: "deprecated")
  submitMessage: String
  subscribedEventType: String
  sweepstakesFilter: GetSweepstakesFilterResponse
  totalResponses: Long
  updatedAt: DateTime!
  uuid: String!
}

# Generated from com/attentivemobile/contracts/survey/poll/Contract.proto
type PollEdge {
  cursor: String!
  node: Poll!
}

input PollGraphQuestionInput {
  accountId: ID!
  runId: String!
}

union PollGraphQuestionPayload = DefaultErrorFailure | PollGraphQuestionSuccess

# Generated from com/attentivemobile/contracts/graph/guru/service/GraphGuruService.proto
type PollGraphQuestionSuccess {
  message: String
  status: ThreadRunStatus!
}

# Generated from com/attentivemobile/contracts/survey/poll/PollKeywordService.proto
type PollKeyword implements Node {
  altMatches: [String!]!
  deleted: Boolean!
  description: String!
  id: ID!
  keyword: String!
  poll: Poll
}

input PollKeywordInput {
  altMatches: [String!]
  deleted: Boolean!
  description: String!
  keyword: String!
  keywordId: ID
}

# Generated from com/attentivemobile/contracts/survey/poll/Contract.proto
type PollOption implements Node {
  id: ID!
  keyword: String!
  optionUuid: String!
  response: String!
}

# Generated from com/attentivemobile/contracts/survey/poll/Contract.proto
type PollOptionEdge {
  cursor: String!
  node: PollOption!
}

input PollPaging {
  hideDisabled: Boolean
  page: Int!
  pageSize: Int!
  searchFilter: String
}

input PollSorting {
  sortBy: PollSortingField!
  sortDirection: SortDirection!
}

enum PollSortingField {
  POLL_SORTING_FIELD_NAME
  POLL_SORTING_FIELD_UPDATED
}

enum PollState {
  CLOSED
  OFF
  OPEN
}

# Generated from com/attentivemobile/contracts/survey/poll/Contract.proto
type PollStats {
  keywordStats: [KeywordStats!]!
  keywordTimeline: KeywordsTimeline!
  poll: Poll
}

enum PollStatus {
  POLL_STATUS_CANCELLED
  POLL_STATUS_COMPLETE
  POLL_STATUS_DELETED
  POLL_STATUS_DRAFT
  POLL_STATUS_FAILED
  POLL_STATUS_IN_PROGRESS
  POLL_STATUS_UNKNOWN
}

# Generated from com/attentivemobile/contracts/survey/poll/PollReportService.proto
type PollUsageHistoryConnection {
  edges: [PollUsageReportEdge!]!
  pageInfo: PageInfo!
  total: Int!
}

# Generated from com/attentivemobile/contracts/survey/poll/PollReportService.proto
type PollUsageReport {
  endDate: DateTime!
  marketingParticipantCount: Int!
  marketingParticipantPercent: String!
  optInsCompleted: Int!
  optInsCompletedPercent: String!
  optInsStarted: Int!
  participantCount: Int!
  runId: Int!
  startDate: DateTime!
  totalVotes: Int!
  transactionalParticipantCount: Int!
  transactionalParticipantPercent: String!
}

# Generated from com/attentivemobile/contracts/survey/poll/PollReportService.proto
type PollUsageReportEdge {
  cursor: String!
  node: PollUsageReport!
}

# Generated from com/attentivemobile/contracts/survey/poll/PollReportService.proto
type PollUsageReportResponse {
  endDate: DateTime!
  optInsCompleted: Int!
  optInsCompletedPercent: String!
  optInsStarted: Int!
  participantCount: Int!
  runId: Int!
  startDate: DateTime!
  subscribedParticipantCount: Int!
  subscribedParticipantPercent: String!
  totalVotes: Int!
  transactionalParticipantCount: Int!
  transactionalParticipantPercent: String!
}

# Generated from com/attentivemobile/contracts/survey/poll/Contract.proto
type PollsConnection {
  edges: [PollEdge!]!
  page: Int!
  pageInfo: PageInfo!
  pageSize: Int!
  totalCount: Int
}

input PollsFilterV2 {
  includedStates: [PollState!]
  searchTerm: String
  showDeleted: Boolean
}

input PostCustomEventKeyInput {
  active: Boolean!
  customEventType: String!
  keyType: CustomEventKeyType!
  propertyKey: String!
}

input PostCustomPropertyDefinitionInput {
  enumValues: [String!]
  isDeleted: Boolean @deprecated(reason: "deprecated")
  name: String!
  propertyType: CustomPropertyType!
}

# Generated from google/type/postal_address.proto
type PostalAddress {
  addressLines: [String!]!
  administrativeArea: String!
  languageCode: String!
  locality: String!
  organization: String!
  postalCode: String!
  recipients: [String!]!
  regionCode: String!
  revision: Int!
  sortingCode: String!
  sublocality: String!
}

# Generated from com/attentivemobile/contracts/geography/Location.proto
type PostalCode implements Node {
  countrySubdivision: CountrySubdivision
  id: ID!
  postalCode: String!
}

# Generated from com/attentivemobile/contracts/geography/Location.proto
type PostalCodeAggregate {
  subdivisionTotal: Long!
  total: Long!
}

# Generated from com/attentivemobile/contracts/geography/Location.proto
type PostalCodeConnection {
  aggregate: PostalCodeAggregate!
  edges: [PostalCodeEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/geography/Location.proto
type PostalCodeEdge {
  cursor: String!
  node: PostalCode!
}

input PostalCodeInput {
  postalCode: String!
}

input PostalCodeInputList {
  postalCodes: [PostalCodeInput!]
}

input PreScheduleComposeCampaignInput {
  companyId: ID!
  composeCampaignId: ID!
  userId: ID!
}

union PreScheduleComposeCampaignPayload = DefaultErrorFailure | PreScheduleComposeCampaignSuccess

# Generated from com/attentivemobile/contracts/campaigns/service/Contract.proto
type PreScheduleComposeCampaignSuccess {
  campaign: ComposeCampaign!
}

# Generated from com/attentivemobile/contracts/messaging/automation/prebuilt/Model.proto
type PrebuiltCampaignContent {
  excludeOffer: Boolean
  name: String!
  scheduledAt: DateTime!
  segmentQuery: [String!]!
  text: String!
}

input PrebuiltCampaignMessageInput {
  excludeOffer: Boolean
  name: String!
  scheduledAt: DateTime!
  segmentQuery: [String!]
  text: String!
}

# Generated from com/attentivemobile/contracts/messaging/automation/prebuilt/Model.proto
type PrebuiltContent implements Node {
  id: ID!
  name: String!
  orchestrationType: String! @deprecated(reason: "deprecated")
  orchestrationTypeEnum: OrchestrationType!
  orchestrations(companyId: ID!): OrchestrationConnection
  prebuiltOrchestrationContent: [PrebuiltOrchestrationContent!]!
  prebuiltProgram: PrebuiltProgram
}

# Generated from com/attentivemobile/contracts/messaging/automation/prebuilt/Contract.proto
type PrebuiltContentConnection {
  pageInfo: PageInfo!
  prebuiltContentEdges: [PrebuiltContentEdge!]!
  totalCount: Long!
}

# Generated from com/attentivemobile/contracts/messaging/automation/prebuilt/Contract.proto
type PrebuiltContentEdge {
  cursor: String!
  node: PrebuiltContent!
}

# Generated from com/attentivemobile/contracts/messaging/automation/prebuilt/Model.proto
type PrebuiltOrchestrationContent implements Node {
  id: ID!
  prebuiltOrchestrationContent: PrebuiltOrchestrationContentPrebuiltOrchestrationContent
}

union PrebuiltOrchestrationContentPrebuiltOrchestrationContent = PrebuiltCampaignContent

# Generated from com/attentivemobile/contracts/messaging/automation/prebuilt/Model.proto
type PrebuiltProgram implements Node {
  description: String!
  id: ID!
  prebuiltContent(after: String, before: String, first: Int, last: Int): PrebuiltContentConnection
  scheduleType: ScheduleType!
  status: ProgramStatus!
  thumbnailUrl: String!
  title: String!
}

# Generated from com/attentivemobile/contracts/messaging/automation/prebuilt/Contract.proto
type PrebuiltProgramConnection {
  pageInfo: PageInfo!
  prebuiltProgramEdges: [PrebuiltProgramEdge!]!
  totalCount: Long!
}

# Generated from com/attentivemobile/contracts/messaging/automation/prebuilt/Contract.proto
type PrebuiltProgramEdge {
  cursor: String!
  node: PrebuiltProgram!
}

enum PreferenceType {
  PREFERENCE_TYPE_ORDERING
  PREFERENCE_TYPE_UNKNOWN
  PREFERENCE_TYPE_VISIBILITY
}

enum PreferredMfaMethod {
  PREFERRED_MFA_METHOD_EMAIL
  PREFERRED_MFA_METHOD_SMS
}

# Generated from com/attentivemobile/protobuf/common/Url.proto
type PresignedUrl {
  expiresAt: DateTime!
  value: String!
}

enum PriceComparator {
  PRICE_COMPARATOR_GREATER_THAN
  PRICE_COMPARATOR_LESS_THAN
}

input PrivacyRequestSearchFilter {
  search: String
}

input PrivacyRequestSorting {
  direction: SortDirection
  sortBy: String
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type PrivateKey {
  bucketName: String!
  keyName: String!
}

input PrivateKeyInput {
  bucketName: String!
  keyName: String!
}

input ProcessCustomFontsInput {
  companyId: ID!
  fileUuids: [String!]
}

# Generated from com/attentivemobile/contracts/brand-kit/BrandFont.proto
type ProcessCustomFontsPayload {
  availableBrandFonts: [AvailableBrandFont!]!
}

input ProcessTypekitInput {
  typekitUrl: String!
}

# Generated from com/attentivemobile/contracts/brand-kit/BrandFont.proto
type ProcessTypekitPayload {
  availableBrandFonts: [AvailableBrandFont!]!
  processedTypekits: [ProcessedTypekit!]!
}

# Generated from com/attentivemobile/contracts/brand-kit/BrandFont.proto
type ProcessedTypekit {
  fontFamilyName: String!
  format: String!
  url: Url!
  variants: [String!]!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto
type Product implements Node {
  attributes: [InventoryAttribute!]!
  availability: ProductAvailability!
  brand: String!
  categories: [String!]!
  collections: [String!]!
  company: Company
  created: DateTime!
  description: String!
  id: ID!
  imageLinks: [ImageLink!]!
  link: String!
  maxPrice: Money!
  minPrice: Money!
  name: String!
  originId: String!
  originPublishedAt: DateTime!
  originUpsertTime: DateTime!
  productOptions: [ProductOptionValue!]!
  tags: [String!]!
  updated: DateTime!
  variants(after: String, before: String, first: Int, last: Int): ProductVariantConnection
}

enum ProductAttributeType {
  PRODUCT_ATTRIBUTE_TYPE_CATEGORY
  PRODUCT_ATTRIBUTE_TYPE_COLLECTION
  PRODUCT_ATTRIBUTE_TYPE_TAG
  PRODUCT_ATTRIBUTE_TYPE_UNKNOWN
}

enum ProductAvailability {
  PRODUCT_AVAILABILITY_AVAILABLE_FOR_ORDER
  PRODUCT_AVAILABILITY_DISCONTINUED
  PRODUCT_AVAILABILITY_IN_STOCK
  PRODUCT_AVAILABILITY_OUT_OF_STOCK
  PRODUCT_AVAILABILITY_PENDING
  PRODUCT_AVAILABILITY_PREORDER
  PRODUCT_AVAILABILITY_REMOVED
  PRODUCT_AVAILABILITY_UNKNOWN
}

input ProductCategoriesFilter {
  name: String
}

# Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto
type ProductCategory implements Node {
  company: Company
  id: ID!
  name: String!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto
type ProductCategoryEdge {
  cursor: String!
  node: ProductCategory!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto
type ProductCategoryOutput {
  productCategory: ProductCategory
}

# Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto
type ProductCollection implements Node {
  company: Company
  id: ID!
  name: String!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto
type ProductCollectionEdge {
  cursor: String!
  node: ProductCollection!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto
type ProductCollectionOutput {
  productCollection: ProductCollection
}

input ProductCollectionsFilter {
  name: String
}

# Generated from com/attentivemobile/protobuf/common/compose/Message.proto
type ProductDiversityCriteria {
  maxPerAttribute: Int!
  productAttributeType: ProductAttributeType!
}

input ProductDiversityCriteriaInput {
  maxPerAttribute: Int!
  productAttributeType: ProductAttributeType!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto
type ProductEventSummary {
  addToCartCount: Int
  id: ID!
  productViewCount: Int
  purchaseCount: Int
  revenue: Money
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type ProductInfo {
  productId: String!
  productName: String!
  productSku: String!
  productUrl: String!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto
type ProductInventoryFilter {
  inventoryComparator: InventoryComparator!
  inventoryQuantity: Long!
}

input ProductInventoryFilterInput {
  inventoryComparator: InventoryComparator!
  inventoryQuantity: Long!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto
type ProductOptionValue {
  name: String!
  values: [String!]!
}

# Generated from com/attentivemobile/protobuf/common/Identifiers.proto
type ProductOriginID {
  company: Company
  productOriginId: String!
}

union ProductProperty = CustomProductProperty | KnownProductProperty

# Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto
type ProductPropertyOption {
  option: String!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto
type ProductPropertyOptionConnection {
  edges: [ProductPropertyOptionEdge!]!
  pageInfo: PageInfo!
  totalCount: Long!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto
type ProductPropertyOptionEdge {
  cursor: String!
  productPropertyOption: ProductPropertyOption!
}

input ProductPropertyOptionsFilter {
  searchTerm: String
}

input ProductPropertyOptionsInput {
  customProductProperty: CustomProductPropertyInput
  knownProductProperty: KnownProductPropertyInput
}

# Generated from com/attentivemobile/contracts/productrecsservice/Contract.proto
type ProductRecommendation {
  availability: String!
  confidence: Long! @deprecated(reason: "deprecated")
  createdAt: DateTime!
  currency: String!
  description: String!
  imageUrl: String!
  link: String!
  maxPrice: Long! @deprecated(reason: "deprecated")
  maximumCompareAtPrice: Float!
  maximumPrice: Float!
  minPrice: Long! @deprecated(reason: "deprecated")
  minimumCompareAtPrice: Float!
  minimumPrice: Float!
  modelMetadata: ModelMetadata!
  modelScore: Float!
  name: String!
  productId: String!
  productRecommendationSource: ProductRecommendationSource! @deprecated(reason: "deprecated")
  recommendationSourceModel: String! @deprecated(reason: "deprecated")
  recommendationSourceModelVersion: String! @deprecated(reason: "deprecated")
}

# Generated from com/attentivemobile/contracts/productrecsservice/Contract.proto
type ProductRecommendationMetaRedacted {
  createdAt: DateTime!
  product: Product
}

enum ProductRecommendationSource {
  RECOMMENDATION_SOURCE_COMPANY_BESTSELLERS_JOB
  RECOMMENDATION_SOURCE_LAST_PURCHASED_CATEGORY_BESTSELLER
  RECOMMENDATION_SOURCE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/productrecsservice/Contract.proto
type ProductRecommendationsRedactedResponse {
  productRecommendations: [ProductRecommendationMetaRedacted!]!
}

# Generated from com/attentivemobile/contracts/productrecsservice/Contract.proto
type ProductRecommendationsResponse {
  productRecommendations: [ProductRecommendation!]!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto
type ProductSegment implements Node {
  company: Company
  created: DateTime!
  filters: [ProductSegmentFilter!]!
  id: ID!
  imageUrl: Url
  name: String!
  productCount: Long!
  updated: DateTime!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto
type ProductSegmentCategoryIdFilter {
  productCategories: [ProductCategoryOutput!]!
}

input ProductSegmentCategoryIdFilterInput {
  ids: [ID!]
}

# Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto
type ProductSegmentCollectionIdFilter {
  productCollections: [ProductCollectionOutput!]!
}

input ProductSegmentCollectionIdFilterInput {
  ids: [ID!]
}

# Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto
type ProductSegmentEdge {
  cursor: String!
  node: ProductSegment!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto
type ProductSegmentFilter {
  filter: ProductSegmentFilterFilter
  inverse: Boolean!
}

union ProductSegmentFilterFilter = ProductInventoryFilter | ProductSegmentCategoryIdFilter | ProductSegmentCollectionIdFilter | ProductSegmentPriceFilter | ProductSegmentTagIdFilter

input ProductSegmentFilterInput {
  filterInput: ProductSegmentFilterInputFilterInput
  inverse: Boolean!
}

input ProductSegmentFilterInputFilterInput {
  categoryIdFilter: ProductSegmentCategoryIdFilterInput
  collectionIdFilter: ProductSegmentCollectionIdFilterInput
  inventoryFilter: ProductInventoryFilterInput
  priceFilter: ProductSegmentPriceFilterInput
  tagFilter: ProductSegmentTagIdFilterInput
}

# Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto
type ProductSegmentPriceFilter {
  price: String!
  priceComparator: PriceComparator!
}

input ProductSegmentPriceFilterInput {
  price: String!
  priceComparator: PriceComparator!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto
type ProductSegmentTagIdFilter {
  productTags: [ProductTagOutput!]!
}

input ProductSegmentTagIdFilterInput {
  ids: [ID!]
}

# Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto
type ProductSegmentsConnection {
  edges: [ProductSegmentEdge!]!
  pageInfo: PageInfo!
  totalCount: Long!
}

input ProductSegmentsFilter {
  name: String
}

# Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto
type ProductSummary implements Node {
  categories: [String!]!
  company: Company
  id: ID!
  imageLinks: [ImageLink!]!
  link: String!
  maxPrice: Money
  minPrice: Money
  name: String!
  variants(after: String, before: String, first: Int, last: Int): ProductVariantConnection
}

# Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto
type ProductTag implements Node {
  company: Company
  id: ID!
  name: String!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto
type ProductTagEdge {
  cursor: String!
  node: ProductTag!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto
type ProductTagOutput {
  productTag: ProductTag
}

input ProductTagsFilter {
  name: String
}

# Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto
type ProductVariant implements Node {
  attributes: [InventoryAttribute!]!
  availability: ProductAvailability!
  availabilityDate: DateTime
  company: Company
  created: DateTime!
  id: ID!
  imageLinks: [ImageLink!]!
  inventoryQuantity: Long!
  isDefault: Boolean!
  name: String!
  originPublishedAt: DateTime!
  originUpsertTime: DateTime!
  prices: [ProductVariantPrice!]!
  product: Product
  productOriginId: String!
  selectedOptions: [ProductVariantOptionValue!]!
  updated: DateTime!
  variantOriginId: String!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto
type ProductVariantConnection {
  edges: [ProductVariantEdge!]!
  pageInfo: PageInfo!
  totalCount: Long!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto
type ProductVariantEdge {
  cursor: String!
  node: ProductVariant!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto
type ProductVariantOptionValue {
  name: String!
  position: Int!
  product: Product
  value: String!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/gql/Contract.proto
type ProductVariantPrice {
  activeTime: DateTime
  compareAtPrice: Money
  created: DateTime!
  expirationTime: DateTime
  isActive: Boolean!
  price: Money!
  priceType: ProductVariantPriceType!
  updated: DateTime!
}

enum ProductVariantPriceType {
  VARIANT_PRICE_TYPE_REGULAR
  VARIANT_PRICE_TYPE_SALE
  VARIANT_PRICE_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type ProductViewEvent {
  companyProduct: Product
  companyProductId: ID!
  created: DateTime! @deprecated(reason: "deprecated")
  id: String! @deprecated(reason: "deprecated")
  productName: String! @deprecated(reason: "deprecated")
  productUrl: String! @deprecated(reason: "deprecated")
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type ProductWrapper {
  companyProduct: Product
}

enum ProfileVendor {
  PROFILE_VENDOR_APPLICATION
  PROFILE_VENDOR_ATTENTIVE
  PROFILE_VENDOR_ATTENTIVE_MEMBERSHIP
  PROFILE_VENDOR_BAZAAR_VOICE
  PROFILE_VENDOR_COMPANY
  PROFILE_VENDOR_INFERRED
  PROFILE_VENDOR_KLAVIYO
  PROFILE_VENDOR_MPARTICLE
  PROFILE_VENDOR_PUNCHH
  PROFILE_VENDOR_RECHARGE
  PROFILE_VENDOR_SEGMENT
  PROFILE_VENDOR_SHOPIFY
  PROFILE_VENDOR_SMILE_IO
  PROFILE_VENDOR_UNKNOWN
  PROFILE_VENDOR_YOTPO
}

# Generated from com/attentivemobile/contracts/messaging/automation/Contract.proto
type ProgramCadence {
  firstSendDate: DateTime!
  programCadenceType: ProgramCadenceType!
  programFrequency: ProgramFrequency!
}

enum ProgramCadenceType {
  PROGRAM_CADENCE_TYPE_FIXED_PERIOD
  PROGRAM_CADENCE_TYPE_UNKNOWN
}

enum ProgramCalendarPeriod {
  PROGRAM_CALENDAR_PERIOD_DAY
  PROGRAM_CALENDAR_PERIOD_MONTH
  PROGRAM_CALENDAR_PERIOD_UNKNOWN
  PROGRAM_CALENDAR_PERIOD_WEEK
}

# Generated from com/attentivemobile/contracts/messaging/automation/Contract.proto
type ProgramFrequency {
  period: Int!
  periodUnit: ProgramCalendarPeriod!
}

# Generated from com/attentivemobile/contracts/messaging/automation/Contract.proto
type ProgramOrchestration implements Node {
  automationProgramId: ID!
  deleted: DateTime
  id: ID!
  orchestrationContent: ProgramOrchestrationOrchestrationContent
  orchestrationId: ID! @deprecated(reason: "deprecated")
  orchestrationType: String!
  prebuiltContentId: ID
}

union ProgramOrchestrationOrchestrationContent = CampaignOrchestration

enum ProgramStatus {
  PROGRAM_STATUS_ACTIVE
  PROGRAM_STATUS_INACTIVE
  PROGRAM_STATUS_UNKNOWN
}

enum PromptRefinementOption {
  PROMPT_REFINEMENT_OPTION_ADD_EMOJIS
  PROMPT_REFINEMENT_OPTION_ADD_URGENCY
  PROMPT_REFINEMENT_OPTION_MAKE_CASUAL
  PROMPT_REFINEMENT_OPTION_MAKE_FUNNY
  PROMPT_REFINEMENT_OPTION_MAKE_SHORTER
  PROMPT_REFINEMENT_OPTION_UNKNOWN
  PROMPT_REFINEMENT_OPTION_USE_BRAND_VOICE
}

# Generated from com/attentivemobile/contracts/user/profile/property/UserPropertyV2.proto
type PropertyDefinitionId {
  company: Company
  definitionName: String!
  displayName: String
  installedApplication: InstalledApplication
  internal: Boolean!
  reservedProperty: ReservedPropertyType
  vendor: ProfileVendor!
  vendorId: String
  wellKnownProperty: Boolean!
}

input PropertyDefinitionInput {
  vendor: ProfileVendor!
  vendorId: String
}

input PropertyInput {
  definitionName: String!
  vendor: ProfileVendor!
  vendorId: String
}

input PropertyLinkInput {
  priority: Int!
  propertyDefinitionId: PropertyDefinitionInput!
}

input PropertyNameInput {
  propertyInput: PropertyInput
  reservedPropertyInput: ReservedPropertyInput
}

# Generated from com/attentivemobile/protobuf/common/targeting/UserProperty.proto
type PropertyOrigin {
  source: String
}

# Generated from com/attentivemobile/protobuf/common/targeting/UserProperty.proto
type PropertyValueMetadata {
  localDateFields: LocalDateFields
  propertyOrigin: PropertyOrigin
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Transformation.proto
type ProtoEventJoiner {
  lowerBoundMilli: Int!
  protoEventJoinerType: ProtoEventJoinerType!
  source: ProtoEventJoinerSource
  sourceParallelism: Int
  upperBoundMilli: Int!
}

union ProtoEventJoinerSource = PulsarTopic | S3Location

enum ProtoEventJoinerType {
  PROTO_EVENT_JOINER_TYPE_ENRICHER_ANALYTICS
  PROTO_EVENT_JOINER_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Transformation.proto
type ProtoEventRateLimiter {
  desiredRps: Int!
}

enum Provider {
  PROVIDER_BANDWIDTH
  PROVIDER_GENERIC
  PROVIDER_IMI_MOBILE
  PROVIDER_MITTO
  PROVIDER_OPEN_MARKET
  PROVIDER_SINCH
  PROVIDER_TWILIO
  PROVIDER_UNKNOWN
  PROVIDER_VIBES
  PROVIDER_ZIPWHIP
}

# Generated from com/attentivemobile/contracts/providermigration/Contract.proto
type ProviderMigration {
  carrier: Carrier!
  endDate: DateTime
  newProvider: Provider!
  oldProvider: Provider!
  phoneNumber: String!
  startDate: DateTime!
  status: ProviderMigrationStatus!
}

# Generated from com/attentivemobile/contracts/providermigration/Contract.proto
type ProviderMigrationConnection {
  edges: [ProviderMigration!]!
  pageInfo: PageInfo!
}

enum ProviderMigrationStatus {
  PROVIDER_MIGRATION_STATUS_COMPLETED
  PROVIDER_MIGRATION_STATUS_IN_PROGRESS
  PROVIDER_MIGRATION_STATUS_NOT_STARTED
  PROVIDER_MIGRATION_STATUS_UNKNOWN
}

# Generated from com/attentivemobile/contracts/providermigration/Contract.proto
type ProviderMigrationsResponse {
  migrations: [ProviderMigration!]!
}

enum PublicEndpoint {
  PUBLIC_ENDPOINT_ADD_CUSTOM_IDENTIFIER
  PUBLIC_ENDPOINT_ADD_PRIVACY_DELETE_REQUEST
  PUBLIC_ENDPOINT_ADD_TO_CART
  PUBLIC_ENDPOINT_AUTOGENERATE_COUPONS
  PUBLIC_ENDPOINT_BULK_SEND_TEXT
  PUBLIC_ENDPOINT_CREATE_WEBHOOK
  PUBLIC_ENDPOINT_CUSTOM_ATTRIBUTES
  PUBLIC_ENDPOINT_CUSTOM_EVENTS
  PUBLIC_ENDPOINT_DELETE_WEBHOOK
  PUBLIC_ENDPOINT_GET_SUBSCRIBER_ELIGIBILITY
  PUBLIC_ENDPOINT_LIST_WEBHOOKS
  PUBLIC_ENDPOINT_ME
  PUBLIC_ENDPOINT_PRODUCT_VIEW
  PUBLIC_ENDPOINT_PURCHASE
  PUBLIC_ENDPOINT_SEND_CAMPAIGN_TEXT
  PUBLIC_ENDPOINT_SEND_TEXT
  PUBLIC_ENDPOINT_SUBSCRIBE_USER
  PUBLIC_ENDPOINT_UNKNOWN
  PUBLIC_ENDPOINT_UNSUBSCRIBE_USER
  PUBLIC_ENDPOINT_UPDATE_WEBHOOK
  PUBLIC_ENDPOINT_UPLOAD_PRODUCT_CATALOG
  PUBLIC_ENDPOINT_UPSERT_MESSAGE_TEXT
  PUBLIC_ENDPOINT_VIEW_PRODUCT_CATALOG_INGESTION
  PUBLIC_ENDPOINT_VIEW_RECENT_CATALOG_UPLOADS
}

input PublishJourneyRevisionInput {
  companyId: ID!
  doEvictSubscribers: Boolean!
  journeyRevisionId: ID!
}

# Generated from com/attentivemobile/contracts/journeys/api/JourneyRevision.proto
type PublishJourneyRevisionPayload {
  journeyRevision: JourneyRevision!
}

union PublishMessageValidationError = EmptyEmailSubject | EmptyMessageBody | MissingEmailMetadata

# Generated from com/attentivemobile/contracts/event/platform/registration/service/DatastreamModel.proto
type PulsarTopic {
  uri: String!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type PurchaseEvent {
  companyProduct: Product
  companyProductId: ID!
  created: DateTime! @deprecated(reason: "deprecated")
  id: String! @deprecated(reason: "deprecated")
  productName: String! @deprecated(reason: "deprecated")
  productUrl: String! @deprecated(reason: "deprecated")
}

input PurchaseEventFilter {
  hasVerb: HasVerb!
  timeCondition: TimeCondition!
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type PushChannelProperties {
  mediaUrl: String
  messageDescription: String!
  messageTitle: String
  openActionUrl: String!
  playSound: Boolean
  sendInAppNotification: Boolean
  showBadgeIcon: Boolean
}

input PushPropertiesInput {
  mediaUrl: String
  messageDescription: String!
  messageTitle: String
  openActionUrl: String!
  playSound: Boolean
  sendInAppNotification: Boolean
  showBadgeIcon: Boolean
}

type Query {
  aIGeneratedText(id: ID!): AIGeneratedText
  accountPermissionDomains: ListAccountPermissionDomainsPayload
  accounts(after: String, companyId: ID, filter: AccountFilter!, first: Int): AccountConnection
  accountsByEmail(after: String, emails: [String!], filter: AccountFilter!, first: Int): AccountConnection
  activeDismissibleModal(companyId: ID, url: String!, userId: ID): DismissibleModal
  activeNotificationBanner: GetActiveNotificationBannerResponse
  adminNotificationConfigurations(after: String, first: Int): ConfigurationNotificationConnection
  agentTemplateRequests(after: String, companyId: ID, filter: TemplateRequestFilter, first: Int, requesterId: ID, reviewerId: ID, searchTerm: String): TemplateRequestConnection
  aiImageGenerationOptions: AIImageGenerationOptionsPayload
  aiProTrialEligibilityStatus(companyId: ID!): GetAiProTrialEligibilityStatusPayload
  allEdsSyncs(after: String, before: String, companyId: ID!, filter: ListSyncsFilter!, first: Int, last: Int): Syncs
  apiRateLimits: CompanyApplicationRouteRateLimitsPayload
  applicationWebhookEvents(after: String, before: String, first: Int, last: Int): WebhookEventConnection
  authenticators(accountId: ID!): GetAuthenticatorsPayload
  automationPrograms(after: String, before: String, companyId: ID!, first: Int, last: Int): AutomationProgramConnection
  circuitBreakerRateLimits: CircuitBreakerRateLimitsResponse
  companies(after: String, filter: CompanySearchFilter, first: Int): CompanyConnection
  companiesByInternalId(internalIds: [Long!]): GetCompaniesByInternalIdResponse @deprecated(reason: "deprecated")
  companyByAttentiveDomain(attentiveDomain: String!): Company
  companyGlobalRateLimits: CompanyGlobalRateLimitsPayload
  composeCampaigns(after: String, before: String, companyId: ID, filter: ComposeCampaignsFilter, first: Int, last: Int): ComposeCampaignsConnection
  concierge: Concierge
  conciergeCompanyTemplates(after: String, companyId: ID, filter: TemplateFilter, first: Int, searchByShortcut: Boolean, searchByText: Boolean, searchByTitle: Boolean, searchTerm: String): CompanyTemplateConnection
  contentSetting(companyId: ID, objectClass: ContentClass!, objectId: String, preferenceType: PreferenceType!, userId: ID): FindSpecificUserContentSettingConnection
  contentSettings(after: String, before: String, companyId: ID, first: Int, last: Int, userId: ID): FindUserContentSettingsConnection
  contentVisibility(companyId: ID!, objectClass: ContentClass!, objectId: String!, userId: ID!): FindUserContentVisibilityResponse
  copyAssistantTemplate(templateName: String!): GetCopyAssistantTemplateResponse
  copyAssistantTemplates: ListCopyAssistantTemplatesPayload
  countries: [Country!]
  country(id: ID!): Country
  countrySubdivision(id: ID!): CountrySubdivision
  countrySubdivisions(countryId: ID): [CountrySubdivision!]
  creativeByInternalID(internalId: String!): Creative
  creativeFormats(after: String, before: String, first: Int, last: Int): CreativeFormatConnection
  creativeTemplateByInternalId(internalId: String!): CreativeTemplate
  customCompanyKeywords(after: String, before: String, companyId: ID!, filter: GetCustomCompanyKeywordsFilter!, first: Int, last: Int, sort: GetCustomCompanyKeywordsSort!): CustomCompanyKeywordsConnection
  datastreamBatchMigrationStatus(workflowId: String!): GetBatchMigrationStatusResponse
  datastreamDetails(datastreamName: String!): GetDatastreamDetailsResponse
  datastreamTeams: ListTeamsResponse
  datastreams(cluster: Cluster, team: String): ListDatastreams
  deleteRequests(after: String, before: String, filter: PrivacyRequestSearchFilter, first: Int, last: Int, sorting: PrivacyRequestSorting): DeleteRequestConnection
  dismissibleModals(after: String, filter: DismissibleModalFilter, first: Int): DismissibleModalConnection
  domainSuppressions(after: String, before: String, first: Int, last: Int): DomainSuppressionConnection
  edsJobSchedule(taskId: String!): GetScheduleResponse
  edsLatestRunReports(intervalDays: Int, lastXRuns: Int, taskUuid: String!, topicType: String!): GetRunReportsResponse
  edsRunReportsByTopic(end: String!, runUuids: [String!], start: String!, taskId: String!, type: String!): GetRunReportsResponse
  edsTaskProcessingConfig(endpoint: String!, taskId: String): EdsTaskProcessingConfig
  emailAccounts(after: String, before: String, filter: EmailAccountFilterInput!, first: Int, last: Int, sort: EmailAccountSort!): EmailAccountConnection
  emailIpAddresses(after: String, available: Boolean, before: String, companyId: ID, domains: [String!], emailAccountId: ID, filter: EmailIPAddressFilterInput, first: Int, ipAddress: String, ipPoolId: ID, ipPoolNames: [String!], last: Int, verified: Boolean, warmupStatus: EmailIPAddressWarmupStatus): EmailIPAddressesConnection
  emailIpPools(after: String, before: String, companyId: ID, filter: EmailIPPoolFilterInput, first: Int, ipAddress: String, ipPoolId: ID, ipPoolName: String, last: Int, poolType: IPPoolType, shared: Boolean): EmailIPPoolsConnection
  emailManagementCsvExport(sortBy: CsvSortByField, sortDirection: SortDirection): EmailManagementCsvResponse
  enabledFlags(after: String, contexts: [ContextEvaluationItem!], featureFlagNames: [String!], first: Int): FlagEnabledConnection
  escalatedConciergeConversations(after: String, filter: EscalationsFilter, first: Int, sort: EscalationsSort): EscalationConnection
  evaluateMessagePreviewRequest(attachments: [UpdateAttachmentRequest!], emailPreviewRequest: EmailPreviewInput, messageId: ID, messageName: String, messageType: ComposeMessageType, productId: ID, rcsPreviewRequest: RcsPreviewInput, textPreviewRequest: TextPreviewInput, userCompositeId: ID, utmParameters: UtmParametersInput): EvaluateMessagePreviewPayload
  eventDestinationJobs(after: String, before: String, filter: GetJobRecordsFilter, first: Int, last: Int, nodeUuid: String): JobRecordConnection
  eventDestinationTasks(after: String, before: String, filter: EventDestinationTasksFilter, first: Int, last: Int, withTaskHistory: Boolean): EventDestinationTasksPayload
  eventDestinations: EventDestinations
  featureFlag(id: ID!): FeatureFlag
  featureFlags(after: String, companyId: ID, first: Int, flagNames: [String!], search: String): FeatureFlagConnection
  getCampaignStats(campaignIds: [ID!], company: ID!): CampaignsStats @deprecated(reason: "deprecated")
  getCouponAllocationJobs(dateRange: AllocationDateRangeInput, endTime: String, startTime: String): AllocationJobsPayload
  getEmailBulkOptOutJobs(dateRange: SolutionsDateRangeInput): GetEmailBulkOptOutJobsResponse
  getEstimatedRecipientCount(channel: ComposeMessageChannel, company: ID!, isSmartSendingEnabled: Boolean, messageId: ID, segmentIdsExcluded: [ID!], segmentIdsIncluded: [ID!]): EstimatedRecipientCount
  getRemainingRecipients(company: ID!, messageId: ID!): RemainingRecipientsCount
  giveawayWinners(creativeId: ID, creativeIds: [ID!], endDate: String!, limit: Int!, startDate: String!): GetGiveawayWinnersPayload
  globalMessageTemplates(after: String, before: String, channel: Channel!, filter: GlobalMessageTemplatesFilterInput, first: Int, last: Int): MessageTemplateConnection
  imageGenerationOptions: ImageGenerationOptionsPayload
  integrationApplications(after: String, before: String, first: Int, last: Int): IntegrationApplicationsConnection
  keywordRuleByKeywordId(keywordId: ID!): KeywordRule
  keywordRulesInfoByKeywordId(companyId: ID!, keywordId: ID!): KeywordRulesInfoPayload
  marketplaceApplications(after: String, before: String, first: Int, last: Int): ApplicationConnection
  messageFeedback(after: String, filter: AgentMessageFeedbackFilterInput, first: Int, handled: Boolean!, recipient: ID, searchTerm: String, sort: MessageFeedbackSortInput): MessageFeedbackConnection
  messages(after: String, filter: FindMessagesFilter!, first: Int, sortBy: MessageSortField, sortDirection: SortDirection): MessageConnection
  messagesCsv(filter: FindMessagesFilter!, sortBy: MessageSortField, sortDirection: SortDirection): MessagesCsvResponse
  node(id: ID!): Node
  nodes(ids: [ID!]!): [Node]!
  notificationBanners(after: String, first: Int): NotificationBannerConnection
  notifications(after: String, companyId: ID!, first: Int, notificationType: NotificationType): NotificationConnection
  phoneNumbers(after: String, before: String, filters: ListPhoneNumbersFilterInput!, first: Int, last: Int): PhoneNumbersConnection
  postalCodes(adjacent: ListAdjacentPostalCodesInput, after: String, countryIsoCode: String, first: Int): PostalCodeConnection
  prebuiltContent(after: String, before: String, first: Int, last: Int, prebuiltProgramId: ID!): PrebuiltContentConnection
  prebuiltPrograms(after: String, before: String, first: Int, last: Int): PrebuiltProgramConnection
  programOrchestrations(companyId: ID!, prebuiltContentId: ID!): OrchestrationConnection
  providerMigrations(carrier: Carrier, phoneNumber: String!): ProviderMigrationsResponse
  rateLimit(aggregator: Aggregator, country: RateLimitCountry, phoneNumberType: PhoneNumberType, provider: Provider!, textChannel: TextChannel!): RateLimit
  roles(companyId: ID!): GetRolesResponse
  routeRateLimits: RouteRateLimitsPayload
  searchProviderMigrations(after: String, before: String, carrier: Carrier, endDate: DateTime, first: Int, last: Int, newProvider: Provider, oldProvider: Provider, phoneNumber: [String!], startDate: DateTime, status: ProviderMigrationStatus): ProviderMigrationConnection
  segmentEnhancementsWorkflowResult(callbackId: SegmentEnhancementsWorkflowResultInputCallbackId, companyId: ID!, workflowId: String!): SegmentEnhancementsWorkflowResultPayload
  shopifyAutoApplyFormat(companyId: ID!): GetShopifyAutoApplyFormatsPayload
  site: Site!
  supportedCustomPropertyDataTypes: ListSupportedCustomPropertyDataTypesResponse
  targetingData(after: String, companyId: ID, contextKey: String, contextValue: String, featureFlagId: ID!, first: Int): TargetingConnection
  targetingDatum(contextKey: String!, contextValue: String!, featureFlagId: ID!): TargetingDatum
  user(id: ID!): User
  users(after: String, companyId: ID, email: String, filter: ListUsersFilter, first: Int, phoneNumber: String): UsersConnection
  validatePostalCodes(countryId: String, postalCodeInput: PostalCodeInputList): ValidatePostalCodesResponse
  validateTemplateField(companyId: ID, content: String!, validationType: TemplateFieldValidationType!): ValidateTemplateFieldResponse
  verifyAIJourneyWelcomeSent(lookbackDurationMinutes: Int!, userCompanyId: ID!): VerifyAIJourneyWelcomeSentPayload
  viewer: Viewer
  workloadConfig(workloadName: String!): WorkloadConfig
  workloadConfigs(tags: [String!], teamName: String): [WorkloadConfig!]
  workloadHistory(workloadName: String!): [WorkloadRun!]
  workloadStates(workloadName: String!): [WorkloadJobState!]
  zendeskHelpCenterArticles(after: String, first: Int, searchTerm: String!): ZendeskHelpCenterArticleConnection
}

input QueueTagAiStartCollectionEventInput {
  companyIds: [ID!]
  config: StartCollectionConfig!
}

union QueueTagAiStartCollectionEventPayload = DefaultErrorFailure | QueueTagAiStartCollectionEventSuccess

# Generated from com/attentivemobile/contracts/tag/ai/queueing/service/Contract.proto
type QueueTagAiStartCollectionEventSuccess {
  responses: [TagAiStartCollectionEventResponse!]!
}

# Generated from com/attentivemobile/contracts/company-service/region/Model.proto
type QuietHours {
  # A time of day (hrs, mins, secs, nanos), representing the end of regional, daily quiet hours.
  end: TimeOfDay!

  # A time of day (hrs, mins, secs, nanos), representing the beginning of regional, daily quiet hours.
  start: TimeOfDay!

  # The timezone for the region and its associated quiet hours.
  timezone: String!
}

input QuietHoursInput {
  # A time of day (hrs, mins, secs, nanos), representing the end of regional, daily quiet hours.
  end: TimeOfDayInput

  # A time of day (hrs, mins, secs, nanos), representing the beginning of regional, daily quiet hours.
  start: TimeOfDayInput

  # The timezone for the region and its associated quiet hours.
  timezone: String
}

# Generated from com/attentivemobile/contracts/ratelimiting/Contract.proto
type RateLimit implements Node {
  aggregator: Aggregator
  channel: TextChannel!
  country: RateLimitCountry!
  id: ID!
  limit: Long!
  phoneNumberType: PhoneNumberType
  provider: Provider!
}

enum RateLimitCountry {
  RATE_LIMIT_COUNTRY_AU
  RATE_LIMIT_COUNTRY_CA
  RATE_LIMIT_COUNTRY_UK
  RATE_LIMIT_COUNTRY_UNKNOWN
  RATE_LIMIT_COUNTRY_US
}

# Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto
type RateLimits {
  emailRateLimit: Float!
  mmsRateLimit: Float!
  smsRateLimit: Float!
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type RbmCard {
  description: String
  media: RbmCardMedia
  suggestions: [RbmSuggestion!]!
  title: String
}

enum RbmCardAlignment {
  RBM_CARD_ALIGNMENT_LEFT
  RBM_CARD_ALIGNMENT_RIGHT
  RBM_CARD_ALIGNMENT_UNKNOWN
}

input RbmCardInput {
  description: String
  media: RbmCardMediaInput
  suggestions: [RbmSuggestionInput!]
  title: String
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type RbmCardMedia {
  height: RbmCardMediaHeight!
  mediaUrl: String!
  thumbnailUrl: String
}

enum RbmCardMediaHeight {
  RBM_CARD_MEDIA_HEIGHT_MEDIUM
  RBM_CARD_MEDIA_HEIGHT_SHORT
  RBM_CARD_MEDIA_HEIGHT_TALL
  RBM_CARD_MEDIA_HEIGHT_UNKNOWN
}

input RbmCardMediaInput {
  height: RbmCardMediaHeight!
  mediaUrl: String!
  thumbnailUrl: String
}

enum RbmCardOrientation {
  RBM_CARD_ORIENTATION_HORIZONTAL
  RBM_CARD_ORIENTATION_UNKNOWN
  RBM_CARD_ORIENTATION_VERTICAL
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type RbmCardProperties {
  alignment: RbmCardAlignment!
  orientation: RbmCardOrientation!
  rbmCard: RbmCard!
}

input RbmCardPropertiesInput {
  alignment: RbmCardAlignment!
  orientation: RbmCardOrientation!
  rbmCard: RbmCardInput!
}

enum RbmCardWidth {
  RBM_CARD_WIDTH_MEDIUM
  RBM_CARD_WIDTH_SMALL
  RBM_CARD_WIDTH_UNKNOWN
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type RbmCarouselProperties {
  cards: [RbmCard!]!
  width: RbmCardWidth!
}

input RbmCarouselPropertiesInput {
  cards: [RbmCardInput!]
  width: RbmCardWidth!
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type RbmChannelProperties {
  fallback: RbmFallback!
  macroStatsLinkId: Long!
  rbmMessageParts: [RbmMessagePart!]!
  shortLinks: [ShortLink!]!
  suggestions: [RbmSuggestion!]!
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type RbmFallback {
  mediaUrl: String
  template: String!
}

input RbmFallbackRequest {
  mediaUrl: String
  template: String!
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type RbmMediaProperties {
  mediaUrl: String!
  thumbnailUrl: String
}

input RbmMediaPropertiesInput {
  fileUrl: String!
  thumbnailUrl: String
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type RbmMessagePart {
  rbmMessageProperties: RbmMessageProperties!
}

input RbmMessagePartInput {
  cardProperties: RbmCardPropertiesInput
  carouselProperties: RbmCarouselPropertiesInput
  mediaProperties: RbmMediaPropertiesInput
  textProperties: RbmTextPropertiesInput
}

union RbmMessageProperties = RbmCardProperties | RbmCarouselProperties | RbmMediaProperties | RbmTextProperties

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type RbmOpenLinkSuggestion {
  template: String!
  url: String!
}

input RbmOpenLinkSuggestionRequest {
  template: String!
  url: String!
}

input RbmPreviewRecipientsInput {
  phoneNumbers: [String!]
}

input RbmPropertiesInput {
  fallback: RbmFallbackRequest
  rbmMessageParts: [RbmMessagePartInput!]
  shortLinkProperties: [ShortLinkProperties!]
  suggestions: [RbmSuggestionInput!]
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type RbmReplySuggestion {
  template: String!
}

input RbmReplySuggestionRequest {
  template: String!
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type RbmSuggestion {
  rbmSuggestionProperties: RbmSuggestionProperties!
}

input RbmSuggestionInput {
  openLinkSuggestion: RbmOpenLinkSuggestionRequest
  replySuggestion: RbmReplySuggestionRequest
}

union RbmSuggestionProperties = RbmOpenLinkSuggestion | RbmReplySuggestion

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type RbmTextProperties {
  template: String!
}

input RbmTextPropertiesInput {
  template: String!
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type RcsCard {
  description: String
  media: RcsCardMedia
  suggestions: [RcsSuggestion!]!
  title: String
}

enum RcsCardAlignment {
  RCS_CARD_ALIGNMENT_LEFT
  RCS_CARD_ALIGNMENT_RIGHT
  RCS_CARD_ALIGNMENT_UNKNOWN
}

input RcsCardInput {
  description: String
  media: RcsCardMediaInput
  suggestions: [RcsSuggestionInput!]
  title: String
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type RcsCardMedia {
  height: RcsCardMediaHeight!
  mediaUrl: String!
  thumbnailUrl: String
}

enum RcsCardMediaHeight {
  RCS_CARD_MEDIA_HEIGHT_MEDIUM
  RCS_CARD_MEDIA_HEIGHT_SHORT
  RCS_CARD_MEDIA_HEIGHT_TALL
  RCS_CARD_MEDIA_HEIGHT_UNKNOWN
}

input RcsCardMediaInput {
  height: RcsCardMediaHeight!
  mediaUrl: String!
  thumbnailUrl: String
}

enum RcsCardOrientation {
  RCS_CARD_ORIENTATION_HORIZONTAL
  RCS_CARD_ORIENTATION_UNKNOWN
  RCS_CARD_ORIENTATION_VERTICAL
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type RcsCardProperties {
  alignment: RcsCardAlignment!
  orientation: RcsCardOrientation!
  rcsCard: RcsCard!
}

input RcsCardPropertiesInput {
  alignment: RcsCardAlignment!
  card: RcsCardInput!
  orientation: RcsCardOrientation!
}

enum RcsCardWidth {
  RCS_CARD_WIDTH_MEDIUM
  RCS_CARD_WIDTH_SMALL
  RCS_CARD_WIDTH_UNKNOWN
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type RcsCarouselProperties {
  cards: [RcsCard!]!
  width: RcsCardWidth!
}

input RcsCarouselPropertiesInput {
  cards: [RcsCardInput!]
  width: RcsCardWidth!
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type RcsChannelProperties {
  fallback: RcsFallback!
  macroStatsLinkId: Long!
  rcsMessageParts: [RcsMessagePart!]!
  shortLinks: [ShortLink!]!
  suggestions: [RcsSuggestion!]!
}

enum RcsContentType {
  RCS_CONTENT_TYPE_CARD
  RCS_CONTENT_TYPE_CAROUSEL
  RCS_CONTENT_TYPE_FILE
  RCS_CONTENT_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type RcsFallback {
  mediaUrl: String
  template: String!
}

input RcsFallbackRequest {
  mediaUrl: String
  template: String!
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type RcsMediaProperties {
  mediaUrl: String!
  thumbnailUrl: String
}

input RcsMediaPropertiesInput {
  fileUrl: String!
  thumbnailUrl: String
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type RcsMessagePart {
  rcsMessageProperties: RcsMessageProperties!
}

input RcsMessagePartInput {
  cardProperties: RcsCardPropertiesInput
  carouselProperties: RcsCarouselPropertiesInput
  mediaProperties: RcsMediaPropertiesInput
  textProperties: RcsTextPropertiesInput
}

union RcsMessageProperties = RcsCardProperties | RcsCarouselProperties | RcsMediaProperties | RcsTextProperties

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type RcsOpenLinkSuggestion {
  template: String!
  url: String!
}

input RcsOpenLinkSuggestionRequest {
  template: String!
  url: String!
}

input RcsPreviewInput {
  fallback: RcsFallbackRequest!
  macroStatsLinkId: Long!
  rcsMessageParts: [RcsMessagePartInput!]
  shortLinkProperties: [ShortLinkProperties!]
  suggestions: [RcsSuggestionInput!]
}

input RcsPreviewRecipientsInput {
  phoneNumbers: [String!]
}

input RcsPropertiesInput {
  fallback: RcsFallbackRequest
  messageParts: [RcsMessagePartInput!]
  shortLinkProperties: [ShortLinkProperties!]
  suggestions: [RcsSuggestionInput!]
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type RcsReplySuggestion {
  template: String!
}

input RcsReplySuggestionRequest {
  template: String!
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type RcsSuggestion {
  rcsSuggestionProperties: RcsSuggestionProperties!
}

input RcsSuggestionInput {
  openLinkSuggestion: RcsOpenLinkSuggestionRequest
  replySuggestion: RcsReplySuggestionRequest
}

union RcsSuggestionProperties = RcsOpenLinkSuggestion | RcsReplySuggestion

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type RcsTextProperties {
  template: String!
}

input RcsTextPropertiesInput {
  template: String!
}

input ReadNotificationInput {
  companyId: ID!
  notificationInstanceId: ID!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type ReadNotificationPayload {
  notification: Notification!
}

input RecipientInput {
  email: String
  phone: String
}

# Generated from com/attentivemobile/contracts/composition/MessageAttachment.proto
type RecommendationAttachment {
  entityToken: String!
  productDiversityCriteria: ProductDiversityCriteria
  productSegment: ProductSegment
  recommendationType: RecommendationAttachmentType!
}

input RecommendationAttachmentProperties {
  entityToken: String!
  productDiversityCriteria: ProductDiversityCriteriaInput
  productSegmentId: ID
}

enum RecommendationAttachmentType {
  RECOMMENDATION_ATTACHMENT_TYPE_COMPANY_BESTSELLER
  RECOMMENDATION_ATTACHMENT_TYPE_PERSONALIZED
  RECOMMENDATION_ATTACHMENT_TYPE_UNKNOWN
}

input RecommendedTemplateInput {
  rank: Long!
  templateId: String!
}

input RecommendedTemplateListInput {
  recommendedTemplates: [RecommendedTemplateInput!]
}

input RecoverDatastreamInput {
  datastreamName: String!
  recoveryPolicy: RecoverDatastreamInputRecoveryPolicy
  runtime: RecoverDatastreamInputRuntime
}

input RecoverDatastreamInputRecoveryPolicy {
  startWithState: StateRecoveryPolicy
  startWithoutState: Boolean
}

input RecoverDatastreamInputRuntime {
  newFlinkJob: FlinkJobWrite
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Contract.proto
type RecoverDatastreamPayload {
  newRuntime: RecoverDatastreamPayloadNewRuntime
  oldRuntime: RecoverDatastreamPayloadOldRuntime
  restorePath: String!
  result: OperationResult!
}

union RecoverDatastreamPayloadNewRuntime = MigrateFlinkJobResponse

union RecoverDatastreamPayloadOldRuntime = MigrateFlinkJobResponse

enum RedeemCouponError {
  REDEEM_COUPON_ERROR_ALREADY_REDEEMED
  REDEEM_COUPON_ERROR_API
  REDEEM_COUPON_ERROR_EXPIRED
  REDEEM_COUPON_ERROR_INVALID
  REDEEM_COUPON_ERROR_UNKNOWN
}

input RedeemGapStoresCouponInput {
  campaignId: String
  companyId: ID!
  couponCode: String!
  encodedSubscriberId: String!
  offerId: String
  region: String
}

# Generated from com/attentivemobile/contracts/mobilewalletservice/Contract.proto
type RedeemGapStoresCouponPayload {
  errorCode: RedeemCouponError
  mobileWalletUrl: String!
}

input RefreshItemListPagesInput {
  companyId: [ID!]
}

union RefreshItemListPagesPayload = DefaultErrorFailure | RefreshItemListPagesSuccess

# Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto
type RefreshItemListPagesSuccess {
  isSuccessful: Boolean
}

input RefreshProgramSendTimesInput {
  prebuiltContentId: ID!
}

# Generated from com/attentivemobile/contracts/messaging/automation/admin/Contract.proto
type RefreshProgramSendTimesPayload {
  prebuiltContent: PrebuiltContent!
}

input RefreshSessionOrChallengeInput {
  id: RefreshSessionOrChallengeInputId
}

input RefreshSessionOrChallengeInputId {
  companyId: ID
  externalCompanyId: Long
}

union RefreshSessionOrChallengePayload = DefaultErrorFailure | RefreshSessionOrChallengeSuccess

union RefreshSessionOrChallengeResponseResponse = ChallengeResponse | RefreshSessionResponse

# Generated from com/attentivemobile/contracts/account-service/AuthenticatorContract.proto
type RefreshSessionOrChallengeSuccess {
  response: RefreshSessionOrChallengeResponseResponse
}

# Generated from com/attentivemobile/contracts/account-service/AuthenticatorContract.proto
type RefreshSessionResponse {
  company: Company
  token: String!
}

input RefreshUserFileDownloadUrlInput {
  id: ID!
}

# Generated from com/attentivemobile/contracts/user/profile/download/Contract.proto
type RefreshUserFileDownloadUrlPayload {
  file: UserFileDownload
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type RefundFailedEvent {
  company: Company
  order: OrderInfo!
  refund: RefundInfo!
}

# Generated from com/attentivemobile/contracts/event/ecommerce/Refund.proto
# Represents a refund applied to some line items to an order
type RefundInfo {
  # The total of all refund transactional amounts toward the customer in this refund (i.e. shipping costs, reimbursed duties, etc)
  amount: Money!

  # A unique identifier of the refund.
  externalRefundId: String!

  # The time at which the refund was created
  processedAt: DateTime!

  # Information about what quantities of what items are being refunded
  refundLineItems: [RefundLineItem!]!
}

# Generated from com/attentivemobile/contracts/event/ecommerce/Refund.proto
# Represents a refund applied to a line item in an order
type RefundLineItem {
  # The quantity of the item refunded
  quantityRefunded: Int!

  # The pertinent line item
  relatedLineItem: OrderProduct!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type RefundSucceededEvent {
  company: Company
  order: OrderInfo!
  refund: RefundInfo!
}

# Generated from com/attentivemobile/contracts/company-service/region/Model.proto
type Region implements Node {
  address: RegionAddress!

  # The company's attn.tv domain for this region. For example, in France, Super Duper Pooper Scoopers might have an attn.tv domain like sdps-fr.attn.tv
  attentiveDomain: String!
  company: Company
  countryCode: String!
  databaseId: Long!

  # A designation for the region, like DEFAULT. In the case of DEFAULT, one and only one Region will be so designated.
  designation: RegionDesignation!

  # The company's domain in this region. For example, in France, Super Duper Pooper Scoopers might have a domain like superduperpooperscoopers.example.fr.
  domain: String!
  fatigueRules: [CompanyRegionFatigueRule!]!
  id: ID!
  languageTag: String!
  localizedSettings: [LocalizedSettings!]!
  privacyUrl: Url!

  # Inverse of Business Hours. The times between which no responses should go out. The start and end fields are in UTC time!
  quietHours: QuietHours!
  salesforceOpportunityId: String @deprecated(reason: "deprecated")
  termsUrl: Url!
  timezone: String!
}

# Generated from com/attentivemobile/contracts/company-service/region/Model.proto
type RegionAddress {
  # street address
  address1: String!

  # additional, optional street address qualifiers like Suite, Flat, Floor, Box, etc.
  address2: String
  countryCode: String!

  # Locality containing the street address, like city or town.
  locality: String!
  postalCode: String

  # Region containing the locality, like state or province. Some countries' postal services don't require regions in addresses.
  region: String
}

input RegionAddressInput {
  # street address
  address1: String

  # additional, optional street address qualifiers like Suite, Flat, Floor, Box, etc.
  address2: String
  countryCode: String

  # Locality containing the street address, like city or town.
  locality: String
  postalCode: String

  # Region containing the locality, like state or province. Some countries' postal services don't require regions in addresses.
  region: String
}

enum RegionDesignation {
  # One and only one region gets the default designation.
  REGION_DESIGNATION_DEFAULT

  # We designate non-default regions "OTHER" to indicate no special relation with other regions.
  REGION_DESIGNATION_OTHER

  # No known, supported designation.
  REGION_DESIGNATION_UNKNOWN
}

input RegionInput {
  address: RegionAddressInput

  # The company's attn.tv domain for this region. For example, in France, Super Duper Pooper Scoopers might have an attn.tv domain like sdps-fr.attn.tv
  attentiveDomain: String
  companyId: ID!
  countryCode: String

  # A designation for the region, like DEFAULT. In the case of DEFAULT, one and only one Region will be so designated.
  designation: RegionDesignation

  # The company's domain in this region. For example, in France, Super Duper Pooper Scoopers might have a domain like superduperpooperscoopers.example.fr.
  domain: String
  fatigueRules: [CompanyRegionFatigueRuleInput!]
  id: ID!
  languageTag: String
  privacyUrl: UrlInput

  # Inverse of Business Hours. The times between which no responses should go out. The start and end fields are in UTC time!
  quietHours: QuietHoursInput
  salesforceAccountId: String
  salesforceOpportunityId: String @deprecated(reason: "deprecated")
  termsUrl: UrlInput
}

# Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/ComparisonTypes.proto
type RelativeTimeComparison {
  comparator: SearchRelativeTimeComparator!
  endTime: Long
  startTime: Long!
  timeUnit: SearchTimeUnit!
}

input RelativeTimeComparisonInput {
  comparator: SearchRelativeTimeComparator!
  endTime: Long
  startTime: Long!
  timeUnit: SearchTimeUnit!
}

# Generated from com/attentivemobile/contracts/campaigns/service/Stats.proto
type RemainingRecipientsCount {
  recipientcount: Int!
}

input RemoveAccountFromCompaniesInput {
  accountId: ID!
  companyIds: [ID!]
}

union RemoveAccountFromCompaniesPayload = DefaultErrorFailure | RemoveAccountFromCompaniesSuccess

# Generated from com/attentivemobile/contracts/account-service/Contract.proto
type RemoveAccountFromCompaniesSuccess {
  accountId: ID!
}

input RemoveAccountFromCompanyInput {
  accountId: ID!
  companyId: ID!
}

# Generated from com/attentivemobile/contracts/account-service/Contract.proto
type RemoveAccountFromCompanyPayload {
  accountId: ID!
}

input RemoveConciergeCompanyPromotionInput {
  id: ID!
}

input RemoveDomainSuppressionInput {
  companyId: Long
  domain: String!
}

union RemoveDomainSuppressionPayload = DefaultErrorFailure | RemoveDomainSuppressionSuccess

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type RemoveDomainSuppressionSuccess {
  suppression: DomainSuppression!
}

input RemoveEmailIPAssignmentsInput {
  ipAssignmentsToRemove: [EmailIPAssignmentInput!]
}

union RemoveEmailIPAssignmentsPayload = DefaultErrorFailure | RemoveEmailIPAssignmentsSuccess

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type RemoveEmailIPAssignmentsSuccess {
  assignmentsRemoved: Long! @deprecated(reason: "deprecated")
  emailIpAssignments: [EmailIPAssignment!]!
}

input RemoveFeatureFlagInput {
  id: ID!
}

# Generated from com/attentivemobile/contracts/featureflag/service/Contract.proto
type RemoveFeatureFlagPayload {
  removed: FeatureFlag
}

input RemoveRegionInput {
  id: ID!
}

# Generated from com/attentivemobile/contracts/company-service/region/Region.proto
type RemoveRegionPayload {
  region: Region!
}

input RemoveTargetingDataInput {
  featureFlagId: ID!
  inputs: [TargetingRemove!]
}

# Generated from com/attentivemobile/contracts/featureflag/service/Contract.proto
type RemoveTargetingDataPayload {
  removed: TargetingDatum
}

input RemoveUserFromAgentQueueInput {
  userId: ID!
}

# Generated from com/attentivemobile/contracts/concierge/service/Contract.proto
type RemoveUserFromAgentQueueResponse {
  userId: String!
}

input RenameAssetInput {
  assetId: AssetId!
  newDisplayName: String!
}

union RenameAssetPayload = DefaultErrorFailure | RenameAssetSuccess

# Generated from com/attentivemobile/contracts/asset/library/service/Contract.proto
type RenameAssetSuccess {
  renamedFolder: Folder
  renamedImage: Image
}

input ReplaceCompanyCodesInput {
  code: [String!]
  companyId: ID!
}

# Generated from com/attentivemobile/contracts/creative-service/creative/LegacyCreativeService.proto
type ReplaceCompanyCodesPayload {
  codes: [String!]!
}

input ReplayByTimeInput {
  desiredRps: Int
  destinationHttpEndpoint: String!
  destinationStreamName: String!
  endTimeUtc: DateTime!
  eventTypeFilters: [EventType!]
  forceProd: Boolean
  operatorParallelism: Int
  s3ReadParallelism: Int
  sourceS3Uri: String!
  sourceType: ReplaySourceType!
  startTimeUtc: DateTime!
}

union ReplayByTimePayload = DefaultErrorFailure | ReplayByTimeSuccess

# Generated from com/attentivemobile/contracts/event/platform/registration/service/replay/Contract.proto
type ReplayByTimeSuccess {
  datadogNotebookUrl: String!
  datastreamName: String!
  flinkJobId: String!
  jobmanagerUrl: String!
}

input ReplayByUUIDsInput {
  destinationBackupName: String!
  destinationStreamName: String!
  validationS3Dir: String!
}

union ReplayByUUIDsPayload = DefaultErrorFailure | ReplayByUUIDsSuccess

# Generated from com/attentivemobile/contracts/event/platform/registration/service/replay/Contract.proto
type ReplayByUUIDsSuccess {
  csvPath: String!
  datastreamName: String!
  flinkJobId: String!
  jobmanagerUrl: String!
  message: String!
  missingUuidCount: Int!
  validationQuery: String!
}

enum ReplaySourceType {
  SOURCE_TYPE_EVENT
  SOURCE_TYPE_GENERIC
  SOURCE_TYPE_ISTIO_LOGS
  SOURCE_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/reports/api/Contract.proto
type Report {
  createdAt: DateTime
  createdBy: Account
  deliveryFrequencies: [ReportDeliveryFrequency!]!
  description: String
  filterDimensions: [ReportFilterWrapper!]!
  groupingDimensions: [ReportGroupingWrapper!]!
  hasWriteAccess: Boolean
  id: ID!
  metricIds: [String!]!

  # This field is defunct - metrics must be pulled separately based on a report's configuration
  metrics: [Metric!]! @deprecated(reason: "deprecated")
  name: String!
  nextDeliveryDate: DateTime
  recipients: [ReportRecipient!]!
  reportClass: ReportClass
  reportDomain: ReportDomain!
  reportId: String!
  reportType: ReportType!
  selectedChart: ReportChart
  selectedMetricId: String
  sftpEnabled: Boolean
  summaryMetricIds: [String!]! @deprecated(reason: "deprecated")
  summaryMetrics: SummaryMetrics
  supportsGroupByEntireRange: Boolean!
  tableSortOrders: [ReportMetricsOrdering!]!

  # The timestamp is in UTC
  updated: DateTime!
  updatedBy: Account
}

enum ReportChart {
  REPORT_CHART_EXPANSION_REVENUE
  REPORT_CHART_INCREMENTAL_REVENUE
  REPORT_CHART_LINE_CHART
  REPORT_CHART_SUBSCRIBER_GROWTH
  REPORT_CHART_SUBSCRIBER_LIFT
  REPORT_CHART_SUPPRESSED_SENDS
}

# Generated from com/attentivemobile/contracts/reports/api/Contract.proto
type ReportClass implements Node {
  filterDimensions: [DimensionWrapper!]!
  groupingDimensions: [DimensionWrapper!]!
  id: ID!
  metricDefinitions: [MetricDefinition!]!
  metrics: [Metric!]! @deprecated(reason: "deprecated")
  name: String!
}

# Generated from com/attentivemobile/contracts/reports/api/Contract.proto
type ReportConnection {
  edges: [ReportEdge!]!
  pageInfo: PageInfo!
  totalCount: Long!
}

input ReportDeliveryFrequenciesUpdateInput {
  deliveryFrequencies: [ReportDeliveryFrequency!]
}

enum ReportDeliveryFrequency {
  REPORT_DELIVERY_FREQUENCY_DAILY
  REPORT_DELIVERY_FREQUENCY_EMPTY
  REPORT_DELIVERY_FREQUENCY_MONTHLY
  REPORT_DELIVERY_FREQUENCY_QUARTERLY
  REPORT_DELIVERY_FREQUENCY_UNKNOWN
  REPORT_DELIVERY_FREQUENCY_WEEKLY
}

# Generated from com/attentivemobile/contracts/reports/api/Contract.proto
type ReportDomain {
  description: String!
  displayOrder: Int!
  domainId: String!
  name: String!
  orderedReportIds: [String!]!
}

# Generated from com/attentivemobile/contracts/reports/api/Contract.proto
type ReportEdge {
  cursor: String!
  node: Report!
}

union ReportFilter = ReportStringFilter | ReportTimeFilter

# Generated from com/attentivemobile/contracts/reports/api/Contract.proto
type ReportFilterDimensionSet {
  key: String!
  value: String!
}

input ReportFilterInput {
  stringFilter: ReportStringFilterInput
  timeFilter: ReportTimeFilterInput
}

# Generated from com/attentivemobile/contracts/reports/api/Contract.proto
type ReportFilterWrapper {
  filter: ReportFilter!
}

input ReportFiltersUpdateInput {
  filters: [ReportFilterInput!]
}

union ReportGrouping = ReportStringGrouping | ReportTimeGrouping

input ReportGroupingInput {
  stringGrouping: ReportStringGroupingInput
  timeGrouping: ReportTimeGroupingInput
}

# Generated from com/attentivemobile/contracts/reports/api/Contract.proto
type ReportGroupingWrapper {
  grouping: ReportGrouping!
}

input ReportGroupingsUpdateInput {
  groupings: [ReportGroupingInput!]
}

# Generated from com/attentivemobile/contracts/reports/api/Contract.proto
type ReportMetricsOrdering {
  dimensionId: String
  metricId: String
  sortDirection: ReportsSortDirection!
  sortRank: Int!
}

input ReportMetricsOrderingInput {
  dimensionId: String
  metricId: String
  sortDirection: ReportsSortDirection!
  sortRank: Int!
}

input ReportMetricsUpdateInput {
  metricIds: [String!]
}

# Generated from com/attentivemobile/contracts/reports/api/CustomReport.proto
type ReportRecipient {
  account: Account
}

input ReportSortOptions {
  column: ReportsSortColumn!
  direction: ReportsSortDirection!
}

# Generated from com/attentivemobile/contracts/reports/api/Contract.proto
type ReportStringFilter {
  dimensionId: String!
  dimensionSets: [ReportFilterDimensionSet!]!
  filterOperator: FilterOperator
  isAllFilterOperator: Boolean
  value: String! @deprecated(reason: "deprecated")
  values: [String!]!
}

input ReportStringFilterInput {
  dimensionId: String!
  filterOperator: FilterOperator!
  value: String
  values: [String!]
}

# Generated from com/attentivemobile/contracts/reports/api/Contract.proto
type ReportStringGrouping {
  dimensionId: String!
}

input ReportStringGroupingInput {
  dimensionId: String!
}

# Generated from com/attentivemobile/contracts/reports/api/Contract.proto
type ReportTimeFilter {
  comparisonPeriod: ComparisonPeriod
  dateFilterPeriod: DateFilterPeriod
  dimensionId: String!
  endDate: DateTime
  startDate: DateTime
}

input ReportTimeFilterInput {
  comparisonPeriod: ComparisonPeriodInput
  dateFilterPeriod: DateFilterPeriod
  dimensionId: String!
  endDate: DateTime
  startDate: DateTime
}

# Generated from com/attentivemobile/contracts/reports/api/Contract.proto
type ReportTimeGrouping {
  dimensionId: String!
  granularity: TimeDimensionGranularity!
}

input ReportTimeGroupingInput {
  dimensionId: String!
  granularity: TimeDimensionGranularity!
}

enum ReportType {
  REPORT_TYPE_CUSTOM
  REPORT_TYPE_LEGACY
  REPORT_TYPE_STANDARD
  REPORT_TYPE_UNKNOWN
}

enum ReportsSortColumn {
  REPORTS_SORT_COLUMN_NAME
  REPORTS_SORT_COLUMN_NEXT_DELIVERY_DATE
  REPORTS_SORT_COLUMN_TYPE
  REPORTS_SORT_COLUMN_UNKNOWN
}

enum ReportsSortDirection {
  REPORTS_SORT_DIRECTION_ASC
  REPORTS_SORT_DIRECTION_DESC
  REPORTS_SORT_DIRECTION_UNKNOWN
}

# Generated from com/attentivemobile/contracts/journeys/api/RevisionStepConfig.proto
type ReservedKeyword {
  keyword: String!
  type: ReservedKeywordType!
}

enum ReservedKeywordType {
  RESERVED_KEYWORD_TYPE_COMPANY_CODE
  RESERVED_KEYWORD_TYPE_INCOMING_TEXT
  RESERVED_KEYWORD_TYPE_SUBSCRIBER_OPT_OUT
  RESERVED_KEYWORD_TYPE_TCPA_COMPLIANCE
  RESERVED_KEYWORD_TYPE_TWO_WAY_MESSAGE
  RESERVED_KEYWORD_TYPE_UNKNOWN
}

input ReservedPropertyInput {
  # Dynamic override rules for reserved properties.
  linkOverride: [PropertyLinkInput!]
  reservedType: ReservedPropertyType!
}

enum ReservedPropertyType {
  RESERVED_PROPERTY_TYPE_COUNTRY
  RESERVED_PROPERTY_TYPE_FIRST_NAME
  RESERVED_PROPERTY_TYPE_FULL_NAME
  RESERVED_PROPERTY_TYPE_LAST_NAME
  RESERVED_PROPERTY_TYPE_LOCALE
  RESERVED_PROPERTY_TYPE_UNKNOWN
}

input ResetAccountMfaInput {
  accountId: ID!
}

# Generated from com/attentivemobile/contracts/account-service/MFAContract.proto
type ResetAccountMfaPayload {
  successful: Boolean!
}

input ResetPollInput {
  companyId: ID!
  pollId: ID!
}

# Generated from com/attentivemobile/contracts/survey/poll/Contract.proto
type ResetPollPayload {
  success: Boolean!
}

input ResetProgramsInput {
  companyId: ID!
}

# Generated from com/attentivemobile/contracts/messaging/automation/admin/Contract.proto
type ResetProgramsPayload {
  company: Company
}

input ResetServerSideTagEventsApiKeyInput {
  companyId: ID!
}

union ResetServerSideTagEventsApiKeyPayload = DefaultErrorFailure | ResetServerSideTagEventsApiKeySuccess

# Generated from com/attentivemobile/contracts/integrations/serversidetag/Contract.proto
type ResetServerSideTagEventsApiKeySuccess {
  success: Boolean!
}

input ResetSftpUserPasswordInput {
  companyId: ID!
}

# Generated from com/attentivemobile/contracts/solutions/sftp/SftpUser.proto
type ResetSftpUserPasswordPayload {
  details: String!
}

input ResetSubscriberInput {
  companyId: Long!
  phone: String!
}

# Generated from com/attentivemobile/contracts/subscriptions/api/Contract.proto
type ResetSubscriberPayload {
  success: Boolean!
}

input RespondToCognitoAuthChallengeInput {
  companyId: ID!
  newPassword: String!
  sessiontoken: String!
}

# Generated from com/attentivemobile/contracts/solutions/sftp/SftpUser.proto
type RespondToCognitoAuthChallengePayload {
  msg: String!
}

input RestartAiProTrialSubscriptionInput {
  companyId: ID!
}

union RestartAiProTrialSubscriptionPayload = AiProTrialActionFailure | RestartAiProTrialSubscriptionSuccess

# Generated from com/attentivemobile/contracts/multiproductenablement/service/aipro/Contract.proto
type RestartAiProTrialSubscriptionSuccess {
  success: Boolean!
}

input RevisePaymentsOfferDiscountInput {
  type: OfferDiscountType
  value: String
}

input RevisePaymentsOfferInput {
  companyId: ID!
  discount: RevisePaymentsOfferDiscountInput
  expiresAt: DateTime
  externalId: String!
  keyword: String
  messageId: ID
  productName: String
  shopifyProductId: String
  shopifyProductPrice: MoneyInput
  shopifyProductVariantId: String
}

# Generated from com/attentivemobile/contracts/payments/v2/Contract.proto
type RevisePaymentsOfferPayload {
  offer: PaymentsOffer
  status: RevisePaymentsOfferStatus!
}

enum RevisePaymentsOfferStatus {
  REVISE_OFFER_STATUS_FAIL_KEYWORD_NOT_ACCEPTABLE
  REVISE_OFFER_STATUS_FAIL_RESERVED_KEYWORD
  REVISE_OFFER_STATUS_OFFER_IN_USE
  REVISE_OFFER_STATUS_SUCCESS
  REVISE_OFFER_STATUS_UNKNOWN
}

# Generated from com/attentivemobile/contracts/journeys/api/RevisionStep.proto
type RevisionStep implements Node {
  childrenSteps: [RevisionStep!]! @deprecated(reason: "deprecated")
  id: ID!
  internalId: String!
  type: RevisionStepType
}

union RevisionStepType = AdderStep | CreateSupportTicketStep | DelayStep | ExperimentStep | ForwardToConciergeStep | IncomingTextStep | SaveUserAttributeStep | VariantStep

enum RevisionStepValue {
  REVISION_STEP_VALUE_CREATE_SUPPORT_TICKET
  REVISION_STEP_VALUE_FORWARD_TO_CONCIERGE
  REVISION_STEP_VALUE_INCOMING_TEXT
  REVISION_STEP_VALUE_KEYWORD_RESPONSE
  REVISION_STEP_VALUE_OPEN_RESPONSE
  REVISION_STEP_VALUE_SAVE_USER_ATTRIBUTE
  REVISION_STEP_VALUE_SEND_CONVERSATION_MESSAGE
  REVISION_STEP_VALUE_SEND_EMAIL
  REVISION_STEP_VALUE_SEND_LEGAL_WELCOME
  REVISION_STEP_VALUE_SEND_MESSAGE
  REVISION_STEP_VALUE_TRIGGER
  REVISION_STEP_VALUE_TWO_WAY_MESSAGE
  REVISION_STEP_VALUE_UNKNOWN
  REVISION_STEP_VALUE_VARIANT
}

# This request will be for security users only in order to revoke roles from other users
input RevokeRolesByEmailInput {
  email: String!
  roles: [Role!]
}

union RevokeRolesByEmailPayload = DefaultErrorFailure | RevokeRolesByEmailSuccess

# Generated from com/attentivemobile/contracts/account-service/AuthorizationContract.proto
# If success is true, the admin role was revoked successfully
type RevokeRolesByEmailSuccess {
  success: Boolean!
}

enum Role {
  ROLE_ADMIN_ACCESS
  ROLE_ATTENTIVE_ACCOUNT
  ROLE_CLIENT_ACCOUNT
  ROLE_CLIENT_ADMIN_ACCOUNT
  ROLE_CLIENT_LIMITED_ACCOUNT
  ROLE_CLIENT_MANAGED_ACCOUNT
  ROLE_CLIENT_MANAGED_ADMIN_ACCOUNT
  ROLE_CLIENT_MEMBER_ACCOUNT
  ROLE_CLIENT_STRATEGY
  ROLE_CONCIERGE
  ROLE_CONCIERGE_OPERATIONS
  ROLE_CS_TAM
  ROLE_E2E_TEST_USER
  ROLE_ENGINEERING
  ROLE_ENGINEERING_IDENTITY
  ROLE_ENGINEERING_SALES
  ROLE_ENGINEERING_SOLUTIONS
  ROLE_ENGINEERING_TACTICAL
  ROLE_IT_SECURITY
  ROLE_MESSAGING_OPS
  ROLE_PRODUCT_MANAGEMENT
  ROLE_READONLY_CONCIERGE
  ROLE_SUBSCRIBER_AUDITOR
  ROLE_SUPER_USER
  ROLE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/generativeaiservice/template/GenerativeAITemplateContract.proto
type RoleBasedTemplateContent {
  content: String!
  role: String!
}

# Generated from com/attentivemobile/contracts/featureflag/service/Contract.proto
type Rollout {
  percent: Float!
  variation: String!
}

input RolloutInput {
  percent: Float!
  variation: String!
}

# Generated from com/attentivemobile/contracts/gateway/service/RateLimiting.proto
type RouteRateLimitsPayload {
  routeRateLimitResponses: [UpdateRouteRateLimitSuccess!]!
}

# Generated from com/attentivemobile/contracts/keywords/KeywordRuleService.proto
type RulesLegendEntry {
  key: String!
  value: String!
}

input RunBackfillInput {
  backfillType: BackfillType!
  companyId: ID!
  endDate: String!
  etlId: Long!
  isRollUp: Boolean!
  isSingleCompanyBackfill: Boolean
  runBy: String!
  startDate: String!
}

# Generated from com/attentivemobile/contracts/solutions/sftp/Sftp.proto
type RunBackfillPayload {
  company: Company
}

# Generated from com/attentivemobile/contracts/eventdestination/telemetry/service/Contract.proto
type RunReport {
  end: String!
  runUuid: String!
  start: String!
  statusCodeCounts: [StatusCodeCountsEntry!]!
  taskId: String!
  type: String!
}

input RunScheduledReportBackfillInput {
  companyId: ID!
  endDate: String!
  etlId: Long!
  startDate: String!
}

# Generated from com/attentivemobile/contracts/solutions/sftp/Sftp.proto
type RunScheduledReportBackfillPayload {
  company: Company
}

input RunSftpReportBackfillInput {
  companyId: ID!
  endDate: String!
  etlId: Long!
  isRollUp: Boolean!
  isSingleCompanyBackfill: Boolean
  startDate: String!
}

# Generated from com/attentivemobile/contracts/solutions/sftp/Sftp.proto
type RunSftpReportBackfillPayload {
  company: Company
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type RunsEntry {
  key: String!
  value: JobErrorReport!
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Datastream.proto
type S3EventSink {
  s3Location: S3Location!
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Datastream.proto
type S3GenericSink {
  s3Location: S3Location!
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/DatastreamModel.proto
type S3Location {
  uri: String!
}

# Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto
type SampleAudienceFilter {
  samplePercentage: Float!
}

input SaveAITemplateInput {
  templateInputs: [TemplateInput!]
}

# Generated from com/attentivemobile/contracts/generativeaiservice/template/GenerativeAITemplateContract.proto
type SaveAITemplatePayload {
  isCreated: Boolean!
}

input SaveAutoJourneySettingsInput {
  autoJourneyTriggerType: AutoJourneyTriggerType!
  companyId: ID!
  discountAmount: String
  enableGoogleLinkTracking: Boolean
  promoCode: String
  utmCampaignName: String
}

# Generated from com/attentivemobile/contracts/messaging/automation/journeys/Contract.proto
type SaveAutoJourneySettingsPayload {
  isAutoJourneysSettingsSaved: Boolean!
}

input SaveAvailableBrandFontsInput {
  companyId: ID!
  fonts: [AvailableBrandFontsInput!]
}

# Generated from com/attentivemobile/contracts/brand-kit/BrandFont.proto
type SaveAvailableBrandFontsPayload {
  availableBrandFonts: [AvailableBrandFont!]!
}

input SaveConciergeCartInput {
  coupon: ConciergeCartCouponInput
  couponCode: String @deprecated(reason: "deprecated")
  items: [ConciergeCartItemInput!]
  sessionId: String!
}

# Generated from com/attentivemobile/contracts/concierge/service/ShoppingCart.proto
type SaveConciergeCartPayload {
  cart: SavedConciergeCart!
}

input SaveConciergeSpecializationInput {
  agentIds: [ID!]
  companyIds: [ID!]
  description: String
  id: ID
  name: String!
  strictRouting: Boolean
}

# Generated from com/attentivemobile/contracts/concierge/service/Specialization.proto
type SaveConciergeSpecializationPayload {
  conciergeSpecialization: ConciergeSpecialization
}

# Generated from com/attentivemobile/contracts/company-service/onboarding/Company.proto
type SaveContactCardFailure implements StandardError {
  companyId: String
  message: String!
  status: StandardErrorStatus!
  title: String!
  traceId: String!
}

input SaveContactCardInput {
  contactCard: CompanyContactCardInput!
}

union SaveContactCardPayload = SaveContactCardFailure | SaveContactCardSuccess

# Generated from com/attentivemobile/contracts/company-service/onboarding/Company.proto
type SaveContactCardSuccess {
  company: Company
}

input SaveGeneratedTextAndFeedbackInput {
  companyId: ID!
  feedbackCategory: GenerativeAITextFeedbackCategory
  feedbackType: GenerativeAITextFeedbackType!
  generatedText: String!
  generatedTextInputMetadata: GeneratedTextInputMetadata!
  groupId: String!
  messageId: ID
  source: String!
  userId: ID!
}

# Generated from com/attentivemobile/contracts/generativeaiservice/feedback/SaveGeneratedTextAndFeedbackContract.proto
type SaveGeneratedTextAndFeedbackPayload {
  statusText: String!
}

input SaveMappingAndEnqueueBatchJobInput {
  fileId: ID!
  jobParameters: SubscriberUploadJobParametersInput!
  mappings: [SubscriberUploadFileMappingInput!]
  subscriberUploadJobType: SubscriberUploadJobType
}

# Generated from com/attentivemobile/contracts/subscriptions/batch/Contract.proto
type SaveMappingAndEnqueueBatchJobPayload {
  fileId: String!
}

input SaveOnboardingSurveySelectionsInput {
  companyId: ID!
  selections: [OnboardingSurveySelectionInput!]
}

input SaveSelectedBrandFontsInput {
  companyId: ID!
  fonts: [SelectedBrandFontsInput!]
}

# Generated from com/attentivemobile/contracts/brand-kit/BrandFont.proto
type SaveSelectedBrandFontsPayload {
  company: Company
  selectedBrandFonts: [SelectedBrandFont!]!
}

# Generated from com/attentivemobile/contracts/journeys/api/RevisionStep.proto
type SaveUserAttributeStep {
  childrenSteps: [AdderStep!]!
  stepParameters: SaveUserAttributeStepParameters!
}

# Generated from com/attentivemobile/contracts/journeys/api/RevisionStepParameters.proto
type SaveUserAttributeStepParameters {
  userAttributeValueId: String!
}

# Generated from com/attentivemobile/contracts/concierge/service/ShoppingCart.proto
type SavedConciergeCart {
  coupon: ConciergeCartCoupon
  couponCode: String @deprecated(reason: "deprecated")
  items: [SavedConciergeCartItem!]!
  sessionId: String!
}

# Generated from com/attentivemobile/contracts/concierge/service/ShoppingCart.proto
type SavedConciergeCartItem {
  lastUpdated: DateTime!
  productVariant: ProductVariant
  quantity: Int!
}

input SceneConstructionInput {
  imageScale: Float
  inputImageUrls: [UrlInput!]
  modifiers: [SceneConstructionModifierInput!]
  scene: String!
  scenePreposition: String!
}

input SceneConstructionModifierInput {
  selectedValues: [String!]
  title: String!
}

# Generated from com/attentivemobile/contracts/generativeaiservice/image/ImageGenerationContract.proto
type SceneConstructionOption {
  options: [String!]!
  title: String!
  type: SceneConstructionOptionType!
}

enum SceneConstructionOptionType {
  SCENE_CONSTRUCTION_OPTION_TYPE_MULTI_SELECT
  SCENE_CONSTRUCTION_OPTION_TYPE_SINGLE_SELECT
  SCENE_CONSTRUCTION_OPTION_TYPE_UNKNOWN
}

input SceneCreationInput {
  imageScale: Float
  inputImageUrls: [UrlInput!]
  modifiers: [SceneCreationModifierInput!]
  scene: String!
  scenePreposition: String!
}

input SceneCreationModifierInput {
  selectedValues: [String!]
  title: String!
}

# Generated from com/attentivemobile/contracts/composition/generation/Contract.proto
type SceneCreationOption {
  options: [String!]!
  title: String!
  type: SceneCreationOptionType!
}

enum SceneCreationOptionType {
  SCENE_CREATION_OPTION_TYPE_MULTI_SELECT
  SCENE_CREATION_OPTION_TYPE_SINGLE_SELECT
  SCENE_CREATION_OPTION_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/journeys/api/JourneySchedule.proto
type ScheduleAssignedJourney {
  evictPreviousRevisionSubscribersActivateStart: Boolean
  journey: Journey
  journeyRevisionActivateStart: JourneyRevision
}

input ScheduleComposeCampaignInput {
  companyId: ID!
  composeCampaignId: ID!
  userId: ID!
}

input ScheduleEdsCronJobInput {
  cron: String!
  description: String
  taskId: String!
  zoneId: String!
}

# Generated from com/attentivemobile/contracts/eventdestination/cron/service/CronScheduler.proto
type ScheduleEdsCronJobPayload {
  msg: String!
}

enum ScheduleType {
  SCHEDULE_TYPE_FIXED_DATE_LIST
  SCHEDULE_TYPE_RECURRING
  SCHEDULE_TYPE_UNKNOWN
}

input ScheduleWarmupCampaignInput {
  campaignName: String!
  companyId: ID!
  messageId: ID
  restMessageId: Long!
  startTime: DateTime!
  timezoneRelative: Boolean!
}

# Generated from com/attentivemobile/contracts/emailwarmups/Contract.proto
type ScheduleWarmupCampaignPayload {
  status: WarmupCampaignStatus!
}

enum ScheduleWarnings {
  SCHEDULE_WARNING_UNSET
  SCHEDULE_WARNING_WARMUP_NOT_FINISHED
  SCHEDULE_WARNING_WARMUP_NOT_SCHEDULED
  SCHEDULE_WARNING_WARMUP_PARTIALLY_CANCELLED
}

input ScheduleWorkloadInput {
  cronSchedule: String
  workloadName: String!
}

union ScheduleWorkloadPayload = DefaultErrorFailure | ScheduleWorkloadSuccess

# Generated from com/attentivemobile/contracts/workload/management/service/runner/Contract.proto
type ScheduleWorkloadSuccess {
  status: String!
}

# Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto
type SchedulingMetadata {
  clientSelectedSendTime: SchedulingMetadataClientSelectedSendTime
  passedTimezonesStrategy: PassedTimezonesStrategy!
  type: CampaignScheduleType!
}

union SchedulingMetadataClientSelectedSendTime = Date | TimestampNoTz

input SchedulingMetadataInput {
  clientSelectedSendTime: SchedulingMetadataInputClientSelectedSendTime
  passedTimezonesStrategy: PassedTimezonesStrategy
  scheduleType: CampaignScheduleType!
}

input SchedulingMetadataInputClientSelectedSendTime {
  date: DateInput
  timestampNoTz: TimestampNoTzInput
}

enum Scope {
  SCOPE_ALL_ACCOUNTS
  SCOPE_ALL_CONCIERGE_SETTINGS
  SCOPE_ALL_COUPONS
  SCOPE_ALL_CUSTOM_ATTRIBUTES
  SCOPE_ALL_CUSTOM_EVENTS
  SCOPE_ALL_ECOMMERCE_EVENTS
  SCOPE_ALL_IDENTITY
  SCOPE_ALL_MESSAGES
  SCOPE_ALL_OFFERS
  SCOPE_ALL_POLLS
  SCOPE_ALL_PRIVACY_REQUESTS
  SCOPE_ALL_PRODUCT_CATALOGS
  SCOPE_ALL_PROVISION
  SCOPE_ALL_SCIM
  SCOPE_ALL_SUBSCRIBER_EVENTS
  SCOPE_ALL_WEBHOOKS
  SCOPE_AUDIT_SUBSCRIPTIONS
  SCOPE_UNKNOWN
  SCOPE_VIEW_POLLS
  SCOPE_VIEW_PRIVACY_REQUESTS
  SCOPE_VIEW_PRODUCT_CATALOGS
  SCOPE_VIEW_PROVISION
  SCOPE_VIEW_SCIM
  SCOPE_VIEW_SUBSCRIBER_EVENTS
}

# Generated from com/attentivemobile/contracts/segmentation/data/service/Contract.proto
type SearchAttribute implements Node {
  company: Company
  dataType: AttributeDataType!
  id: ID!
  isArray: Boolean!
  name: String!
  searchAttributeFields(after: String, before: String, filter: ListSearchAttributeFieldsFilter, first: Int, last: Int): SearchAttributeFieldConnection
  source: AttributeSource!
  status: AttributeStatus!
  type: AttributeType!
}

# Generated from com/attentivemobile/contracts/segmentation/data/service/Contract.proto
type SearchAttributeConnection {
  edges: [SearchAttributeEdge!]!
  pageInfo: PageInfo!
}

input SearchAttributeDefinitionInput {
  alias: String!
  scope: AttributeScope!
  vendor: ProfileVendor!
}

# Generated from com/attentivemobile/contracts/segmentation/data/service/Contract.proto
type SearchAttributeEdge {
  cursor: String!
  node: SearchAttribute!
}

# Generated from com/attentivemobile/contracts/segmentation/data/service/Contract.proto
type SearchAttributeField implements Node {
  alias: String!
  company: Company
  dataType: AttributeDataType!
  dynamicOptions(after: String, before: String, first: Int, last: Int): DynamicOptionConnection
  id: ID!
  jsonPath: String!
  searchAttributeId: ID!
}

# Generated from com/attentivemobile/contracts/segmentation/data/service/Contract.proto
type SearchAttributeFieldConnection {
  edges: [SearchAttributeFieldEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/segmentation/data/service/Contract.proto
type SearchAttributeFieldEdge {
  cursor: String!
  node: SearchAttributeField!
}

input SearchAttributeFilter {
  wellKnownPropertyFilter: WellKnownPropertyFilter
}

union SearchAttributeIdentifier = SearchAttributeMetadataIdentifier

input SearchAttributeInput {
  metadataIdentifier: SearchAttributeDefinitionInput
}

# Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/SearchDefinitionOutput.proto
type SearchAttributeMetadataIdentifier {
  alias: String!
  scope: AttributeScope!
  vendor: ProfileVendor!
}

enum SearchBooleanComparator {
  SEARCH_BOOLEAN_COMPARATOR_EQUALS
  SEARCH_BOOLEAN_COMPARATOR_UNKNOWN
}

enum SearchDateComponentComparator {
  SEARCH_DATE_COMPONENT_COMPARATOR_MONTH_OF
  SEARCH_DATE_COMPONENT_COMPARATOR_UNKNOWN
}

enum SearchDistanceComparator {
  SEARCH_DISTANCE_COMPARATOR_UNKNOWN
  SEARCH_DISTANCE_COMPARATOR_WITHIN
}

enum SearchDistanceUnit {
  SEARCH_DISTANCE_UNIT_KILOMETERS
  SEARCH_DISTANCE_UNIT_MILES
  SEARCH_DISTANCE_UNIT_UNKNOWN
}

input SearchEventFilter {
  cadenceCondition: CadenceCondition
  event: UserSearchEvent!
  eventPropertiesFilter: [EventPropertiesFilter!]
  isNot: Boolean!
  timeCondition: TimeCondition
}

union SearchFieldIdentifier = Alias

input SearchFieldInput {
  alias: String
}

enum SearchFrequencyComparator {
  SEARCH_FREQUENCY_COMPARATOR_BETWEEN
  SEARCH_FREQUENCY_COMPARATOR_EQUALS
  SEARCH_FREQUENCY_COMPARATOR_LESS_THAN
  SEARCH_FREQUENCY_COMPARATOR_LESS_THAN_EQUALS
  SEARCH_FREQUENCY_COMPARATOR_MORE_THAN
  SEARCH_FREQUENCY_COMPARATOR_MORE_THAN_EQUALS
  SEARCH_FREQUENCY_COMPARATOR_UNKNOWN
}

enum SearchNumericComparator {
  SEARCH_NUMERIC_COMPARATOR_BETWEEN
  SEARCH_NUMERIC_COMPARATOR_EQUALS
  SEARCH_NUMERIC_COMPARATOR_IN
  SEARCH_NUMERIC_COMPARATOR_LESS_THAN
  SEARCH_NUMERIC_COMPARATOR_LESS_THAN_EQUALS
  SEARCH_NUMERIC_COMPARATOR_MORE_THAN
  SEARCH_NUMERIC_COMPARATOR_MORE_THAN_EQUALS
  SEARCH_NUMERIC_COMPARATOR_UNKNOWN
}

enum SearchOperator {
  SEARCH_OPERATOR_ALL
  SEARCH_OPERATOR_ANY
  SEARCH_OPERATOR_UNKNOWN
}

input SearchPropertyFilter {
  attributeFilter: SearchAttributeFilter
  eventFilter: SearchEventFilter
}

enum SearchRelativeTimeComparator {
  SEARCH_RELATIVE_TIME_COMPARATOR_BETWEEN_THE_LAST
  SEARCH_RELATIVE_TIME_COMPARATOR_BETWEEN_THE_LAST_IGNORE_YEAR
  SEARCH_RELATIVE_TIME_COMPARATOR_BETWEEN_THE_NEXT
  SEARCH_RELATIVE_TIME_COMPARATOR_BETWEEN_THE_NEXT_IGNORE_YEAR
  SEARCH_RELATIVE_TIME_COMPARATOR_DAYS_AGO
  SEARCH_RELATIVE_TIME_COMPARATOR_DAYS_AGO_IGNORE_YEAR
  SEARCH_RELATIVE_TIME_COMPARATOR_DAYS_UNTIL
  SEARCH_RELATIVE_TIME_COMPARATOR_DAYS_UNTIL_IGNORE_YEAR
  SEARCH_RELATIVE_TIME_COMPARATOR_TODAY
  SEARCH_RELATIVE_TIME_COMPARATOR_TODAY_IGNORE_YEAR
  SEARCH_RELATIVE_TIME_COMPARATOR_UNKNOWN
}

enum SearchStringComparator {
  SEARCH_STRING_COMPARATOR_EQUALS
  SEARCH_STRING_COMPARATOR_IN
  SEARCH_STRING_COMPARATOR_LIKE
  SEARCH_STRING_COMPARATOR_UNKNOWN
}

# Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/SearchDefinitionOutput.proto
type SearchSubCondition {
  operation: SearchSubConditionOperation
}

input SearchSubConditionInput {
  operation: SearchSubConditionInputOperation
}

input SearchSubConditionInputOperation {
  comparison: ComparisonInput
  groupedComparisons: GroupedComparisonInput
}

union SearchSubConditionOperation = Comparison | GroupedComparison

enum SearchTimeComparator {
  SEARCH_TIME_COMPARATOR_AFTER
  SEARCH_TIME_COMPARATOR_BEFORE
  SEARCH_TIME_COMPARATOR_BETWEEN
  SEARCH_TIME_COMPARATOR_BETWEEN_IGNORE_YEAR
  SEARCH_TIME_COMPARATOR_ON
  SEARCH_TIME_COMPARATOR_ON_IGNORE_YEAR
  SEARCH_TIME_COMPARATOR_OVER_ALL_TIME
  SEARCH_TIME_COMPARATOR_UNKNOWN
}

enum SearchTimeUnit {
  SEARCH_TIME_UNIT_DAY
  SEARCH_TIME_UNIT_HOURS
  SEARCH_TIME_UNIT_MONTH
  SEARCH_TIME_UNIT_UNKNOWN
}

# Generated from com/attentivemobile/contracts/user/profile/api/Graph.proto
type Segment implements Node {
  # The associated company for this segment
  company: Company
  companyNode: Company
  description: String!
  id: ID!
  internalId: Long!

  # status of segment materialization
  materializationStatus(segmentId: ID): SegmentMaterializationStatus
  metadata: [SegmentMetadata!]!
  name: String!

  # the size of the segment. note: may be slow to load!
  segmentCount: SegmentCountPayload

  # The associated segment enhancement for this segment
  segmentEnhancement: SegmentEnhancement
  source: SegmentSource!
  status: SegmentStatus!
}

# Generated from com/attentivemobile/contracts/composition/MessageAttachment.proto
type SegmentAttachment {
  entityToken: String!
  materializedSegmentHash: String
}

input SegmentAttachmentProperties {
  entityToken: String!
  materializedSegmentHash: String @deprecated(reason: "deprecated")
  segmentId: ID!
}

# Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto
type SegmentAudienceFilter {
  isExcludingSubscribers: Boolean!
  segment: Segment
  segmentId: Int!
  segmentType: SegmentType
}

enum SegmentAutomationType {
  SEGMENT_AUTOMATION_TYPE_BLENDED
  SEGMENT_AUTOMATION_TYPE_EXCLUSION
  SEGMENT_AUTOMATION_TYPE_REVENUE_BOOSTING
  SEGMENT_AUTOMATION_TYPE_REVENUE_MAXIMIZING
  SEGMENT_AUTOMATION_TYPE_ROI_BOOSTING
  SEGMENT_AUTOMATION_TYPE_ROI_MAXIMIZING
  SEGMENT_AUTOMATION_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type SegmentConnection {
  edges: [SegmentEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type SegmentCountItem {
  activeSubscribers: Long!
  activeSubscribersNotFatigued: Long!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type SegmentCountPayload {
  email: SegmentCountItem
  sms: SegmentCountItem!
  smsAndEmail: SegmentCountItem
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type SegmentEdge {
  cursor: String!
  node: Segment!
}

# Generated from com/attentivemobile/protobuf/common/SegmentEnhancement.proto
type SegmentEnhancement implements Node {
  audienceSize: Long
  audienceTheme: String! @deprecated(reason: "deprecated")
  baseAudienceId: String
  chunk: [SegmentEnhancementChunk!]!
  company: Company
  cutoffPercentileType: String! @deprecated(reason: "deprecated")
  effects: [EnhancementEffect!]!
  id: ID!
  isRecommended: Boolean!
  mlCutoffPercentile: String! @deprecated(reason: "deprecated")
  productFilter: AudienceAiProductFilter!
  subscribersAdded: Long! @deprecated(reason: "deprecated")
  subscribersRemoved: Long! @deprecated(reason: "deprecated")
  type: EnhancementType!
  uuid: String!
}

# Generated from com/attentivemobile/protobuf/common/Chunk.proto
type SegmentEnhancementChunk {
  chunkMetadataOneOf: SegmentEnhancementChunkChunkMetadataOneOf
  enhancementType: EnhancementType!
  modelVersion: String
  shouldExclude: Boolean!
}

union SegmentEnhancementChunkChunkMetadataOneOf = ExclusionHeuristicChunk | ExclusionInvalidSubscribersChunk | ExclusionProductAffinityAlphaChunk | InclusionProductAffinityAlphaChunk | InclusionUserPurchasePropensityChunk

input SegmentEnhancementsFilter {
  excludeFatigued: Boolean
  identifyAndKeep: Boolean
}

input SegmentEnhancementsWorkflowResultInputCallbackId {
  campaignMessageGroupId: ID
}

# Generated from com/attentivemobile/contracts/segment/recommendation/service/Contract.proto
type SegmentEnhancementsWorkflowResultPayload {
  company: Company
  segmentEnhancementWorkflowStatus: SegmentEnhancementsWorkflowResultPayloadSegmentEnhancementWorkflowStatus
}

union SegmentEnhancementsWorkflowResultPayloadSegmentEnhancementWorkflowStatus = FailureSegmentEnhancementWorkflowStatus | InProgressSegmentEnhancementWorkflowStatus | NotFoundSegmentEnhancementWorkflowStatus | SuccessSegmentEnhancementWorkflowStatus

input SegmentFilterInput {
  isExclusion: Boolean
  segmentId: ID
  segmentType: SegmentType
}

input SegmentFiltersInput {
  segment: [SegmentFilterInput!]
}

input SegmentId {
  legacySegmentId: ID
  segmentId: ID
}

enum SegmentMaterializationStatus {
  SEGMENT_MATERIALIZATION_STATUS_FAILED
  SEGMENT_MATERIALIZATION_STATUS_NOT_MARKED_FOR_MATERIALIZATION
  SEGMENT_MATERIALIZATION_STATUS_SYNCED
  SEGMENT_MATERIALIZATION_STATUS_SYNCING
  SEGMENT_MATERIALIZATION_STATUS_UNKNOWN
}

# Generated from com/attentivemobile/contracts/user/profile/api/Graph.proto
type SegmentMetadata {
  tag: String!
  value: String!
}

enum SegmentSource {
  SEGMENT_SOURCE_DYNAMIC
  SEGMENT_SOURCE_INTERNAL_API
  SEGMENT_SOURCE_MANUAL_UPLOAD
  SEGMENT_SOURCE_THIRD_PARTY
  SEGMENT_SOURCE_UNKNOWN
}

enum SegmentStatus {
  SEGMENT_STATUS_ACTIVE
  SEGMENT_STATUS_DELETED
  SEGMENT_STATUS_INACTIVE
  SEGMENT_STATUS_UNKNOWN
}

input SegmentStatusFilter {
  includeStatuses: [SegmentStatus!]
}

enum SegmentTag {
  TAG_ALL_SUBSCRIBERS
  TAG_BLENDED
  TAG_CONCIERGE_FOLLOWUP
  TAG_EXCLUSION
  TAG_EXIT_RULE
  TAG_JOURNEY_TEMPLATE
  TAG_MANUAL_SEGMENT_CREATED_IN_SS
  TAG_ML_ATTRIBUTE
  TAG_ML_DEFAULT
  TAG_RETARGETING
  TAG_REVENUE_BOOSTING
  TAG_REVENUE_MAXIMIZING
  TAG_ROI_BOOSTING
  TAG_ROI_MAXIMIZING
  TAG_UNKNOWN
  TAG_WARMUPS
}

input SegmentTagFilter {
  excludeTags: [SegmentTag!]
  includeTags: [SegmentTag!]
}

input SegmentTemplateIdentifierInput {
  name: String
}

input SegmentTemplateInput {
  identifier: SegmentTemplateIdentifierInput!
  values: [SegmentTemplateValueInput!]
}

input SegmentTemplateValueInput {
  key: String!
  value: String!
  values: [String!]
}

enum SegmentType {
  AUDIENCES_AI_EXCLUSION
  AUDIENCES_AI_INCLUSION
  AUDIENCES_AI_UNKNOWN
}

# Generated from com/attentivemobile/contracts/segmentation/service/segment/contract.proto
type SegmentV2 implements Node {
  automationType: SegmentAutomationType
  company: Company
  id: ID!
  internalId: String!
  internalLegacyId: Long!
  name: String!
  searchDefinition: AudienceSearchDefinition!

  # The associated segment enhancement for this segment
  segmentEnhancement: SegmentEnhancement
}

# Generated from com/attentivemobile/contracts/brand-kit/BrandFont.proto
type SelectedBrandFont {
  fallbackFont: String!
  fontFamilyName: String!
  fontType: FontType!
  placement: Placement!
  properties: [BrandFontProperties!]!
  selectedBrandFontId: Long!
  sources: [AvailableBrandFontSource!]!
}

# Generated from com/attentivemobile/contracts/brand-kit/BrandFont.proto
type SelectedBrandFontFamily {
  cssUrl: Url
  fallbackFont: String!
  fontFamilyName: String!
  fontType: FontType!
  sources: [AvailableBrandFontSource!]!
  variants: [BrandFontVariant!]!
}

input SelectedBrandFontsInput {
  fallbackFont: String!
  fontFamilyName: String!
  fontType: FontType!
  placement: Placement!
  properties: [BrandFontPropertiesInput!]
  selectedBrandFontId: Long
}

# Add product to cart event input.
input SendAddProductToCartEventInput {
  companyId: ID

  # Timestamp of when the action occurred in ISO 8601 format. If the timestamp is older than 12 hours, it will not trigger any relevant journeys. If no timestamp is provided, it is assumed the event occurred when the endpoint is called.
  occurredAt: DateTime

  # Detailed information about the products in this request.
  products: [EcommerceProductInput!]

  # User associated with the action. Note that this is a visitor to the site and does not need to be actively subscribed to Attentive.
  visitor: EventVisitorInput!
}

# Generated from com/attentivemobile/contracts/analytics/event/handler/Contract.proto
# Response to the AddToCartEvent mutation.
type SendAddProductToCartEventPayload {
  # Timestamp of when the action occurred in ISO 8601 format. If the timestamp is older than 12 hours, it will not trigger any relevant journeys. If no timestamp is provided, it is assumed the event occurred when the endpoint is called.
  occurredAt: DateTime

  # Detailed information about the products in this request.
  products: [EcommerceProduct!]!

  # User associated with the action. Note that this is a visitor to the site and does not need to be actively subscribed to Attentive.
  visitor: EventVisitor!
}

# Generated from com/attentivemobile/contracts/concierge/service/Contract.proto
type SendAgentHeartbeatPayload {
  conciergeAgent: ConciergeAgent
}

input SendAgentMessageInput {
  content: String!
  lastInboundMessageBody: String
  lastMessageId: String
  linkTypesUsed: [ConciergeLinkType!]
  mediaUrl: String
  recommendedTemplateRequestId: String
  recommendedTemplates: RecommendedTemplateListInput
  templatesUsed: TemplateReferenceListInput
  userId: ID!
}

input SendApplicationWebhookTestEventInput {
  eventType: ApplicationWebhookEventTypeInput!
  signingKey: String!
  url: String!
}

# Generated from com/attentivemobile/contracts/externaltokens/Contract.proto
type SendApplicationWebhookTestEventPayload {
  responseStatus: JSON!
}

input SendClientMessageInput {
  body: String!
  mediaUrl: String
  userId: ID!
}

# Generated from com/attentivemobile/contracts/conversations/v1/Conversation.proto
type SendClientMessagePayload {
  enqueued: Boolean!
}

input SendEmailMessagePreviewRequestInput {
  companyId: ID!
  emailPreviewProperties: EmailPreviewPropertiesInput!
  emailPreviewRecipients: EmailPreviewRecipientsInput!
  fullCompanySend: Boolean!
  messageId: ID!
}

# Generated from com/attentivemobile/contracts/composition/Contract.proto
type SendEmailMessagePreviewResponsePayload {
  failedSendDestinations: [String!]!
  s3Url: String!
  successfulSendDestinations: [String!]!
}

# Generated from com/attentivemobile/contracts/concierge/service/Contract.proto
type SendMessagePayload {
  sendId: String!
  sentContent: String!
}

input SendMessageToAudienceInput {
  messageText: String!
  pollId: ID!
}

# Generated from com/attentivemobile/contracts/survey/poll/Contract.proto
type SendMessageToAudiencePayload {
  success: Boolean!
}

input SendMfaOneTimePasscodeInput {
  deviceIdentifier: String
  mfaToken: String!
  preferredMfaMethod: PreferredMfaMethod
}

# Generated from com/attentivemobile/contracts/account-service/MFAContract.proto
type SendMfaOneTimePasscodePayload {
  error: String
  successful: Boolean!
}

input SendPasswordResetEmailInput {
  email: String!
}

# Generated from com/attentivemobile/contracts/account-service/AuthenticationContract.proto
type SendPasswordResetEmailPayload {
  successful: Boolean!
}

input SendPhonyInboundMessageInput {
  body: String!
  fromPhone: String!
  provider: Provider!
  toPhone: String!
}

# Generated from com/attentivemobile/contracts/conversations/v1/Conversation.proto
type SendPhonyInboundMessagePayload {
  sendId: String!
}

# Product purchase event input.
input SendProductPurchaseEventInput {
  companyId: String

  # Timestamp of when the action occurred in ISO 8601 format. If the timestamp is older than 12 hours, it will not trigger any relevant journeys. If no timestamp is provided, it is assumed the event occurred when the endpoint is called.
  occurredAt: DateTime

  # Detailed information about the products in this request.
  products: [EcommerceProductInput!]

  # User associated with the action. Note that this is a visitor to the site and does not need to be actively subscribed to Attentive.
  visitor: EventVisitorInput!
}

# Generated from com/attentivemobile/contracts/analytics/event/handler/Contract.proto
# Response to productPurchaseEvent mutation.
type SendProductPurchaseEventPayload {
  # Timestamp of when the action occurred in ISO 8601 format. If the timestamp is older than 12 hours, it will not trigger any relevant journeys. If no timestamp is provided, it is assumed the event occurred when the endpoint is called.
  occurredAt: DateTime

  # Detailed information about the products in this request.
  products: [EcommerceProduct!]!

  # User associated with the action. Note that this is a visitor to the site and does not need to be actively subscribed to Attentive.
  visitor: EventVisitor!
}

# Product view event input.
input SendProductViewEventInput {
  companyId: String

  # Timestamp of when the action occurred in ISO 8601 format. If the timestamp is older than 12 hours, it will not trigger any relevant journeys. If no timestamp is provided, it is assumed the event occurred when the endpoint is called.
  occurredAt: DateTime

  # Detailed information about the products in this request.
  products: [EcommerceProductInput!]

  # User associated with the action. Note that this is a visitor to the site and does not need to be actively subscribed to Attentive.
  visitor: EventVisitorInput!
}

# Generated from com/attentivemobile/contracts/analytics/event/handler/Contract.proto
# Response to the ProductViewEvent mutation.
type SendProductViewEventPayload {
  # Timestamp of when the action occurred in ISO 8601 format. If the timestamp is older than 12 hours, it will not trigger any relevant journeys. If no timestamp is provided, it is assumed the event occurred when the endpoint is called.
  occurredAt: DateTime

  # Detailed information about the products in this request.
  products: [EcommerceProduct!]!

  # User associated with the action. Note that this is a visitor to the site and does not need to be actively subscribed to Attentive.
  visitor: EventVisitor!
}

input SendRbmMessagePreviewInput {
  companyId: ID!
  messageId: ID!
  rbmPreviewRecipients: RbmPreviewRecipientsInput!
  rbmProperties: RbmPropertiesInput!
}

union SendRbmMessagePreviewPayload = DefaultErrorFailure | SendRbmMessagePreviewSuccess

# Generated from com/attentivemobile/contracts/composition/Contract.proto
type SendRbmMessagePreviewSuccess {
  failedSendDestinations: [String!]!
  successfulSendDestinations: [String!]!
}

input SendRcsMessagePreviewInput {
  companyId: ID!
  messageId: ID!
  rcsProperties: RcsPropertiesInput!
  recipients: RcsPreviewRecipientsInput!
}

union SendRcsMessagePreviewPayload = DefaultErrorFailure | SendRcsMessagePreviewSuccess

# Generated from com/attentivemobile/contracts/composition/Contract.proto
type SendRcsMessagePreviewSuccess {
  failedSendDestinations: [String!]!
  successfulSendDestinations: [String!]!
}

input SendTextMessagePreviewRequestInput {
  companyId: ID!
  fullCompanySend: Boolean!
  messageId: ID!
  textPreviewRecipients: TextPreviewRecipientsInput!
  textPreviewRequest: TextPreviewInput!
}

# Generated from com/attentivemobile/contracts/composition/Contract.proto
type SendTextMessagePreviewResponsePayload {
  body: String!
  failedSendDestinations: [String!]!
  mediaUrl: String
  successfulSendDestinations: [String!]!
}

# Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto
type SendTime {
  filters: [CampaignAudienceFilter!]!
  messageMembers: [CampaignMessageMember!]!
  startTime: DateTime!
  status: CampaignStatus!
}

# Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto
# This metadata is used for passing along to the copyMessage mutation to sync content for send time experiments.
type SendTimeExperimentMessageMetadata {
  composeMessage: ComposeMessage
  sendTime: DateTime!
}

# Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto
type SendTimeExperimentMetadata {
  mainCampaignMessageMember: CampaignMessageMember
  sendTimeExperimentMessageMetadata: [SendTimeExperimentMessageMetadata!]!
  sendTimes: [DateTime!]!
}

input SendTimeExperimentMetadataAttachmentInput {
  experimentSendTimes: SendTimeExperimentSendTimeInput
}

input SendTimeExperimentSendTimeInput {
  experimentSendTime: [DateTime!]
}

# Generated from com/attentivemobile/contracts/concierge/service/Message.proto
type SendingAgent {
  account: Account
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type SendingDomain {
  default: Boolean
  dnsRecords: DnsRecords!
  domain: String!
  domainId: Long!
  lastValidationAttempt: DateTime
  shared: Boolean!
  subdomain: String!
  valid: Boolean!
}

# Generated from com/attentivemobile/contracts/integrations/serversidetag/Contract.proto
type ServerSideTagDnsRecord {
  name: String!
  type: String!
  value: String!
}

input SetAIBrandVoiceSettingsInput {
  aiBrandVoiceSettings: AIBrandVoiceSettingsInput!
  companyId: ID!
}

union SetAIBrandVoiceSettingsPayload = DefaultErrorFailure | SetAIBrandVoiceSettingsSuccess

# Generated from com/attentivemobile/contracts/messaging/automation/brandvoice/AIBrandVoice.proto
type SetAIBrandVoiceSettingsSuccess {
  aiBrandVoiceSettings: AIBrandVoiceSettings!
  statusText: String!
}

input SetAIBrandVoiceSettingsV2Input {
  aiBrandVoiceSettings: AIBrandVoiceSettingsV2Input!
  companyId: ID!
}

union SetAIBrandVoiceSettingsV2Payload = DefaultErrorFailure | SetAIBrandVoiceSettingsV2Success

# Generated from com/attentivemobile/contracts/generativeaiservice/brandvoice/AIBrandVoice.proto
type SetAIBrandVoiceSettingsV2Success {
  aiBrandVoiceSettings: AIBrandVoiceSettingsV2!
  statusText: String!
}

input SetAIJourneyLinkConfigurationInput {
  companyId: ID!
  linkConfigurationInput: AIJourneyLinkConfigurationInput!
}

union SetAIJourneyLinkConfigurationPayload = DefaultErrorFailure | SetAIJourneyLinkConfigurationSuccess

# Generated from com/attentivemobile/contracts/messaging/automation/aijourneys/onboarding/AIJourneyLinkConfigurationServiceContract.proto
type SetAIJourneyLinkConfigurationSuccess {
  linkConfigurationInput: AIJourneyLinkConfiguration!
}

input SetAIJourneyLinkTrackingParamsInput {
  aiJourneyTrackingParamsInput: [AIJourneyTrackingParamsInput!]
  companyId: ID!
}

union SetAIJourneyLinkTrackingParamsPayload = DefaultErrorFailure | SetAIJourneyLinkTrackingParamsSuccess

# Generated from com/attentivemobile/contracts/messaging/automation/aijourneys/onboarding/AIJourneyLinkConfigServiceContract.proto
type SetAIJourneyLinkTrackingParamsSuccess {
  aiJourneyTrackingParams: [AIJourneyTrackingParams!]!
}

input SetAccountMfaPreferencesInput {
  mfaPhoneNumber: String
  mfaToken: String!
  preferredOtpDeliveryMethod: MfaOneTimePasscodeDeliveryMethod
}

input SetAiBrandVoiceSummaryInput {
  brandVoiceSummary: String!
  companyId: ID!
}

union SetAiBrandVoiceSummaryPayload = DefaultErrorFailure | SetAiBrandVoiceSummarySuccess

# Generated from com/attentivemobile/contracts/generativeaiservice/brandvoice/AIBrandVoice.proto
type SetAiBrandVoiceSummarySuccess {
  aiBrandVoiceSummary: AiBrandVoiceSummary!
  statusText: String!
}

input SetBrandColorSchemeInput {
  colors: [BrandColorInput!]
  companyId: ID!
}

# Generated from com/attentivemobile/contracts/brand-kit/BrandColorSchemeService.proto
type SetBrandColorSchemePayload {
  colors: [BrandColor!]!
  company: Company
}

input SetConciergeAgentAvailabilityInput {
  accountId: ID!
  isAvailable: Boolean!
}

# Generated from com/attentivemobile/contracts/concierge/service/Contract.proto
type SetConciergeAgentAvailabilityPayload {
  account: Account
}

# Request message for setting custom attributes.
input SetCustomAttributesInput {
  companyId: ID

  # Any metadata associated with the attribute. Object keys and values are expected to be strings. Both object keys and object values are case sensitive. "Favorite color" and "Favorite Color" would be considered different custom attributes.
  properties: JSON!

  # User associated with the action. Note that this is a visitor to the site and does not need to be actively subscribed to Attentive.
  user: EventUserInput!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
# Response message to a request for setting custom attributes.
type SetCustomAttributesPayload {
  # Any metadata associated with the attribute.
  properties: JSON!

  # User associated with the action. Note that this is a visitor to the site and does not need to be actively subscribed to Attentive.
  user: EventUser!
}

input SetDefaultDomainInput {
  companyId: ID!
  domainId: Long!
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type SetDefaultDomainPayload {
  sendingDomain: SendingDomain!
}

input SetDefaultPermissionsForCompanyInput {
  companyId: ID!
  permissionsId: ID!
}

union SetDefaultPermissionsForCompanyPayload = DefaultErrorFailure | SetDefaultPermissionsForCompanySuccess

# Generated from com/attentivemobile/contracts/account/permission/service/AccountPermissionsService.proto
type SetDefaultPermissionsForCompanySuccess {
  accountPermissions: AccountPermissions!
}

input SetEmailIPAddressWarmupDateInput {
  disableWarmup: Boolean
  ipAddress: String!
  warmupDate: DateTime
}

union SetEmailIPAddressWarmupDatePayload = DefaultErrorFailure | SetEmailIPAddressWarmupDateSuccess

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type SetEmailIPAddressWarmupDateSuccess {
  disableWarmup: Boolean
  ipAddress: String!
  warmupDate: DateTime
}

input SetPermissionsForAccountInput {
  accountId: ID!
  companyId: ID!
  permissionsId: ID!
}

union SetPermissionsForAccountPayload = DefaultErrorFailure | SetPermissionsForAccountSuccess

# Generated from com/attentivemobile/contracts/account/permission/service/AccountPermissionsService.proto
type SetPermissionsForAccountSuccess {
  accountPermissions: AccountPermissions!
}

input SetSubscriberBlocklistStatusByAgentInput {
  shouldBlocklist: Boolean!
  userId: ID!
}

# Generated from com/attentivemobile/contracts/concierge/service/Contract.proto
type SetSubscriberBlocklistStatusByAgentPayload {
  isBlocklisted: Boolean!
  user: User
}

input SetSubscriberOptInStatusByAgentInput {
  desiredOptInStatus: Boolean!
  userId: ID!
}

# Generated from com/attentivemobile/contracts/concierge/service/Contract.proto
type SetSubscriberOptInStatusByAgentPayload {
  isOptedIn: Boolean!
  user: User
}

input SetSweepstakesFilterInput {
  numToSelect: Int!
  pollId: ID!
  pollKeywordIds: [ID!]
  selectorType: SweepstakesSelectorType!
  subscriberType: SweepstakesSubscriberType!
}

union SetSweepstakesFilterPayload = DefaultErrorFailure | SetSweepstakesFilterSuccess

# Generated from com/attentivemobile/contracts/survey/poll/Contract.proto
type SetSweepstakesFilterSuccess {
  poll: Poll!
}

input SetTargetingDataInput {
  featureFlagId: ID!
  inputs: [TargetingData!]
}

# Generated from com/attentivemobile/contracts/featureflag/service/Contract.proto
type SetTargetingDataPayload {
  values: [TargetingDatum!]!
}

input SetupTemplatizedPostbackInput {
  body: JsonStringInput!
  companyId: ID!
  companyPostbackTemplateId: String!
  conditionalMacros: [JsonStringInput!]
  endpoint: String!
  httpRequestType: HttpRequestType!
  integrationEvent: [IntegrationEventTypeInput!]
  integrationType: IntegrationType!
  postbackHeaders: [TemplatizedPostbackHeaderSettingsInput!]
  templateName: String!
  templatizedAuthSettingsInput: TemplatizedAuthSettingInput!
  vendor: Vendor!
}

# Generated from com/attentivemobile/contracts/integrations/templatizedpostbacks/Contract.proto
type SetupTemplatizedPostbackPayload {
  responseBody: String!
  responseCode: Long!
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type SftpAuthKey {
  bucketName: String!
  keyName: String!
}

input SftpAuthKeyInput {
  bucketName: String!
  keyName: String!
}

# Generated from com/attentivemobile/contracts/solutions/sftp/SftpUser.proto
type SftpCognitoUserInternalStatus {
  email: String!
}

# Generated from com/attentivemobile/contracts/solutions/sftp/SftpUser.proto
type SftpCognitoUserStatusResponse {
  forceChangePassword: Boolean!
  hasEmail: Boolean!
  internalStatus: SftpCognitoUserInternalStatus!
}

enum SftpConnectionStatus {
  SFTP_CONNECTION_STATUS_CONNECTION_ERROR
  SFTP_CONNECTION_STATUS_MISSING_FILE
  SFTP_CONNECTION_STATUS_MISSING_PATH
  SFTP_CONNECTION_STATUS_SUCCESS
}

enum SftpReportFeature {
  SFTP_REPORT_FEATURE_CONVERSIONS
  SFTP_REPORT_FEATURE_GENERAL_DATAFEED
  SFTP_REPORT_FEATURE_OPT_OUT
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type SftpSettings {
  authKey: SftpAuthKey
  filename: String!
  host: String!
  password: String!
  path: String!
  port: Int!
  timezone: String!
  username: String!
}

input SftpSettingsInput {
  authKey: SftpAuthKeyInput
  filename: String!
  host: String!
  password: String!
  path: String!
  port: Int!
  timezone: String!
  username: String!
}

enum ShippingProvider {
  SHIPPING_PROVIDER_DHL
  SHIPPING_PROVIDER_FEDEX
  SHIPPING_PROVIDER_UNKNOWN
  SHIPPING_PROVIDER_UPS
  SHIPPING_PROVIDER_USPS
}

enum ShortCodeLeaseOwner {
  SHORT_CODE_LEASE_OWNER_AGGREGATOR
  SHORT_CODE_LEASE_OWNER_ATTENTIVE
  SHORT_CODE_LEASE_OWNER_CLIENT
  SHORT_CODE_LEASE_OWNER_UNKNOWN
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type ShortLink {
  displayUrl: String!
  isOpenInBrowser: Boolean
  messageLinkId: String!
  messageToken: String
  redirectTargetUrl: String!
  shouldDisableHttpsPrefix: Boolean
  videoLinkPreviewProperties: VideoPreviewLinkProperties
}

input ShortLinkProperties {
  displayUrl: String
  isOpenInBrowser: Boolean
  messageLinkId: String
  messageToken: String
  redirectTargetUrl: String!
  videoLinkProperties: VideoLinkPropertiesInput
}

input SingleSearch {
  searchDefinition: UserSearchDefinition!
}

type Site {
  appStatus: AppStatus!
  appTeardownTransaction: AppTeardownTransaction!
  authStatus: AuthStatus!
  companyTransition: CompanyTransition!
  degradationWarningMessage: String
  developerBanner: DeveloperBanner!
  toasts: [Toast!]!
}

# Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto
type SliceAudienceFilter {
  count: Int!
  index: Int!
}

input SnowflakeSecureDataSharingInput {
  bucket: String
  datasetOrderingKey: String
  datasetSql: String!
  keyName: String
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type SnowflakeSecureDataSharingSettings {
  bucket: String
  datasetOrderingKey: String
  datasetSql: String!
  keyName: String
}

union SnowflakeSettingsConfirmationResult = SnowflakeSettingsDataSample | SnowflakeSettingsDisplayableError

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type SnowflakeSettingsDataSample {
  columnNames: [String!]!
  dataSampleRows: [SnowflakeSettingsDataSampleRow!]!
  dataTypes: [String!]!
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type SnowflakeSettingsDataSampleRow {
  values: [String!]!
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type SnowflakeSettingsDisplayableError {
  errorMessage: String!
}

# Generated from com/attentivemobile/contracts/solutions/bulkoptout/SolutionsOptout.proto
type SolutionsDateRange {
  endTime: DateTime
  startTime: DateTime
}

input SolutionsDateRangeInput {
  endTime: DateTime
  startTime: DateTime
}

input SolutionsEmailOptOutInput {
  companyId: ID!
  email: [String!]
  filename: String
  totalRows: Long
}

# Generated from com/attentivemobile/contracts/solutions/bulkoptout/SolutionsOptout.proto
type SolutionsEmailOptOutPayload {
  jobId: String!
  successfulOptouts: [SuccessfulOptoutsEntry!]! @deprecated(reason: "deprecated")
}

input SolutionsPhoneOptOutInput {
  companyId: ID!
  countryCode: String
  filename: String
  optOutSource: OptOutSource
  phones: [String!]
}

# Generated from com/attentivemobile/contracts/solutions/bulkoptout/SolutionsOptout.proto
type SolutionsPhoneOptOutPayload {
  wasSuccessful: Boolean!
}

input SolutionsUpdateMessageLinkTargetInput {
  companyId: ID!
  messageLinkId: Long!
  newUrl: String!
}

# Generated from com/attentivemobile/contracts/solutions/MessageProperties.proto
type SolutionsUpdateMessageLinkTargetPayload {
  newUrl: String!
}

enum SortDirection {
  SORT_DIRECTION_ASC
  SORT_DIRECTION_DESC
  SORT_DIRECTION_UNKNOWN
}

enum SourceType {
  SOURCE_TYPE_COMPANY_SETTINGS
  SOURCE_TYPE_CONVERSATION
  SOURCE_TYPE_FEEDBACK
  SOURCE_TYPE_UNKNOWN
}

interface StandardError {
  message: String!
  status: StandardErrorStatus!
  title: String!
  traceId: String!
}

# Standard error status.
enum StandardErrorStatus {
  STANDARD_ERROR_STATUS_ABORTED
  STANDARD_ERROR_STATUS_ALREADY_EXISTS
  STANDARD_ERROR_STATUS_CANCELLED
  STANDARD_ERROR_STATUS_DATA_LOSS
  STANDARD_ERROR_STATUS_DEADLINE_EXCEEDED
  STANDARD_ERROR_STATUS_FAILED_PRECONDITION
  STANDARD_ERROR_STATUS_INTERNAL
  STANDARD_ERROR_STATUS_INVALID_ARGUMENT
  STANDARD_ERROR_STATUS_NOT_FOUND
  STANDARD_ERROR_STATUS_NOT_SET
  STANDARD_ERROR_STATUS_OUT_OF_RANGE
  STANDARD_ERROR_STATUS_PERMISSION_DENIED
  STANDARD_ERROR_STATUS_RESOURCE_EXHAUSTED
  STANDARD_ERROR_STATUS_UNAUTHENTICATED
  STANDARD_ERROR_STATUS_UNAVAILABLE
  STANDARD_ERROR_STATUS_UNIMPLEMENTED
  STANDARD_ERROR_STATUS_UNKNOWN
}

input StartAiProTrialSubscriptionInput {
  companyId: ID!
  formSubmittedDate: DateInput!
  startDate: DateInput!
}

union StartAiProTrialSubscriptionPayload = AiProTrialActionFailure | StartAiProTrialSubscriptionSuccess

# Generated from com/attentivemobile/contracts/multiproductenablement/service/aipro/Contract.proto
type StartAiProTrialSubscriptionSuccess {
  success: Boolean!
}

input StartCollectionConfig {
  percentage: Float!
  tagAiConfig: [TagAiConfig!]
}

input StartDatastreamInput {
  datastreamName: String!
  entryClass: String!
  entryClassArgs: [EntryClassArgsEntry!]
  jarName: String!
  mode: OperationMode!
  restorePath: String!
  targetNewFlinkCluster: Boolean
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/GraphContract.proto
type StartDatastreamPayload {
  datastreamName: String!
  flinkJobId: String!
  result: OperationResult!
  startPath: String!
  state: DatastreamStatus!
}

input StateRecoveryPolicy {
  checkpointMaxSecondsBehind: Int!
  failoverStartWithoutState: Boolean!
}

# Generated from com/attentivemobile/contracts/eventdestination/telemetry/service/Contract.proto
type StatusCodeCountsEntry {
  key: String!
  value: Long!
}

input StopDatastreamInput {
  async: Boolean!
  datastreamName: String!
  mode: OperationMode!
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Contract.proto
type StopDatastreamPayload {
  datastreamName: String!
  result: OperationResult!
  state: DatastreamStatus!
  stopPath: String!
}

input StreamingReplayInput {
  datadogEnv: String!
  endTimeUtc: DateTime!
  serviceName: String!
  startTimeUtc: DateTime!
  useConcurrentReplay: Boolean
}

union StreamingReplayPayload = DefaultErrorFailure | StreamingReplaySuccess

# Generated from com/attentivemobile/contracts/streaming/replay/service/replay/Contract.proto
type StreamingReplaySuccess {
  status: String!
}

enum StringComparator {
  STRING_COMPARATOR_EQUALS
  STRING_COMPARATOR_IN
  STRING_COMPARATOR_LIKE
  STRING_COMPARATOR_UNKNOWN
}

# Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/ComparisonTypes.proto
type StringComparison {
  comparator: SearchStringComparator!
  isNot: Boolean
  values: [String!]!
}

input StringComparisonInput {
  comparator: SearchStringComparator!
  isNot: Boolean
  values: [String!]
}

input StringCondition {
  comparator: StringComparator!
  isNot: Boolean
  values: [String!]
}

input StringDatasetFilter {
  operator: StringFilterOperator!
  values: [String!]
}

# Generated from com/attentivemobile/contracts/metricsapi/DatasetsContract.proto
type StringDatasetValue {
  value: String!
}

# Generated from com/attentivemobile/contracts/metricsapi/Contract.proto
type StringDimension {
  description: String
  dimensionId: String!
  name: String!

  # A new dimensionValues type on Company will replace this field
  possibleValues: [String!]! @deprecated(reason: "deprecated")
}

enum StringFilterOperator {
  STRING_FILTER_OPERATOR_IN
  STRING_FILTER_OPERATOR_NOT_IN
  STRING_FILTER_OPERATOR_UNKNOWN
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type StringPropertyValue {
  value: String!
}

# Generated from com/attentivemobile/contracts/user/profile/property/UserPropertyV2.proto
type StringPropertyValueV2 {
  stringValue: String!
}

# Generated from com/attentivemobile/contracts/brand-kit/BrandElementStyles.proto
type StylableElement {
  name: String!
}

input StylableElementInput {
  name: String!
}

input SubmitGeneratedTextFeedbackInput {
  companyId: ID!
  generatedTextFeedback: [GenerativeAITextFeedbackInput!]
}

# Generated from com/attentivemobile/contracts/generativeaiservice/feedback/GenerativeAIFeedbackContract.proto
type SubmitGeneratedTextFeedbackPayload {
  statusText: String!
}

input SubmitGenerativeAIOptInInput {
  companyId: ID!
}

# Generated from com/attentivemobile/contracts/generativeaiservice/legal/GenerativeAILegalContract.proto
type SubmitGenerativeAIOptInPayload {
  company: Company
}

# Subscribe mutation input.
input SubscribeInput {
  companyId: ID

  # External Identifiers for a user.
  externalIdentifiers: ExternalIdentifiersForSubscribeInput

  # The locale of the user. This field is required if sign source id is omitted.
  locale: LocaleForSubscribeInput

  # The unique identifier of the sign-up source. This can be found in the Sign-up Units tab of the Attentive platform in the ID column for any API sign-up method. Or, you can contact our White Glove team (whiteglove@attentivemobile.com) or your Client Strategy Manager at Attentive to request a sign-up source for either marketing or transactional opt-ins. Our team will review API opt-in units with [compliance in mind](https://docs.attentivemobile.com/pages/legal-docs/legal-disclosure-language/). This field is not required if both locale and subscription type are provided.
  signupSourceId: String

  # Opt in subscriber silently (do not send a Reply Y to subscribe text). NOTE: This property is disabled by default. We strongly recommend maintaining the double opt-in flow. The double opt in serves a couple of important purposes from a legal/compliance perspective. Contact your dedicated Client Strategy Manager (CSM) or our whiteglove@attentivemobile.com team to enable it. If wanting to skip legal/reply y language, set this to be true. We will not send reply y message or legal message to subscribers added with this flag set to true.
  singleOptIn: Boolean

  # Type of subscription. This field is required if sign source id is omitted.
  type: SubscriptionType

  # User associated with the action. Note that this is a visitor to the site and does not need to be actively subscribed to Attentive.
  user: UserInput!
}

# Generated from com/attentivemobile/contracts/subscriptions/api/v2/Contract.proto
# Response to subscribe mutation.
type SubscribePayload {
  # External Identifiers for a user.
  externalIdentifiers: ExternalIdentifiersForSubscribe

  # Subscribe results.
  subscribeResults: [Subscription!]!
}

# Generated from com/attentivemobile/protobuf/common/Identifiers.proto
type SubscriberID {
  id: Long!
}

enum SubscriberIdentifier {
  ID
  PHONE
}

# Generated from com/attentivemobile/contracts/keywords/KeywordService.proto
type SubscriberInfo {
  subscriptionType: String!
}

# Generated from com/attentivemobile/contracts/concierge/service/Message.proto
type SubscriberInitiated {
  subscriberInitiated: Boolean!
}

# Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto
type SubscriberOptimizedSendTimeAudienceFilter {
  includeSubscribersWithoutOptimalSendTime: Boolean!
  optimizedWindowSizeInMins: Int!
  windowStartTimeInUtc: DateTime!
}

# Generated from com/attentivemobile/contracts/subscriptions/batch/Model.proto
type SubscriberTag {
  key: String!
  value: String!
}

input SubscriberTagInput {
  key: String!
  value: String!
}

# Generated from com/attentivemobile/contracts/subscriptions/batch/Model.proto
type SubscriberUploadClientId {
  customIdNamespace: String
  type: SubscriberUploadClientIdType!
  value: String! @deprecated(reason: "deprecated")
}

input SubscriberUploadClientIdInput {
  customIdNamespace: String
  type: SubscriberUploadClientIdType!
  value: String!
}

enum SubscriberUploadClientIdType {
  SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_CLIENT_USER_ID
  SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_CORDIAL_CUSTOMER_ID
  SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_CUSTOM_ID
  SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_KLAVIYO_ID
  SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_SALESFORCE_MARKETING_CLOUD_SUBSCRIBER_ID
  SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_SHOPIFY_ID
  SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_UNKNOWN
}

enum SubscriberUploadDestinationType {
  DESTINATION_TYPE_EMAIL
  DESTINATION_TYPE_PHONE
  DESTINATION_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/subscriptions/batch/Model.proto
type SubscriberUploadFile implements Node {
  company: Company
  id: ID!
  lastUpdated: DateTime!
  mapping: SubscriberUploadFileMappingTemplate!
  name: String!
  sampleData(numberOfLines: Long): [SubscriberUploadFileColumn!]
  timeUploaded: DateTime!
  uploadedBy: String!
}

# Generated from com/attentivemobile/contracts/subscriptions/batch/Model.proto
type SubscriberUploadFileColumn {
  header: String!
  values: [String!]!
}

union SubscriberUploadFileField = CustomPropertyDefinition | DestinationEmail | DestinationPhone | SubscriberUploadClientId | WellKnownProperty

# Generated from com/attentivemobile/contracts/subscriptions/batch/Model.proto
type SubscriberUploadFileFieldMapping {
  customPropertyDefinition: CustomPropertyDefinition
  destinationType: SubscriberUploadDestinationType
  field: SubscriberUploadFileField! @deprecated(reason: "deprecated")
  header: String!
  subscriberUploadClientId: SubscriberUploadClientId
  wellKnownProperty: WellKnownProperty
}

input SubscriberUploadFileMappingInput {
  clientId: SubscriberUploadClientIdInput
  customPropertyId: Long
  destinationType: SubscriberUploadDestinationType
  email: String @deprecated(reason: "deprecated")
  header: String!
  newCustomProperty: CustomPropertyDefinitionInput
  phone: String @deprecated(reason: "deprecated")
  wellKnownProperty: String
  wellKnownPropertyId: Long @deprecated(reason: "deprecated")
}

# Generated from com/attentivemobile/contracts/subscriptions/batch/Model.proto
type SubscriberUploadFileMappingTemplate {
  emailMapped: Boolean!
  mappings: [SubscriberUploadFileFieldMapping!]!
  phoneMapped: Boolean!
  unusedClientIds: [SubscriberUploadClientIdType!]!
  unusedCustomProperties: [CustomPropertyDefinition!]!
  unusedHeaders: [String!]!
  unusedWellKnownProperties: [WellKnownProperty!]!
}

# Generated from com/attentivemobile/contracts/subscriptions/batch/Model.proto
type SubscriberUploadJob implements Node {
  batchId: Long!
  company: Company
  id: ID!
  jobType: SubscriberUploadJobType!
  jobUploadResults: SubscriberUploadJobResults!
  parameters: SubscriberUploadJobParameters!
  processingTime: Duration!
  status: SubscriberUploadJobStatus!
  subscriberUploadFile: SubscriberUploadFile!
  subscriptionFlow: SubscriberUploadSubscriptionFlow!
  validationResult: SubscriberUploadValidationResult!
}

# Generated from com/attentivemobile/contracts/subscriptions/batch/Contract.proto
type SubscriberUploadJobConnection {
  edges: [SubscriberUploadJobEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/subscriptions/batch/Contract.proto
type SubscriberUploadJobEdge {
  cursor: String!
  node: SubscriberUploadJob!
}

# Generated from com/attentivemobile/contracts/subscriptions/batch/Model.proto
type SubscriberUploadJobParameters {
  assignCoupons: Boolean! @deprecated(reason: "deprecated")
  journey: Journey
  offer: String
  sendWelcome: Boolean! @deprecated(reason: "deprecated")
  strategy: SubscriberUploadUpdateStrategy!
  subscriberTag: SubscriberTag!
  subscriptionTypes: SubscriberUploadSubscriptionType!
}

input SubscriberUploadJobParametersInput {
  assignCoupons: Boolean!
  companyId: ID!
  journeyId: ID
  locale: LocaleInput
  offer: String
  sendWelcome: Boolean!
  strategy: SubscriberUploadUpdateStrategy!
  subscriberTag: SubscriberTagInput!
  subscriptionFlow: SubscriberUploadSubscriptionFlow!
  subscriptionTypes: [SubscriberUploadSubscriptionType!]
}

# Generated from com/attentivemobile/contracts/subscriptions/batch/Model.proto
type SubscriberUploadJobResults {
  existingActiveOptOutSubscribersCount: Long!
  existingActiveSubscribersCount: Long!
  existingJoinedSubscribersCount: Long!
  invalidSubscribersCount: Long!
  validSubscribersCount: Long!
}

enum SubscriberUploadJobStatus {
  STATUS_ABANDONED
  STATUS_COMPLETE
  STATUS_INVALID
  STATUS_IN_PROGRESS
  STATUS_PROCESS
  STATUS_UNKNOWN
  STATUS_UPLOADED
}

enum SubscriberUploadJobType {
  JOB_TYPE_ADD_SUBSCRIBERS
  JOB_TYPE_OPT_OUT_EMAILS
  JOB_TYPE_OPT_OUT_PHONES
  JOB_TYPE_SUBSCRIBER_TAG_ONLY
  JOB_TYPE_SUPPRESS_SUBSCRIPTIONS
  JOB_TYPE_UNKNOWN
  JOB_TYPE_UNSUPPRESS_SUBSCRIPTIONS
  JOB_TYPE_UPDATE_EXISTING
}

enum SubscriberUploadSubscriptionFlow {
  SUBSCRIPTION_FLOW_DOUBLE_OPT_IN
  SUBSCRIPTION_FLOW_SILENT_OPT_IN
  SUBSCRIPTION_FLOW_SINGLE_OPT_IN
  SUBSCRIPTION_FLOW_UNKNOWN
}

enum SubscriberUploadSubscriptionType {
  SUBSCRIPTION_TYPE_EMAIL_CHECKOUT_ABANDONED
  SUBSCRIPTION_TYPE_EMAIL_MARKETING
  SUBSCRIPTION_TYPE_EMAIL_TRANSACTIONAL
  SUBSCRIPTION_TYPE_TEXT_CHECKOUT_ABANDONED
  SUBSCRIPTION_TYPE_TEXT_MARKETING
  SUBSCRIPTION_TYPE_TEXT_TRANSACTIONAL
  SUBSCRIPTION_TYPE_UNKNOWN
}

enum SubscriberUploadUpdateStrategy {
  SUBSCRIBER_UPDATE_STRATEGY_OVERWRITE
  SUBSCRIBER_UPDATE_STRATEGY_REMOVE_DUPLICATES
  SUBSCRIBER_UPDATE_STRATEGY_UNKNOWN
  SUBSCRIBER_UPDATE_STRATEGY_UPDATE_EXISTING
}

# Generated from com/attentivemobile/contracts/subscriptions/batch/Model.proto
type SubscriberUploadValidationResult {
  duplicates: Long!
  invalidEnumValues: [InvalidEnumValuesEntry!]!
  invalidRows: Long!
  invalidRowsPreCheckReportUrl: String!
  totalRows: Long!
}

# Generated from com/attentivemobile/contracts/subscriptions/api/v2/Model.proto
# Subscription details.
type Subscription {
  # Channel for subscription.
  channel: SubscriptionChannel!

  # Destination address for subscription.
  destinationAddress: DestinationAddress

  # Type of subscription.
  type: SubscriptionType!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type SubscriptionAttribution {
  name: String!
}

# Channel of subscription.
enum SubscriptionChannel {
  CHANNEL_EMAIL
  CHANNEL_TEXT
  CHANNEL_UNKNOWN
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
# Subscription connection details.
type SubscriptionConnection {
  # Sign up date timestamp.
  signupDate: DateTime!

  # Subscription eligibility details.
  subscriptionEligibilities: [SubscriptionEligibility!]!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
# Subscription eligibility information.
type SubscriptionEligibility {
  attribution: SubscriptionAttribution

  # Eligibility information.
  eligibility: Eligibility!

  # Subscription details.
  subscription: Subscription!
}

# Type and channel of a subscription.
input SubscriptionInput {
  # Channel of subscription.
  channel: SubscriptionChannel!

  # Type of subscription.
  type: SubscriptionType!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type SubscriptionOptInCompletedEvent {
  created: DateTime! @deprecated(reason: "deprecated")
  id: String! @deprecated(reason: "deprecated")
  subscription: Subscription!
}

enum SubscriptionOptOutSource {
  OPT_OUT_SOURCE_ATTENTIVE_SUPPORT_EMAIL
  OPT_OUT_SOURCE_CCPA
  OPT_OUT_SOURCE_CLIENT
  OPT_OUT_SOURCE_CRM_UI_UNSUBSCRIBE_BUTTON
  OPT_OUT_SOURCE_EMAIL
  OPT_OUT_SOURCE_KEYWORD
  OPT_OUT_SOURCE_LITIGATOR
  OPT_OUT_SOURCE_SCRIPT
  OPT_OUT_SOURCE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type SubscriptionOptedOutEvent {
  created: DateTime! @deprecated(reason: "deprecated")
  id: String! @deprecated(reason: "deprecated")
  subscription: Subscription!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type SubscriptionSuppressedEvent {
  subscription: Subscription!
  suppressionReason: SuppressionReason!
}

# Type of subscription.
enum SubscriptionType {
  TYPE_CHECKOUT_ABANDONED
  TYPE_MARKETING
  TYPE_TRANSACTIONAL
  TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type SubscriptionUnsuppressedEvent {
  subscription: Subscription!
}

# Generated from com/attentivemobile/contracts/segment/recommendation/service/Contract.proto
type SuccessSegmentEnhancementWorkflowStatus {
  enhancements: [SegmentEnhancement!]!
}

# Generated from com/attentivemobile/contracts/solutions/bulkoptout/SolutionsOptout.proto
type SuccessfulOptoutsEntry {
  key: String!
  value: Boolean!
}

# Generated from com/attentivemobile/contracts/reports/api/Contract.proto
type SummaryMetrics {
  summaryMetricIds: [String!]!
}

input SummaryMetricsInput {
  summaryMetricIds: [String!]
}

enum SuppressionReason {
  SUPPRESSION_REASON_HIGH_BLOCK_RATE
  SUPPRESSION_REASON_HIGH_BOUNCE_RATE
  SUPPRESSION_REASON_INVALID_ADDRESS
  SUPPRESSION_REASON_MANUAL
  SUPPRESSION_REASON_ROLE_ACCOUNTS
  SUPPRESSION_REASON_SPAM_REPORTER
  SUPPRESSION_REASON_TEMPORARY_ADDRESS
  SUPPRESSION_REASON_UNKNOWN
}

input SwapCreativeExternalNameInput {
  creativeIdOne: String!
  creativeIdTwo: String!
}

# Generated from com/attentivemobile/contracts/solutions/companyconfiguration/Configuration.proto
type SwapCreativeExternalNamePayload {
  success: Boolean
}

enum SweepstakesSelectorType {
  SWEEPSTAKES_SELECTOR_TYPE_FIRST
  SWEEPSTAKES_SELECTOR_TYPE_LAST
  SWEEPSTAKES_SELECTOR_TYPE_MOST_VOTES
  SWEEPSTAKES_SELECTOR_TYPE_RANDOM
}

enum SweepstakesSubscriberType {
  SWEEPSTAKES_SUBSCRIBER_TYPE_ANY
  SWEEPSTAKES_SUBSCRIBER_TYPE_MARKETING
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type Sync implements Node {
  connectionSettings: SyncConnectionSettings
  description: String!
  fetchOptions: FetchOptions!
  fileSettings: FileSettings!
  fileType: EdsJobIngestionFormat!
  id: ID!
  latestSync: DateTime
  latestSyncRunStatus: SyncRunStatus
  name: String!
  schedule: SyncSchedule!
  status: SyncStatus!
  syncId: String!
  syncMethod: EdsJobSyncMethod!

  # Retrieve all runs of this sync.
  syncRuns(after: String, before: String, first: Int, last: Int): SyncRuns
  syncType: EdsJobSync!
  targetConfig: JSON!
}

union SyncConnectionSettings = AttentiveS3Settings | SftpSettings | SnowflakeSecureDataSharingSettings

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type SyncEdge {
  cursor: String!
  node: Sync!
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type SyncError {
  message: String!
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type SyncRun implements Node {
  failed: Int!
  id: ID!
  ingested: Int!
  status: SyncRunStatus!

  # Retrieve the details of this specific run.
  syncRunDetails: SyncRunDetails
  trigger: SyncTrigger!
  updated: DateTime!
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type SyncRunDetails {
  links: SyncRunLinks!
  stepTroubleshootingDetails: [SyncRunStepTroubleshootingDetails!]!
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type SyncRunEdge {
  cursor: String!
  node: SyncRun!
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type SyncRunLinks {
  datadog: String!
  druid: String!
  temporal: String!
}

enum SyncRunStatus {
  SYNC_RUN_STATUS_CANCELED
  SYNC_RUN_STATUS_COMPLETED_MIXED_SUCCESS
  SYNC_RUN_STATUS_COMPLETED_SUCCESSFULLY
  SYNC_RUN_STATUS_FAILED
  SYNC_RUN_STATUS_RUNNING
  SYNC_RUN_STATUS_SCHEDULED
  SYNC_RUN_STATUS_UNKNOWN
}

enum SyncRunStep {
  SYNC_RUN_STEP_CHUNK_FILE
  SYNC_RUN_STEP_FILE_DOWNLOAD
  SYNC_RUN_STEP_FILE_SURVEY
  SYNC_RUN_STEP_INGEST_DATA
  SYNC_RUN_STEP_PREPROCESSOR
  SYNC_RUN_STEP_PRODUCT_CATALOG_UPLOAD
  SYNC_RUN_STEP_SET_RATELIMIT
  SYNC_RUN_STEP_SNOWFLAKE_CONTENT_DOWNLOAD
  SYNC_RUN_STEP_SYNC_START
  SYNC_RUN_STEP_TRANSFORM_DATA
  SYNC_RUN_STEP_UNKNOWN
  SYNC_RUN_STEP_WAITING_FOR_EXTERNAL_SYSTEM_RESULTS
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type SyncRunStepTroubleshootingDetails {
  details: JSON!
  status: SyncRunStatus!
  step: SyncRunStep!
  timestamp: DateTime!
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type SyncRuns {
  edges: [SyncRunEdge!]!
  pageInfo: PageInfo!
  totalCount: Int!
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type SyncSchedule {
  schedule: SyncScheduleSchedule
}

input SyncScheduleInput {
  schedule: SyncScheduleInputSchedule
}

input SyncScheduleInputSchedule {
  cronSchedule: CronInput
  oneTimeSchedule: OneTimeInput
}

union SyncScheduleSchedule = Cron | OneTime

enum SyncStatus {
  SYNC_STATUS_ACTIVE
  SYNC_STATUS_INACTIVE
  SYNC_STATUS_UNKNOWN
}

enum SyncTrigger {
  SYNC_TRIGGER_MANUAL
  SYNC_TRIGGER_SCHEDULED
  SYNC_TRIGGER_UNKNOWN
}

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type Syncs {
  edges: [SyncEdge!]!
  pageInfo: PageInfo!
  totalCount: Int!
}

# Generated from com/attentivemobile/protobuf/common/tags/Tag.proto
type Tag implements Node {
  company: Company
  id: ID!
  name: String!
  uuid: String! @deprecated(reason: "deprecated")
}

input TagAiConfig {
  analyzers: [String!]
  collector: String!
}

# Generated from com/attentivemobile/contracts/tag/ai/queueing/service/Contract.proto
type TagAiStartCollectionEventResponse {
  company: Company
  error: String
}

# Generated from com/attentivemobile/contracts/tagbuildservice/Contract.proto
type TagConfig {
  company: Company
  description: String
  dtagConfig: JsonString!
  enrichers: [String!]!
  preset: String!
  tagVersion: String!
  transformers: [String!]!
  triggers: [String!]!
}

# Generated from com/attentivemobile/contracts/tagbuildservice/Contract.proto
type TagConfigRelease {
  created: String!
  id: String!
  tagConfig: TagConfig!
  userEmail: String!
}

# Generated from com/attentivemobile/contracts/tagbuildservice/Contract.proto
type TagConfigReleaseEdge {
  cursor: String!
  node: TagConfigRelease!
}

# Generated from com/attentivemobile/contracts/tagbuildservice/Contract.proto
type TagConfigReleasesConnection {
  edges: [TagConfigReleaseEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/tag/service/Contract.proto
type TagEdge {
  cursor: String!
  node: Tag!
}

# Generated from com/attentivemobile/contracts/tagbuildservice/taghealth/Contract.proto
type TagEventStats {
  addToCartStats: EventStats
  productViewStats: EventStats
  purchaseStats: EventStats
}

input TagFilter {
  name: String
}

# Generated from com/attentivemobile/contracts/tag/service/Contract.proto
type TagPayload {
  tag: Tag!
}

# Generated from com/attentivemobile/protobuf/common/tags/Tag.proto
type TaggableEntity {
  campaignMessageGroup: CampaignMessageGroup
  taggableEntityType: TaggableEntityType!
}

enum TaggableEntityType {
  TAGGABLE_ENTITY_TYPE_CAMPAIGN_MESSAGE_GROUP
  TAGGABLE_ENTITY_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/tag/service/Contract.proto
type TagsConnection {
  edges: [TagEdge!]!
  pageInfo: PageInfo!
  totalCount: Long!
}

input TakeActionOnFeedbackInput {
  messageFeedbackId: ID!
  outcome: MessageFeedbackOutcome!
}

# Generated from com/attentivemobile/contracts/concierge/service/Feedback.proto
type TakeActionOnFeedbackPayload {
  messageFeedback: MessageFeedback!
}

enum TargetCreativeStatus {
  TARGET_CREATIVE_STATUS_ACTIVE
  TARGET_CREATIVE_STATUS_INACTIVE
  TARGET_CREATIVE_STATUS_UNKNOWN
}

# If the message is already created, you can sync the content by passing in the target_message_id. A new message will be created if target_message_id is blank.
input TargetMessageInput {
  name: String!
  targetMessageId: ID
  utmParameters: UtmParametersInput
}

# Generated from com/attentivemobile/contracts/featureflag/service/Contract.proto
type TargetingConnection {
  edges: [TargetingDatumEdge!]!
  pageInfo: PageInfo!
  totalCount: Long!
}

input TargetingData {
  contextKey: String!
  contextValue: String!
  description: String!
  rolloutVariants: [RolloutInput!]
}

# Generated from com/attentivemobile/contracts/featureflag/service/Contract.proto
type TargetingDatum {
  contextKey: String!
  contextValue: String!
  created: DateTime!
  description: String!
  featureFlag: FeatureFlag
  featureFlagId: String!
  rolloutVariants: [Rollout!]!
  updated: DateTime!
}

# Generated from com/attentivemobile/contracts/featureflag/service/Contract.proto
type TargetingDatumEdge {
  cursor: String!
  node: TargetingDatum!
}

input TargetingRemove {
  contextKey: String!
  contextValue: String!
}

# Generated from com/attentivemobile/contracts/concierge/service/Template.proto
type TemplateEdge {
  cursor: String!
  node: AgentTemplate!
}

enum TemplateFieldValidationType {
  TEMPLATE_FIELDS_VALIDATION_TYPE_SHORTCUT
  TEMPLATE_FIELDS_VALIDATION_TYPE_UNKNOWN
}

enum TemplateFilter {
  TEMPLATE_FILTER_COMPANY
  TEMPLATE_FILTER_GLOBAL
  TEMPLATE_FILTER_UNKNOWN
}

input TemplateInput {
  content: String!
  role: String!
  templateName: String!
}

input TemplateReferenceInput {
  templateId: String!
  templateText: String!
}

input TemplateReferenceListInput {
  templates: [TemplateReferenceInput!]
}

# Generated from com/attentivemobile/contracts/concierge/service/TemplateRequest.proto
type TemplateRequestConnection {
  edges: [TemplateRequestEdge!]!
  pageInfo: PageInfo!
  totalCount: Long!
}

# Generated from com/attentivemobile/contracts/concierge/service/TemplateRequest.proto
type TemplateRequestEdge {
  cursor: String!
  node: AgentTemplateRequest!
}

enum TemplateRequestFilter {
  TEMPLATE_REQUEST_FILTER_REQUESTED
  TEMPLATE_REQUEST_FILTER_REVIEWED
  TEMPLATE_REQUEST_FILTER_UNKNOWN
}

enum TemplateRequestStatus {
  TEMPLATE_REQUEST_STATUS_APPROVED
  TEMPLATE_REQUEST_STATUS_DENIED
  TEMPLATE_REQUEST_STATUS_READ
  TEMPLATE_REQUEST_STATUS_UNKNOWN
  TEMPLATE_REQUEST_STATUS_UNREAD
}

enum TemplateRole {
  TEMPLATE_ROLE_SYSTEM
  TEMPLATE_ROLE_UNKNOWN
  TEMPLATE_ROLE_USER
}

# Generated from com/attentivemobile/contracts/concierge/service/Template.proto
type TemplateSource {
  source: TemplateSourceSource
}

union TemplateSourceSource = CompanySettingsSource | ConversationSource | FeedbackSource

enum TemplatingSyntaxType {
  TEMPLATING_SYNTAX_TYPE_LIQUID
  TEMPLATING_SYNTAX_TYPE_NON_LIQUID
  TEMPLATING_SYNTAX_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/integrations/templatizedpostbacks/Contract.proto
type TemplatizedAuthSetting {
  templatizedAuthSettings: [TemplatizedAuthSettingsEntry!]!
}

input TemplatizedAuthSettingInput {
  templatizedAuthSettingsInput: [TemplatizedAuthSettingsInputEntry!]
}

# Generated from com/attentivemobile/contracts/integrations/templatizedpostbacks/Contract.proto
type TemplatizedAuthSettingsEntry {
  key: String!
  value: String!
}

input TemplatizedAuthSettingsInputEntry {
  key: String!
  value: String!
}

# Generated from com/attentivemobile/contracts/integrations/templatizedpostbacks/Contract.proto
type TemplatizedHeaderSettingEntry {
  key: String!
  value: String!
}

input TemplatizedHeaderSettingInputEntry {
  key: String!
  value: String!
}

# Generated from com/attentivemobile/contracts/integrations/templatizedpostbacks/Contract.proto
type TemplatizedPostback {
  body: JsonString!
  companyPostbackTemplateId: String!
  conditionalMacros: [JsonString!]!
  endpoint: String!
  headerSettings: [TemplatizedPostbackHeaderSettings!]!
  httpRequestType: HttpRequestType!
  responseBody: String!
  responseCode: Long!
  templateName: String!
  templatizedAuthSettings: TemplatizedAuthSetting!
}

# Generated from com/attentivemobile/contracts/integrations/templatizedpostbacks/Contract.proto
type TemplatizedPostbackHeaderSettings {
  templatizedHeaderSetting: [TemplatizedHeaderSettingEntry!]!
}

input TemplatizedPostbackHeaderSettingsInput {
  templatizedHeaderSettingInput: [TemplatizedHeaderSettingInputEntry!]
}

input TemplatizedPostbackSettingsInput {
  companyId: ID!
  companyPostbackTemplateId: String!
  enabled: Boolean!
  integrationEvent: [IntegrationEventTypeInput!]
  templateName: String!
}

# Generated from com/attentivemobile/contracts/integrations/templatizedpostbacks/Contract.proto
type TemplatizedPostbackSettingsPayload {
  responseBody: String!
  responseCode: Long!
}

enum TextChannel {
  TEXT_CHANNEL_MMS
  TEXT_CHANNEL_SMS
  TEXT_CHANNEL_UNKNOWN
}

input TextContentInput {
  body: String!
}

input TextGenVendorOptions {
  vendorOptions: TextGenVendorOptionsVendorOptions
}

input TextGenVendorOptionsVendorOptions {
  openaiOptions: OpenAIOptions
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type TextMessageProperties {
  body: String!
  estimatedNumberOfMessageParts: Int!
  personalizedLinkId: Long
  shortLinks: [ShortLink!]!
}

input TextMessagePropertiesRequest {
  body: String!
  shortLinkProperties: [ShortLinkProperties!]
}

input TextMessagingService {
  messagingServiceProvider: MessagingServiceProvider
  sourcePhone: String
}

input TextPreviewInput {
  body: String!
  mediaUrl: String
  personalizedLinkId: String
  shortLinkProperties: [ShortLinkProperties!]
}

input TextPreviewRecipientsInput {
  phoneNumbers: [String!]
}

# Generated from com/attentivemobile/protobuf/common/compose/Message.proto
type TextTemplate {
  body: String!
}

input TextTemplateInput {
  body: String!
}

# Generated from com/attentivemobile/contracts/payments/v2/Contract.proto
type TextToBuyConfig {
  company: Company
  disableOrderConfirmMessages: Boolean!
  id: String!
  paymentCompanyStatus: PaymentCompanyStatus!
}

enum TextToBuyMessageType {
  TEXT_TO_BUY_MESSAGE_TYPE_CAMPAIGN
  TEXT_TO_BUY_MESSAGE_TYPE_JOURNEY
  TEXT_TO_BUY_MESSAGE_TYPE_UNKNOWN
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type ThirdPartyMessageId {
  id: String!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type ThirdPartyPropertyDefinition implements Node {
  id: ID!
  key: String!
  vendor: ProfileVendor!
}

input ThirdPartySegmentMetadata {
  source: String!
  thirdPartyId: String!
}

enum ThreadRunStatus {
  THREAD_RUN_STATUS_CANCELLED
  THREAD_RUN_STATUS_CANCELLING
  THREAD_RUN_STATUS_COMPLETED
  THREAD_RUN_STATUS_EXPIRED
  THREAD_RUN_STATUS_FAILED
  THREAD_RUN_STATUS_IN_PROGRESS
  THREAD_RUN_STATUS_QUEUED
  THREAD_RUN_STATUS_REQUIRES_ACTION
  THREAD_RUN_STATUS_UNKNOWN
}

enum TimeComparator {
  TIME_COMPARATOR_AFTER
  TIME_COMPARATOR_BEFORE
  TIME_COMPARATOR_BETWEEN
  TIME_COMPARATOR_DAY_OF
  TIME_COMPARATOR_MONTH_OF
  TIME_COMPARATOR_OVER_ALL_TIME
  TIME_COMPARATOR_UNKNOWN
  TIME_COMPARATOR_WITHIN
}

# Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/ComparisonTypes.proto
type TimeComparison {
  comparator: SearchTimeComparator!
  endTime: DateTime
  startTime: DateTime
}

input TimeComparisonInput {
  comparator: SearchTimeComparator!
  endTime: DateTime
  startTime: DateTime
}

input TimeCondition {
  comparator: TimeComparator!
  endTime: DateTime
  time: DateTime
}

# Generated from com/attentivemobile/protobuf/common/conditions/Time.proto
type TimeConditionOutput {
  comparator: TimeComparator!
  endTime: DateTime!
  time: DateTime!
}

input TimeDatasetFilter {
  endDate: DateTime!
  startDate: DateTime!
}

# Generated from com/attentivemobile/contracts/metricsapi/DatasetsContract.proto
type TimeDatasetValue {
  value: DateTime!
}

# Generated from com/attentivemobile/contracts/metricsapi/Contract.proto
type TimeDimension {
  description: String
  dimensionId: String!

  # Only populated when the TimeDimension is returned as a MetricDefinition subfield. Denotes the earliest date the metric has data when queried by this time dimension
  earliestAvailableDate: DateTime

  # Only populated when the TimeDimension is returned as a MetricDefinition subfield. Denotes the granularities the metric can be grouped by with the dimension
  granularities: [TimeDimensionGranularity!]!
  name: String!
}

enum TimeDimensionGranularity {
  TIME_DIMENSION_GRANULARITY_15MIN
  TIME_DIMENSION_GRANULARITY_DAILY
  TIME_DIMENSION_GRANULARITY_HOURLY
  TIME_DIMENSION_GRANULARITY_MONTHLY
  TIME_DIMENSION_GRANULARITY_UNKNOWN
  TIME_DIMENSION_GRANULARITY_WEEKLY
  TIME_DIMENSION_GRANULARITY_YEARLY
}

# Generated from com/attentivemobile/contracts/common/TimeOfDay.proto
type TimeOfDay {
  hours: Int!
  minutes: Int!
  nanos: Int!
  seconds: Int!
}

input TimeOfDayInput {
  hours: Int!
  minutes: Int!
  nanos: Int!
  seconds: Int!
}

# Generated from com/attentivemobile/contracts/keywords/KeywordRuleService.proto
type TimeWindowRule {
  created: DateTime!
  endTime: LocalTimeMessage!
  id: Int!
  startTime: LocalTimeMessage!
  timeZoneId: String!
  updated: DateTime!
}

# Generated from com/attentivemobile/contracts/survey/poll/Contract.proto
type TimelineEntry {
  keyword: String!
  timestamp: DateTime!
}

# Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto
type TimestampNoTz {
  day: Int!
  hour: Int!
  minute: Int!
  month: Int!
  year: Int!
}

input TimestampNoTzInput {
  day: Int!
  hour: Int!
  minute: Int!
  month: Int!
  year: Int!
}

# Generated from com/attentivemobile/protobuf/common/campaigns/Campaign.proto
type TimezoneAudienceFilter {
  includeSubscribersWithoutTimezone: Boolean!
  zoneOffset: String!
}

type Toast {
  text: String!
  title: String
  type: ToastType
}

enum ToastType {
  error
  success
}

input ToggleArchivePollInput {
  companyId: ID!
  pollId: ID!
  shouldArchive: Boolean!
}

# Generated from com/attentivemobile/contracts/survey/poll/Contract.proto
type ToggleArchivePollPayload {
  success: Boolean!
}

# A Solutions-owned wrapper around Creatives' EnableCreativeTemplate/DisableCreativeTemplate rpc which is not on the graph
input ToggleCreativeTemplateInput {
  companyId: ID!
  enabled: Boolean!
  templateId: String!
}

# Generated from com/attentivemobile/contracts/solutions/companyconfiguration/Configuration.proto
type ToggleCreativeTemplatePayload {
  success: Boolean!
}

input ToggleLegacyReportInput {
  companyId: ID!
  reportId: String!
}

# Generated from com/attentivemobile/contracts/reports/api/Contract.proto
type ToggleLegacyReportPayload {
  message: String!
}

input ToggleLegacyScheduledReportInput {
  scheduledReportId: String!
}

# Generated from com/attentivemobile/contracts/reports/api/Contract.proto
type ToggleLegacyScheduledReportPayload {
  message: String!
}

input ToggleMfaForCompanyInput {
  companyId: ID!
  enabled: Boolean!
}

# Generated from com/attentivemobile/contracts/company-service/Contract.proto
type ToggleMfaForCompanyPayload {
  successful: Boolean!
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Datastream.proto
type TopicToMessageOffsetsEntry {
  key: String!
  value: String!
}

# Generated from com/attentivemobile/contracts/event/ecommerce/Fulfillment.proto
type Tracking {
  trackingLink: Url!
  trackingNumber: String!

  # The company tracking the shipment of items in this fulfillment.
  trackingProvider: String!
}

enum TrafficSplitType {
  TRAFFIC_SPLIT_TYPE_CUSTOM
  TRAFFIC_SPLIT_TYPE_EVENLY
  TRAFFIC_SPLIT_TYPE_UNKNOWN
}

input TriggerEmailIPAddressDomainVerificationInput {
  domain: String!
}

union TriggerEmailIPAddressDomainVerificationPayload = DefaultErrorFailure | TriggerEmailIPAddressDomainVerificationSuccess

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type TriggerEmailIPAddressDomainVerificationSuccess {
  domain: String!
}

input TriggerGenerateMessageWorkflowInput {
  companyId: ID!
  excludeLinks: [String!]
  messageType: [MessageType!]
}

union TriggerGenerateMessageWorkflowPayload = DefaultErrorFailure | TriggerGenerateMessageWorkflowSuccess

# Generated from com/attentivemobile/contracts/personalization/service/aijourneys/defaultmessage/AIJourneyDefaultMessageServiceContract.proto
type TriggerGenerateMessageWorkflowSuccess {
  status: GenerateMessageWorkflowStatus!
}

input TriggerJourneyPreviewInput {
  companyId: ID!
  journeyId: ID!
  recipient: RecipientInput
}

# Generated from com/attentivemobile/contracts/journeys/api/Contract.proto
type TriggerJourneyPreviewPayload {
  eventType: EventType!
}

# Generated from com/attentivemobile/contracts/journeys/api/JourneyTrigger.proto
type TriggerSource {
  oneofSource: TriggerSourceOneofSource
}

input TriggerSourceInput {
  applicationTriggerSourceRequest: ApplicationTriggerSourceInput
  customTriggerSourceRequest: CustomTriggerSourceInput
}

union TriggerSourceOneofSource = ApplicationTriggerSource | CustomTriggerSource | GlobalTriggerSource | VendorTriggerSource

input TriggerWorkloadInput {
  workloadName: String!
}

union TriggerWorkloadPayload = DefaultErrorFailure | TriggerWorkloadSuccess

# Generated from com/attentivemobile/contracts/workload/management/service/runner/Contract.proto
type TriggerWorkloadSuccess {
  jobState: WorkloadJobState!
  workloadName: String!
}

input TurnOffTagAiCollectionAndReleaseTagConfigInput {
  companyId: ID!
}

union TurnOffTagAiCollectionAndReleaseTagConfigPayload = DefaultErrorFailure | TurnOffTagAiCollectionAndReleaseTagConfigSuccess

# Generated from com/attentivemobile/contracts/tagbuildservice/Contract.proto
type TurnOffTagAiCollectionAndReleaseTagConfigSuccess {
  releaseId: Long!
}

input TurnOnTagAiCollectionAndReleaseTagConfigInput {
  companies: [ID!]
  tagAiConfig: String!
}

union TurnOnTagAiCollectionAndReleaseTagConfigPayload = DefaultErrorFailure | TurnOnTagAiCollectionAndReleaseTagConfigSuccess

# Generated from com/attentivemobile/contracts/tagbuildservice/Contract.proto
type TurnOnTagAiCollectionAndReleaseTagConfigSuccess {
  companies: [TurnOnTagAiCollectionCompanyResponse!]!
}

# Generated from com/attentivemobile/contracts/tagbuildservice/Contract.proto
type TurnOnTagAiCollectionCompanyResponse {
  company: Company
  error: String
  releaseId: Long!
}

# Generated from com/attentivemobile/protobuf/common/targeting/segmentation/output/SearchDefinitionOutput.proto
type TypeComparison {
  values: TypeComparisonValues
}

input TypeComparisonInput {
  values: TypeComparisonInputValues
}

input TypeComparisonInputValues {
  booleanComparison: BooleanComparisonInput
  dateComponentComparison: DateComponentComparisonInput
  distanceComparison: DistanceComparisonInput
  existenceComparison: ExistenceComparisonInput
  frequencyComparison: FrequencyComparisonInput
  numericComparison: NumericComparisonInput
  relativeTimeComparison: RelativeTimeComparisonInput
  stringComparison: StringComparisonInput
  timeComparison: TimeComparisonInput
}

union TypeComparisonValues = BooleanComparison | DateComponentComparison | DistanceComparison | ExistenceComparison | FrequencyComparison | NumericComparison | RelativeTimeComparison | StringComparison | TimeComparison

# Generated from com/attentivemobile/contracts/creative-service/creative/CreativeGraphService.proto
type UnarchiveCreativeFailure {
  error: String!
}

input UnarchiveCreativeInput {
  companyId: ID!
  creativeId: ID!
}

# Generated from com/attentivemobile/contracts/creative-service/creative/CreativeGraphService.proto
type UnarchiveCreativePayload {
  creative: Creative! @deprecated(reason: "deprecated")
  response: UnarchiveCreativePayloadResponse
}

union UnarchiveCreativePayloadResponse = UnarchiveCreativeFailure | UnarchiveCreativeSuccess

# Generated from com/attentivemobile/contracts/creative-service/creative/CreativeGraphService.proto
type UnarchiveCreativeSuccess {
  creative: Creative!
}

input UnassignCreativesFromJourneyInput {
  companyId: ID!
  creativeId: [ID!]
  journeyId: ID!
}

# Generated from com/attentivemobile/contracts/journeys/api/Contract.proto
type UnassignCreativesFromJourneyPayload {
  journey: Journey!
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type UndefinedMacro {
  macro: String!
}

# Generated from com/attentivemobile/contracts/concierge/service/Contract.proto
type UnhandledUsersConnection {
  edges: [UnhandledUsersEdge!]!
  pageInfo: PageInfo!
  totalCount: Long!
}

# Generated from com/attentivemobile/contracts/concierge/service/Contract.proto
type UnhandledUsersEdge {
  cursor: String!
  node: User
}

input UninstallTagInput {
  companyId: ID!
  tagUrl: String!
}

# Generated from com/attentivemobile/contracts/conversations/v1/Conversation.proto
type UnreadCountResponse {
  clientConversationUnreadCount: Long!
}

input UnscheduleEdsCronJobInput {
  taskId: String!
  triggerType: String!
}

# Generated from com/attentivemobile/contracts/eventdestination/cron/service/CronScheduler.proto
type UnscheduleEdsCronJobPayload {
  msg: String!
}

# Unsubscribe mutation input.
input UnsubscribeInput {
  companyId: ID

  # Optional notification properties to override.
  notification: UnsubscribeNotificationInput
  optOutSource: SubscriptionOptOutSource

  # Subscriptions.
  subscriptions: [SubscriptionInput!]

  # User associated with the action. Note that this is a visitor to the site and does not need to be actively subscribed to Attentive.
  user: UserInput!
}

# Unsubscribe notification details.
input UnsubscribeNotificationInput {
  # Whether or not notification is disabled.
  disabled: Boolean

  # Standard language tags as defined by the ISO. Currently only supports en-US and fr-CA. Case sensitive, must match exactly.
  language: String
}

# Generated from com/attentivemobile/contracts/subscriptions/api/v2/Contract.proto
# Response for unsubscribe mutation.
type UnsubscribePayload {
  # Unsubscribe subscription results.
  unsubscribeResults: [Subscription!]!
}

# Generated from com/attentivemobile/contracts/concierge/service/Template.proto
type UnsupportedMacros {
  macros: [UserConversationMacro!]!
}

input UpdateAIBrandVoiceSettingsInput {
  aiBrandVoiceSettings: AIBrandVoiceSettingsUpdateInput!
  companyId: ID!
}

union UpdateAIBrandVoiceSettingsPayload = DefaultErrorFailure | UpdateAIBrandVoiceSettingsSuccess

# Generated from com/attentivemobile/contracts/messaging/automation/brandvoice/AIBrandVoice.proto
type UpdateAIBrandVoiceSettingsSuccess {
  aiBrandVoiceSettings: AIBrandVoiceSettings!
  statusText: String!
}

input UpdateAIBrandVoiceSettingsV2Input {
  aiBrandVoiceSettings: AIBrandVoiceSettingsUpdateV2Input!
  companyId: ID!
}

union UpdateAIBrandVoiceSettingsV2Payload = DefaultErrorFailure | UpdateAIBrandVoiceSettingsV2Success

# Generated from com/attentivemobile/contracts/generativeaiservice/brandvoice/AIBrandVoice.proto
type UpdateAIBrandVoiceSettingsV2Success {
  aiBrandVoiceSettings: AIBrandVoiceSettingsV2!
  statusText: String!
}

input UpdateAdminNotificationConfigurationInput {
  adminNotificationConfiguration: AdminNotificationConfigurationInput!
  adminNotificationConfigurationId: ID!
  deleted: Boolean!
  notificationTemplateId: Long!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type UpdateAdminNotificationConfigurationPayload {
  adminNotificationConfiguration: AdminNotificationConfiguration!
}

input UpdateAgentTemplateRequestInput {
  id: String!
  shortcut: String
  status: TemplateRequestStatus!
  text: String!
  title: String!
}

input UpdateAiGrowTrialEligibilityInput {
  companyId: ID!
  eligibilityStatus: AiGrowTrialEligibilityStatus!
  formSubmittedDate: DateTime
  internalId: String
  signupFormLink: String
}

union UpdateAiGrowTrialEligibilityPayload = DefaultErrorFailure | UpdateAiGrowTrialEligibilitySuccess

# Generated from com/attentivemobile/contracts/multiproductenablement/service/AiGrowTrialStatusGraphService.proto
type UpdateAiGrowTrialEligibilitySuccess {
  clientEligibility: ClientEligibility!
}

input UpdateApplicationWebhookInput {
  applicationId: ID!
  applicationWebhookId: ID!
  clientSecret: String!
  destination: String!
  eventTypeIds: [Long!]
  webhookStatus: WebhookStatus!
  webhookType: ApplicationWebhookType!
}

# Generated from com/attentivemobile/contracts/externaltokens/Contract.proto
type UpdateApplicationWebhookPayload {
  applicationWebhook: ApplicationWebhook!
}

input UpdateAttachmentRequest {
  attachmentContextRequest: AttachmentBodyProperties!
  attachmentId: String
  attachmentType: AttachmentType!
}

input UpdateAttributionSettingsInput {
  companyId: ID!
  emailClickWindow: AttributionWindowInput
  emailViewWindow: AttributionWindowInput
  filterMachineOpened: Boolean
  newRevenueModel: NewRevenueModelInput
  textClickWindow: AttributionWindowInput
  textViewWindow: AttributionWindowInput
}

# Generated from com/attentivemobile/contracts/lift/service/Contract.proto
type UpdateAttributionSettingsPayload {
  company: Company
}

input UpdateAutoJourneyInput {
  clientSettings: AutoJourneyClientSettingsInput
  config: AutoJourneyConfigInput
  id: ID!
  status: AutoJourneyStatus
}

# Generated from com/attentivemobile/contracts/messaging/automation/autojourneys/AutoJourneysContract.proto
type UpdateAutoJourneysPayload {
  journey: AutoJourney!
}

input UpdateAutomationProgramInput {
  companyId: ID!
  couponPoolId: Long
  id: ID!
  messageLinkTargetUrl: String
  updateEmailNotificationSettingsRequest: UpdateEmailNotificationSettingsInput
  updateProgramCadenceRequest: UpdateProgramCadenceInput
}

# Generated from com/attentivemobile/contracts/messaging/automation/Contract.proto
type UpdateAutomationProgramPayload {
  automationProgram: AutomationProgram!
}

input UpdateBlockCompanyInfoInput {
  blockCompanyInfo: [BlockCompanyInfoInput!]
  companyId: ID!
}

union UpdateBlockCompanyInfoPayload = DefaultErrorFailure | UpdateBlockCompanyInfoSuccess

# Generated from com/attentivemobile/contracts/personalization/service/aijourneys/onboarding/companyinfo/BlockCompanyInfo.proto
type UpdateBlockCompanyInfoSuccess {
  blockCompanyInfo: [BlockCompanyInfo!]!
  success: Boolean!
}

input UpdateCampaignMessageGroupInput {
  campaignMessageGroupId: ID!
  companyId: ID!
  downgradePreapproved: Boolean
  experimentMetadata: CampaignExperimentMetadataAttachmentInput
  messageMembers: [CampaignMessageGroupMessageMemberInput!]
  name: String
  passedTimezonesStrategy: PassedTimezonesStrategy
  scheduleType: CampaignScheduleType
  segments: SegmentFiltersInput
  sendTimeExperimentMetadata: SendTimeExperimentMetadataAttachmentInput
  smartSendingEnabled: Boolean
  startTime: DateTime
  status: CampaignMessageGroupStatus
  tags: CampaignTagsInput
  type: CampaignMessageGroupType
  userId: ID!
}

input UpdateCircuitBreakerRateLimitInput {
  endpoint: String!
  isActive: Boolean!
  rateLimit: Long!
}

union UpdateCircuitBreakerRateLimitPayload = DefaultErrorFailure | UpdateCircuitBreakerRateLimitSuccess

# Generated from com/attentivemobile/contracts/gateway/service/RateLimiting.proto
type UpdateCircuitBreakerRateLimitSuccess {
  endpoint: String!
  id: Long!
  isActive: Boolean!
  rateLimit: Long!
}

input UpdateCompanyApplicationRouteRateLimitInput {
  applicationId: String!
  companyId: ID!
  endpoint: String!
  isActive: Boolean!
  rateLimit: Long!
}

union UpdateCompanyApplicationRouteRateLimitPayload = DefaultErrorFailure | UpdateCompanyApplicationRouteRateLimitSuccess

# Generated from com/attentivemobile/contracts/gateway/service/RateLimiting.proto
type UpdateCompanyApplicationRouteRateLimitSuccess {
  applicationId: String!
  company: Company
  endpoint: String!
  id: Long!
  isActive: Boolean!
  rateLimit: Long!
}

input UpdateCompanyBrandVoiceInput {
  brandVoice: CompanyBrandVoiceInput!
  companyId: ID!
}

# Generated from com/attentivemobile/contracts/concierge/service/ConversationManager.proto
type UpdateCompanyBrandVoicePayload {
  company: Company
}

input UpdateCompanyEmailIntegrationInput {
  companyId: ID!
  integrationLevel: EmailIntegrationLevel!
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type UpdateCompanyEmailIntegrationPayload {
  emailAccount: EmailAccount!
}

input UpdateCompanyGlobalRateLimitInput {
  companyId: ID!
  isActive: Boolean!
  rateLimit: Long!
}

union UpdateCompanyGlobalRateLimitPayload = DefaultErrorFailure | UpdateCompanyGlobalRateLimitSuccess

# Generated from com/attentivemobile/contracts/gateway/service/RateLimiting.proto
type UpdateCompanyGlobalRateLimitSuccess {
  company: Company
  id: Long!
  isActive: Boolean!
  rateLimit: Long!
}

input UpdateCompanyLinkParametersInput {
  companyId: ID!
  companyLinkParameterInput: [CompanyLinkParameterInput!]
}

union UpdateCompanyLinkParametersPayload = DefaultErrorFailure | UpdateCompanyLinkParametersSuccess

# Generated from com/attentivemobile/contracts/composition/Contract.proto
type UpdateCompanyLinkParametersSuccess {
  company: Company
  companyLinkParameter: [CompanyLinkParameter!]!
}

input UpdateCompanyRegionLocalizedSettingsInput {
  localizedSettings: [LocalizedSettingsInput!]
  regionId: ID!
}

# Generated from com/attentivemobile/contracts/company-service/region/Region.proto
type UpdateCompanyRegionLocalizedSettingsPayload {
  region: Region!
}

input UpdateCompanySettingsInput {
  companyId: ID!
  companySettings: CompanySettingsInput!
}

# Generated from com/attentivemobile/contracts/company-service/companysetting/CompanySetting.proto
type UpdateCompanySettingsPayload {
  company: Company
}

input UpdateCompanyV2Input {
  # the address of the company
  address: AddressInput

  # the company's unique attentive domain e.g. siren.attentive.com
  attentiveDomain: String

  # the automated response to subscriber messages without keywords
  autoResponse: String

  # whether to send automatic auto responses for this company
  autoResponseActive: Boolean

  # a url pointing to the shopping cart on the company's website
  cartLink: String

  # the click through attribution window in days for this company
  clickThroughAttributionWindow: Long
  companyId: ID!

  # the type of the company
  companyType: CompanyType

  # the message subscribers will get to confirm opt in
  confirmOptIn: String

  # the main country of traffic (as opposed to the address country)
  country: String

  # the creative holdout rate of the company
  creativeHoldoutRate: Float

  # the desktop URL of the company
  desktopUrl: String

  # the display name of the company
  displayName: String

  # the company's domain
  domain: String

  # the contact email for this company
  email: String

  # the external id of the company
  externalId: Long

  # the response subscribers will get if they text HELP
  helpResponse: String

  # the message holdout rate of the company
  messageHoldoutRate: Float

  # the SID of the messaging service of this company
  messagingServiceSid: String

  # is MFA enabled for this company
  mfaEnabled: Boolean

  # the name of the company
  name: String

  # whether non-Attentive links are allowed in messages
  nonAttentiveMessageLinksAllowed: Boolean

  # the contact phone of the company
  phone: String

  # a url pointing to the company's privacy policy
  privacyLink: String

  # the end time of quiet hours for this company
  quietHoursEnd: String

  # the start time of quiet hours for this company
  quietHoursStart: String

  # whether we reply to messages if the subscriber is already opted out
  replyWhenOptedOut: Boolean

  # the id of the corresponding Salesforce account
  salesforceAccountId: String

  # the id of the corresponding Salesforce opportunity
  salesforceOpportunityId: String

  # the service begin message for this company
  serviceBeginMessage: String

  # the service end message for this company
  serviceEndMessage: String

  # the service verification keyword for this company
  serviceVerificationKeyword: String

  # the settings url of the company
  settingsUrl: String

  # the response subscribers will get if they text START
  startResponse: String

  # the status of the company
  status: String

  # the response subscribers will get if they text STOP
  stopResponse: String

  # a phrase such as 'stop 2 stop' to append at the end of messages for compliance
  stopToStopPhrase: String

  # the message subscribers will get when they first subscribe
  subscribedResponse: String

  # a url pointing to the company's terms and conditions
  termsLink: String

  # the timezone of the company
  timezone: String

  # whether TTJ double opt in is on for this company
  ttjDoubleOptIn: Boolean

  # the view through attribution window in days for this company
  viewThroughAttributionWindow: Long
}

union UpdateCompanyV2Payload = DefaultErrorFailure | UpdateCompanyV2Success

# Generated from com/attentivemobile/contracts/company-service/Contract.proto
type UpdateCompanyV2Success {
  company: Company
}

input UpdateCompanyVendorIntegrationStatusInput {
  companyId: ID!
  status: CompanyVendorIntegrationStatus!
  vendor: Vendor!
}

union UpdateCompanyVendorIntegrationStatusPayload = DefaultErrorFailure | UpdateCompanyVendorIntegrationStatusSuccess

# Generated from com/attentivemobile/contracts/integrations/v2/Contract.proto
type UpdateCompanyVendorIntegrationStatusSuccess {
  companyVendorIntegration: CompanyVendorIntegration!
}

input UpdateComposeCampaignInput {
  campaignId: ID!
  campaignMessageGroupIdOrder: [ID!]
  campaignStatus: ComposeCampaignStatus
  companyId: ID!
  name: String
  tags: CampaignTagsInput
  type: ComposeCampaignType
  userId: ID!
}

input UpdateCompositionMessageInput {
  attachments: [UpdateAttachmentRequest!]
  companyId: ID!
  emailProperties: EmailPropertiesRequest
  ignoreFatigue: Boolean
  mediaUrls: [UrlInput!]
  messageId: ID!
  messageTags: [ComposeMessageTagInput!]
  name: String!
  pushProperties: PushPropertiesInput
  rbmProperties: RbmPropertiesInput @deprecated(reason: "deprecated")
  rcsProperties: RcsPropertiesInput
  status: ComposeMessageStatus!
  textMessageProperties: TextMessagePropertiesRequest
  updatedUtmParameters: UtmParametersInput
  validationRulesToSkip: [ValidationRuleToSkipInput!]
}

# Generated from com/attentivemobile/contracts/composition/Contract.proto
type UpdateCompositionMessagePayload {
  updateMessageResult: UpdateMessageResult!
}

input UpdateConciergeCompanyNotesInput {
  companyId: ID!
  notes: String!
}

# Generated from com/attentivemobile/contracts/concierge/service/Notes.proto
type UpdateConciergeCompanyNotesPayload {
  company: Company
}

input UpdateConciergeCompanyPromotionInput {
  end: DateTime
  id: ID!
  limitedTime: Boolean
  note: String!
  start: DateTime
  startPromoting: DateTime
  title: String!
  type: ConciergeCompanyPromotionType!
}

# Generated from com/attentivemobile/contracts/concierge/service/CompanyPromotions.proto
type UpdateConciergeCompanyPromotionPayload {
  company: Company
}

input UpdateConciergeJourneyControlsInput {
  companyId: ID!
  conciergeIncentiveConfigurationInput: ConciergeIncentiveConfigurationInput!
  isConciergeEnabled: Boolean!
  journeyId: ID!
}

# Generated from com/attentivemobile/contracts/concierge/service/ConversationManager.proto
type UpdateConciergeJourneyControlsPayload {
  journey: Journey
}

input UpdateConciergeSelfServeSettingsAgentPreferencesInput {
  agentName: String!
  companyId: ID!
  couponSetId: Long
  customUrlParametersInput: [CustomUrlParametersInputEntry!]
  isUtmTrackingEnabled: Boolean!
  messageTone: MessageTone!
  utmCampaign: String
}

input UpdateConciergeSelfServeSettingsCustomerSupportInput {
  additionalUrls: [String!]
  companyId: ID!
  customerServiceEmail: String!
  escalationEmails: [String!]
  faqUrl: String
  returnPolicyUrl: String!
}

input UpdateConciergeSelfServeSettingsJourneysInput {
  companyId: ID!
  siteVisitorJourneyId: Long!
}

# Generated from com/attentivemobile/contracts/concierge/service/SelfServe.proto
type UpdateConciergeSelfServeSettingsJourneysPayload {
  company: Company
}

# Generated from com/attentivemobile/contracts/concierge/service/SelfServe.proto
type UpdateConciergeSelfServeSettingsPayload {
  company: Company
}

input UpdateConciergeSelfServeSettingsShippingInput {
  companyId: ID!
  domesticShippingTimeframe: String!
  freeShippingPricePoint: String
  internationalShippingTimeframe: String
  processingTimeBeforeShipping: String!
  shippingProviderOther: String
  shippingProviders: [ShippingProvider!]
}

input UpdateConciergeSelfServeSettingsSignUpInput {
  companyId: ID!
  conciergeRegistrationName: String!
}

input UpdateConciergeSelfServeSettingsSubmissionInput {
  companyId: ID!
}

input UpdateConciergeSettingsInput {
  companyId: ID!
  isConciergeEnabled: Boolean!
  isRouteAllInboundsEnabled: Boolean
}

# Generated from com/attentivemobile/contracts/concierge/service/ConversationManager.proto
type UpdateConciergeSettingsPayload {
  company: Company
}

input UpdateConversationsSettingsInput {
  companyId: ID!
  escalationEmails: [String!]
  incentiveConfiguration: ConciergeIncentiveConfigurationInput!
  isConciergeForCampaignsEnabled: Boolean!
  isRouteAllInboundsEnabled: Boolean!
  utmConfiguration: UtmConfigurationInput!
}

# Generated from com/attentivemobile/contracts/concierge/service/ConversationManager.proto
type UpdateConversationsSettingsPayload {
  company: Company
}

input UpdateCouponSetInput {
  autoRefresh: Boolean
  codeTemplate: String
  couponUrlTemplate: String
  couponValueInput: CouponValueInput
  description: String
  distributionTtlInSeconds: Long
  distributionTtlUnit: DistributionUnit
  externalId: Long
  id: ID!
  name: String
  staticCode: String
  status: CouponSetStatus
}

# Generated from com/attentivemobile/contracts/coupon/CouponContract.proto
type UpdateCouponSetPayload {
  updated: CouponSet!
}

input UpdateCouponUploadInput {
  couponUploadId: ID!
  distributionStart: DateTime
  distributionStop: DateTime
}

# Generated from com/attentivemobile/contracts/coupon/Contract.proto
type UpdateCouponUploadPayload {
  couponUpload: CouponUpload
}

input UpdateCreateSupportTicketStepInput {
  customerServiceGroupId: String!
  vendorId: String!
  vendorName: String!
}

input UpdateCreativeContentInput {
  companyId: ID!
  content: JsonStringInput!
  creativeContentId: ID!
}

union UpdateCreativeContentPayload = DefaultErrorFailure | UpdateCreativeContentSuccess

# Generated from com/attentivemobile/contracts/creative-service/creative/CreativeContentService.proto
type UpdateCreativeContentSuccess {
  creativeContent: CreativeContent!
}

input UpdateCreativeExperimentGroupTrafficSplitRequest {
  experimentGroupId: String!
  percent: String!
}

input UpdateCreativeExperimentTrafficSplitInput {
  companyId: ID!
  experimentGroups: [UpdateCreativeExperimentGroupTrafficSplitRequest!]
  experimentId: String!
}

union UpdateCreativeExperimentTrafficSplitPayload = DefaultErrorFailure | UpdateCreativeExperimentTrafficSplitSuccess

# Generated from com/attentivemobile/contracts/creative-service/experiment/CreativeExperimentService.proto
type UpdateCreativeExperimentTrafficSplitSuccess {
  experiment: CreativeExperiment!
}

input UpdateCreativeInput {
  companyId: ID!
  creativeId: ID!
  delete: DeleteCreativePropertiesInput
  update: UpdateCreativePropertiesInput
}

# Generated from com/attentivemobile/contracts/creative-service/creative/CreativeGraphService.proto
type UpdateCreativePayload {
  creativeOrError: UpdateCreativePayloadCreativeOrError
}

union UpdateCreativePayloadCreativeOrError = Creative | CreativeMutationError

# Optionally set certain values on a Creative
input UpdateCreativePropertiesInput {
  alreadySubscribedMessageId: ID
  config: JsonStringInput
  couponSetId: ID
  customProperties: [CreativeCustomPropertyInput!]
  externalName: String
  language: String
  name: String
  rules: JsonStringInput
  smsBody: String
  src: String
  status: CreativeStatus
}

input UpdateCreativeRuleInput {
  companyId: ID!
  definitionSource: CreativeRuleDefinitionSource!
  description: String!
  name: String!
  rule: JSON!
  ruleId: ID!
}

union UpdateCreativeRulePayload = DefaultErrorFailure | UpdateCreativeRuleSuccess

# Generated from com/attentivemobile/contracts/creative-service/rules/CreativeRuleService.proto
type UpdateCreativeRuleSuccess {
  rule: CreativeRule!
}

input UpdateCreativeScheduleAndMembersInput {
  members: [CreateOrUpdateCreativeScheduleMemberInput!]
  schedule: UpdateCreativeScheduleInput!
  scheduleId: ID!
}

input UpdateCreativeScheduleInput {
  companyId: ID!
  completed: DateTime
  description: String
  endTime: DateTime
  name: String
  scheduledEvent: Boolean!
  startTime: DateTime
  timezone: String
  userId: String
}

input UpdateCreativeScheduleWorkflowInput {
  applyStatusPermanently: Boolean!
  companyId: ID!
  endTime: DateTime
  name: String!
  scheduleId: ID @deprecated(reason: "deprecated")
  scheduleMembers: [CreativeScheduleMemberDetails!]
  startTime: DateTime!
  timezone: String
  workflowId: ID
}

union UpdateCreativeScheduleWorkflowPayload = DefaultErrorFailure | UpdateCreativeScheduleWorkflowSuccess

# Generated from com/attentivemobile/contracts/creative/workflow/service/workflows/CreativeSchedulesWorkflowService.proto
type UpdateCreativeScheduleWorkflowSuccess {
  schedule: CreativeWorkflowSchedule!
}

input UpdateCustomCompanyKeywordInput {
  attributeName: String
  companyId: ID!
  keyword: String
  keywordId: ID!
  keywordOrdinal: Int
  mediaUrl: String
  messageText: String
  shouldForceSend: Boolean
  shouldShortenLink: Boolean
  subscriptionType: String
}

union UpdateCustomCompanyKeywordPayload = DefaultErrorFailure | UpdateCustomCompanyKeywordSuccess

# Generated from com/attentivemobile/contracts/keywords/KeywordService.proto
type UpdateCustomCompanyKeywordSuccess {
  keyword: CustomCompanyKeyword
}

input UpdateCustomEventKeyInput {
  companyId: ID!
  customEventKeyId: ID!
  updatedFields: PostCustomEventKeyInput!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type UpdateCustomEventKeyPayload {
  customEventKey: CustomEventKey!
}

input UpdateCustomKeywordInput {
  companyId: ID!
  isActive: Boolean
  isTransactional: Boolean
  keyword: String!
  keywordId: ID!
  response: String!
  subscriberTag: String!
}

# Generated from com/attentivemobile/contracts/tactical/companycustomkeywords/CompanyCustomKeywordService.proto
type UpdateCustomKeywordPayload {
  customKeyword: CustomKeyword!
}

input UpdateCustomPropertyDefinitionInput {
  companyId: ID!
  customPropertyDefinitionId: ID!
  updatedFields: PostCustomPropertyDefinitionInput!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type UpdateCustomPropertyDefinitionPayload {
  customPropertyDefinition: CustomPropertyDefinition!
}

input UpdateCustomReportInput {
  companyId: ID!
  deliveryFrequencies: ReportDeliveryFrequenciesUpdateInput
  filters: ReportFiltersUpdateInput
  groupings: ReportGroupingsUpdateInput
  metrics: ReportMetricsUpdateInput
  name: String
  recipients: [ID!]
  reportId: String!
  selectedChart: ReportChart
  selectedMetricId: String
  sftpEnabled: Boolean
  summaryMetricIds: [String!] @deprecated(reason: "deprecated")
  summaryMetrics: SummaryMetricsInput
  tableSortOrders: [ReportMetricsOrderingInput!]
}

# Generated from com/attentivemobile/contracts/reports/api/Contract.proto
type UpdateCustomReportPayload {
  report: Report!
}

input UpdateDatastreamParallelismInput {
  datastreamName: String!
  filterParallelism: Int!
  kinesisSinkMaxBufferTimeMillis: Int!
  kinesisSinkParallelism: Int!
  kinesisSinkRateLimitPercentage: Int!
  kinesisSourceParallelism: Int!
  pulsarSinkParallelism: Int!
  pulsarSourceParallelism: Int!
  s3SinkParallelism: Int!
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Contract.proto
type UpdateDatastreamParallelismPayload {
  datastreamName: String!
  result: OperationResult!
}

input UpdateDatastreamsParallelismInput {
  datastreamName: [String!]
  filterParallelism: Int!
  kinesisSinkMaxBufferTimeMillis: Int!
  kinesisSinkParallelism: Int!
  kinesisSinkRateLimitPercentage: Int!
  kinesisSourceParallelism: Int!
  pulsarSinkParallelism: Int!
  pulsarSourceParallelism: Int!
  s3SinkParallelism: Int!
}

union UpdateDatastreamsParallelismPayload = DefaultErrorFailure | UpdateDatastreamsParallelismSuccess

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Contract.proto
type UpdateDatastreamsParallelismSuccess {
  result: String!
}

input UpdateDelayStepInput {
  durationSeconds: Long!
  durationUnit: JourneyDurationUnit!
}

input UpdateDismissibleModalInput {
  description: String
  endTime: DateTime!
  featureFlags: [String!]
  id: ID!
  imageUrl: String
  modalId: String!
  primaryButtonAction: ButtonAction!
  primaryButtonText: String!
  primaryButtonUrl: String
  secondaryButtonAction: ButtonAction
  secondaryButtonText: String
  secondaryButtonUrl: String
  startTime: DateTime!
  title: String
  url: String!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type UpdateDismissibleModalPayload {
  modal: DismissibleModal!
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type UpdateEmailAccountPayload {
  emailAccount: EmailAccount!
}

input UpdateEmailAccountPoolsInput {
  campaignsPoolId: ID
  id: ID!
  transactionalPoolId: ID
  triggeredPoolId: ID
}

input UpdateEmailIPAddressInput {
  domain: String
  ipAddress: String!
  ipPoolIds: [Long!]
}

union UpdateEmailIPAddressPayload = DefaultErrorFailure | UpdateEmailIPAddressSuccess

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type UpdateEmailIPAddressSuccess {
  ipAddress: EmailIPAddress!
}

input UpdateEmailIPPoolInput {
  assignedCompanies: EmailPoolAssignedCompanies
  assignedIpAddresses: EmailPoolAssignedIPAddresses
  companyId: Long
  id: ID!
  name: String
  shared: Boolean
  type: IPPoolType
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type UpdateEmailIPPoolPayload {
  ipPool: EmailIPPool!
}

input UpdateEmailNotificationSettingsInput {
  updateEmailRecipientRequests: [UpdateEmailRecipientInput!]
}

input UpdateEmailRecipientInput {
  email: String!
}

input UpdateEmailSettingsInput {
  bccEmails: [String!]
  ccEmails: [String!]
  companyId: ID!
  enableCopyRecipientsForCampaigns: Boolean
  enableCopyRecipientsForJourneys: Boolean
  linkSubdomain: String!
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type UpdateEmailSettingsPayload {
  company: Company
  emailSettings: EmailSettings!
}

input UpdateEncryptionInput {
  etlConfigId: Long!
  isEncrypted: Boolean
  pgpKeyString: String
}

# Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto
type UpdateEncryptionPayload {
  message: String!
}

input UpdateEscalationEmailsInput {
  companyId: ID!
  emails: [String!]
}

# Generated from com/attentivemobile/contracts/concierge/service/Escalation.proto
type UpdateEscalationEmailsPayload {
  company: Company
}

input UpdateEtlConfigCompaniesData {
  companyId: ID!
  isEnabled: Boolean!
}

input UpdateEtlConfigCompaniesInput {
  etlConfigId: Long!
  updateEtlConfigCompaniesData: [UpdateEtlConfigCompaniesData!]
}

union UpdateEtlConfigCompaniesPayload = DefaultErrorFailure | UpdateEtlConfigCompaniesSuccess

# Generated from com/attentivemobile/contracts/solutions/sftp/EtlConfig.proto
type UpdateEtlConfigCompaniesSuccess {
  message: String!
}

input UpdateExperimentStepInput {
  childrenSteps: [UpdateVariantStepInput!]
  trafficSplitType: TrafficSplitType!
}

input UpdateFilterInput {
  filter: NotificationFilterInput!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type UpdateFilterPayload {
  filter: NotificationFilter!
}

input UpdateImpactMetadataInput {
  datastreamName: String!
  impactMetadata: DatastreamImpactMetadataInput!
}

# Generated from com/attentivemobile/contracts/event/platform/registration/service/Contract.proto
type UpdateImpactMetadataPayload {
  datastreamName: String!
}

input UpdateIntegrationApplicationInput {
  category: String!
  displayName: String!
  id: ID!
  name: String!
}

union UpdateIntegrationApplicationPayload = DefaultErrorFailure | UpdateIntegrationApplicationSuccess

# Generated from com/attentivemobile/contracts/integrations/applications/Applications.proto
type UpdateIntegrationApplicationSuccess {
  application: IntegrationApplication!
}

input UpdateIntegrationVendorSettingsInput {
  companyVendorIntegrationId: ID!
  features: FeatureMapInput!
  fields: [FieldsEntry!]
}

input UpdateJourneyInput {
  companyId: ID!
  description: String
  journeyId: ID!
  name: String
}

# Generated from com/attentivemobile/contracts/journeys/api/Contract.proto
type UpdateJourneyPayload {
  journey: Journey!
}

input UpdateJourneyRevisionApprovalStatusInput {
  approvalStatus: ApprovalStatus!
  companyId: ID!
  journeyId: ID!
  revisionApprovalHash: String!
}

# Generated from com/attentivemobile/contracts/journeys/api/Contract.proto
type UpdateJourneyRevisionApprovalStatusPayload {
  journey: Journey!
}

input UpdateJourneyRevisionInput {
  companyId: ID!
  eligibilityDurationSeconds: Long!
  eligibilityDurationUnit: JourneyDurationUnit!
  revisionId: ID!
}

# Generated from com/attentivemobile/contracts/journeys/api/JourneyRevision.proto
type UpdateJourneyRevisionPayload {
  journeyRevision: JourneyRevision!
}

input UpdateJourneyScheduleInput {
  companyId: ID!
  journeyScheduleId: ID!
  name: String
  startTime: DateTime
}

# Generated from com/attentivemobile/contracts/journeys/api/JourneySchedule.proto
type UpdateJourneySchedulePayload {
  updatedJourneySchedule: JourneySchedule!
}

input UpdateJourneyStatusInput {
  companyId: ID!
  doEvictSubscribers: Boolean!
  isActive: Boolean!
  journeyId: ID!
}

# Generated from com/attentivemobile/contracts/journeys/api/Contract.proto
type UpdateJourneyStatusPayload {
  journey: Journey!
}

input UpdateJourneyTriggerInput {
  description: String!
  journeyTriggerId: ID!
  maxJourneyLimit: Long
  name: String!
}

# Generated from com/attentivemobile/contracts/journeys/api/JourneyTrigger.proto
type UpdateJourneyTriggerPayload {
  journeyTrigger: JourneyTrigger!
}

input UpdateJourneyTriggerStatusInput {
  companyId: ID!
  enabled: Boolean!
  journeyTriggerId: ID!
}

# Generated from com/attentivemobile/contracts/journeys/api/JourneyTrigger.proto
type UpdateJourneyTriggerStatusPayload {
  company: Company
}

input UpdateLegacyScheduledReportRecipientInput {
  companyId: ID!
  newUserId: String!
  scheduledReportId: String!
}

# Generated from com/attentivemobile/contracts/reports/api/Contract.proto
type UpdateLegacyScheduledReportRecipientPayload {
  message: String!
}

input UpdateLegacyTagConfigInput {
  companyId: ID!
  newConfig: [LegacyTagConfigUpdateInput!]
  updateTagVersion: Boolean
}

# Generated from com/attentivemobile/contracts/tagbuildservice/Contract.proto
type UpdateLegacyTagConfigPayload {
  rowsUpdated: Long!
  updatedConfig: [LegacyTagConfig!]!
}

# Generated from com/attentivemobile/contracts/composition/Contract.proto
type UpdateMessageFailure {
  errors: [UpdateMessageValidationError!]!
}

input UpdateMessageInput {
  attachments: [UpdateAttachmentRequest!]
  companyId: ID!
  emailProperties: EmailPropertiesRequest
  ignoreFatigue: Boolean
  mediaUrls: [UrlInput!]
  messageId: ID!
  messageTags: [ComposeMessageTagInput!]
  name: String!
  pushProperties: PushPropertiesInput
  rbmProperties: RbmPropertiesInput @deprecated(reason: "deprecated")
  rcsProperties: RcsPropertiesInput
  status: ComposeMessageStatus!
  textMessageProperties: TextMessagePropertiesRequest
  updatedUtmParameters: UtmParametersInput
  validationRulesToSkip: [ValidationRuleToSkipInput!]
}

union UpdateMessageResult = UpdateMessageFailure | UpdateMessageSuccess

# Generated from com/attentivemobile/contracts/composition/Contract.proto
type UpdateMessageSuccess {
  composeMessage: ComposeMessage!
}

input UpdateMessageTemplateInput {
  channel: Channel!
  companyId: Long!
  id: ID!
  templateMetadata: ComposeMessageTemplateMetaDataInput!
  templateName: String!
  templateType: MessagingTemplateType!
}

# Generated from com/attentivemobile/contracts/compose/api/Contract.proto
type UpdateMessageTemplatePayload {
  messageTemplate: ComposeMessageTemplate!
}

union UpdateMessageValidationError = ConflictingAttachments | InvalidCouponCodeUsage | InvalidLiquidSyntax | InvalidMacroUsages | UndefinedMacro

input UpdateMetadataEntry {
  key: String!
  value: String!
}

input UpdateMobileWalletCardInput {
  companyId: ID!
  updateMobileWalletCardItems: [UpdateMobileWalletCardItemInput!]
}

input UpdateMobileWalletCardItemInput {
  cardText: String!
  couponId: String!
  expirationDate: DateTime
  externalCampaignId: String!
  externalOfferId: String!
  mediaUrl: String
  messageText: String
  phone: String
  updateMetadata: [UpdateMetadataEntry!]
  uuid: String
}

input UpdateNBAConfigInput {
  nextBestActionConfiguration: NextBestActionConfigurationInput!
  nextBestActionConfigurationId: ID!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type UpdateNBAConfigPayload {
  nextBestActionConfiguration: NextBestActionConfiguration!
}

input UpdateNotificationBannerInput {
  callToAction: String!
  description: String!
  eventDate: String!
  id: ID!
  startTime: DateTime!
  stopTime: DateTime!
  title: String!
  url: String!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type UpdateNotificationBannerPayload {
  banner: NotificationBanner!
}

input UpdateNotificationEmailTemplateInput {
  emailTemplate: NotificationEmailTemplateInput!
  notificationEmailTemplateId: ID!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type UpdateNotificationEmailTemplatePayload {
  emailTemplate: NotificationEmailTemplate!
}

input UpdatePollStatusInput {
  pollId: ID!
  status: PollStatus!
}

# Generated from com/attentivemobile/contracts/survey/poll/Contract.proto
type UpdatePollStatusPayload {
  error: String
  poll: Poll
  status: PollStatus!
}

input UpdatePollV2Input {
  allowAltSignupUnit: Boolean
  allowBreakTies: Boolean
  allowMarketingSubmitMessage: Boolean
  allowMultiVote: Boolean
  altSignupUnitId: Int
  applicationId: String
  closedMessage: String
  companyId: ID!
  decimalPrecision: Int
  description: String
  duplicateVoteMessage: String
  enableParticipantAttributeName: Boolean
  marketingSubmitMessage: String
  name: String
  onClosedMessage: String
  participantAttributeName: String
  pollId: ID!
  state: PollState
  submitMessage: String
  subscribedEventType: String
}

# Generated from com/attentivemobile/contracts/survey/poll/Contract.proto
type UpdatePollV2Payload {
  error: String
  poll: Poll
}

input UpdatePrebuiltContentInput {
  id: ID!
  name: String
  orchestrationContent: [OrchestrationContentInput!]
  orchestrationType: OrchestrationType
}

# Generated from com/attentivemobile/contracts/messaging/automation/prebuilt/Contract.proto
type UpdatePrebuiltContentPayload {
  prebuiltContent: PrebuiltContent!
}

input UpdatePrebuiltProgramInput {
  description: String
  id: ID!
  programStatus: ProgramStatus
  scheduleType: ScheduleType
  thumbnailUrl: String
  title: String
}

# Generated from com/attentivemobile/contracts/messaging/automation/prebuilt/Contract.proto
type UpdatePrebuiltProgramPayload {
  prebuiltProgram: PrebuiltProgram!
}

input UpdateProductSegmentInput {
  companyId: ID!
  filters: [ProductSegmentFilterInput!]
  id: ID!
  name: String!
}

# Generated from com/attentivemobile/contracts/product/attribute/service/Contract.proto
type UpdateProductSegmentPayload {
  productSegment: ProductSegment
}

input UpdateProgramCadenceInput {
  programCadenceType: ProgramCadenceType
  updateProgramFrequencyRequest: UpdateProgramFrequencyInput
}

input UpdateProgramFrequencyInput {
  period: Int
  periodUnit: ProgramCalendarPeriod
}

input UpdateProviderMigrationInput {
  carrier: Carrier
  endDate: DateTime
  newProvider: Provider
  oldProvider: Provider
  phoneNumber: String!
  startDate: DateTime
  status: ProviderMigrationStatus
}

# Generated from com/attentivemobile/contracts/providermigration/Contract.proto
type UpdateProviderMigrationPayload {
  migrations: [ProviderMigration!]!
}

union UpdateProviderMigrationsPayload = DefaultErrorFailure | UpdateProviderMigrationsSuccess

# Generated from com/attentivemobile/contracts/providermigration/Contract.proto
type UpdateProviderMigrationsSuccess {
  migrations: [ProviderMigration!]!
}

input UpdateRateLimitInput {
  aggregator: Aggregator
  country: RateLimitCountry
  phoneNumberType: PhoneNumberType
  provider: Provider!
  rateLimit: Long!
  textChannel: TextChannel!
}

# Generated from com/attentivemobile/contracts/ratelimiting/Contract.proto
type UpdateRateLimitPayload {
  rateLimit: RateLimit!
}

input UpdateRegionInput {
  region: RegionInput!
}

# Generated from com/attentivemobile/contracts/company-service/region/Region.proto
type UpdateRegionPayload {
  region: Region!
}

input UpdateRequest {
  id: String!
  isVisible: Boolean!
  shortcut: String
  text: String!
  title: String!
}

input UpdateRevisionStepInput {
  companyId: ID!
  revisionId: String!
  revisionStepId: String!
  updateCreateSupportTicketStepRequest: UpdateCreateSupportTicketStepInput
  updateDelayStepRequest: UpdateDelayStepInput
  updateExperimentStepRequest: UpdateExperimentStepInput
  updateVariantStepRequest: UpdateVariantStepInput
}

# Generated from com/attentivemobile/contracts/journeys/api/RevisionStep.proto
type UpdateRevisionStepPayload {
  journeyRevision: JourneyRevision!
}

input UpdateRouteRateLimitInput {
  endpoint: String!
  isActive: Boolean!
  rateLimit: Long!
}

union UpdateRouteRateLimitPayload = DefaultErrorFailure | UpdateRouteRateLimitSuccess

# Generated from com/attentivemobile/contracts/gateway/service/RateLimiting.proto
type UpdateRouteRateLimitSuccess {
  endpoint: String!
  id: Long!
  isActive: Boolean!
  rateLimit: Long!
}

input UpdateSegmentFromSearchDefinitionInput {
  companyId: ID!
  description: String
  metadata: [CreateSegmentMetadataInput!]
  name: String!
  searchDefinition: AudienceSearchDefinitionInput!
  segmentId: SegmentId!
}

union UpdateSegmentFromSearchDefinitionPayload = DefaultErrorFailure | UpdateSegmentFromSearchDefinitionSuccess

# Generated from com/attentivemobile/contracts/segmentation/service/segment/contract.proto
type UpdateSegmentFromSearchDefinitionSuccess {
  legacy: Segment
  segment: SegmentV2
}

input UpdateSendScheduledReportOverSftpInput {
  active: Boolean
  companyId: ID!
  reportSystem: String
  scheduledReportIds: [Long!]
}

union UpdateSendScheduledReportOverSftpPayload = DefaultErrorFailure | UpdateSendScheduledReportOverSftpSuccess

# Generated from com/attentivemobile/contracts/solutions/sftp/Sftp.proto
type UpdateSendScheduledReportOverSftpSuccess {
  unscheduledReportIds: [Long!]!
}

input UpdateSftpUserEmailInput {
  companyId: ID!
  email: String!
}

# Generated from com/attentivemobile/contracts/solutions/sftp/SftpUser.proto
type UpdateSftpUserEmailPayload {
  email: String!
}

input UpdateShopifyAutoApplyFormatsInput {
  companyId: ID!
  discountLinkFormat: DiscountLinkFormat!
}

union UpdateShopifyAutoApplyFormatsPayload = DefaultErrorFailure | UpdateShopifyAutoApplyFormatsSuccess

# Generated from com/attentivemobile/contracts/personalization/service/aijourneys/onboarding/autoapply/ShopifyAutoApplyFormatsContract.proto
type UpdateShopifyAutoApplyFormatsSuccess {
  discountLinkFormat: DiscountLinkFormat!
  success: Boolean!
}

input UpdateSyncInput {
  connectionSettings: UpdateSyncInputConnectionSettings
  description: String
  fetchOptions: FetchOptionsInput!
  fileSettings: FileSettingsInput!
  fileType: EdsJobIngestionFormat!
  name: String
  schedule: SyncScheduleInput
  syncId: String!
  syncMethod: EdsJobSyncMethod
  targetConfig: JSON!
}

input UpdateSyncInputConnectionSettings {
  attentiveS3Settings: AttentiveS3SettingsInput
  sftpSettings: SftpSettingsInput
  snowflakeSecureDataShareSettings: SnowflakeSecureDataSharingInput
}

union UpdateSyncPayload = DefaultErrorFailure | UpdateSyncSuccess

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type UpdateSyncSuccess {
  error: SyncError
  success: Boolean
  sync: Sync!
}

input UpdateTagInput {
  companyId: ID!
  name: String!
  tagId: ID!
}

input UpdateVariantStepInput {
  id: String
  name: String!
  percentage: String!
  sortOrder: String!
}

input UpdateWebTemplateInput {
  webTemplate: WebTemplateInput!
  webTemplateId: ID!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type UpdateWebTemplatePayload {
  webTemplate: WebTemplate!
}

input UpdateWebhookInput {
  companyId: String

  # (optional) Disable the webhook endpoint if set to true.
  disabled: Boolean!

  # The list of events this webhook is subscribed to.
  events: [String!]

  # The id of the webhook to update.
  id: ID!

  # The destination url of the webhook.
  url: String!
}

# Generated from com/attentivemobile/contracts/externaltokens/Webhooks.proto
# Response for updateWebhook mutation.
type UpdateWebhookPayload {
  # Webhook details.
  webhook: Webhook!
}

input UpdateWorkloadConfigInput {
  newWorkloadConfig: WorkloadConfigInput!
}

union UpdateWorkloadConfigPayload = DefaultErrorFailure | UpdateWorkloadConfigSuccess

# Generated from com/attentivemobile/contracts/workload/management/service/configuration/Contract.proto
type UpdateWorkloadConfigSuccess {
  newWorkloadConfig: WorkloadConfig!
  oldWorkloadConfig: WorkloadConfig!
}

input UploadManualSegmentInput {
  companyId: ID!
  externalId: String!
  isMultiFileUpload: Boolean
  name: String
  operation: UploadManualSegmentOperation!
  s3FileOrDirectoryPath: String!
  source: ManualSegmentSource!
  thirdPartySegmentUploadMetadata: ThirdPartySegmentMetadata
}

enum UploadManualSegmentOperation {
  UPLOAD_MANUAL_SEGMENT_OPERATION_APPEND_TO_EXISTING
  UPLOAD_MANUAL_SEGMENT_OPERATION_CREATE
  UPLOAD_MANUAL_SEGMENT_OPERATION_CREATE_OR_REPLACE
  UPLOAD_MANUAL_SEGMENT_OPERATION_REPLACE
  UPLOAD_MANUAL_SEGMENT_OPERATION_UNKNOWN
}

union UploadManualSegmentPayload = DefaultErrorFailure | UploadManualSegmentSuccess

# Generated from com/attentivemobile/contracts/segmentation/service/ManualSegmentUpload.proto
type UploadManualSegmentSuccess {
  segment: Segment
}

input UpsertAIJourneyDefaultMessageInput {
  companyId: ID!
  messageContent: String!
  messageType: MessageType!
}

union UpsertAIJourneyDefaultMessagePayload = DefaultErrorFailure | UpsertAIJourneyDefaultMessageSuccess

# Generated from com/attentivemobile/contracts/personalization/service/aijourneys/defaultmessage/AIJourneyDefaultMessageServiceContract.proto
type UpsertAIJourneyDefaultMessageSuccess {
  defaultMessage: AIJourneyDefaultMessage!
}

input UpsertAccountInput {
  accountId: ID
  authRoles: [Role!]
  companyId: ID!
  email: String
  firstName: String
  lastName: String
  phone: String
}

# Generated from com/attentivemobile/contracts/account-service/Contract.proto
type UpsertAccountPayload {
  account: Account!
  created: Boolean!
}

input UpsertAccountPermissionsInput {
  companyId: ID!
  description: String!
  featurePermissions: [UpsertFeaturePermissionsInput!]
  id: ID
  name: String!
}

union UpsertAccountPermissionsPayload = DefaultErrorFailure | UpsertAccountPermissionsSuccess

# Generated from com/attentivemobile/contracts/account/permission/service/AccountPermissionsService.proto
type UpsertAccountPermissionsSuccess {
  accountPermissions: AccountPermissions!
}

input UpsertAffiliateUserInput {
  companyId: ID!
  email: String!
  password: String
}

# Generated from com/attentivemobile/contracts/externaltokens/AffiliateUsers.proto
type UpsertAffiliateUserPayload {
  affiliateUserId: String!
  token: String!
}

input UpsertAreaCodeRuleRequest {
  areaCodes: [String!]
}

input UpsertClientSegmentImportInput {
  clientRowInput: ClientSegmentImportsRowInput!
  companyId: ID!
}

# Generated from com/attentivemobile/contracts/solutions/clientsegmentimports/ClientSegmentImports.proto
type UpsertClientSegmentImportPayload {
  clientSegmentImportsRow: ClientSegmentImportsRow!
}

input UpsertCustomAttributeRuleRequest {
  expression: String!
}

input UpsertEdsTaskProcessingConfigInput {
  endpoint: String!
  id: String
  limitPerSec: Int!
  retryTimes: Int!
  taskId: String
}

union UpsertEdsTaskProcessingConfigPayload = DefaultErrorFailure | UpsertEdsTaskProcessingConfigSuccess

# Generated from com/attentivemobile/contracts/eventdestination/service/Contract.proto
type UpsertEdsTaskProcessingConfigSuccess {
  config: EdsTaskProcessingConfig!
}

input UpsertFeatureOperationsInput {
  create: Boolean!
  delete: Boolean!
  read: Boolean!
  update: Boolean!
}

input UpsertFeaturePermissionsInput {
  feature: UpsertFeaturePermissionsInputFeature
  featureOperations: UpsertFeatureOperationsInput!
  id: ID
}

input UpsertFeaturePermissionsInputFeature {
  domainFeatureId: ID
  featureName: AccountPermissionFeatureName
}

union UpsertKeywordRulePayload = DefaultErrorFailure | UpsertKeywordRuleSuccess

input UpsertKeywordRuleRequestInput {
  areaCodeRule: UpsertAreaCodeRuleRequest
  companyId: ID!
  customAttributeRule: UpsertCustomAttributeRuleRequest
  id: Int
  keywordId: ID!
  marketingSubscriberRule: Boolean
  ruleExpression: String
  timeWindowRule: UpsertTimeWindowRuleRequest
}

# Generated from com/attentivemobile/contracts/keywords/KeywordRuleService.proto
type UpsertKeywordRuleSuccess {
  keywordRule: KeywordRule!
}

input UpsertLocalTimeMessage {
  hour: Int!
  minute: Int!
  second: Int!
}

input UpsertPollKeywordInput {
  companyId: ID!
  pollId: ID!
  pollKeywords: [PollKeywordInput!]
}

# Generated from com/attentivemobile/contracts/survey/poll/PollKeywordService.proto
type UpsertPollKeywordPayload {
  pollKeywords: [PollKeyword!]!
  success: Boolean!
}

input UpsertTimeWindowRuleRequest {
  endTime: UpsertLocalTimeMessage!
  startTime: UpsertLocalTimeMessage!
  timeZoneId: String!
}

# Generated from com/attentivemobile/protobuf/common/Url.proto
type Url {
  value: String!
}

input UrlInput {
  value: String!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
# User details.
type User implements Node {
  activeDismissibleModal(companyId: ID, url: String!): DismissibleModal
  agentTemplates: ListAgentTemplatesResponse
  blocklistStatus: UserBlocklistStatus

  # City for this user.
  city: String!

  # The associated company for this user
  company: Company
  conciergeConversationDetails: UserConversationDetails
  contentSettings(after: String, before: String, companyId: ID, first: Int, last: Int): FindUserContentSettingsConnection
  conversationEscalation: UserConversationEscalation

  # Country for this user.
  country: String!
  coupons(after: String, first: Int): UserCouponConnection

  # Email for this user.
  email: String!
  events(after: String, first: Int, includeNonSubscriberData: Boolean, timeCondition: TimeCondition, typeFilter: [EventType!]): GetUserEventsResponse

  # First name for this user.
  firstName: String!
  hasConciergeConversion: Boolean

  # ID for this user.
  id: ID!

  # Last name for this user.
  lastName: String!
  latestConciergeSession: ConciergeSession
  messages(after: String, dateRange: DateRange, first: Int, onlyDelivered: Boolean, slidingWindowDays: Int): MessageConnection
  optInStatus: UserOptInStatus
  personalizedProductRecommendations: ProductRecommendationsRedactedResponse
  recommendedAgentTemplates: ListAgentTemplatesResponse

  # State for this user.
  state: String!

  # Subscribed phone number for this user.
  subscribedPhone: String!
  subscriberId: Long! @deprecated(reason: "deprecated")

  # SubscriptionConnections associated with this user.
  subscriptions: SubscriptionConnection

  # Timezone for this user.
  timezone: String!
  userConversationLock: UserConversationLock
  userConversationMacros: ListUserConversationMacrosResponse
  userProperties: ListUserPropertiesResponse
  userPropertiesV2(after: String, filter: UserPropertiesV2Filter, first: Int): UserPropertyConnection

  # Zip code for this user.
  zipcode: String!
}

enum UserAuthStatus {
  authenticated
  unauthenticated
  unknown
}

# Generated from com/attentivemobile/contracts/concierge/service/Contract.proto
type UserBlocklistStatus {
  isBlocklisted: Boolean!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type UserContentSetting implements Node {
  companyId: ID
  deleted: Boolean!
  id: ID!
  objectClass: ContentClass!
  objectId: String!
  preferenceType: PreferenceType!
  preferenceValue: String!
  userId: ID
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type UserContentSettingsEdge {
  cursor: String!
  node: UserContentSetting!
}

input UserConversationDeescalationInput {
  userId: ID!
}

# Generated from com/attentivemobile/contracts/concierge/service/Escalation.proto
type UserConversationDeescalationPayload {
  user: User
}

# Generated from com/attentivemobile/contracts/concierge/service/UserParameters.proto
type UserConversationDetails {
  couponPool: CouponSet
}

# Generated from com/attentivemobile/contracts/concierge/service/Escalation.proto
type UserConversationEscalation {
  escalatedBy: Account
  lastNudged: DateTime
  note: String!
  timestamp: DateTime!
  user: User
}

input UserConversationEscalationInput {
  note: String!
  userId: ID!
}

# Generated from com/attentivemobile/contracts/concierge/service/Escalation.proto
type UserConversationEscalationPayload {
  user: User
}

# Generated from com/attentivemobile/contracts/concierge/service/Lock.proto
type UserConversationLock {
  lockExpiration: DateTime!
  lockedBy: Account
}

input UserConversationLockInput {
  userId: ID!
}

# Generated from com/attentivemobile/contracts/concierge/service/Lock.proto
type UserConversationLockPayload {
  user: User
}

# Generated from com/attentivemobile/contracts/concierge/service/Macro.proto
type UserConversationMacro {
  category: MacroCategory!
  token: String!
  type: MacroType!
}

input UserConversationNudgeInput {
  userId: ID!
}

# Generated from com/attentivemobile/contracts/concierge/service/Escalation.proto
type UserConversationNudgePayload {
  user: User
}

input UserConversationUnlockInput {
  userId: ID!
}

# Generated from com/attentivemobile/contracts/concierge/service/Lock.proto
type UserConversationUnlockPayload {
  user: User
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type UserCoupon {
  couponCode: String!
  couponSet: CouponSet
  couponUrl: String!
  created: DateTime!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type UserCouponConnection {
  edges: [UserCouponEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type UserCouponEdge {
  cursor: String!
  node: UserCoupon!
}

enum UserDownloadField {
  USER_DOWNLOAD_FIELD_EMAIL
  USER_DOWNLOAD_FIELD_UNKNOWN
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type UserEdge {
  cursor: String!
  node: User!
  user: User! @deprecated(reason: "deprecated")
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type UserEvent {
  created: DateTime!
  event: UserEventEvent
  id: String!
  user: User
}

union UserEventEvent = AddToCartEvent | CartUpdatedEvent | CheckoutAbandonedEvent | CheckoutStartedEvent | CheckoutUpdatedEvent | CustomEvent | EmailOpened | EmailSent | FulfillmentAvailableForPickupEvent | FulfillmentCancelledEvent | FulfillmentCreatedEvent | FulfillmentDeliveredEvent | FulfillmentDeliveryAttemptFailureEvent | FulfillmentDeliveryExceptionEvent | FulfillmentInTransitEvent | FulfillmentOutForDeliveryEvent | FulfillmentPendingEvent | JoinEvent | MessageLinkClick | OptOutEvent | OrderCancelledEvent | OrderConfirmedEvent | ProductViewEvent | PurchaseEvent | RefundFailedEvent | RefundSucceededEvent | SubscriptionOptInCompletedEvent | SubscriptionOptedOutEvent | SubscriptionSuppressedEvent | SubscriptionUnsuppressedEvent

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type UserEventsEdge {
  cursor: String!
  node: UserEvent!
}

# Generated from com/attentivemobile/contracts/user/profile/download/Model.proto
type UserFileDownload implements Node {
  company: Company
  completedAt: DateTime
  downloadType: UserFileDownloadType!
  downloadUrl: PresignedUrl
  failureCount: Long
  id: ID!
  listSize: Long
  metadata: String!
  startedAt: DateTime
  status: UserFileDownloadStatus!
  submittedAt: DateTime!
  userFields: [UserFileDownloadField!]!
}

enum UserFileDownloadField {
  USER_FILE_DOWNLOAD_FIELD_EMAIL
  USER_FILE_DOWNLOAD_FIELD_PHONE_NUMBER
  USER_FILE_DOWNLOAD_FIELD_UNKNOWN
}

input UserFileDownloadFilterInput {
  segmentId: Long
  status: UserStatus
}

enum UserFileDownloadStatus {
  USER_FILE_DOWNLOAD_STATUS_CANCELLED
  USER_FILE_DOWNLOAD_STATUS_COMPLETED
  USER_FILE_DOWNLOAD_STATUS_PROCESSING
  USER_FILE_DOWNLOAD_STATUS_SUBMITTED
  USER_FILE_DOWNLOAD_STATUS_UNKNOWN
}

enum UserFileDownloadType {
  USER_FILE_DOWNLOAD_TYPE_AUTO_SUPPRESSED
  USER_FILE_DOWNLOAD_TYPE_SEGMENT
  USER_FILE_DOWNLOAD_TYPE_SUPPRESSED
  USER_FILE_DOWNLOAD_TYPE_UNKNOWN
  USER_FILE_DOWNLOAD_TYPE_USER_SUPPRESSED
}

# User associated with the action. Note that this is a visitor to the site and does not need to be actively subscribed to Attentive.
input UserInput {
  # A user's email we use to fetch subscription eligibility. Example: email=test@gmail.com
  email: String

  # A user's phone number we use to fetch subscription eligibility. Example: phone=+13115552368
  phone: String
}

# Generated from com/attentivemobile/contracts/concierge/service/Contract.proto
type UserOptInStatus {
  isOptedIn: Boolean!
}

input UserPropertiesV2Filter {
  propertyNameFilter: [PropertyNameInput!]
}

# Generated from com/attentivemobile/contracts/composition/MessageAttachment.proto
type UserPropertyAttachment {
  entityToken: String!
}

input UserPropertyAttachmentProperties {
  entityToken: String!
}

# Generated from com/attentivemobile/contracts/user/profile/property/UserPropertyV2.proto
type UserPropertyConnection {
  edges: [UserPropertyEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type UserPropertyDefinition {
  type: UserPropertyDefinitionType
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type UserPropertyDefinitionConnection {
  edges: [UserPropertyDefinitionEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type UserPropertyDefinitionConnectionV2 {
  edges: [UserPropertyDefinitionEdgeV2!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type UserPropertyDefinitionEdge {
  cursor: String!
  node: UserPropertyDefinition!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type UserPropertyDefinitionEdgeV2 {
  cursor: String!
  node: UserPropertyDefinitionV2!
}

union UserPropertyDefinitionType = CustomPropertyDefinition | WellKnownPropertyDefinition

union UserPropertyDefinitionV2 = CustomPropertyDefinition | WellKnownPropertyDefinition

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type UserPropertyDefinitionValue {
  created: DateTime!
  metadata: PropertyValueMetadata
  property: UserPropertyDefinitionValueProperty
  source: String!

  # The installed application that sent this value
  sourceInstalledApplication: InstalledApplication
  value: UserPropertyValue!
}

union UserPropertyDefinitionValueProperty = CustomPropertyDefinition | ThirdPartyPropertyDefinition | WellKnownProperty

# Generated from com/attentivemobile/contracts/user/profile/property/UserPropertyV2.proto
type UserPropertyEdge {
  cursor: String!
  node: UserPropertyV2!
}

enum UserPropertySource {
  USER_PROPERTY_SOURCE_ANY
  USER_PROPERTY_SOURCE_CUSTOM
  USER_PROPERTY_SOURCE_WELL_KNOWN
}

# Generated from com/attentivemobile/contracts/user/profile/property/UserPropertyV2.proto
type UserPropertyV2 {
  originatingEntity: OriginatingEntity!
  propertyId: PropertyDefinitionId!
  timestamp: DateTime!
  user: User
  userPropertyValue: UserPropertyValueV2!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type UserPropertyValue {
  customPropertyValue: UserPropertyValueCustomPropertyValue
}

union UserPropertyValueCustomPropertyValue = BoolPropertyValue | ConversationalState | DatePropertyValue | EnumeratedValue | FloatPropertyValue | ProductRecommendationsResponse | StringPropertyValue

union UserPropertyValueV2 = BooleanPropertyValueV2 | CollectionPropertyValueV2 | DateTimeValueV2 | DateValueV2 | DoubleValueV2 | EnumeratedValueV2 | JsonPropertyValueV2 | LongValueV2 | StringPropertyValueV2

input UserSearchCountsRequest {
  baseAudienceDefinition: BaseAudienceDefinition
  requestId: String!
  searchFormat: UserSearchCountsRequestSearchFormat
}

input UserSearchCountsRequestSearchFormat {
  fullListSearch: FullListSearch
  includeExcludeSearch: IncludeExcludeSearch
  singleSearch: SingleSearch
}

# Generated from com/attentivemobile/contracts/segmentation/service/search/contract.proto
type UserSearchCountsResponse {
  counts: Long!
  requestId: String!
}

input UserSearchDefinition {
  newSegmentId: ID
  segmentId: ID
  templateDefinition: SegmentTemplateInput
}

enum UserSearchEvent {
  USER_SEARCH_EVENT_ADD_TO_CART
  USER_SEARCH_EVENT_CART_UPDATED
  USER_SEARCH_EVENT_CHECKOUT_ABANDONED
  USER_SEARCH_EVENT_CHECKOUT_STARTED
  USER_SEARCH_EVENT_CHECKOUT_UPDATED
  USER_SEARCH_EVENT_CUSTOM_EVENT
  USER_SEARCH_EVENT_FULFILLMENT_AVAILABLE_FOR_PICKUP
  USER_SEARCH_EVENT_FULFILLMENT_CANCELLED
  USER_SEARCH_EVENT_FULFILLMENT_CREATED
  USER_SEARCH_EVENT_FULFILLMENT_DELIVERED
  USER_SEARCH_EVENT_FULFILLMENT_DELIVERY_ATTEMPT_FAILURE
  USER_SEARCH_EVENT_FULFILLMENT_DELIVERY_EXCEPTION
  USER_SEARCH_EVENT_FULFILLMENT_IN_TRANSIT
  USER_SEARCH_EVENT_FULFILLMENT_OUT_FOR_DELIVERY
  USER_SEARCH_EVENT_FULFILLMENT_PENDING
  USER_SEARCH_EVENT_ORDER_CANCELLED
  USER_SEARCH_EVENT_ORDER_CONFIRMED
  USER_SEARCH_EVENT_ORDER_SUBSCRIPTION_CHARGE_FAILED
  USER_SEARCH_EVENT_ORDER_SUBSCRIPTION_CHARGE_SCHEDULED
  USER_SEARCH_EVENT_ORDER_SUBSCRIPTION_CREATED
  USER_SEARCH_EVENT_ORDER_SUBSCRIPTION_SKIPPED
  USER_SEARCH_EVENT_PAGE_VIEW
  USER_SEARCH_EVENT_PRODUCT_VIEW
  USER_SEARCH_EVENT_PURCHASE
  USER_SEARCH_EVENT_REFUND_FAILED
  USER_SEARCH_EVENT_REFUND_SUCCEEDED
  USER_SEARCH_EVENT_SUBSCRIPTION_SUPPRESSED
}

input UserSearchFilter {
  operation: FilterOperation!
  propertyFilters: [SearchPropertyFilter!]
}

enum UserStatus {
  ANY
  AUTO_SUPPRESSED
  ELIGIBLE
  INELIGIBLE
  NOT_YET_JOINED
  OPTED_OUT
  SUPPRESSED
  UNKNOWN
  USER_SUPPRESSED
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type UsersConnection {
  edges: [UserEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/concierge/service/ConversationManager.proto
type UtmConfiguration {
  customUrlParams: [CustomUrlParamsEntry!]!
  isUtmTrackingEnabled: Boolean!
  utmCampaign: String
}

input UtmConfigurationInput {
  customUrlParamsInput: [CustomUrlParamsInputEntry!]
  isUtmTrackingEnabled: Boolean!
  utmCampaign: String
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type UtmParametersEntry {
  key: String!
  value: String!
}

input UtmParametersInput {
  utmParametersInput: [UtmParametersInputEntry!]
}

input UtmParametersInputEntry {
  key: String!
  value: String!
}

# Generated from com/attentivemobile/contracts/messaging/automation/autojourneys/AutoJourneysModel.proto
type UtmSettings {
  customParams: [CustomParamsEntry!]!
  isUtmTrackingEnabled: Boolean!
  utmCampaign: String
}

input UtmSettingsInput {
  customUrlInputParams: [CustomUrlInputParamsEntry!]
  isUtmTrackingEnabled: Boolean!
  utmCampaign: String
}

input ValidateMappingForFileInput {
  companyId: ID!
  fileId: ID!
  locale: LocaleInput
  mappings: [SubscriberUploadFileMappingInput!]
  subscriberUploadJobType: SubscriberUploadJobType
  uploadSubscriptionType: SubscriberUploadSubscriptionType!
}

# Generated from com/attentivemobile/contracts/subscriptions/batch/Contract.proto
type ValidateMappingForFilePayload {
  validationResult: SubscriberUploadValidationResult!
}

# Generated from com/attentivemobile/contracts/geography/Location.proto
type ValidatePostalCodesResponse {
  invalidPostalCodes: [InvalidPostalCode!]!
  postalCodes: [PostalCode!]!
}

# Generated from com/attentivemobile/contracts/concierge/service/Template.proto
type ValidateTemplateFieldResponse {
  valid: Boolean!
  validationType: TemplateFieldValidationType!
}

# Generated from com/attentivemobile/contracts/event/platform/batchjobs/service/Contract.proto
type ValidatedMigratedJob {
  datastreamName: String!
  jobUrl: String!
  newJobId: String!
  restoredPath: String!
  stoppedPath: String!
  successfulCheckpoints: [Checkpoint!]!
}

union ValidationErrorInformation = ConflictingAttachments | EmptyEmailSubject | EmptyMessageBody | InvalidCouponCodeUsage | InvalidLink | InvalidLiquidSyntax | InvalidMacroUsages | MissingEmailMetadata | UndefinedMacro

enum ValidationRuleName {
  VALIDATION_RULE_NAME_UNKNOWN
  VALIDATION_RULE_UNSUBSCRIBE_LINK
}

input ValidationRuleToSkipInput {
  validationRuleName: ValidationRuleName!
}

# Generated from com/attentivemobile/contracts/journeys/api/RevisionStep.proto
type VariantStep {
  childrenSteps: [AdderStep!]!
  stepParameters: VariantStepParameters!
}

# Generated from com/attentivemobile/contracts/journeys/api/RevisionStepParameters.proto
type VariantStepParameters {
  name: String!
  percentage: Float!
  sortOrder: Int!
}

enum Vendor {
  AFTERSHIP
  BLUECORE
  BLUESHIFT_EVENTS
  BRAZE
  BRONTO
  CHEETAHMAIL
  CHEETAHMAIL_SETUSER
  CONVERSIO
  CORDIAL
  CROWD_TWIST
  CUSTOM
  DRIP
  EMARSYS
  FRESHDESK
  GORGIAS
  HUBSPOT
  IBM_WATSON
  ITERABLE
  JUSTUNO
  KLAVIYO
  KLAVIYO_SUBSCRIBE
  KUSTOMER
  LISTRAK
  MAILCHIMP
  MAROPOST
  MAURICES_RESPONSYS
  MPARTICLE
  OMAHA_STEAKS
  OMNISEND
  RAINBOWSHOPS
  REEBOK
  REJOINER
  RESPONSYS
  RETENTION_SCIENCE
  REVOLVE
  SAILTHRU
  SALESFORCE
  SALESFORCE_DATA_EXTENSION
  SALESFORCE_JOURNEY
  SEGMENT_ANALYTICS
  SEGMENT_ANALYTICS_PG_BRANDS
  SHOPIFY
  SILVERPOP
  SIMON
  SPROUTS
  SWELL
  VENDOR_ACTIVECAMPAIGN
  VENDOR_ADOBE
  VENDOR_ADOBE_EXPERIENCE_PLATFORM
  VENDOR_AIRSHIP
  VENDOR_ATTENTIVE_GOOGLE_PRODUCT_CATALOGUE
  VENDOR_AWEBER
  VENDOR_BAZAARVOICE
  VENDOR_BIGCOMMERCE
  VENDOR_BLOOMREACH
  VENDOR_BLOTOUT_WHITELABEL
  VENDOR_BRIERLY
  VENDOR_BUBBLEPAY_SHOPIFY
  VENDOR_CAMPAIGN_MONITOR
  VENDOR_CHATTER
  VENDOR_CONSTANT_CONTACT
  VENDOR_CONVERTKIT
  VENDOR_DOTDIGITAL
  VENDOR_ELOQUA
  VENDOR_EMAIL_OPT_OUT
  VENDOR_EPSILON
  VENDOR_EPSILON_LOYALTY
  VENDOR_EXPONEA
  VENDOR_FACEBOOK_ADS
  VENDOR_FIVETRAN
  VENDOR_GLADLY
  VENDOR_GOOGLE_ADS
  VENDOR_GOOGLE_CONTENT_API
  VENDOR_GOOGLE_TAG_MANAGER
  VENDOR_HELPSCOUT
  VENDOR_INTERCOM
  VENDOR_LIVEPERSON
  VENDOR_LOAD_TEST
  VENDOR_LOYALTYLION
  VENDOR_MAGENTO
  VENDOR_MAILTRAIN
  VENDOR_META_ADS
  VENDOR_MINDBODY
  VENDOR_NARVAR
  VENDOR_OLO
  VENDOR_OMETRIA
  VENDOR_OPTIMIZELY
  VENDOR_OPTIMOVE
  VENDOR_PASSKIT
  VENDOR_PAYTRONIX
  VENDOR_POST_PILOT
  VENDOR_PUNCHH
  VENDOR_REAMAZE
  VENDOR_RECHARGE
  VENDOR_REMARKETY
  VENDOR_RICHPANEL
  VENDOR_SALESFORCE_SERVICE_CLOUD
  VENDOR_SATISFILABS
  VENDOR_SEGMENT
  VENDOR_SENDGRID
  VENDOR_SENDINBLUE
  VENDOR_SENDLANE
  VENDOR_SKECHERS
  VENDOR_SMILE_IO
  VENDOR_SNOWFLAKE_DATA_SHARE
  VENDOR_STAMPED
  VENDOR_TALLY
  VENDOR_TALON_ONE
  VENDOR_TRAVELCLICK
  VENDOR_TREASUREDATA
  VENDOR_WOOCOMMERCE
  VENDOR_WORLD_MARKET
  VENDOR_YESMAIL
  VENDOR_YOTPO
  VENDOR_ZETA_GLOBAL
  VENDOR_ZOHO
  WHATCOUNTS
  ZAIUS
  ZENDESK
}

# Generated from com/attentivemobile/contracts/mobilewalletservice/CouponsService.proto
type VendorCompanyCard implements Node {
  couponDisplayRegex: String!
  created: DateTime!
  failRedirectUrl: String!
  id: ID!
  requireExpiryOnIssue: Boolean!
  updated: DateTime!
  uuid: String!
  vendorCampaignId: String!
  vendorOfferId: String!
}

# Generated from com/attentivemobile/contracts/mobilewalletservice/Contract.proto
type VendorCompanyCardConnection {
  edges: [VendorCompanyCardEdge!]!
  pageInfo: PageInfo!
  totalCount: Int!
}

# Generated from com/attentivemobile/contracts/mobilewalletservice/Contract.proto
type VendorCompanyCardEdge {
  cursor: String!
  node: VendorCompanyCard!
}

# Generated from com/attentivemobile/protobuf/common/EventOrigin.proto
type VendorOrigin {
  vendor: String!
}

# Generated from com/attentivemobile/contracts/journeys/api/JourneyTrigger.proto
type VendorTriggerSource {
  eventType: EventType!
  vendor: Vendor!
}

# Generated from com/attentivemobile/contracts/messaging/automation/admin/Contract.proto
type VerifyAIJourneyMessageSentPayload {
  company: Company
  result: Boolean!
}

# Generated from com/attentivemobile/contracts/messaging/automation/admin/Contract.proto
type VerifyAIJourneyWelcomeSentPayload {
  company: Company
  eventCount: Int!
}

input VerifyChallengeForAdminAccessInput {
  companyId: ID!
  nonce: String!
}

union VerifyChallengeForAdminAccessPayload = DefaultErrorFailure | VerifyChallengeForAdminAccessSuccess

# Generated from com/attentivemobile/contracts/account-service/AuthenticatorContract.proto
# If success is true, the user has passed authorization and we return back a token with new JWT
type VerifyChallengeForAdminAccessSuccess {
  company: Company
  success: Boolean!
  token: String!
}

input VerifyChallengeInput {
  companyId: ID!
  nonce: String!
}

union VerifyChallengePayload = DefaultErrorFailure | VerifyChallengeSuccess

# Generated from com/attentivemobile/contracts/account-service/AuthenticatorContract.proto
# If success is true, the user has passed authorization and we return back a token
type VerifyChallengeSuccess {
  company: Company
  success: Boolean!
  token: String!
}

input VerifyMfaOneTimePasscodeInput {
  mfaToken: String!
  oneTimePasscode: String!
  rememberDevice: Boolean
}

# Generated from com/attentivemobile/contracts/account-service/MFAContract.proto
type VerifyMfaOneTimePasscodePayload {
  deviceId: String
  error: String
  jwt: String
}

input VerifySendingDomainInput {
  companyId: ID!
  domainId: Long!
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type VerifySendingDomainPayload {
  domainValidation: DomainValidationResult!
}

input VerifyServerSideTagDnsRecordsInput {
  companyId: ID!
}

union VerifyServerSideTagDnsRecordsPayload = DefaultErrorFailure | VerifyServerSideTagDnsRecordsSuccess

# Generated from com/attentivemobile/contracts/integrations/serversidetag/Contract.proto
type VerifyServerSideTagDnsRecordsSuccess {
  dnsRecords: [ServerSideTagDnsRecord!]!
  valid: Boolean!
}

input VerifySubdomainInput {
  companyId: ID!
  domainId: Long!
}

# Generated from com/attentivemobile/contracts/emails/management/Contract.proto
type VerifySubdomainPayload {
  domainValidation: DomainValidationResult!
}

# Generated from com/attentivemobile/contracts/asset/library/service/Assets.proto
type Video implements Node {
  company: Company
  createdAt: DateTime!
  displayName: String!
  fileType: String!
  heightPixels: Int!
  id: ID!
  parent: Folder
  sizeBytes: Long!
  storageUrl: String!
  updatedAt: DateTime!
  widthPixels: Int!
}

input VideoLinkPropertiesInput {
  imagePreviewUrl: UrlInput!
  title: String!
  videoPreviewUrl: UrlInput!
}

# Generated from com/attentivemobile/contracts/composition/ComposeMessage.proto
type VideoPreviewLinkProperties {
  imagePreviewUrl: String!
  title: String!
  videoPreviewUrl: String!
}

# Generated from com/attentivemobile/contracts/account-service/Contract.proto
# Viewer details
type Viewer {
  account: Account
  beeFreeAuthToken: BeeFreeAuthToken

  # Installed application details.
  installedApplication: InstalledApplication
  roles(companyId: ID!): GetRolesResponse
  scopes(companyId: ID!): [Scope!]
}

enum VisibilityType {
  ALL
  NONE
  SUPERUSER
  VISIBILITY_TYPE_UNKNOWN
}

enum WarmupCampaignStatus {
  WARMUP_CAMPAIGN_STATUS_SCHEDULED
  WARMUP_CAMPAIGN_STATUS_UNSET
}

# Generated from com/attentivemobile/contracts/emailwarmups/Contract.proto
type WarmupSeriesSizeAndDuration {
  emailSubscriberListSize: Long!
  warmupDays: Long!
  warningMessage: WarmupWarnings!
}

enum WarmupWarnings {
  WARMUP_WARNING_LOW_90_DAYS_ENGAGEMENT
  WARMUP_WARNING_LOW_365_DAYS_ENGAGEMENT
  WARMUP_WARNING_UNSET
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type WebTemplate implements Node {
  callToAction: String!
  description: String!
  id: ID!
  subtitle: String!
  title: String!
  url: String!
}

input WebTemplateInput {
  callToAction: String!
  description: String!
  subtitle: String!
  title: String!
  url: String!
}

# Generated from com/attentivemobile/contracts/externaltokens/Webhooks.proto
# Webhook details.
type Webhook implements Node {
  # Timestamp of when the webhook was disabled.
  disabledAt: DateTime

  # The list of events this webhook is subscribed to.
  events: [String!]!

  # Id of the webhook.
  id: ID!

  # The destination url of the webhook.
  url: String!
}

# Generated from com/attentivemobile/contracts/externaltokens/Contract.proto
type WebhookEventConnection {
  edges: [WebhookEventEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/externaltokens/Contract.proto
type WebhookEventEdge {
  cursor: String!
  node: WebhookEventType!
}

# Generated from com/attentivemobile/contracts/externaltokens/Contract.proto
type WebhookEventType {
  displayName: String!
  internalId: Long!
  name: String!
  type: String!
}

enum WebhookStatus {
  DISABLED
  ENABLED
  INACTIVE
  WEBHOOK_STATUS_UNKNOWN
}

# Generated from com/attentivemobile/contracts/messaging/automation/aijourneys/onboarding/AIJourneyLinkConfiguration.proto
type WelcomeLinkConfiguration {
  linkConfig: WelcomeLinkConfigurationLinkConfig
}

input WelcomeLinkConfigurationInput {
  linkConfig: WelcomeLinkConfigurationInputLinkConfig
}

input WelcomeLinkConfigurationInputLinkConfig {
  couponDisplayLinkInput: CouponDisplayScreenConfigInput
  customLinkInput: CustomLinkConfigInput
  offerLinkInput: OfferLinkConfigInput
}

union WelcomeLinkConfigurationLinkConfig = CouponDisplayScreenConfig | CustomLinkConfig | OfferLinkConfig

# Generated from com/attentivemobile/protobuf/common/targeting/UserProperty.proto
type WellKnownProperty {
  type: WellKnownPropertyType!
}

# Generated from com/attentivemobile/contracts/user/profile/Contract.proto
type WellKnownPropertyDefinition implements Node {
  id: ID!
  metadata: WellKnownPropertyMetadata!
  type: WellKnownPropertyType!
}

input WellKnownPropertyFilter {
  stringCondition: StringCondition
  type: WellKnownPropertyType
}

# Generated from com/attentivemobile/protobuf/common/targeting/UserProperty.proto
type WellKnownPropertyMetadata {
  dataType: CustomPropertyType!
  displayName: String!
  internal: Boolean!
}

enum WellKnownPropertyType {
  WELL_KNOWN_PROPERTY_AREA_CODE
  WELL_KNOWN_PROPERTY_CITY
  WELL_KNOWN_PROPERTY_CONVERSATIONAL_STATE
  WELL_KNOWN_PROPERTY_COUNTRY
  WELL_KNOWN_PROPERTY_DEVICE_TYPE
  WELL_KNOWN_PROPERTY_FIRST_NAME
  WELL_KNOWN_PROPERTY_FULL_NAME
  WELL_KNOWN_PROPERTY_IS_LITIGIOUS
  WELL_KNOWN_PROPERTY_LANGUAGE
  WELL_KNOWN_PROPERTY_LAST_NAME
  WELL_KNOWN_PROPERTY_LOCALE
  WELL_KNOWN_PROPERTY_MAIL_EXCHANGE
  WELL_KNOWN_PROPERTY_OPERATING_SYSTEM
  WELL_KNOWN_PROPERTY_REGION
  WELL_KNOWN_PROPERTY_TIMEZONE
  WELL_KNOWN_PROPERTY_TYPES_BESTSELLER_RECOMMENDATION
  WELL_KNOWN_PROPERTY_TYPES_CATEGORY_BESTSELLER_LAST_PURCHASED
  WELL_KNOWN_PROPERTY_TYPES_ESTIMATED_LIFETIME_VALUE
  WELL_KNOWN_PROPERTY_TYPES_IS_PART_OF_AI_JOURNEY
  WELL_KNOWN_PROPERTY_TYPES_KLAVIYO_LAST_EMAIL_OPEN
  WELL_KNOWN_PROPERTY_TYPES_MAILCHIMP_MEMBER_RATING
  WELL_KNOWN_PROPERTY_TYPES_NOTIFY_WHEN_BACK_IN_STOCK
  WELL_KNOWN_PROPERTY_TYPES_SALESFORCE_SERVICE_CLOUD_CONTACT_ID
  WELL_KNOWN_PROPERTY_TYPES_SMS_CHURN_PROBABILITY
  WELL_KNOWN_PROPERTY_TYPES_UNKNOWN
  WELL_KNOWN_PROPERTY_ZIP
}

# Generated from com/attentivemobile/contracts/workload/management/service/configuration/Contract.proto
type WorkloadConfig {
  cronSchedule: String!
  entryClass: String!
  jar: String!
  jobStates: [WorkloadJobState!]!
  payloadArgs: JSON!
  payloadSparkConfigs: JSON!
  status: String!
  tags: [String!]!
  teamName: String!
  workloadName: String!
}

input WorkloadConfigInput {
  cronSchedule: String
  entryClass: String!
  inputArgs: JSON
  inputSparkConfigs: JSON
  jar: String!
  tags: [String!]
  teamName: String!
  workloadName: String!
}

# Generated from com/attentivemobile/contracts/workload/management/service/runner/Contract.proto
type WorkloadJobState {
  applicationId: String!
  runId: String!
  state: String!
}

# Generated from com/attentivemobile/contracts/workload/management/service/runner/Contract.proto
type WorkloadRun {
  appId: String!
  appTags: [String!]!
  duration: Duration!
  endTimeUtc: DateTime!
  finalStatus: String!
  runName: String!
  startTimeUtc: DateTime!
  state: String!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type ZendeskHelpCenterArticle implements Node {
  id: ID!
  snippet: String!
  title: String!
  url: String!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type ZendeskHelpCenterArticleConnection {
  edges: [ZendeskHelpCenterArticleEdge!]!
  pageInfo: PageInfo!
}

# Generated from com/attentivemobile/contracts/notification/service/Contract.proto
type ZendeskHelpCenterArticleEdge {
  cursor: String!
  node: ZendeskHelpCenterArticle!
}

enum ZendeskJwtType {
  ZENDESK_JWT_TYPE_MESSAGING
  ZENDESK_JWT_TYPE_SSO
  ZENDESK_JWT_TYPE_UNKNOWN
}
`;

